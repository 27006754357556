import React from 'react'
import styled from 'styled-components'
import { UniParagraphAnchorStyles } from './UniParagraph'

interface Properties {
  summary?: string
  children: React.ReactNode
}

export const UniDetails = ({ summary, children }: Properties) => {
  return (
    <details>
      <Summary>{summary}</Summary>
      {children}
    </details>
  )
}

const Summary = styled.summary`
  display: list-item;
  ${UniParagraphAnchorStyles}
  cursor: pointer;
`
