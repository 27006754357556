import React, { useState, useEffect, useContext } from 'react'
import { useTranslation, Trans } from 'react-i18next'
import styled from 'styled-components'
import k from '../../../i18n/keys'
import VisuallyHidden from '../VisuallyHidden'
import { UniButton } from '../uni/UniButton'
import { UniSelectWithLabel } from '../uni/UniSelectWithLabel'
import { UniInputWithLabel } from '../uni/UniInputWithLabel'
import { UniParagraph } from '../uni/UniParagraph'
import { UniBanner } from '../uni/UniBanner'
import { Actions } from '../uni/UniForm'
import { WindowWidthContext } from '../../../WindowWidthContext'

interface Properties {
  id: string
  className?: string
  previousPage: () => void
  canPreviousPage: boolean
  pageIndex: number
  gotoPage: (updater: number | ((pageIndex: number) => number)) => void
  pageOptions: number[]
  pageSize: number
  setPageSize: (pageSize: number) => void
  nextPage: () => void
  canNextPage: boolean
}

const Pagination = ({
  id,
  className,
  previousPage,
  canPreviousPage,
  pageIndex,
  gotoPage,
  pageOptions,
  pageSize,
  setPageSize,
  nextPage,
  canNextPage
}: Properties) => {
  const { t } = useTranslation()
  const [index, setIndex] = useState(pageIndex + 1)
  const { isPhoneOrSmaller } = useContext(WindowWidthContext)

  useEffect(() => {
    setIndex(pageIndex + 1)
  }, [pageIndex])

  return (
    <Nav aria-label={t(k.PAGINATION)} className={`pagination ${className}`}>
      <UniBanner fullWidth border={false} paddingBlock={false}>
        <Actions noPadTop firstToStart lastToEnd noWrap>
          <UniButton
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
            className={'previousButton'}
            visuallyHideText={isPhoneOrSmaller}
            icon="left"
            small={isPhoneOrSmaller}
          >
            <Trans
              t={t}
              i18nKey={k.PREVIOUS_PAGE}
              components={[<VisuallyHidden />]} //eslint-disable-line react/jsx-key
            />
          </UniButton>
          <PageStepper>
            <UniParagraph small noMargin aria-hidden="true">
              {t(k.PAGE)}
            </UniParagraph>
            <PageIndexInput
              id={`${id}-page-index`}
              className={'page-index'}
              type="number"
              label={t(k.GO_TO_PAGE)}
              visuallyHideLabel
              value={`${index}`}
              onChange={(value) => {
                const page = value ? Number(value) - 1 : 0
                gotoPage(page)
              }}
            />
            <UniParagraph small noMargin>
              <Trans
                t={t}
                i18nKey={k.PAGE_OF}
                components={[<VisuallyHidden />, <span />]} //eslint-disable-line react/jsx-key
                values={{ currentPage: pageIndex + 1, totalPages: pageOptions.length }}
              />
            </UniParagraph>
          </PageStepper>
          {!isPhoneOrSmaller ? (
            <PageSelector>
              <UniSelectWithLabel
                id={`${id}-page-size`}
                className={'page-size-options'}
                label={t(k.AMOUNT_OF_ITEMS_PER_PAGE)}
                visuallyHideLabel
                value={`${pageSize}`}
                onChange={(value) => {
                  setPageSize(Number(value))
                }}
                options={[25, 50, 100, 200, 300].map((pageSize) => ({
                  value: `${pageSize}`,
                  label: `${pageSize} ${t(k.ROWS)}`
                }))}
              />
            </PageSelector>
          ) : null}
          <UniButton
            onClick={() => nextPage()}
            disabled={!canNextPage}
            className={'nextButton'}
            visuallyHideText={isPhoneOrSmaller}
            icon="right"
            small={isPhoneOrSmaller}
          >
            <Trans
              t={t}
              i18nKey={k.NEXT_PAGE}
              components={[<VisuallyHidden />]} //eslint-disable-line react/jsx-key
            />
          </UniButton>
        </Actions>
      </UniBanner>
    </Nav>
  )
}

const Nav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-flow: row wrap;
  padding: 3px;
  padding-top: 0.7em;
  border-top: 2px solid rgba(0, 0, 0, 0.05);
`

const PageStepper = styled.div`
  flex: auto 0 1;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  gap: 5px;
  padding: 5px;
`

const PageIndexInput = styled(UniInputWithLabel)`
  width: 72px;
  text-align: center;
`

const PageSelector = styled.div`
  flex: auto 0 1;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  padding: 5px;
`

export default Pagination
