import { withTranslation } from 'react-i18next'
import k from '../../i18n/keys'
import React from 'react'
import Card, { CardNoContentMessage, CardSubHeading } from '../common/Card'
import TeachingCourseOrWorkshopList from './TeachingCourseOrWorkshopList'
import CreateTeachingButton from './CreateTeachingButton'
import ThesisInstructorList from './ThesisInstructorList'

class TeachingCard extends React.Component {
  render() {
    const { t } = this.props
    return (
      <Card heading={t(k.TEACHING)} cardAction={this._getCardAction()} modifier="teaching">
        <CardSubHeading>{t(k.COURSES_AND_WORKSHOPS)}</CardSubHeading>
        <TeachingCourseOrWorkshopList
          teachings={this.props.doctoralCandidate.teachings}
          doctoralCandidateId={this.props.doctoralCandidate.id}
          linkTarget={`${this.props.baseUrl}teachings/`}
          noContentMessage={this._getNoCoursesOrWorkshopsContentMessage()}
          white
        />

        <CardSubHeading>{t(k.THESIS_INSTRUCTION)}</CardSubHeading>
        <ThesisInstructorList
          teachings={this.props.doctoralCandidate.teachings}
          doctoralCandidateId={this.props.doctoralCandidate.id}
          linkTarget={`${this.props.baseUrl}teachings/`}
          noContentMessage={this._getNoThesisInstructionsContentMessage()}
          white
        />
      </Card>
    )
  }

  _getCardAction() {
    if (this.props.showCreateButton) {
      return <CreateTeachingButton />
    }
    return null
  }

  _getNoCoursesOrWorkshopsContentMessage() {
    const { t, noContentMessageRole } = this.props
    return <CardNoContentMessage text={t(k.NO_COURSES_OR_WORKSHOPS_IN_PLA)} role={noContentMessageRole} />
  }

  _getNoThesisInstructionsContentMessage() {
    const { t, noContentMessageRole } = this.props
    return <CardNoContentMessage text={t(k.NO_THESIS_INSTRUCTIONS_IN_PLAN)} role={noContentMessageRole} />
  }
}

export default withTranslation()(TeachingCard)
