import React from 'react'

export function getMonths(firstMonth, lastMonth) {
  const months = []
  const currentMonth = firstMonth.clone()
  while (currentMonth.isBefore(lastMonth) || currentMonth.isSame(lastMonth)) {
    months.push(currentMonth.clone())
    currentMonth.add(1, 'month')
  }
  return months
}

export function getMonthOption(monthMoment) {
  const monthFormattedForDataStoring = monthMoment.format('YYYY-MM')
  return (
    <option key={monthFormattedForDataStoring} value={monthFormattedForDataStoring}>
      {monthMoment.format('YYYY MMMM')}
    </option>
  )
}

export function getMonthObject(monthMoment) {
  return {
    value: monthMoment.format('YYYY-MM'),
    label: monthMoment.format('YYYY MMMM')
  }
}
