import React from 'react'
import { useTranslation, withTranslation } from 'react-i18next'
import isObject from 'lodash/isObject'
import isString from 'lodash/isString'
import k from '../../../i18n/keys'
import Heading from './Heading'
import Table from './Table'

import {
  reportsToRows,
  createCell,
  formatTimePeriod,
  FreeTextRowsHelper,
  hasFreeTextButNoTableContent
} from './Helpers'

const titleClassModifier = 'international-activity-title'
const locationClassModifier = 'international-activity-location'
const datesClassModifier = 'international-activity-dates'

const internationalActivitiesIsString = ({ internationalActivities }) => isString(internationalActivities)
const internationalActivitiesIsObject = ({ internationalActivities }) => isObject(internationalActivities)

const getCells = (internationalActivity) => [
  createCell(internationalActivity.name, titleClassModifier),
  createCell(internationalActivity.location, locationClassModifier),
  createCell(formatTimePeriod(internationalActivity.beginsAt, internationalActivity.endsAt), datesClassModifier)
]

const headings = (t) => [
  createCell(t(k.NAME), titleClassModifier),
  createCell(t(k.LOCATION), locationClassModifier),
  createCell(t(k.DATES), datesClassModifier)
]

const InternationalActivities = (props) => {
  const { t } = useTranslation()
  return (
    <div id="cumulative-report__international-activities">
      <Heading text={t(k.CONFERENCES_AND_MOBILITY1)} />
      <InternationalActivitiesFreeTextRows reports={props.reports} />
      <InternationalActivitiesTable reports={props.reports} />
    </div>
  )
}

const InternationalActivitiesFreeTextRows = ({ reports }) => {
  return (
    <FreeTextRowsHelper
      reports={reports}
      reportFieldName="internationalActivities"
      filterFunction={internationalActivitiesIsString}
    />
  )
}

const InternationalActivitiesTable = ({ reports }) => {
  const { t } = useTranslation()
  const filteredReports = reports.filter(internationalActivitiesIsObject)
  const rows = reportsToRows(filteredReports, (report) => report.internationalActivities, getCells)
  return (
    <Table
      headings={headings(t)}
      rows={rows}
      label="International activities"
      noContentMessage={
        hasFreeTextButNoTableContent(reports, internationalActivitiesIsString, internationalActivitiesIsObject)
          ? ''
          : undefined
      }
    />
  )
}

export default withTranslation()(InternationalActivities)
