import styled, { css } from 'styled-components'
import { GRAYSCALE_DARK_TEXT, ADDITIONAL_YELLOW, BRAND_MAIN, GRAYSCALE_MEDIUM_TEXT } from './colors'
import { DEFAULT_FONT } from './typography'
import { fromTabletUp } from '../../styledUtils'

export const UniParagraphAnchorStyles = css`
  ${DEFAULT_FONT}
  appearance: none;
  border: none;
  background: none;
  padding: 0;
  color: ${BRAND_MAIN};
  font-size: 15px;
  line-height: 22px;
  font-weight: 600;
  transition-duration: 0.1s;
  transition-property: all;
  text-decoration: underline;
  text-underline-offset: 2px;
  text-decoration-thickness: 1px;

  ${fromTabletUp(css`
    font-size: 16px;
    line-height: 24px;
  `)}

  ${fromTabletUp(css`
    font-size: 16px;
    line-height: 24px;
  `)}

  &:hover {
    text-decoration-thickness: 2px;
  }

  &:focus-visible {
    outline: solid 2px ${ADDITIONAL_YELLOW};
    outline-offset: 2px;
  }

  ${fromTabletUp(css`
    font-size: 16px;
  `)}
`

export const UniParagraphStyles = css`
  color: ${GRAYSCALE_DARK_TEXT};
  font-size: 15px;
  line-height: 22px;
  transition-duration: 0.1s;
  transition-property: all;

  ${DEFAULT_FONT}

  ${fromTabletUp(css`
    font-size: 16px;
    line-height: 24px;
  `)}

  a:not(.uni-button) {
    ${UniParagraphAnchorStyles}
  }
`

export const UniParagraph = styled.p<{
  muted?: boolean
  small?: boolean
  semiBold?: boolean
  textAlignEnd?: boolean
  wide?: boolean
  narrow?: boolean
  truncated?: boolean
  noMargin?: boolean
  noMarginTop?: boolean
  noMarginBottom?: boolean
}>`
  ${UniParagraphStyles}
  margin: 16px 0;

  ${fromTabletUp(css`
    margin: 24px 0;
  `)}

  ${(props) =>
    props.muted &&
    css`
      color: ${GRAYSCALE_MEDIUM_TEXT};
    `}

  ${(props) =>
    props.semiBold &&
    css`
      font-weight: 600;
    `}

  ${({ textAlignEnd = false }) =>
    textAlignEnd &&
    css`
      text-align: end;
    `}

  ${(props) =>
    props.noMargin &&
    css`
      margin: 0;

      ${fromTabletUp(css`
        margin: 0;
      `)}
    `}


  ${(props) =>
    props.noMarginTop &&
    css`
      margin-top: 0;

      ${fromTabletUp(css`
        margin-top: 0;
      `)}
    `}

  ${(props) =>
    props.noMarginBottom &&
    css`
      margin-bottom: 0;

      ${fromTabletUp(css`
        margin-bottom: 0;
      `)}
    `}

  ${(props) =>
    props.small &&
    css`
      font-size: 14px;
      line-height: 20px;

      ${fromTabletUp(css`
        font-size: 15px;
        line-height: 22px;
      `)}

      a {
        font-size: 14px;
        line-height: 20px;

        ${fromTabletUp(css`
          font-size: 15px;
          line-height: 22px;
        `)}
      }
    `}

  ${({ wide }) =>
    wide &&
    css`
      max-width: 800px;
    `}

  ${({ narrow }) =>
    narrow &&
    css`
      max-width: 400px;
    `}


  ${({ truncated }) =>
    truncated &&
    css`
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    `}
`
