import { useTranslation } from 'react-i18next'
import k from '../../i18n/keys'
import React from 'react'
import { UniTextareaWithLabel } from './uni/UniTextareaWithLabel'
import { UniParagraph } from './uni/UniParagraph'

interface EditMarkdownProps {
  value: string
  label?: string
  onChange: (value: string) => void
  id?: string
  placeholder?: string
  rows?: number
  visuallyHideLabel?: boolean
  autoFocus?: boolean
  required?: boolean
  isValid?: boolean
}

const EditMarkdown = ({
  value,
  label,
  onChange,
  id,
  placeholder,
  rows,
  visuallyHideLabel = false,
  autoFocus = true,
  required,
  isValid
}: EditMarkdownProps) => {
  const { t } = useTranslation()

  return (
    <div>
      <UniTextareaWithLabel
        required={required}
        label={label}
        id={id}
        value={value}
        isValid={isValid}
        onChange={(updatedText: string) => onChange(updatedText)}
        autoFocus={autoFocus}
        placeholder={placeholder}
        validationHintAfter={
          <UniParagraph noMargin>
            {t(k.YOU_MAY_USE)}{' '}
            <a href="https://guides.github.com/features/mastering-markdown/" target="_blank" rel="noopener noreferrer">
              {t(k.MARKDOWN)}
            </a>
            {`:`} <code>{t(k.ITALICS)}</code> <code>{t(k.BOLD)}</code> <code>{t(k.LINKS_HTTPS)}</code>{' '}
            <code>{t(k.HEADINGS)}</code> <code>{t(k.LISTS)}</code> <code>{`![images](/images/hy-logo.png)`}</code>{' '}
            {t(k.AND_MORE)}
          </UniParagraph>
        }
        visuallyHideLabel={visuallyHideLabel}
      />
    </div>
  )
}

export default EditMarkdown
