import React, { PropsWithChildren } from 'react'
import styled, { css } from 'styled-components'
import { HyIcon } from '../HyIcon'

interface PageAlertProps {
  className?: string
  warning?: boolean
  hint?: boolean
}

export const PageAlert = ({
  className,
  warning = false,
  hint = false,
  children
}: PropsWithChildren<PageAlertProps>) => (
  <Container className={className} warning={warning} hint={hint}>
    {hint && <HyIcon info />} <span>{children}</span>
  </Container>
)

const Container = styled.div<{ warning: boolean; hint: boolean }>`
  border: 1px solid #a94442;
  border-radius: 3px;
  color: #a94442;
  font-size: 16px;
  padding: 10px 15px;

  ${(props) =>
    props.warning &&
    css`
      border-color: #8a6d3b;
      color: #8a6d3b;
    `}

  ${(props) =>
    props.hint &&
    css`
      border-color: #ddd;
      color: #767676;
      display: flex;
      font-size: 14px;
      column-gap: 6px;
    `}
`
