import React from 'react'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'
import k from '../../i18n/keys'
import { UniContainer } from './uni/layout'
import { GRAYSCALE_DARK } from './uni/colors'
import { fromTabletUp } from '../styledUtils'
import LanguageSelect from './LanguageSelect'
import { AvailableLanguage } from '../../i18n/init'
import { UniParagraphStyles } from './uni/UniParagraph'
import { UniShortcut } from './uni/UniShortcut'
const logoUrl = new URL('../../../assets/logo.png', import.meta.url)

const translatedPrivacyPolicyLinks: { [key in AvailableLanguage]: string } = {
  fi: 'https://www.helsinki.fi/fi/tutustu-meihin/tietojen-kasittely-yliopistolla/tietosuoja',
  sv: 'https://www.helsinki.fi/sv/om-oss/behandling-av-uppgifter-vid-universitetet/dataskydd',
  en: 'https://www.helsinki.fi/en/about-us/processing-data-university/data-protection'
}

const Footer = () => {
  const { t, i18n } = useTranslation()
  const translatedPrivacyPolicyLink = translatedPrivacyPolicyLinks[i18n.resolvedLanguage as AvailableLanguage]
  return (
    <AlignBottom id="Footer">
      <UniContainer wide>
        <UniContainer wide paddingTop paddingBottom borderTop paddingInline={false}>
          <Flex>
            <LogoColumn>
              <Logo src={logoUrl.toString()} alt={t(k.LOGO_OF_UNIVERSITY_OF_HELSINKI)} />
            </LogoColumn>
            <InfoColumn>
              <SiteName>
                <ThessaText>{t(k.THESSA)}</ThessaText>
                <SiteUrl>{'thessa.helsinki.fi'}</SiteUrl>
              </SiteName>
              <FooterLanguageSelect id={`footer-language-select`} />
            </InfoColumn>
            <ShortcutColumn>
              <UniShortcut
                links={[
                  { link: translatedPrivacyPolicyLink, title: t(k.PRIVACY_POLICY), external: true },
                  { link: '/accessibility-statement', title: t(k.ACCESSIBILITY_STATEMENT) },
                  { link: '/contact', title: t(k.ASK_FOR_HELP_AND_GIVE_FEEDBACK) },
                  { link: '/about', title: t(k.ABOUT_THESSA) },
                  { link: '/sitemap', title: t(k.SITEMAP) }
                ]}
                grid
              />
            </ShortcutColumn>
          </Flex>
        </UniContainer>
      </UniContainer>
    </AlignBottom>
  )
}

const AlignBottom = styled.footer`
  margin-block-start: auto;
`

const Flex = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px;

  ${fromTabletUp(css`
    flex-flow: row nowrap;
    justify-content: space-between;
  `)}
`

const LogoColumn = styled.div``

const Logo = styled.img`
  width: 3.75em;
  height: 3.75em;
  filter: invert(100%);
`

const InfoColumn = styled.div`
  margin-right: auto;
  padding-right: 48px;
`

const SiteName = styled.div`
  ${UniParagraphStyles}
  font-weight: 700;
  letter-spacing: -0.6px;
  text-transform: uppercase;
  flex: 1 1 100%;
  padding-top: 2px;

  ${fromTabletUp(css`
    flex: 1 1 auto;
  `)}
`

const SiteUrl = styled.div`
  font-size: 15px;
  line-height: 14px;
  padding-top: 4px;
`

const ThessaText = styled.span`
  font-size: 18px;
`

const FooterLanguageSelect = styled(LanguageSelect)`
  position: relative;
  padding-top: 18px;
  margin-top: 18px;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 1px;
    width: 36px;
    background: ${GRAYSCALE_DARK};
  }
`

const ShortcutColumn = styled.div`
  flex: 1 1 auto;

  ${fromTabletUp(css`
    flex: 0 1 50%;
  `)}
`

export default Footer
