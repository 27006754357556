import { Bus } from 'baconjs'

export const createCourse = new Bus()
export const updateCourse = new Bus()
export const deleteCourse = new Bus()
export const createPublication = new Bus()
export const updatePublication = new Bus()
export const deletePublication = new Bus()
export const createConference = new Bus()
export const updateConference = new Bus()
export const createInternationalActivity = new Bus()
export const updateInternationalActivity = new Bus()
export const deleteInternationalActivity = new Bus()
export const createSalary = new Bus()
export const updateSalary = new Bus()
export const deleteSalary = new Bus()
export const createGrant = new Bus()
export const updateGrant = new Bus()
export const deleteGrant = new Bus()
export const createTeaching = new Bus()
export const updateTeaching = new Bus()
export const deleteTeaching = new Bus()
export const updateNotes = new Bus()
