import React from 'react'
import { withTranslation } from 'react-i18next'
import assign from 'lodash/assign'
import filter from 'lodash/filter'
import reduce from 'lodash/reduce'
import sortBy from 'lodash/sortBy'
import moment from 'moment'
import { inject, observer } from 'mobx-react'
import PropTypes from 'prop-types'
import k from '../../i18n/keys'
import ThessaList from '../common/ThessaList'
import { ITEM_TYPE } from '../highlight/constants'

@inject('HighlightStore')
@observer
class CourseList extends React.Component {
  static propTypes = {
    doctoralCandidateId: PropTypes.number.isRequired
  }

  render() {
    const { HighlightStore, doctoralCandidateId, white, t } = this.props
    const doctoralCandidateHighlights = HighlightStore.getDoctoralCandidateHighlights(doctoralCandidateId)
    const columns = {
      title: t(k.COURSE_TITLE),
      credits: t(k.CREDITS),
      isCompleted: t(k.DONE1),
      completionDate: t(k.COMPLETED)
    }
    const sortedCourses = sortBy(this.props.courses, ({ completedAt }) => completedAt)
    const coursesForRendering = sortedCourses.map(({ id, title, credits, isCompleted, completedAt }) => {
      const completionAtDateTimeFormatted = moment(completedAt, 'YYYY-MM').format('YYYY-MM')
      const completionAtFormatted = moment(completedAt, 'YYYY-MM-DD').format('YYYY MMM')
      const courseForRendering = {
        id,
        title,
        credits,
        isCompleted: isCompleted ? (
          <span className="glyphicon glyphicon-ok text-success" role="img" aria-label={t(k.COURSE_IS_COMPLETED)} />
        ) : null,
        completionDate: <time dateTime={completionAtDateTimeFormatted}>{completionAtFormatted}</time>,
        newOrChanged: doctoralCandidateHighlights && doctoralCandidateHighlights[ITEM_TYPE.COURSE][id]
      }

      if (this.props.linkTarget) {
        assign(courseForRendering, {
          linkTarget: `${this.props.linkTarget}${id}`
        })
      }

      return courseForRendering
    })
    const totalPlannedCourseCredits = countTotalCredits(sortedCourses)
    const completedCourses = filter(sortedCourses, ({ isCompleted }) => isCompleted)
    const totalCompletedCourseCredits = countTotalCredits(completedCourses)
    const totalCompletedCourseCreditsFormatted = `${totalCompletedCourseCredits} / ${totalPlannedCourseCredits}`
    const totalCompletedCourseCreditsLabel = `${totalCompletedCourseCreditsFormatted} - ${t(k.TOTAL_AMOUNT_OF_CREDITS)}`
    const totalRow = {
      credits: <span aria-label={totalCompletedCourseCreditsLabel}>{totalCompletedCourseCreditsFormatted}</span>
    }

    return (
      <ThessaList
        id="doctoral-candidate-overview__courses"
        label={t(k.COURSES)}
        columns={columns}
        rows={coursesForRendering}
        noContentMessage={this.props.noContentMessage}
        totalRow={totalRow}
        white={white}
        defaultSortByID="completionDate"
        defaultSortByDesc={true}
      />
    )
  }
}

const countTotalCredits = (courses) =>
  reduce(courses, (accumulator, course) => accumulator + course.credits, 0).toFixed(1)

export default withTranslation()(CourseList)
