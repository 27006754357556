import React from 'react'
import { inject, observer } from 'mobx-react'
import moment from 'moment'
import sortBy from 'lodash/sortBy'
import { PhdProjectList } from '../phd-project/PhdProjectList'
import { TextCell } from '../phd-project/common/Table'
import { Description } from '../phd-project/common/Details'
import { IconDetails } from '../common/IconDetails'
import { DATE_FORMAT } from '../../dateConstants'
import { ConferenceAndMobilityForm } from './form/ConferenceAndMobilityForm'
import { formattedConferenceParticipation, formattedType } from './constants'
import { ConferenceOrMobilityType, getConferenceParticipation } from './types'
import HighlightStore from '../highlight/HighlightStore'
import { FormComponent } from '../phd-project/types'
import { usePhdProject } from '../doctoral-candidate-overview/phd-project-context/PhdProjectContextProvider'
import { usePhdProjectActions } from '../doctoral-candidate-overview/phd-project-context/usePhdProjectActions'
import { Field } from '../doctoral-candidate-overview/types'
import InternationalActivity from '../../models/InternationalActivity'
import k from '../../i18n/keys'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Heading3 } from '../phd-project/common/Heading3'

interface ConferenceAndMobilityListProps {
  HighlightStore: HighlightStore
}

export const ConferenceAndMobilityList = inject('HighlightStore')(
  observer(({ HighlightStore }: ConferenceAndMobilityListProps) => {
    const { t } = useTranslation()
    const phdProject = usePhdProject()
    const actions = usePhdProjectActions()
    const doctoralCandidateHighlights = HighlightStore.getDoctoralCandidateHighlights(phdProject.doctoralCandidateId)
    const conferenceAndMobilityHighlights =
      (doctoralCandidateHighlights && doctoralCandidateHighlights.internationalActivities) || {}
    return (
      <Container>
        <Heading3>{t(k.CONFERENCES_AND_MOBILITY)}</Heading3>
        <PhdProjectList<InternationalActivity>
          columns={{
            titles: [t(k.NAME), t(k.LOCATION), t(k.DATES)],
            phone: '1fr 1fr 1fr',
            tablet: '1fr 1fr 1fr',
            desktop: '1fr 1fr 1fr'
          }}
          items={sortBy(phdProject[Field.InternationalActivities], [
            'beginsAt',
            'endsAt',
            (item) => item.name.toLocaleLowerCase(),
            (item) => item.location.toLocaleLowerCase()
          ])}
          Row={Row}
          Details={Details}
          Form={ConferenceAndMobilityForm as FormComponent<InternationalActivity>}
          createButtonText={t(k.ADD_CONFERENCE_OR_MOBILITY)}
          onCreate={async (internationalActivity) => {
            const createdInternationalActivity = await actions.createInternationalActivity(internationalActivity)
            return createdInternationalActivity.id
          }}
          onEdit={async (internationalActivity: InternationalActivity) => {
            await actions.editInternationalActivity(internationalActivity)
          }}
          onDelete={async (internationalActivity: InternationalActivity) => {
            await actions.deleteInternationalActivity(internationalActivity.id)
          }}
          highlights={conferenceAndMobilityHighlights}
          noContentMessage={t(k.NO_CONFERENCES_OR_MOBILITY_PLA)}
        />
      </Container>
    )
  })
)

const Container = styled.div`
  margin: 20px 0 30px 0;
`

interface RowProps {
  item: InternationalActivity
  highlight: unknown
}

const Row = observer(({ item, highlight }: RowProps) => (
  <>
    <TextCell heading className="conference-and-mobility-list__name">
      {highlight}
      {item.name}
    </TextCell>
    <TextCell className="conference-and-mobility-list__location">{item.location}</TextCell>
    <TextCell>
      {moment(item.beginsAt, DATE_FORMAT.DEFAULT).format(DATE_FORMAT.DISPLAY)}
      {`–`}
      {moment(item.endsAt, DATE_FORMAT.DEFAULT).format(DATE_FORMAT.DISPLAY)}
    </TextCell>
  </>
))

interface DetailsProps {
  item: InternationalActivity
}

const Details = observer(({ item }: DetailsProps) => {
  const iconDetailsRows = [{ icon: 'briefcase', text: formattedType[item.type] }]
  if (item.type === ConferenceOrMobilityType.CONFERENCE) {
    iconDetailsRows.push({ icon: 'star', text: getFormattedParticipationTypeAndRole(item) })
  }
  return (
    <>
      <IconDetails id={`conference-and-mobility-list__description--${item.id}`} rows={iconDetailsRows} />
      <Description label="Description" text={item.description} className="conference-and-mobility-list__description" />
    </>
  )
})

function getFormattedParticipationTypeAndRole(internationalActivity: InternationalActivity) {
  const conferenceParticipation = getConferenceParticipation(internationalActivity)
  return formattedConferenceParticipation[conferenceParticipation] || 'N/A'
}
