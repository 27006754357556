import React from 'react'
import EditButton from './EditButton'
import Title from './Title'
import { getDoctoralCandidateId, hasRole } from '../../stores/AuthenticationStore'
import './Container.scss'
import { Role } from '../../stores/role'
import { UniContainer } from '../common/uni/layout'

const Container = ({ doctoralCandidate, onEdit, title }) => {
  return (
    <UniContainer wide paddingInline={false} paddingBottom>
      {title && <Title title={title} />}
      {isAuthorizedDoctoralCandidate(doctoralCandidate) && <EditButton onEdit={onEdit} title={title} />}
    </UniContainer>
  )
}

const isAuthorizedDoctoralCandidate = (doctoralCandidate) =>
  doctoralCandidate && hasRole(Role.DOCTORAL_CANDIDATE) && getDoctoralCandidateId() === doctoralCandidate.id

export default Container
