import { useTranslation } from 'react-i18next'
import k from '../../i18n/keys'
import React, { Fragment } from 'react'
import sortBy from 'lodash/sortBy'
import { inject, observer } from 'mobx-react'
import EqualColumnsOnDesktop from '../layout/EqualColumnsOnDesktop'
import './SupervisorsAndThesisCommitteeOverview.scss'
import { HIGHLIGHT, ITEM_TYPE } from '../highlight/constants'
import HighlightBalloon from '../common/HighlightBalloon'
import { formatRole } from '../../stores/AuthenticationStore'
import { Link } from 'react-router'
import PageBlock from '../common/page/PageBlock'
import PageContent from '../common/page/PageContent'
import { UniButton } from '../common/uni/UniButton'
import { UniBanner } from '../common/uni/UniBanner'
import { UniParagraph, UniParagraphStyles } from '../common/uni/UniParagraph'
import { Actions } from '../common/uni/UniForm'
import { WithRole } from '../phd-project/WithRole'
import UniTag from '../common/uni/UniTag'
import VisuallyHidden from '../common/VisuallyHidden'
import styled from 'styled-components'

const SupervisorsAndThesisCommitteeOverview = inject('HighlightStore')(
  observer(({ supervisorRelationships, thesisCommitteeMembers, doctoralCandidateId, HighlightStore }) => {
    const { t } = useTranslation()
    const highlights = HighlightStore.getDoctoralCandidateHighlights(doctoralCandidateId)

    return (
      <div className="supervisors-and-thesis-committee-overview">
        <PageBlock wide padBottom>
          <PageContent paddingInline={false} border={false}>
            <EqualColumnsOnDesktop>
              <Fragment>
                <h3 id="supervisors">{t(k.SUPERVISORS)}</h3>
                <Table className="table" aria-labelledby="supervisors">
                  <thead>
                    <tr>
                      <TableHead>
                        <VisuallyHidden>{t(k.SUPERVISORS)}</VisuallyHidden>
                      </TableHead>
                      <TableHead>
                        <VisuallyHidden>{t(k.ROLE)}</VisuallyHidden>
                      </TableHead>
                      <TableHead>
                        <VisuallyHidden>{t(k.EMAIL)}</VisuallyHidden>
                      </TableHead>
                    </tr>
                  </thead>
                  <tbody>{getSupervisorRows(supervisorRelationships, highlights, t)}</tbody>
                </Table>
                <UniBanner paddingBlock={false} variant="info">
                  <UniParagraph>
                    {t(k.SUPERVISOR_RELATIONSHIP_OVERVIEW.IF_MISSING)}{' '}
                    <a href={t(k.SUPERVISOR_RELATIONSHIP_OVERVIEW.LINK)}>
                      {t(k.SUPERVISOR_RELATIONSHIP_OVERVIEW.IF_MISSING_THEN_CONTACT)}
                    </a>
                    {'. '}
                    {t(k.SUPERVISOR_RELATIONSHIP_OVERVIEW.IF_CHANGED)}{' '}
                    <a href={t(k.SUPERVISOR_RELATIONSHIP_OVERVIEW.LINK)}>
                      {t(k.SUPERVISOR_RELATIONSHIP_OVERVIEW.IF_CHANGED_THEN_CONTACT)}
                    </a>
                    {'.'}
                  </UniParagraph>
                </UniBanner>
              </Fragment>
              <Fragment>
                <h3 id="thesis-committee">{t(k.THESIS_COMMITTEE.TITLE)}</h3>
                <Table className="table" aria-labelledby="thesis-committee">
                  <thead>
                    <tr>
                      <TableHead>
                        <VisuallyHidden>{t(k.THESIS_COMMITTEE_MEMBER)}</VisuallyHidden>
                      </TableHead>
                      <TableHead>
                        <VisuallyHidden>{t(k.EMAIL)}</VisuallyHidden>
                      </TableHead>
                    </tr>
                  </thead>
                  <tbody>{getThesisCommitteeMemberRows(thesisCommitteeMembers, highlights, t)}</tbody>
                </Table>
                <WithRole doctoralCandidate>
                  <Actions lastToEnd>
                    <UniButton as={Link} to="/profile/thesis-committee" secondary icon="edit">
                      {t(k.EDIT_THESIS_COMMITTEE)}
                    </UniButton>
                  </Actions>
                </WithRole>
              </Fragment>
            </EqualColumnsOnDesktop>
          </PageContent>
        </PageBlock>
      </div>
    )
  })
)

const getSupervisorRows = (supervisorRelationships, highlights, t) =>
  sortBy(supervisorRelationships, 'name').map(({ id, name, email, roles }) => {
    const isNewSupervisor = highlights && highlights[ITEM_TYPE.SUPERVISOR][id] === HIGHLIGHT.NEW
    return (
      <tr key={email}>
        <td className="supervisors-and-thesis-committee-overview__supervisor-name">
          {isNewSupervisor && (
            <HighlightContainer>
              <HighlightBalloon tooltipContent={t(k.NEW)} />
            </HighlightContainer>
          )}

          {name}
        </td>
        <td>
          {roles.map((role) => (
            <UniTag key={role} variant="info">
              {formatRole(role, t)}
            </UniTag>
          ))}
        </td>
        <td>
          <WordBreak>
            <a href={`mailto:${email}`}>{email}</a>
          </WordBreak>
        </td>
      </tr>
    )
  })

const getThesisCommitteeMemberRows = (thesisCommitteeMembers, highlights, t) =>
  sortBy(thesisCommitteeMembers, 'name').map(({ id, name, email }) => {
    const isNewThesisCommitteeMember = highlights && highlights[ITEM_TYPE.THESIS_COMMITTEE_MEMBER][id] === HIGHLIGHT.NEW
    return (
      <tr key={email}>
        <td className="supervisors-and-thesis-committee-overview__thesis-committee-member-name">
          {isNewThesisCommitteeMember && (
            <HighlightContainer>
              <HighlightBalloon tooltipContent={t(k.NEW)} />
            </HighlightContainer>
          )}

          {name}
        </td>
        <td>
          <WordBreak>
            <a href={`mailto:${email}`}>{email}</a>
          </WordBreak>
        </td>
      </tr>
    )
  })

const HighlightContainer = ({ children }) => (
  <div className="supervisors-and-thesis-committee-overview__highlight">{children}</div>
)

export default SupervisorsAndThesisCommitteeOverview

const Table = styled.table`
  ${UniParagraphStyles}
`

const TableHead = styled.th`
  padding: 0 !important;
`

const WordBreak = styled.div`
  word-break: break-word;
`
