import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import translationFI from '../../translations/json/fi.json'
import translationSV from '../../translations/json/sv.json'
import translationEN from '../../translations/json/en.json'
import moment from 'moment'
import 'moment/locale/fi'
import 'moment/locale/sv'

export type AvailableLanguage = 'fi' | 'sv' | 'en'

export const defaultLanguage = 'fi'

const initI18n = (fallbackLanguage: AvailableLanguage = defaultLanguage) => {
  i18n.on('languageChanged', (currentLanguage: string) => {
    moment.locale(currentLanguage)
  })

  i18n.use(initReactI18next).init({
    lng: localStorage.getItem('currentLanguage'),
    fallbackLng: fallbackLanguage,
    interpolation: { escapeValue: false },
    resources: {
      fi: { translation: translationFI },
      sv: { translation: translationSV },
      en: { translation: translationEN }
    }
  })
}

export default initI18n
