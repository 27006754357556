import { useTranslation } from 'react-i18next'
import k from '../../i18n/keys'
import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { observer } from 'mobx-react'
import PublicationsCard from '../publication/PublicationsCard'
import CoursesCard from '../course/CoursesCard'
import FundingCard from '../funding/FundingCard'
import TeachingCard from '../teaching/TeachingCard'
import NotesCard from '../note/NotesCard'
import PreliminaryTitleOfDissertation from '../preliminary-title-of-dissertation/PreliminaryTitleOfDissertation'
import { ConferenceAndMobilityList } from '../international-activity/ConferenceAndMobilityList'
import PageBlock from '../common/page/PageBlock'
import PageContent from '../common/page/PageContent'
import { UniHeading2 } from '../common/uni/UniHeading'
import { ReportOverview } from './report-overview/ReportOverview'
import { fromTabletUp, fromLargeDesktopUp } from '../styledUtils'
import { Meetings } from '../thesis-committee/Meetings'

const StudyPlanOverview = observer(({ doctoralCandidate, baseUrl, isUserDoctoralCandidate }) => {
  const { t } = useTranslation()
  return (
    <div className="doctoral-candidate-overview">
      <PageBlock wide row fullHeight>
        <PageContent lightBlue paddingInline border={false}>
          <UniHeading2
            id="doctoralCandidateOverviewHeading"
            className="doctoral-candidate-overview__study-plan-section-heading"
            marginBottom
          >
            {t(k.MY_PHD_PROJECT)}
          </UniHeading2>
          <PreliminaryTitleOfDissertation doctoralCandidate={doctoralCandidate} />
          <TwoColumns>
            <OneColumn>
              <PublicationsCard
                baseUrl={baseUrl}
                doctoralCandidate={doctoralCandidate}
                showCreateButton={isUserDoctoralCandidate}
              />

              <ConferenceAndMobilityList />
              <FundingCard
                baseUrl={baseUrl}
                doctoralCandidate={doctoralCandidate}
                showCreateButton={isUserDoctoralCandidate}
              />
            </OneColumn>
            <OneColumn>
              {doctoralCandidate.isFastDoctoralCandidate && <Meetings />}

              <CoursesCard
                baseUrl={baseUrl}
                doctoralCandidate={doctoralCandidate}
                showCreateButton={isUserDoctoralCandidate}
              />
              <TeachingCard
                baseUrl={baseUrl}
                doctoralCandidate={doctoralCandidate}
                showCreateButton={isUserDoctoralCandidate}
              />
              <NotesCard doctoralCandidate={doctoralCandidate} showEditButton={isUserDoctoralCandidate} />
            </OneColumn>
          </TwoColumns>
          <ReportOverview baseUrl={baseUrl} doctoralCandidate={doctoralCandidate} />
        </PageContent>
      </PageBlock>
    </div>
  )
})

StudyPlanOverview.propTypes = {
  baseUrl: PropTypes.string.isRequired,
  doctoralCandidate: PropTypes.object.isRequired,
  isUserDoctoralCandidate: PropTypes.bool,
  useFluidContainer: PropTypes.bool
}

StudyPlanOverview.defaultProps = {
  isUserDoctoralCandidate: false,
  useFluidContainer: false
}

const TwoColumns = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 16px;

  ${fromTabletUp(css`
    gap: 32px;
  `)}

  ${fromLargeDesktopUp(css`
    grid-template-columns: 1fr 1fr;
  `)}
`

const OneColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  ${fromTabletUp(css`
    gap: 32px;
  `)}
`

export default StudyPlanOverview
