import { withTranslation } from 'react-i18next'
import k from '../../i18n/keys'
import React from 'react'
import find from 'lodash/find'
import moment from 'moment'
import { withRouter } from 'react-router'
import PageBlock from '../common/page/PageBlock'
import { UniParagraph } from '../common/uni/UniParagraph'
import { UniHeading2 } from '../common/uni/UniHeading'
import { Helmet } from 'react-helmet'
import { generatePageTitle } from '../../util'

export const teachingTypeFormatted = {
  course_or_workshop: 'Course or workshop',
  thesis_instructor: 'Thesis instruction'
}

class ViewTeaching extends React.Component {
  render() {
    const { t } = this.props
    const teachingId = parseInt(this.props.params.teachingId)
    const teaching = find(this.props.doctoralCandidate.teachings, ({ id }) => id === teachingId)
    if (teaching) {
      const beginsAtFormatted = moment(teaching.beginsAt, 'YYYY-MM-DD').format('MMMM Do YYYY')
      const endsAtFormatted = moment(teaching.endsAt, 'YYYY-MM-DD').format('MMMM Do YYYY')

      return (
        <PageBlock padTop>
          <Helmet>
            <title>
              {generatePageTitle(teaching.courseName || teaching.studentName || t(k.UNTITLED_TEACHING), t(k.THESSA))}
            </title>
          </Helmet>
          <div className="doctoral-candidate-details">
            {teaching.courseName || teaching.studentName ? (
              <>
                {teaching.type === 'course_or_workshop' ? <UniHeading2>{teaching.courseName}</UniHeading2> : null}
                {teaching.type === 'thesis_instructor' ? <UniHeading2>{teaching.studentName}</UniHeading2> : null}
              </>
            ) : (
              <UniHeading2 marginBottom>
                <em>{t(k.UNTITLED_TEACHING)}</em>
              </UniHeading2>
            )}
            <dl>
              <UniParagraph as="dt" noMarginBottom>
                {t(k.TYPE_OF_TEACHING)}
              </UniParagraph>
              <UniParagraph as="dd" noMarginTop>
                {teachingTypeFormatted[teaching.type]}
              </UniParagraph>
              <UniParagraph as="dt" noMarginBottom>
                {t(k.DATES1)}
              </UniParagraph>
              <UniParagraph as="dd" noMarginTop>{`${beginsAtFormatted} ${`-`} ${endsAtFormatted}`}</UniParagraph>
              {this._renderCourseOrWorkshopSpecificFields(teaching)}
              {this._renderThesisInstructionSpecificFields(teaching)}
            </dl>
          </div>
        </PageBlock>
      )
    }

    return null
  }

  _renderCourseOrWorkshopSpecificFields(teaching) {
    const { t } = this.props
    if (teaching.type === 'course_or_workshop') {
      return (
        <div>
          <UniParagraph as="dt" noMarginBottom>
            {t(k.COURSE_OR_WORKSHOP_NAME)}
          </UniParagraph>
          <UniParagraph as="dd" noMarginTop>
            {teaching.courseName}
          </UniParagraph>
          <UniParagraph as="dt" noMarginBottom>
            {t(k.MY_ROLE)}
          </UniParagraph>
          <UniParagraph as="dd" noMarginTop>
            {teaching.myRole}
          </UniParagraph>
          <UniParagraph as="dt" noMarginBottom>
            {t(k.WORK_HOURS)}
          </UniParagraph>
          <UniParagraph as="dd" noMarginTop>
            {teaching.workHours}
          </UniParagraph>
        </div>
      )
    }
    return null
  }

  _renderThesisInstructionSpecificFields(teaching) {
    const { t } = this.props
    if (teaching.type === 'thesis_instructor') {
      return (
        <div>
          <UniParagraph as="dt" noMarginBottom>
            {t(k.STUDENT_S_NAME)}
          </UniParagraph>
          <UniParagraph as="dd" noMarginTop>
            {teaching.studentName}
          </UniParagraph>
          <UniParagraph as="dt" noMarginBottom>
            {t(k.MY_ROLE)}
          </UniParagraph>
          <UniParagraph as="dd" noMarginTop>
            {teaching.myRole}
          </UniParagraph>
          <UniParagraph as="dt" noMarginBottom>
            {t(k.THESIS_LEVEL)}
          </UniParagraph>
          <UniParagraph as="dd" noMarginTop>
            {teaching.thesisLevel}
          </UniParagraph>
          <UniParagraph as="dt" noMarginBottom>
            {t(k.THESIS_TOPIC_OR_TITLE)}
          </UniParagraph>
          <UniParagraph as="dd" noMarginTop>
            {teaching.thesisTopicOrTitle}
          </UniParagraph>
        </div>
      )
    }
    return null
  }
}

export default withTranslation()(withRouter(ViewTeaching))
