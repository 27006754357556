export const HIGHLIGHT = {
  NEW: 'new',
  CHANGED: 'changed'
}

export const ITEM_TYPE = {
  COURSE: 'courses',
  PUBLICATION: 'publications',
  INTERNATIONAL_ACTIVITY: 'internationalActivities',
  TEACHING: 'teachings',
  SALARY: 'salaries',
  GRANT: 'grants',
  SUPERVISION_PLAN: 'supervisionPlans',
  SUPERVISOR: 'supervisors',
  THESIS_COMMITTEE_MEMBER: 'thesisCommitteeMembers'
}
