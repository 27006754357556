import styled, { css } from 'styled-components'
import { DEFAULT_FONT } from '../../common/uni/typography'

export const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
`

export const TextCell = styled.div<{ heading?: boolean }>`
  font-size: 14px;
  line-height: 18px;
  padding: 12px 0;
  word-break: break-word;
  ${DEFAULT_FONT}

  ${(props) =>
    props.heading &&
    css`
      font-weight: 600;
    `}
`
