import React, { ReactElement, ReactNode, useRef } from 'react'
import Tippy from '@tippyjs/react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { ADDITIONAL_YELLOW } from './uni/colors'

export type Placement = 'right' | 'left' | 'top' | 'bottom'

interface Tooltip {
  content: ReactNode
  disabled?: boolean
  visible?: boolean
  arrow?: boolean
  animation?: string | boolean
  duration?: number
  placement?: Placement
  onHide?: () => void
  children: ReactNode
}

const Tooltip: React.FC<Tooltip> = ({
  content,
  disabled,
  visible,
  arrow = true,
  animation = 'fade',
  duration = 0,
  placement = 'top',
  onHide,
  children
}) => {
  const ref = useRef(null)
  const childrenWithForcedTypeFix = children as ReactElement
  return (
    <Tippy
      ref={ref}
      content={content}
      disabled={disabled}
      interactive={true}
      arrow={arrow}
      animation={animation}
      duration={duration}
      placement={placement}
      visible={visible}
      onHide={onHide}
      aria={{
        content: 'describedby'
      }}
    >
      {childrenWithForcedTypeFix}
    </Tippy>
  )
}

Tooltip.propTypes = {
  content: PropTypes.node.isRequired
}

export const TooltipFocusableControl = styled.button`
  appearance: none;
  background: transparent;
  border: none;
  padding: 0;
  margin: 0;
  cursor: default;

  &:focus {
    outline: solid 2px ${ADDITIONAL_YELLOW};
    outline-offset: 2px;
  }
`

export const TooltipSmallMessageContainer = styled.div`
  max-width: 200px;
  text-align: center;
  padding: 6px 0;

  *:last-child {
    margin-bottom: 0;
  }
`

export default Tooltip
