import React, { ReactNode, useContext } from 'react'
import styled, { css } from 'styled-components'
import { WindowWidthContext } from '../../WindowWidthContext'
import { UniParagraph } from '../common/uni/UniParagraph'
import { GRAYSCALE_WHITE } from '../common/uni/colors'

interface PhDProjectNotificationProps {
  id: string
  icon: ReactNode
  body: ReactNode
  cta: ReactNode
}

export const PhDProjectNotification = ({ id, icon, body, cta }: PhDProjectNotificationProps) => {
  const { isPhoneOrSmaller } = useContext(WindowWidthContext)
  return (
    <Container className="phd-project__notification-container" id={id} isPhoneOrSmaller={isPhoneOrSmaller}>
      <div className="container">
        <ContentGrid className="phd-project__notification" isPhoneOrSmaller={isPhoneOrSmaller}>
          <IconContainer>{icon}</IconContainer>
          <Paragraph noMargin>{body}</Paragraph>
          <div>{cta}</div>
        </ContentGrid>
      </div>
    </Container>
  )
}

const Container = styled.div<{ isPhoneOrSmaller: boolean }>`
  background: #337ab7;
  color: #fff;
  font-size: 16px;
  margin-bottom: 20px;
  padding: 10px 0;

  ${({ isPhoneOrSmaller }) =>
    isPhoneOrSmaller
      ? css`
          padding: 20px 0;
        `
      : css`
          padding: 10px 0;
        `}
`

const ContentGrid = styled.div<{ isPhoneOrSmaller: boolean }>`
  ${({ isPhoneOrSmaller }) =>
    isPhoneOrSmaller
      ? css`
          display: flex;
          flex-direction: column;
          gap: 10px;
        `
      : css`
          align-items: center;
          display: grid;
          grid-template-columns: 20px 1fr max-content;
          gap: 20px;
        `}
`

const IconContainer = styled.div`
  font-size: 20px;
  height: 20px;
  width: 20px;
  line-height: 20px;
`

const Paragraph = styled(UniParagraph)`
  color: ${GRAYSCALE_WHITE};
`
