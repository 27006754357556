import React from 'react'
import styled, { css } from 'styled-components'
import { StrongCell } from '../../common/list/List'
import { MyDoctoralCandidateRole } from '../../../model'
import { useTranslation } from 'react-i18next'
import k from '../../../i18n/keys'

interface NameAndRolesProperties {
  name: string
  roles: MyDoctoralCandidateRole[]
  hasActiveStudyRight: boolean
  clickable?: boolean
}

export const NameAndRoles = ({ name, roles, hasActiveStudyRight, clickable }: NameAndRolesProperties) => {
  const { t } = useTranslation()
  const formattedRoleNames = t(k.ROLE_NAME, { returnObjects: true }) as { [index: string]: string }
  const sortedRoles = roles.map((role) => formattedRoleNames[role]).sort()
  return (
    <NameAndRolesCell className="name-and-roles" hasActiveStudyRight={hasActiveStudyRight} clickable={clickable}>
      <span className="name">{name}</span>
      <Relationships className="roles">{sortedRoles.join(', ')}</Relationships>
    </NameAndRolesCell>
  )
}

const NameAndRolesCell = styled(StrongCell)<{ hasActiveStudyRight: boolean }>`
  grid-column-start: 2;

  ${({ hasActiveStudyRight }) =>
    !hasActiveStudyRight &&
    css`
      .name {
        color: #767676;
        text-decoration: line-through rgba(0, 0, 0, 0.3);
      }
    `}
`

const Relationships = styled.div`
  color: #767676;
  font-size: 85%;
  font-weight: 400;
  font-style: italic;
`
