import { useTranslation } from 'react-i18next'
import k from '../../../i18n/keys'
import React from 'react'
import PropTypes from 'prop-types'
import UniTag from '../../common/uni/UniTag'
import { IsNewOrChanged } from '../../common/IsNewOrChanged'
import { UniLinkBox } from '../../common/uni/UniLinkBox'
import styled from 'styled-components'
import { GRAYSCALE_DARK } from '../../common/uni/colors'
import VisuallyHidden from '../../common/VisuallyHidden'
import { getStatusLabel, getTagVariant } from '../supervision-plan-heading/SupervisionPlanHeading'

const SupervisionPlanListItem = ({
  linkTarget,
  title,
  draft = false,
  active = false,
  pending = false,
  expired = false,
  newOrChanged
}) => {
  const { t } = useTranslation()
  return (
    <UniLinkBox
      className="supervision-plan-list-item"
      link={{
        label: (
          <>
            {t(k.VIEW_SUPERVISION_PLAN)}
            {': '}
            <VisuallyHidden>{title}</VisuallyHidden>
          </>
        ),
        to: linkTarget
      }}
      visuallyHideLinkLabel
    >
      <IsNewOrChanged supervisionPlanList newOrChanged={newOrChanged} />
      <Content>
        <Title className="supervision-plan-list-item__title">{title}</Title>
        <LabelList className="supervision-plan-list-item__labels">
          <UniTag variant={getTagVariant(draft, active, pending, expired)}>
            {getStatusLabel(t, draft, active, pending, expired)}
          </UniTag>
        </LabelList>
      </Content>
    </UniLinkBox>
  )
}

SupervisionPlanListItem.propTypes = {
  linkTarget: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  active: PropTypes.bool,
  pending: PropTypes.bool,
  expired: PropTypes.bool,
  newOrChanged: PropTypes.string
}

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const Title = styled.h3`
  font-size: 16px;
  color: ${GRAYSCALE_DARK};
  margin: 0;
`

const LabelList = styled.ul`
  order: -1;
  display: flex;
  flex-flow: row wrap;
  gap: 8px;
  padding: 0;
  margin: 0;
  margin-bottom: 8px;
`

export default SupervisionPlanListItem
