import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { TFunction } from 'i18next'
import styled from 'styled-components'
import { Link, withRouter, WithRouterProps } from 'react-router'
import k from '../../../i18n/keys'
import { onKeyboardClick } from '../../../util'
import { Cell, HighlightCell, IconCell } from '../../common/list/List'
import { StudentNumber } from '../StudentNumber'
import Tooltip, { TooltipFocusableControl } from '../../common/Tooltip'
import Spinner from '../../common/Spinner'
import { MyDoctoralCandidate } from '../MyDoctoralCandidates'
import HighlightStore from '../../highlight/HighlightStore'
import { NameAndRoles } from './NameAndRoles'
import { WindowWidthContext } from '../../../WindowWidthContext'
import { inject } from 'mobx-react'

interface DoctoralCandidateWithAccountRowProperties {
  doctoralCandidate: MyDoctoralCandidate
  isLoading: boolean
  HighlightStore?: HighlightStore
}

export const DoctoralCandidateWithAccountRow = inject('HighlightStore')(
  withRouter(
    ({
      doctoralCandidate,
      isLoading,
      HighlightStore,
      router
    }: DoctoralCandidateWithAccountRowProperties & WithRouterProps) => {
      const { t } = useTranslation()
      const { isDesktopOrLarger } = useContext(WindowWidthContext)
      const hasHighlights = HighlightStore.hasDoctoralCandidateHighlights(doctoralCandidate.doctoralCandidateId)
      const hasReportStatusProblem = doctoralCandidate.latestReportStatus === 'not ok'
      const hasSupervisionPlanStatusProblem = doctoralCandidate.supervisionPlanStatus === 'not ok'
      const hasProblem = hasReportStatusProblem || hasSupervisionPlanStatusProblem
      const problemTitles = getProblemTitle(hasReportStatusProblem, hasSupervisionPlanStatusProblem, t)
      const path = `/doctoral-candidates/${doctoralCandidate.doctoralCandidateId}`
      return (
        <LinkRow to={path} onKeyPress={onKeyboardClick(() => router.push(path))}>
          {hasHighlights && <HighlightCell tooltip={t(k.CHANGED_SINCE_LAST_OPENED)} />}
          <NameAndRoles
            name={doctoralCandidate.name}
            roles={doctoralCandidate.roles}
            hasActiveStudyRight={doctoralCandidate.hasActiveStudyRight}
          />
          {isDesktopOrLarger && (
            <Cell>
              <StudentNumber studentNumber={doctoralCandidate.studentNumber} />
            </Cell>
          )}
          {isDesktopOrLarger && (
            <Cell>
              <Progress>
                <div className="progress">
                  <div className="progress-bar" style={{ width: `${doctoralCandidate.progress}%` }} />
                </div>
                <ProgressPercentage>
                  {doctoralCandidate.progress}
                  {`%`}
                </ProgressPercentage>
              </Progress>
            </Cell>
          )}
          {hasProblem ? (
            <Tooltip
              content={problemTitles.map((problemTitle) => (
                <ProblemTitle key={problemTitle}>{problemTitle}</ProblemTitle>
              ))}
            >
              <TooltipContent>
                <IconCell tabIndex={0}>
                  <ProblemIcon
                    className="problem-icon glyphicon glyphicon-alert"
                    role="img"
                    aria-label={problemTitles.join(' ')}
                  />
                </IconCell>
              </TooltipContent>
            </Tooltip>
          ) : (
            <IconCell>
              <OkIcon className="glyphicon glyphicon-ok" />
            </IconCell>
          )}
          <IconCell tabIndex={0} role="button" aria-label={`Open doctoral candidate ${doctoralCandidate.name}`}>
            {isLoading ? <Spinner /> : <span className="glyphicon glyphicon-menu-right" />}
          </IconCell>
        </LinkRow>
      )
    }
  )
)

function getProblemTitle(
  hasReportStatusProblem: boolean,
  hasSupervisionPlanStatusProblem: boolean,
  t: TFunction
): string[] {
  const problemTitles = []
  if (hasReportStatusProblem) {
    problemTitles.push(t(k.CHECK_REPORT_STATUS))
  }
  if (hasSupervisionPlanStatusProblem) {
    problemTitles.push(t(k.CHECK_SUPERVISION_PLAN_STATUS))
  }
  return problemTitles
}

const LinkRow = styled(Link)`
  color: inherit;
  display: contents;

  &:hover,
  &:focus,
  &:active {
    color: inherit;
    text-decoration: inherit;
  }
`

const Progress = styled.div`
  display: flex;
  column-gap: 10px;

  .progress {
    flex-grow: 1;
    margin-bottom: 0;
  }
`

const ProgressPercentage = styled.div`
  width: 40px;
`

const ProblemTitle = styled.div`
  text-align: center;

  & + & {
    margin-top: 3px;
  }
`

const ProblemIcon = styled.span`
  color: #d9534f;
`

const OkIcon = styled.span`
  color: #5db05d;
`

const TooltipContent = styled(TooltipFocusableControl)`
  display: flex;
  justify-content: center;
  align-items: stretch;
  width: 100%;
`
