import React from 'react'
import './Page.scss'

interface Page {
  id?: string
}

const Page: React.FC<Page> = ({ id, children }) => (
  <div className="thessa-page" id={id}>
    {children}
  </div>
)

export default Page
