import React from 'react'
import { useTranslation } from 'react-i18next'
import k from '../../i18n/keys'
import { UniButton } from '../common/uni/UniButton'

const EditButton = ({ onEdit, title }) => {
  const { t } = useTranslation()
  const buttonText = title ? t(k.EDIT_PRELIMINARY_TITLE_OF_DISSERTATION) : t(k.CREATE_PRELIMINARY_TITLE_OF_DISSERTATION)

  return (
    <UniButton id="preliminaryTitleOfDissertationEditButton" onClick={onEdit} secondary icon="edit">
      <span>{buttonText}</span>
    </UniButton>
  )
}

export default EditButton
