import k from '../../../i18n/keys'
import React from 'react'
import styled, { css } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { LanguageMenu } from '../LanguageMenu'
import { BRAND_MAIN, BRAND_MAIN_NEARLY_BLACK } from './colors'
import { UniContainer } from './layout'
import { fromTabletUp, fromPhoneUp } from '../../styledUtils'
import LanguageSelect from '../LanguageSelect'
import { DEFAULT_FONT } from './typography'
const logoUrl = new URL('../../../../assets/logo.png', import.meta.url)

export const TopMenuBar = () => {
  const { t } = useTranslation()
  return (
    <UniContainer wide id={'topMenuBar'}>
      <UniContainer wide borderBottom paddingInline={false}>
        <Flex>
          <UniversityHomeLink href="https://helsinki.fi" target="_blank">
            <Logo src={logoUrl.toString()} alt={t(k.LOGO_OF_UNIVERSITY_OF_HELSINKI)} />
            {t(k.UNIVERSITY_OF_HELSINKI)}
          </UniversityHomeLink>
          <LanguageMenuDesktop />
          <LanguageSelectMobile />
        </Flex>
      </UniContainer>
    </UniContainer>
  )
}

const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 48px;
`

const Logo = styled.img`
  width: 2em;
  height: 2em;
  filter: invert(100%);
`

const UniversityHomeLink = styled.a`
  display: flex;
  align-items: center;
  gap: 0.2em;
  color: ${BRAND_MAIN_NEARLY_BLACK};
  ${DEFAULT_FONT}
  font-size: 11px;
  font-weight: 600;
  line-height: 14px;
  text-transform: uppercase;
  padding-right: 2em;

  ${fromPhoneUp(css`
    letter-spacing: -0.6px;
    font-size: 14px;
  `)}

  ${fromTabletUp(css`
    gap: 1em;
  `)}

  &:hover,
  &:focus {
    color: ${BRAND_MAIN};
    text-decoration: none;
  }

  &:focus {
    outline-offset: 3px;
  }
`

const LanguageMenuDesktop = styled(LanguageMenu)`
  display: none;

  ${fromPhoneUp(css`
    display: flex;
  `)}
`

const LanguageSelectMobile = styled(LanguageSelect)`
  display: flex;
  margin-block-start: -12px;

  ${fromPhoneUp(css`
    display: none;
  `)}
`

export const TopMenuBarButton = styled.button<{ active?: boolean }>`
  background: transparent;
  border: none;
  color: ${BRAND_MAIN_NEARLY_BLACK};
  font-size: 11px;
  font-weight: 600;
  padding: 12px 8px;
  text-transform: uppercase;
  ${DEFAULT_FONT}

  &:hover,
  &:focus {
    color: ${BRAND_MAIN};
  }

  &:focus {
    outline-offset: 3px;
  }

  ${(props) =>
    props.active &&
    css`
      text-decoration: underline;
    `}
`
