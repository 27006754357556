import React from 'react'
import { useTranslation } from 'react-i18next'
import k from '../../../i18n/keys'
import FreeTextRows from './FreeTextRows'

const SocialImpact = ({ reports }) => {
  const { t } = useTranslation()
  return (
    <FreeTextRows heading={t(k.SOCIAL_IMPACT_AND_PUBLIC_OUTREACH)} reports={reports} reportFieldName="socialImpact" />
  )
}

export default SocialImpact
