import React from 'react'
import styled from 'styled-components'
import { TopMenuBar } from '../common/uni/TopMenuBar'
import Footer from '../common/Footer'
import MainMenu from '../common/header/Header'

interface Properties {
  id?: string
  className?: string
  children?: React.ReactNode
}

const Frame = ({ id, className, children }: Properties) => {
  return (
    <Container id={id} className={className}>
      <TopMenuBar />
      <MainMenu />
      <Main>{children}</Main>
      <Footer />
    </Container>
  )
}

const Container = styled.div`
  min-height: 100vh;
  display: flex;
  flex-flow: column nowrap;
`

const Main = styled.main`
  flex: 1 1 100%;
  position: relative;
  z-index: 1;
  display: flex;
  flex-flow: column nowrap;
`

export default Frame
