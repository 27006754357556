import React from 'react'
import { UniDetails } from '../uni/UniDetails'
import { UniBanner } from '../uni/UniBanner'
import { UniHeading2 } from '../uni/UniHeading'
import { UniLabel } from '../uni/UniLabel'
import { Actions } from '../uni/UniForm'
import { Trans, useTranslation } from 'react-i18next'
import k from '../../../i18n/keys'
import { SortButton, FilterElement, SortIndicator } from './Table'
import { HeaderGroup } from 'react-table'

interface CardFiltersProperties {
  id: string
  headerGroups: HeaderGroup<
    {
      [key: string]: any //eslint-disable-line @typescript-eslint/no-explicit-any
    } & object
  >[]
  sorting: boolean
  filtering: boolean
}

const CardFilters = ({ id, headerGroups, sorting, filtering }: CardFiltersProperties) => {
  const { t } = useTranslation()
  return (
    <UniBanner marginTop small marginBottom>
      <UniDetails summary="Sorting and filtering">
        {headerGroups.map((headerGroup) => (
          <>
            {sorting ? (
              <>
                <UniHeading2 withDivider marginBottom marginTop small>
                  {t(k.SORT_BY)}
                </UniHeading2>
                <Actions noPadTop marginBottom grid>
                  {headerGroup.headers.map((column, key) => (
                    <SortButton
                      {...column.getSortByToggleProps({ title: '' })}
                      key={key}
                      details
                      small
                      secondary={!column.isSorted}
                      aria-describedby={`${id}-sort-help`}
                    >
                      {column.render('Header')}
                      {column.isSorted ? (
                        <SortIndicator isSorted={column.isSorted || column.isSortedDesc}>
                          {column.isSortedDesc ? (
                            <span className="glyphicon glyphicon-sort-by-attributes" role="img" aria-hidden="true" />
                          ) : (
                            <span
                              className="glyphicon glyphicon-sort-by-attributes-alt"
                              role="img"
                              aria-hidden="true"
                            />
                          )}
                        </SortIndicator>
                      ) : null}
                    </SortButton>
                  ))}
                </Actions>
              </>
            ) : null}
            {filtering ? (
              <>
                <UniHeading2 withDivider marginBottom marginTop small>
                  <Trans t={t} i18nKey={k.FILTER_BY} values={{ target: '' }} />
                </UniHeading2>
                <Actions noPadTop marginBottom grid>
                  {headerGroup.headers.map((column, key) => (
                    <div key={key}>
                      <UniLabel aria-hidden="true">{column.render('Header')}</UniLabel>
                      <FilterElement details>{column.canFilter ? column.render('Filter') : null}</FilterElement>
                    </div>
                  ))}
                </Actions>
              </>
            ) : null}
          </>
        ))}
      </UniDetails>
    </UniBanner>
  )
}

export default CardFilters
