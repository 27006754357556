import React from 'react'
import { withTranslation } from 'react-i18next'
import assign from 'lodash/assign'
import sortBy from 'lodash/sortBy'
import moment from 'moment'
import { inject, observer } from 'mobx-react'
import k from '../../i18n/keys'
import ThessaList from '../common/ThessaList'
import { ITEM_TYPE } from '../highlight/constants'

@inject('HighlightStore')
@observer
class SalaryList extends React.Component {
  render() {
    const { HighlightStore, doctoralCandidateId, white, t } = this.props
    const doctoralCandidateHighlights = HighlightStore.getDoctoralCandidateHighlights(doctoralCandidateId)
    const columns = {
      title: t(k.SALARY_SOURCE),
      dates: t(k.FOR_DATES)
    }
    const sortedSalaries = sortBy(this.props.salaries, 'beginsAt')
    const salariesForRendering = sortedSalaries.map(({ id, source, beginsAt, endsAt }) => {
      const beginsAtDateTimeFormatted = moment(beginsAt, 'YYYY-MM-DD').format('YYYY-MM-DD')
      const beginsAtFormatted = moment(beginsAt, 'YYYY-MM-DD').format('MMM D YYYY')
      const endsAtDateTimeFormatted = moment(endsAt, 'YYYY-MM-DD').format('YYYY-MM-DD')
      const endsAtFormatted = moment(endsAt, 'YYYY-MM-DD').format('MMM D YYYY')
      const salaryForRendering = {
        id,
        title: source,
        dates: (
          <>
            <time dateTime={beginsAtDateTimeFormatted}>{beginsAtFormatted}</time>
            {'—'}
            <time dateTime={endsAtDateTimeFormatted}>{endsAtFormatted}</time>
          </>
        ),
        newOrChanged: doctoralCandidateHighlights && doctoralCandidateHighlights[ITEM_TYPE.SALARY][id]
      }

      if (this.props.linkTarget) {
        assign(salaryForRendering, {
          linkTarget: `${this.props.linkTarget}${id}`
        })
      }

      return salaryForRendering
    })

    return (
      <ThessaList
        id="doctoral-candidate-overview__salaries"
        label="Salaries"
        columns={columns}
        rows={salariesForRendering}
        noContentMessage={this.props.noContentMessage}
        white={white}
        defaultSortByID="dates"
        defaultSortByDesc={true}
      />
    )
  }
}

export default withTranslation()(SalaryList)
