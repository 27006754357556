import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { PhdProjectListRow } from './PhdProjectListRow'
import { WithRole } from './WithRole'
import { DetailsComponent, FormComponent, OnDelete, ResponsiveColumns, RowComponent } from './types'
import { DEFAULT_FONT } from '../common/uni/typography'
import { getPhdProjectListColumns, INDENT } from './constants'
import { UniButton } from '../common/uni/UniButton'
import { GRAYSCALE_DARK } from '../common/uni/colors'
import { CardNoContentMessage } from '../common/Card'

interface PhdProjectListProps<T extends { id: number | string }> {
  columns: ResponsiveColumns
  items: T[]
  Row: RowComponent<T>
  Details: DetailsComponent<T>
  Form: FormComponent<T>
  createButtonText: string
  onCreate: (item: T) => Promise<number>
  onEdit: (item: T) => Promise<void>
  onDelete: OnDelete<T>
  highlights: { [key: number | string]: string }
  noContentMessage: string
}

export const PhdProjectList = <T extends { id: number | string }>({
  columns,
  items,
  Row,
  Details,
  Form,
  createButtonText,
  onCreate,
  onEdit,
  onDelete,
  highlights,
  noContentMessage
}: PhdProjectListProps<T>) => {
  const [isAddFormExpanded, setIsAddFormExpanded] = useState(false)
  const [lastCreatedId, setLastCreatedId] = useState<number>()
  const hasRows = items.length > 0
  const hideForm = () => setIsAddFormExpanded(false)
  return (
    <div>
      {items.length > 0 ? (
        <>
          <ColumnHeadings $columns={columns}>
            {columns.titles.map((title) => (
              <ColumnHeading key={title}>{title}</ColumnHeading>
            ))}
          </ColumnHeadings>
          <Items>
            {items.map((item) => (
              <PhdProjectListRow<T>
                key={item.id}
                columns={columns}
                item={item}
                Row={Row}
                Details={Details}
                Form={Form}
                onEdit={onEdit}
                onDelete={onDelete}
                newOrChanged={highlights[item.id]}
                wasCreatedNow={lastCreatedId === item.id}
              />
            ))}
          </Items>
        </>
      ) : null}

      {items.length === 0 && !isAddFormExpanded ? (
        <CardNoContentMessage text={noContentMessage} paddingBlock paddingInline />
      ) : null}

      <AddRow hasRows={hasRows} isExpanded={isAddFormExpanded}>
        <>
          {!isAddFormExpanded && (
            <WithRole doctoralCandidate>
              <UniButton secondary icon="plus" onClick={() => setIsAddFormExpanded(true)}>
                {createButtonText}
              </UniButton>
            </WithRole>
          )}

          {isAddFormExpanded && (
            <AddFormContainer>
              <Form
                onCancel={hideForm}
                onSave={(itemToCreate) =>
                  onCreate(itemToCreate).then((createdItemId) => {
                    setLastCreatedId(createdItemId)
                    setIsAddFormExpanded(false)
                  })
                }
              />
            </AddFormContainer>
          )}
        </>
      </AddRow>
    </div>
  )
}

const ColumnHeadings = styled.div<{ $columns: ResponsiveColumns }>`
  align-items: end;
  display: grid;
  padding: 0 0 0 ${INDENT};
  grid-column-gap: 16px;
  ${(props) => getPhdProjectListColumns(props.$columns)}
`

const ColumnHeading = styled.div`
  color: ${GRAYSCALE_DARK};
  font-size: 15px;
  font-weight: 600;
  padding: 8px 0;
  transition: all 0.2s;
  word-break: break-word;
  ${DEFAULT_FONT}
`

const Items = styled.div`
  display: grid;
  grid-row-gap: 2px;
`

const AddRow = styled.div<{ hasRows: boolean; isExpanded: boolean }>`
  background: transparent;
  border-radius: 3px;
  margin-top: ${INDENT};
  padding-left: ${INDENT};
  min-height: 1em;
  transition: all 0.2s;

  ${(props) =>
    props.isExpanded &&
    css`
      background: #fff;
      box-shadow: 0 0 15px rgba(0, 0, 0, 0.05);
      margin-top: 10px;
    `}
`

const AddFormContainer = styled.div`
  padding: 20px 24px;
`
