import { css } from 'styled-components'
import { ResponsiveColumns } from './types'
import { fromDesktopUp, fromTabletUp } from '../styledUtils'

export const INDENT = '16px'
export const CARET_WIDTH = '14px'

function columnTemplate(columns: string) {
  return css`
    grid-template-columns: ${columns} calc(${INDENT} + ${CARET_WIDTH} + ${INDENT});
  `
}

export function getPhdProjectListColumns(columns: ResponsiveColumns) {
  return css`
    ${columnTemplate(columns.phone)}
    ${fromTabletUp(columnTemplate(columns.tablet))}
    ${fromDesktopUp(columnTemplate(columns.desktop))}
  `
}
