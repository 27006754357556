import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import moment from 'moment'
import k from '../../../i18n/keys'
import Heading from './Heading'
import Table from './Table'
import { reportsToRows, createCell } from './Helpers'
import { getStatusAt, statusTexts } from '../../publication/PublicationHelpers'

const titleClassModifier = 'publication-title'
const inThesisClassModifier = 'in-thesis'
const publicationStatusClassModifier = 'publication-status'
const publishedAtClassModifier = 'published-at'

const getCells = (t) => {
  return (publication) => {
    const reportingPeriodEndMoment = moment(publication.reportingPeriodEnd, 'YYYY-MM-DD')
    const status = getStatusAt(publication.statuses, reportingPeriodEndMoment)
    const publishedAt =
      status.status === 'published' ? moment(status.statusStartedAt, 'YYYY-MM-DD').format('YYYY MMMM') : ''
    return [
      createCell(publication.title, titleClassModifier),
      createCell(
        publication.inThesis ? (
          <span className="glyphicon glyphicon-ok text-success" aria-label="Publication is in thesis." />
        ) : null,
        inThesisClassModifier
      ),

      createCell(t(statusTexts[status.status]), publicationStatusClassModifier),
      createCell(publishedAt, publishedAtClassModifier)
    ]
  }
}

const Publications = (props) => {
  const { t } = useTranslation()
  const headings = [
    createCell(t(k.TITLE), titleClassModifier),
    createCell(t(k.IN_THESIS), inThesisClassModifier),
    createCell(t(k.STATUS), publicationStatusClassModifier),
    createCell(t(k.PUBLISHED), publishedAtClassModifier)
  ]
  const rows = reportsToRows(props.reports, ({ publications }) => publications, getCells(t))
  return (
    <div id="cumulative-report__publications">
      <Heading text={t(k.PUBLICATIONS)} />
      <Table headings={headings} rows={rows} label={t(k.PUBLICATIONS)} />
    </div>
  )
}

Publications.propTypes = {
  reports: PropTypes.arrayOf(PropTypes.object)
}

export default Publications
