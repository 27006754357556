import { InternationalActivityResponse } from '../components/doctoral-candidate-overview/responseTypes'

export default class InternationalActivity {
  id: number
  type: 'conference' | 'research-visit' | 'other'
  name: string
  location: string
  beginsAt: string
  endsAt: string
  conferenceRole: 'participant' | 'organizer'
  participationType: 'poster' | 'talk' | 'participation_without_presentation'
  description: string

  constructor(response: InternationalActivityResponse[number]) {
    this.updateFromResponse(response)
  }

  updateFromResponse({
    id,
    type,
    name,
    location,
    beginsAt,
    endsAt,
    conferenceRole,
    participationType,
    description
  }: InternationalActivityResponse[number]) {
    this.id = id
    this.type = type
    this.name = name
    this.location = location
    this.beginsAt = beginsAt
    this.endsAt = endsAt
    this.conferenceRole = conferenceRole
    this.participationType = participationType
    this.description = description
  }
}
