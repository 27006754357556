import { css } from 'styled-components'
import { fromPhoneUp } from '../../styledUtils'
import { BRAND_MAIN_NEARLY_BLACK, GRAYSCALE_MEDIUM } from '../uni/colors'

export const LinkStyles = css`
  display: flex;
  align-items: center;
  gap: 8px;
  position: relative;
  color: ${BRAND_MAIN_NEARLY_BLACK};
  font-weight: bold;
  text-transform: uppercase;
  padding: 12px 16px;
  border-radius: 100px;

  ${fromPhoneUp(css`
    gap: 12px;
  `)}

  &:focus,
  &:hover,
  &:active {
    color: ${BRAND_MAIN_NEARLY_BLACK};
    text-decoration: none;
  }
`

export const LinkInteractionStyles = (
  current: boolean | 'time' | 'true' | 'false' | 'step' | 'page' | 'location' | 'date' = false
) => css`
  &:before {
    content: '';
    position: absolute;
    inset: 4px 2px;
    background-color: ${GRAYSCALE_MEDIUM};
    opacity: 0;
    transition: opacity 0.1s, 0.1s transform 0.1s;
    border-radius: 16px;
    transform: scale(0.75);

    ${fromPhoneUp(css`
      inset: 6px 4px;
    `)}
  }

  @media (hover: hover) {
    &:hover {
      color: ${BRAND_MAIN_NEARLY_BLACK};
      text-decoration: none;

      &:before {
        opacity: 0.5;
        transform: scale(1);
        transition: opacity 0.2s, transform 0.1s;
      }
    }
  }

  &:active {
    &:before {
      opacity: 0.36;
      transform: scale(0.96);
    }
  }

  ${current &&
  css`
    &:before {
      opacity: 0.5;
      transform: scale(1);
      transition: opacity 0.2s, transform 0.1s;
    }

    @media (hover: hover) {
      &:hover {
        &:before {
          opacity: 0.54;
          transform: scale(1.04);
        }
      }
    }

    &:active {
      &:before {
        opacity: 0.36;
        transform: scale(0.96);
      }
    }
  `}

  & > * {
    position: relative;
  }
`
