import React from 'react'
import { useTranslation } from 'react-i18next'
import k from '../../../i18n/keys'
import FreeTextRows from './FreeTextRows'

const SummaryNegative = ({ reports }) => {
  const { t } = useTranslation()
  return <FreeTextRows heading={t(k.THREE_NEGATIVE_THINGS)} reports={reports} reportFieldName="summaryNegative" />
}

export default SummaryNegative
