import { withTranslation } from 'react-i18next'
import k from '../../i18n/keys'
import React from 'react'
import Card, { CardNoContentMessage, CardSubHeading } from '../common/Card'
import SalaryList from './SalaryList'
import GrantList from './GrantList'
import CreateSalaryButton from './CreateSalaryButton'
import CreateGrantButton from './CreateGrantButton'

class FundingCard extends React.Component {
  render() {
    const { t } = this.props
    return (
      <Card heading={t(k.FUNDING)} modifier="funding">
        <CardSubHeading cardAction={this._getCreateSalaryButton()}>{t(k.SALARIES)}</CardSubHeading>
        <SalaryList
          salaries={this.props.doctoralCandidate.salaries}
          doctoralCandidateId={this.props.doctoralCandidate.id}
          linkTarget={`${this.props.baseUrl}salaries/`}
          noContentMessage={this._getNoSalariesContentMessage()}
          white
        />

        <CardSubHeading cardAction={this._getCreateGrantButton()}>{t(k.GRANTS)}</CardSubHeading>
        <GrantList
          grants={this.props.doctoralCandidate.grants}
          doctoralCandidateId={this.props.doctoralCandidate.id}
          linkTarget={`${this.props.baseUrl}grants/`}
          noContentMessage={this._getNoGrantsContentMessage()}
          white
        />
      </Card>
    )
  }

  _getCreateSalaryButton() {
    if (this.props.showCreateButton) {
      return <CreateSalaryButton />
    }
    return null
  }

  _getCreateGrantButton() {
    if (this.props.showCreateButton) {
      return <CreateGrantButton />
    }
    return null
  }

  _getNoSalariesContentMessage() {
    const { t, noContentMessageRole } = this.props
    return <CardNoContentMessage text={t(k.NO_SALARIES_IN_PLAN_YET)} role={noContentMessageRole} />
  }

  _getNoGrantsContentMessage() {
    const { t, noContentMessageRole } = this.props
    return <CardNoContentMessage text={t(k.NO_GRANTS_IN_PLAN_YET)} role={noContentMessageRole} />
  }
}

export default withTranslation()(FundingCard)
