import styled from 'styled-components'

const progressIndicator = new URL('../../../html/images/progress-indicator.png', import.meta.url)

export const Spinner = styled.div`
  animation: infinite-rotation 0.7s linear infinite;
  background: transparent url(${progressIndicator.toString()});
  background-size: contain;
  height: 16px;
  width: 16px;
`

export default Spinner
