import React from 'react'
import styled, { css } from 'styled-components'
import { DEFAULT_FONT } from './typography'
import { ADDITIONAL_YELLOW, BRAND_MAIN, GRAYSCALE_BLACK, GRAYSCALE_DARK_TEXT, LINK_BLUE } from './colors'
import { UniLabel } from './UniLabel'
import {
  Icon,
  ValidationErrorAfterContainer,
  ValidationHintAfterContainer,
  ValidationHintBeforeContainer
} from './UniInput'
import { useTranslation } from 'react-i18next'
import k from '../../../i18n/keys'

interface UniRadioProps<T> {
  id: string
  label: string
  name: string
  options: UniRadioOption<T>[]
  value: string
  onChange: (value: T) => void
  required?: boolean
  isValid?: boolean
  validationHintAfter?: string | JSX.Element
  validationHintBefore?: string | JSX.Element
  hasInvalidHighlight?: boolean
}

interface UniRadioOption<T> {
  value: T
  label: string
}

export const UniRadio = <T extends unknown>({
  id,
  label,
  name,
  options,
  value,
  onChange,
  required,
  isValid,
  validationHintAfter,
  validationHintBefore,
  hasInvalidHighlight
}: UniRadioProps<T>) => {
  const { t } = useTranslation()
  const isEmpty = !value
  const highlightInvalidField = hasInvalidHighlight && !isValid

  return (
    <Container>
      <UniLabel required={required} id={id} as="p">
        {label}
      </UniLabel>
      <RadioGroupContainer>
        {validationHintBefore && (
          <ValidationHintBeforeContainer id={`${id}-help-before`} aria-hidden="true">
            {validationHintBefore}
          </ValidationHintBeforeContainer>
        )}
        <RadioGroup
          role="radiogroup"
          aria-required={required}
          aria-labelledby={id}
          aria-describedby={
            validationHintBefore || validationHintAfter
              ? `${validationHintBefore ? `${id}-help-before ` : ''}${validationHintAfter ? `${id}-help-after ` : ''}`
              : undefined
          }
        >
          {options.map((option) => (
            <UniRadioOption<T>
              key={option.label}
              name={name}
              option={option}
              checked={option.value === value}
              onChange={() => onChange(option.value)}
            />
          ))}
        </RadioGroup>
        <ValidationHintAfterContainer
          id={validationHintAfter || highlightInvalidField ? `${id}-help-after` : undefined}
          showInvalidColor={highlightInvalidField}
          aria-hidden="true"
        >
          {highlightInvalidField ? (
            <ValidationErrorAfterContainer borderTop>
              <span>{isEmpty ? `${label} ${t(k.IS_MISSING)}` : t(k.THE_VALUE_ENTERED_IS_INVALID)}</span>
              <Icon info />
            </ValidationErrorAfterContainer>
          ) : null}
          {validationHintAfter}
        </ValidationHintAfterContainer>
      </RadioGroupContainer>
    </Container>
  )
}

const GAP_HEIGHT = 12

const Container = styled.div`
  align-items: start;
  display: flex;
  flex-direction: column;
  gap: ${GAP_HEIGHT}px;
`

const RadioGroupContainer = styled.div``

const RadioGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;

  &:not(:first-child) {
    margin-top: ${GAP_HEIGHT}px;
  }
`

interface UniRadioOptionProps<T> {
  name: string
  option: UniRadioOption<T>
  checked: boolean
  onChange: () => void
}

const UniRadioOption = <T extends unknown>({ name, option, checked, onChange }: UniRadioOptionProps<T>) => (
  <OptionContainer>
    <OptionInput type="radio" checked={checked} name={name} onChange={onChange} />
    <OptionIcon checked={checked} />
    <OptionText>{option.label}</OptionText>
  </OptionContainer>
)

const OptionContainer = styled.label`
  align-items: start;
  color: ${GRAYSCALE_DARK_TEXT};
  cursor: pointer;
  display: flex;
  font-size: 16px;
  font-weight: 400;
  gap: 6px;
  line-height: 24px;
  margin: 0;
  padding: 0;
  ${DEFAULT_FONT}

  &:hover {
    color: ${BRAND_MAIN};
  }
`

const OptionInput = styled.input`
  appearance: none;
  border: 0;
  clip: rect(0 0 0 0);
  margin: -1px !important;
  overflow: hidden;
  padding: 0 !important;
  position: absolute;
  width: 1px;
  height: 1px;
`

const OptionIcon = styled.div<{ checked: boolean }>`
  border: 2px solid ${LINK_BLUE};
  border-radius: 50%;
  flex-shrink: 0;
  height: 20px;
  margin-top: 1px;
  width: 20px;

  ${(props) =>
    props.checked &&
    css`
      background-image: radial-gradient(circle, ${GRAYSCALE_BLACK} 0, ${GRAYSCALE_BLACK} 5px, transparent 6px);
    `}

  ${OptionInput}:focus + & {
    outline: solid 2px ${ADDITIONAL_YELLOW};
    outline-offset: 2px;
  }
`

const OptionText = styled.span`
  letter-spacing: -0.4px;
  flex-grow: 1;
`
