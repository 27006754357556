import React, { createContext, PropsWithChildren } from 'react'
import { getQueryParameters } from './util'

export enum FeatureFlag {
  DummyFlag = 'DummyFlag'
}

const featureFlagQueryParameterKey = 'ff'
export const FeatureFlagContext = createContext<{ hasFeatureFlag: (featureFlag: FeatureFlag) => boolean }>({
  hasFeatureFlag: () => false
})

export const FeatureFlagContextProvider = ({ children }: PropsWithChildren<{}>) => {
  const queryParameters = getQueryParameters()
  const flags = queryParameters[featureFlagQueryParameterKey] || []
  const validFlags = flags.filter(isValidFeatureFlag)
  const hasFeatureFlag = (featureFlag: FeatureFlag) => validFlags.includes(featureFlag)
  return <FeatureFlagContext.Provider value={{ hasFeatureFlag }}>{children}</FeatureFlagContext.Provider>
}

function isValidFeatureFlag(possibleFeatureFlag: any): possibleFeatureFlag is FeatureFlag {
  return Object.values(FeatureFlag).includes(possibleFeatureFlag)
}
