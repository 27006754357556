import React from 'react'
import { useTranslation } from 'react-i18next'
import k from '../../../i18n/keys'
import FreeTextRows from './FreeTextRows'

const SummaryPositive = ({ reports }) => {
  const { t } = useTranslation()
  return <FreeTextRows heading={t(k.THREE_POSITIVE_THINGS)} reports={reports} reportFieldName="summaryPositive" />
}
export default SummaryPositive
