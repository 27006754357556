import React from 'react'
import styled from 'styled-components'
import { UniParagraph } from '../common/uni/UniParagraph'

const Title = ({ title }) => {
  return (
    <PreliminaryTitle
      id="preliminaryTitleOfDissertation"
      className="doctoral-candidate-overview__study-plan-section-preliminary-title-of-dissertation"
      noMargin
    >
      {title}
    </PreliminaryTitle>
  )
}

const PreliminaryTitle = styled(UniParagraph)`
  margin-bottom: 24px;
`

export default Title
