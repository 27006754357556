export interface ReportFields {
  phdCompletionPercentage: number
  graduationMonth: string
  timeSpentOfYearPercentage: number
  socialImpact: string
  otherWork: string
  summaryPositive: string
  summaryNegative: string
  progress: string
  careerPlanning: string
}

export enum ReportStatus {
  Draft = 'draft',
  Final = 'final',
  Submitted = 'submitted'
}
