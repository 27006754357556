import moment from 'moment'

export const DATE_FORMAT = {
  DEFAULT: 'YYYY-MM-DD',
  DEFAULT_LONG: 'YYYY-MM-DD HH:mm:ss',
  DEFAULT_MONTH: 'YYYY-MM',
  DISPLAY: 'D.M.YYYY',
  DISPLAY_GRADUATION: 'YYYY MMMM',
  DISPLAY_WRITTEN: 'Do MMMM YYYY'
}

export const DATE_FORMAT_DATE_FNS = {
  DISPLAY_DATE: 'd.M.y'
}

export const SISU_LAUNCH_TIMESTAMP = 1627765200

export const toDisplayFormat = (dateInDefaultFormat: string) =>
  moment(dateInDefaultFormat, DATE_FORMAT.DEFAULT).format(DATE_FORMAT.DISPLAY)

export const toGraduationMonthFormat = (dateInDefaultFormat: string) =>
  moment(dateInDefaultFormat, DATE_FORMAT.DEFAULT_MONTH).format(DATE_FORMAT.DISPLAY_GRADUATION)
