import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'
import k from '../../../i18n/keys'
import { Location } from 'history'
import React, { useState, useEffect, useRef } from 'react'
import { IndexLink, browserHistory } from 'react-router'
import isEqual from 'lodash/isEqual'
import unionWith from 'lodash/unionWith'
import { AuthenticatedUser } from '../../../model'
import { authenticatedUser } from '../../../stores/AuthenticationStore'
import { UniContainer } from '../uni/layout'
import { DEFAULT_FONT } from '../uni/typography'
import { fromTabletUp } from '../../styledUtils'
import { useActionOutside } from '../../../util'
import Navigation, { NavigationItemProperties } from './Navigation'
import { LinkStyles } from './LinkStyles'
import { DropdownStyles } from './DropdownStyles'
import Profile from './Profile'
import ToggleButton from './ToggleButton'

const MainMenu = () => {
  const ref = useRef(null)
  const { t } = useTranslation()
  const [isNavigationCollapsed, setIsNavigationCollapsed] = useState(true)
  const [isProfileCollapsed, setIsProfileCollapsed] = useState(true)
  const [currentAuthenticatedUser, setCurrentAuthenticatedUser] = useState<AuthenticatedUser>(null)
  const [location, setLocation] = useState<Location>(undefined)

  const collapseAll = () => {
    setIsProfileCollapsed(true)
    setIsNavigationCollapsed(true)
  }

  const { navigationItems, profileItems } = useGetNavigationItems(currentAuthenticatedUser, location, () =>
    collapseAll()
  )
  useActionOutside(() => collapseAll(), ref)
  useActionOutside(() => collapseAll(), ref)

  useEffect(() => {
    const unsubscribeFromLocation = browserHistory.listen((location: Location) => setLocation(location))
    const unsubscribeFromAuthenticatedUser = authenticatedUser.onValue((authenticatedUser: AuthenticatedUser) =>
      setCurrentAuthenticatedUser(authenticatedUser)
    )

    return () => {
      unsubscribeFromLocation()
      unsubscribeFromAuthenticatedUser()
    }
  }, [])

  const isLoading = currentAuthenticatedUser === null
  const isLoggedIn = typeof currentAuthenticatedUser !== 'undefined'

  return (
    <header>
      <Container wide ref={ref}>
        <DropdownToggleButton
          isCollapsed={isNavigationCollapsed}
          setIsCollapsed={setIsNavigationCollapsed}
          collapseAll={collapseAll}
          controls={'main-dropdown-menu'}
        />
        <HomeLink
          to={'/'}
          onClick={() => {
            setIsProfileCollapsed(true)
            setIsNavigationCollapsed(true)
          }}
        >
          {t(k.THESSA)}
        </HomeLink>
        <DropdownNavigation
          id={'main-dropdown-menu'}
          isLoading={isLoading}
          isCollapsed={isNavigationCollapsed}
          items={navigationItems}
        />
        <RowNavigation id={'main-menu'} isLoading={isLoading} items={navigationItems} />
        <HeaderProfile
          isLoading={isLoading}
          isLoggedIn={isLoggedIn}
          isCollapsed={isProfileCollapsed}
          setIsCollapsed={setIsProfileCollapsed}
          name={currentAuthenticatedUser?.firstName}
          items={profileItems}
          collapseAll={collapseAll}
        />
      </Container>
    </header>
  )
}

const Container = styled(UniContainer)`
  position: relative;
  z-index: 2;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  ${DEFAULT_FONT}
  margin-block: 24px;

  ${fromTabletUp(css`
    gap: 32px;
    margin-block: 32px;
  `)}
`

const DropdownToggleButton = styled(ToggleButton)`
  margin-left: -12px;

  ${fromTabletUp(css`
    display: none;
  `)}
`

const HomeLink = styled(IndexLink)`
  ${LinkStyles}
  padding-inline: 8px;
  align-items: flex-start;
  font-size: 18px;
  line-height: 18px;

  ${fromTabletUp(css`
    font-size: 24px;
    margin-left: -16px;
    padding-inline: 16px;
  `)}
`

const DropdownNavigation = styled(Navigation)<{ isCollapsed?: boolean }>`
  ${DropdownStyles('bottomLeft')}

  ${({ isCollapsed }) =>
    isCollapsed &&
    css`
      display: none;
    `}

  ${fromTabletUp(css`
    display: none;
  `)}
`

const RowNavigation = styled(Navigation)`
  display: none;

  ${fromTabletUp(css`
    display: flex;
  `)}
`

const HeaderProfile = styled(Profile)`
  display: flex;
  justify-content: flex-end;

  ${fromTabletUp(css`
    margin-inline-start: auto;
  `)}
`

const useGetNavigationItems = (
  user: AuthenticatedUser,
  location: Location,
  onClickFunction: () => void
): { navigationItems: NavigationItemProperties[]; profileItems: NavigationItemProperties[] } => {
  const { t } = useTranslation()

  if (!user) {
    const unauthenticatedNavigationItems = [
      { path: '/', title: t(k.FRONTPAGE), isActive: /^\/$/, location, onClickFunction },
      { path: '/about', title: t(k.ABOUT), isActive: /^\/about/, location, onClickFunction }
    ]

    return {
      navigationItems: unauthenticatedNavigationItems,
      profileItems: [{ path: '/', title: t(k.LOGIN), icon: 'login', location, onClickFunction }]
    }
  }

  const commonNavigationItems = [
    { path: '/help', title: t(k.HELP1), isActive: /^\/help/ },
    { path: '/about', title: t(k.ABOUT), isActive: /^\/about/ }
  ]

  const doctoralCandidateNavigationItems = [
    { path: '/phd-project', title: t(k.MY_PHD_PROJECT), isActive: /^\/phd-project/ }
  ]
  const supervisorNavigationItems = [
    {
      path: '/doctoral-candidates',
      title: t(k.MY_DOCTORAL_CANDIDATES1),
      isActive: /^\/doctoral-candidates/
    }
  ]

  const thesisCommitteeMemberNavigationItems = [
    {
      path: '/doctoral-candidates',
      title: t(k.MY_DOCTORAL_CANDIDATES1),
      isActive: /^\/doctoral-candidates/
    }
  ]

  const foremanNavigationItems = [
    {
      path: '/doctoral-candidates',
      title: t(k.MY_DOCTORAL_CANDIDATES1),
      isActive: /^\/doctoral-candidates/
    }
  ]

  const coordinatorNavigationItems = [
    {
      path: '/coordinator',
      title: t(k.COORDINATOR_TOOLS.MAIN_HEADING),
      isActive: /^\/coordinator$/
    },
    {
      path: '/coordinator/doctoral-candidates',
      title: t(k.DOCTORAL_CANDIDATES1),
      isActive: /^\/coordinator\/doctoral-candidates/
    },
    { path: '/users', title: t(k.USERS1), isActive: /^\/users/ },
    { path: '/statistics', title: t(k.STATISTICS1), isActive: /^\/statistics/ }
  ]

  const isDoctoralCandidate = user.doctoralCandidate !== undefined
  const isThesisCommitteeMember = user.isThesisCommitteeMember
  const isCoordinator = user.coordinator !== undefined

  let navigationItems: NavigationItemProperties[] = []

  if (isDoctoralCandidate) {
    navigationItems = unionWith(navigationItems, doctoralCandidateNavigationItems, isEqual)
  }
  if (user.isSupervisor) {
    navigationItems = unionWith(navigationItems, supervisorNavigationItems, isEqual)
  }
  if (isThesisCommitteeMember) {
    navigationItems = unionWith(navigationItems, thesisCommitteeMemberNavigationItems, isEqual)
  }
  if (isCoordinator) {
    navigationItems = unionWith(navigationItems, coordinatorNavigationItems, isEqual)
  }
  if (user.isForeman) {
    navigationItems = unionWith(navigationItems, foremanNavigationItems, isEqual)
  }
  navigationItems = unionWith(navigationItems, commonNavigationItems, isEqual)
  navigationItems = navigationItems.map((navigationItem) => ({ ...navigationItem, location, onClickFunction }))

  return {
    navigationItems,
    profileItems: [
      { path: '/reminders', title: t(k.REMINDERS1), isActive: /^\/reminders/, location, onClickFunction },
      { path: '/profile', title: t(k.PROFILE1), isActive: /^\/profile/, location, onClickFunction }
    ]
  }
}

export default MainMenu
