import React, { useContext, useEffect } from 'react'
import styled, { css } from 'styled-components'
import { matchSorter } from 'match-sorter'
import { useTranslation } from 'react-i18next'
import { useTable, useFilters, usePagination, useSortBy, useFlexLayout, useGroupBy } from 'react-table'
import { CSVLink } from 'react-csv'
import moment from 'moment'
import cloneDeep from 'lodash/cloneDeep'
import k from '../../../i18n/keys'
import Pagination from './Pagination'
import { useSessionStorage } from '../../../util'
import type { Row } from '../../../model'
import { DEFAULT_FONT } from '../uni/typography'
import { ADDITIONAL_YELLOW, GRAYSCALE_LIGHT, GRAYSCALE_MEDIUM_TEXT, GRAYSCALE_WHITE } from '../uni/colors'
import { UniParagraph } from '../uni/UniParagraph'
import { fromTabletUp, fromPhoneUp } from '../../styledUtils'
import { WindowWidthContext } from '../../../WindowWidthContext'
import { ButtonStyles, UniButtonProps } from '../uni/UniButton'
import { Actions } from '../uni/UniForm'
import VisuallyHidden from '../VisuallyHidden'
import CardFilters from './CardFilters'
import CardList from './CardList'

const formatDataForCsvExport = <T extends object>(rows: Row<T>[]) => {
  return cloneDeep(rows).map((row) => {
    const { original } = row
    delete original.doctoralCandidateId
    delete original.userId
    return original
  })
}

const getFilename = () => {
  const today = moment().format('YYYY-MM-DD')
  return `reporting-statistics-${today}.csv`
}

const fuzzyTextFilterFunction = <T extends object>(rows: Row<T>[], id: string[], filterValue: string) => {
  const [firstId] = id
  return matchSorter(rows, filterValue, {
    keys: [(row) => row.values[firstId]]
  })
}

fuzzyTextFilterFunction.autoRemove = (value: string) => !value

interface Properties {
  id: string
  className?: string
  columns: any //eslint-disable-line @typescript-eslint/no-explicit-any
  data: any //eslint-disable-line @typescript-eslint/no-explicit-any
  truncatedCells?: boolean
  sorting?: boolean
  filtering?: boolean
  pagination?: boolean
  showExportButton?: boolean
  showFooter?: boolean
  cardTitleAccessor?: string
  edgePadding?: boolean
  white?: boolean
  defaultPageSize?: number
  defaultSortByID?: string
  defaultSortByDesc?: boolean
}

const Table = ({
  id,
  className,
  columns,
  data,
  truncatedCells = true,
  sorting = false,
  filtering = false,
  pagination = false,
  showExportButton = false,
  showFooter = false,
  cardTitleAccessor = undefined,
  edgePadding = false,
  white = false,
  defaultPageSize,
  defaultSortByID,
  defaultSortByDesc = false
}: Properties) => {
  const { t } = useTranslation()
  const { isPhoneOrSmaller } = useContext(WindowWidthContext)
  const showCardView = isPhoneOrSmaller
  const [sessionStoragePageIndex, setSessionStoragePageIndex] = useSessionStorage(id, 0)
  const filterTypes = React.useMemo(
    () => ({
      fuzzyText: fuzzyTextFilterFunction
    }),
    []
  )

  const defaultColumn = React.useMemo(
    () => ({
      Filter: () => null
    }),
    []
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
    prepareRow
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      filterTypes,
      disableSortRemove: true,
      initialState: {
        pageIndex: sessionStoragePageIndex,
        ...(defaultPageSize ? { pageSize: defaultPageSize } : {}),
        ...(defaultSortByID
          ? {
              sortBy: [
                {
                  id: defaultSortByID,
                  desc: defaultSortByDesc
                }
              ]
            }
          : {})
      }
    },
    useFilters,
    useGroupBy,
    useSortBy,
    useFlexLayout,
    usePagination
  )

  const tableData = pagination ? page : rows

  useEffect(() => {
    setSessionStoragePageIndex(pageIndex)
  }, [pageIndex])

  return (
    <div id={id} className={className}>
      {showCardView ? (
        <>
          {sorting && filtering ? (
            <CardFilters id={id} headerGroups={headerGroups} sorting={sorting} filtering={filtering} />
          ) : null}
          {tableData.length > 0 ? (
            <CardList
              headerGroups={headerGroups}
              tableData={tableData}
              prepareRow={prepareRow}
              cardTitleAccessor={cardTitleAccessor}
              white={white}
            />
          ) : null}
        </>
      ) : (
        <Wrapper>
          <TableElement {...getTableProps()}>
            <Head white={white}>
              {headerGroups.map((headerGroup, key) => (
                <CellRow {...headerGroup.getHeaderGroupProps()} key={key} className={`${className}__heading`}>
                  {headerGroup.headers.map((column) => (
                    <Cell
                      key={key}
                      as="th"
                      {...column.getHeaderProps()}
                      small
                      noMargin
                      truncated={truncatedCells}
                      header
                      edgePadding={edgePadding}
                    >
                      {sorting ? (
                        <SortButton
                          {...column.getSortByToggleProps({ title: '' })}
                          aria-describedby={`${id}-sort-help`}
                          header
                        >
                          {column.render('Header')}
                          {column.isSorted ? (
                            <SortIndicator isSorted={column.isSorted || column.isSortedDesc}>
                              {column.isSortedDesc ? (
                                <span
                                  className="glyphicon glyphicon-sort-by-attributes"
                                  role="img"
                                  aria-hidden="true"
                                />
                              ) : (
                                <span
                                  className="glyphicon glyphicon-sort-by-attributes-alt"
                                  role="img"
                                  aria-hidden="true"
                                />
                              )}
                            </SortIndicator>
                          ) : null}
                        </SortButton>
                      ) : (
                        column.render('Header')
                      )}
                      {filtering ? (
                        <FilterElement header>{column.canFilter ? column.render('Filter') : null}</FilterElement>
                      ) : null}
                    </Cell>
                  ))}
                </CellRow>
              ))}
            </Head>
            <Body {...getTableBodyProps()}>
              {tableData.map((row, key) => {
                prepareRow(row)
                return (
                  <CellRow {...row.getRowProps()} body white={white} key={key} className={`${className}__list-row`}>
                    {row.cells.map((cell, key) => {
                      return (
                        <Cell
                          key={key}
                          as="td"
                          {...cell.getCellProps()}
                          small
                          noMargin
                          truncated={truncatedCells}
                          body
                          edgePadding={edgePadding}
                        >
                          {cell.render('Cell')}
                        </Cell>
                      )
                    })}
                  </CellRow>
                )
              })}
            </Body>
            {showFooter ? (
              <Footer>
                {footerGroups.map((group, key) => (
                  <CellRow {...group.getFooterGroupProps()} footer key={key} className={`${className}__footer-row`}>
                    {group.headers.map((column, key) => (
                      <Cell
                        key={key}
                        as="td"
                        {...column.getFooterProps()}
                        small
                        noMargin
                        truncated={truncatedCells}
                        footer
                        edgePadding={edgePadding}
                      >
                        {column.Footer ? column.render('Footer') : null}
                      </Cell>
                    ))}
                  </CellRow>
                ))}
              </Footer>
            ) : null}
          </TableElement>
        </Wrapper>
      )}

      {pagination ? (
        <Pagination
          id={id}
          previousPage={previousPage}
          canPreviousPage={canPreviousPage}
          pageIndex={pageIndex}
          gotoPage={gotoPage}
          pageOptions={pageOptions}
          pageSize={pageSize}
          setPageSize={setPageSize}
          nextPage={nextPage}
          canNextPage={canNextPage}
        />
      ) : null}

      <VisuallyHidden id={`${id}-sort-help`}>{t(k.SORT_BY)}</VisuallyHidden>

      {showExportButton ? (
        <Actions className="export-button" lastToEnd>
          <ExportButton data={formatDataForCsvExport(tableData)} filename={getFilename()}>
            {t(k.EXPORT)}
          </ExportButton>
        </Actions>
      ) : null}
    </div>
  )
}

const ExportButton = styled(CSVLink)`
  ${ButtonStyles}
`

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
`

const TableElement = styled.table`
  width: 100%;
  table-layout: fixed;
  border: none;
`

const Head = styled.thead<{
  white?: boolean
}>`
  border-bottom: 2px solid rgba(0, 0, 0, 0.04);
`

export const CellRow = styled.tr<{
  body?: boolean
  footer?: boolean
  white?: boolean
  border?: boolean
  grid?: boolean
}>`
  &:not(:last-child) {
    border-bottom: 2px solid rgba(0, 0, 0, 0.04);
  }

  ${({ body }) =>
    body &&
    css`
      position: relative;

      &:last-child {
        margin-bottom: 12px;
      }
    `}

  ${({ white }) =>
    white &&
    css`
      background-color: ${GRAYSCALE_WHITE};
    `}

  ${({ grid }) =>
    grid &&
    css`
      position: relative;
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 12px;
      row-gap: 4px;
      margin-bottom: 16px;
      padding: 8px 12px;

      &:not(:last-child) {
        border-bottom: 0;
      }

      ${fromPhoneUp(css`
        grid-template-columns: 1fr 1fr 1fr;
        column-gap: 24px;
      `)}
    `}

  ${({ border }) =>
    border &&
    css`
      border: 2px solid rgba(0, 0, 0, 0.04);

      &:not(:last-child) {
        border-bottom: 2px solid rgba(0, 0, 0, 0.04);
      }
    `}
`

const Body = styled.tbody``

const Footer = styled.tfoot`
  background-color: ${GRAYSCALE_LIGHT};
`

export const Cell = styled(UniParagraph)<{
  header?: boolean
  body?: boolean
  footer?: boolean
  $title?: boolean
  edgePadding?: boolean
  paddingInline?: boolean
  paddingBlock?: boolean
  fullWidth?: boolean
  white?: boolean
}>`
  font-weight: normal;
  transition: all 0.1s ease-in-out;

  ${({ paddingBlock = true }) =>
    paddingBlock &&
    css`
      padding-block: 4px;

      ${fromTabletUp(css`
        padding-block: 8px;
      `)};
    `}

  ${({ paddingInline = true }) =>
    paddingInline &&
    css`
      padding-inline: 8px;

      ${fromTabletUp(css`
        padding-inline: 16px;
      `)}
    `}

  ${({ edgePadding }) =>
    !edgePadding &&
    css`
      &:first-child {
        padding-left: 0px;
      }

      &:last-child {
        padding-right: 0px;
      }
    `}

  ${({ header }) =>
    header &&
    css`
      color: ${GRAYSCALE_MEDIUM_TEXT};
      display: flex;
      flex-flow: column nowrap;
    `}
    
  ${({ footer }) =>
    footer &&
    css`
      font-weight: 600;
      padding-block: 12px;
    `}

  ${({ $title }) =>
    $title &&
    css`
      font-weight: 600;
    `}

  ${({ fullWidth }) =>
    fullWidth &&
    css`
      width: 100%;
      grid-column: 1/-1;
    `}
`

export const SortButton = styled.button<UniButtonProps & { header?: boolean; details?: boolean }>`
  ${DEFAULT_FONT}
  font-weight: 600;

  ${({ header }) =>
    header &&
    css`
      position: relative;
      appearance: none;
      color: ${GRAYSCALE_MEDIUM_TEXT};
      border: 0;
      background: none;
      padding: 0;
      width: 100%;
      text-align: left;
      display: flex;
      align-items: center;
      transition: all 0.1s ease-in-out;

      &:hover {
        text-decoration: underline;
        text-underline-offset: 2px;
        text-decoration-thickness: 2px;
      }

      &:focus-visible {
        outline: solid 2px ${ADDITIONAL_YELLOW};
        outline-offset: -2px;
      }

      ${Cell}:first-child & {
        padding-left: 0;
      }

      ${Cell}:last-child & {
        padding-right: 0;
      }
    `}

  ${({ details }) =>
    details &&
    css`
      ${ButtonStyles}
      text-align: left;
    `}
`

export const SortIndicator = styled.span<{ isSorted: boolean }>`
  display: flex;
  align-items: center;
  padding: 0 5px;
  color: rgba(0, 0, 0, 0);
  transition: all 0.1s ease-in-out;

  ${({ isSorted }) =>
    isSorted &&
    css`
      color: inherit;
    `}

  ${SortButton}:hover & {
    color: inherit;
  }
`

export const FilterElement = styled.div<{ header?: boolean; details?: boolean }>`
  ${({ header }) =>
    header &&
    css`
      width: 100%;
      display: grid;

      &:not(:empty) {
        margin-top: 5px;

        ${Cell}:first-child & {
          padding-left: 0;
        }

        ${Cell}:last-child & {
          padding-right: 0;
        }
      }
    `}
`

export default Table
