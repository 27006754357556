import { useTranslation } from 'react-i18next'
import k from '../../i18n/keys'
import React from 'react'
import PropTypes from 'prop-types'
import { inject, observer } from 'mobx-react'
import SupervisionPlanList from '../supervision-plan/supervision-plan-list/SupervisionPlanList'
import { STATUS } from '../../storeConstants'
import PageBlock from '../common/page/PageBlock'
import PageContent from '../common/page/PageContent'
import { UniHeading2 } from '../common/uni/UniHeading'
import { UniParagraph } from '../common/uni/UniParagraph'

const SupervisionPlanOverview = inject('SupervisionPlanStore')(
  observer((props) => {
    const { t } = useTranslation()
    if (props.SupervisionPlanStore.status === STATUS.LOADING) {
      return null
    }

    return (
      <div className="doctoral-candidate-overview__supervision-plans-section">
        <PageBlock wide={true}>
          <PageContent border={false} paddingInline={false}>
            <UniHeading2 marginBottom>{t(k.SUPERVISION_PLAN, { count: 2 })}</UniHeading2>
            <SupervisionPlanList
              supervisionPlans={props.SupervisionPlanStore.supervisionPlans}
              doctoralCandidateId={props.doctoralCandidate.id}
              showCreateButton={props.isUserSupervisor}
              linkTarget={`${props.baseUrl}supervision-plans/`}
              noContentMessage={<NoContentMessage />}
            />
          </PageContent>
        </PageBlock>
      </div>
    )
  })
)

const NoContentMessage = () => {
  const { t } = useTranslation()
  return (
    <>
      <UniParagraph className="doctoral-candidate-overview__supervision-plans-section-empty">
        <em>{t(k.THERE_ARE_NO_SUPERVISION_PLANS)}</em>
      </UniParagraph>
      <UniParagraph className="doctoral-candidate-overview__supervision-plans-section-help" wide noMargin>
        {t(k.DOCTORAL_CANDIDATE_AND_SUPERVI)}
      </UniParagraph>
    </>
  )
}

SupervisionPlanOverview.propTypes = {
  baseUrl: PropTypes.string.isRequired,
  doctoralCandidate: PropTypes.object.isRequired,
  isUserSupervisor: PropTypes.bool,
  useFluidContainer: PropTypes.bool
}

SupervisionPlanOverview.defaultProps = {
  isUserSupervisor: false,
  useFluidContainer: false
}

export default SupervisionPlanOverview
