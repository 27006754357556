import { deleteMedia, downloadMedia, loadMedia, uploadMedia } from '../actions/ReportActions'
import { ajaxDelete, ajaxGet, ajaxPostFile } from '../AjaxUtil'

export const loadMediaResponse = loadMedia.flatMap(({ doctoralCandidateId, reportId }) =>
  ajaxGet(`/rest/doctoral-candidates/${doctoralCandidateId}/reports/${reportId}/load-media`)
)

export const deleteMediaResponse = deleteMedia.flatMap(({ doctoralCandidateId, reportId, mediaId }) =>
  ajaxDelete(`/rest/doctoral-candidates/${doctoralCandidateId}/reports/${reportId}/delete-media/${mediaId}`)
)

export const downloadMediaResponse = downloadMedia.flatMap(({ doctoralCandidateId, reportId, mediaId }) =>
  window.open(`/rest/doctoral-candidates/${doctoralCandidateId}/reports/${reportId}/download-media/${mediaId}`)
)

export const uploadMediaResponse = uploadMedia.flatMap(({ media, doctoralCandidateId, reportId, onComplete }) =>
  ajaxPostFile(`/rest/doctoral-candidates/${doctoralCandidateId}/reports/${reportId}/upload-media`, media, onComplete)
)
