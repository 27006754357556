import React from 'react'
import { withTranslation } from 'react-i18next'
import k from '../../../i18n/keys'
import Heading from './Heading'
import MediaList from '../../common/MediaList'
import { downloadMedia } from '../../../actions/ReportActions'
import { downloadMediaResponse } from '../../../stores/ReportStore'

class Attachments extends React.Component {
  componentDidMount() {
    this.unsubcribeFromDownloadMediaResponse = downloadMediaResponse.onValue()
  }

  componentWillUnmount() {
    this.unsubcribeFromDownloadMediaResponse()
  }

  render() {
    const { t } = this.props
    return (
      <div>
        <Heading text={t(k.ATTACHMENTS)} />
        {this.props.reports.map((report) => (
          <MediaList media={report.media} downloadMedia={this.downloadMedia.bind(this)} key={report.id} />
        ))}
      </div>
    )
  }

  downloadMedia(media) {
    downloadMedia.push({
      doctoralCandidateId: this.props.doctoralCandidate.id,
      reportId: media.model_id,
      mediaId: media.id
    })
  }
}

export default withTranslation()(Attachments)
