import { ConferenceOrMobilityType, ConferenceParticipationType } from './types'

export const formattedType: { [key in ConferenceOrMobilityType]: string } = {
  [ConferenceOrMobilityType.CONFERENCE]: 'Conference',
  [ConferenceOrMobilityType.RESEARCH_VISIT]: 'Research visit',
  [ConferenceOrMobilityType.OTHER]: 'Other'
}

export const formattedConferenceParticipation: {
  [key in ConferenceParticipationType]: string
} = {
  [ConferenceParticipationType.PARTICIPANT_POSTER]: 'Participant with poster',
  [ConferenceParticipationType.PARTICIPANT_TALK]: 'Participant with talk',
  [ConferenceParticipationType.PARTICIPANT_WITHOUT_PRESENTATION]: 'Participant without presentation',
  [ConferenceParticipationType.ORGANISER]: 'Organiser'
}
