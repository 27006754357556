import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import k from '../../i18n/keys'
import type { MyDoctoralCandidate } from './MyDoctoralCandidates'
import sortBy from 'lodash/sortBy'
import { Cell, HeadingCell, List } from '../common/list/List'
import styled from 'styled-components'
import { WindowWidthContext } from '../../WindowWidthContext'
import { PageBodyTextButton } from '../common/page/PageBodyTextButton'
import { DoctoralCandidateWithAccountRow } from './row/DoctoralCandidateWithAccountRow'
import { DoctoralCandidateWithoutAccount } from './row/DoctoralCandidateWithoutAccount'
import PageBodyText from '../common/page/PageBodyText'

interface DoctoralCandidateListProperties {
  doctoralCandidates: MyDoctoralCandidate[]
  loadingDoctoralCandidateId: number
}

export const DoctoralCandidateList = ({
  doctoralCandidates,
  loadingDoctoralCandidateId
}: DoctoralCandidateListProperties) => {
  const { t } = useTranslation()
  const { isDesktopOrLarger } = useContext(WindowWidthContext)
  const [showOnlyActiveStudyRights, setShowOnlyActiveStudyRights] = useState(true)
  const filteredDoctoralCandidates = doctoralCandidates.filter((doctoralCandidate) => {
    if (showOnlyActiveStudyRights) {
      return doctoralCandidate.hasActiveStudyRight
    }
    return true
  })
  const sortedDoctoralCandidates = sortBy(filteredDoctoralCandidates, 'name')
  const isHeadingRowVisible = sortedDoctoralCandidates.length > 0
  const hasNoDoctoralCandidates = doctoralCandidates.length === 0
  const isNoActiveDoctoralCandidatesNotificationVisible =
    showOnlyActiveStudyRights && sortedDoctoralCandidates.length === 0 && doctoralCandidates.length > 0
  const hasMoreDoctoralCandidates = doctoralCandidates.length > sortedDoctoralCandidates.length
  const isToggleOnlyActiveDoctoralCandidatesButtonVisible = hasMoreDoctoralCandidates || !showOnlyActiveStudyRights
  return (
    <Container>
      <List
        id="doctoral-candidate-list"
        columns={
          isDesktopOrLarger ? '0 max-content max-content 1fr max-content max-content' : '0 1fr max-content max-content'
        }
      >
        {isHeadingRowVisible && (
          <>
            <FirstHeadingCell>{t(k.NAME)}</FirstHeadingCell>
            {isDesktopOrLarger && <HeadingCell>{t(k.STUDENT_NUMBER)}</HeadingCell>}
            {isDesktopOrLarger && <HeadingCell>{t(k.PROGRESS)}</HeadingCell>}
            <HeadingCell>{t(k.STATUS)}</HeadingCell>
          </>
        )}
        {hasNoDoctoralCandidates && (
          <EmptyNotification clickable={false}>{t(k.YOU_HAVE_NO_DOCTORAL_CANDIDATE)}</EmptyNotification>
        )}
        {isNoActiveDoctoralCandidatesNotificationVisible && (
          <EmptyNotification clickable={false}>
            {t(k.YOU_HAVE_NO_ACTIVE_DOCTORAL_CANDIDATES_IN_THESSA)}
          </EmptyNotification>
        )}
        {sortedDoctoralCandidates.map((doctoralCandidate) => {
          const hasAccount = !!doctoralCandidate.doctoralCandidateId
          if (hasAccount) {
            return (
              <DoctoralCandidateWithAccountRow
                key={doctoralCandidate.studentNumber}
                doctoralCandidate={doctoralCandidate}
                isLoading={loadingDoctoralCandidateId === doctoralCandidate.doctoralCandidateId}
              />
            )
          }

          return (
            <DoctoralCandidateWithoutAccount
              key={doctoralCandidate.studentNumber}
              doctoralCandidate={doctoralCandidate}
            />
          )
        })}
      </List>
      {isToggleOnlyActiveDoctoralCandidatesButtonVisible && (
        <ToggleOnlyActiveStudyRightsContainer>
          <ToggleOnlyActiveStudyRightsButton onClick={() => setShowOnlyActiveStudyRights(!showOnlyActiveStudyRights)}>
            {showOnlyActiveStudyRights ? t(k.SHOW_ALL_DOCTORAL_CANDIDATES) : t(k.HIDE_INACTIVE_DOCTORAL_RESEARCHERS)}
          </ToggleOnlyActiveStudyRightsButton>
        </ToggleOnlyActiveStudyRightsContainer>
      )}
    </Container>
  )
}

const Container = styled.div``

const FirstHeadingCell = styled(HeadingCell)`
  grid-column-start: 2;
`

const EmptyNotification = styled(Cell)`
  grid-column: 2 / span 6;
`

const ToggleOnlyActiveStudyRightsContainer = styled(PageBodyText)`
  display: flex;
  justify-content: center;
  padding: 12px 15px;
`

const ToggleOnlyActiveStudyRightsButton = styled(PageBodyTextButton)`
  &:active,
  &:focus {
    outline: none;
  }
`
