import React from 'react'
import { useTranslation } from 'react-i18next'
import k from '../../i18n/keys'

interface ILoadingIndicator {
  id?: string
  className?: string
}

const LoadingIndicator: React.FC<ILoadingIndicator> = ({ id, className, children }) => {
  const { t } = useTranslation()
  return (
    <div id={id} className={className}>
      <div className="progress-indicator" /> {children || t(k.LOADING_WITH_ELLIPSIS)}
    </div>
  )
}

export default LoadingIndicator
