import React from 'react'
import { getUserId } from '../../stores/AuthenticationStore'
import { SupervisorRole } from '../../model'
import { Role } from '../../stores/role'
import { usePhdProject } from '../doctoral-candidate-overview/phd-project-context/PhdProjectContextProvider'

interface Roles {
  doctoralCandidate?: boolean
  supervisor?: boolean
  mainSupervisorForDoctoralCandidate?: { supervisorRelationships: Array<{ userId: number; roles: SupervisorRole[] }> }
  thesisCommitteeMember?: boolean
  coordinator?: boolean
  notDoctoralCandidate?: boolean
}

interface Properties extends Roles {
  children: React.ReactNode
}

export const WithRole = ({ children, ...properties }: Properties) => {
  const phdProject = usePhdProject()
  const { allowedRoles, mainSupervisorUserId } = useAllowedRoles(properties)

  if (allowedRoles.includes(phdProject.role) || mainSupervisorUserId === getUserId()) {
    return <>{children}</>
  }

  return null
}

const useAllowedRoles = (properties: Roles) => {
  const doctoralCandidate = properties.mainSupervisorForDoctoralCandidate
  const mainSupervisor = doctoralCandidate?.supervisorRelationships.find(({ roles }) =>
    roles.includes(SupervisorRole.MainSupervisor)
  )
  const allowedRoles = []
  if (properties.notDoctoralCandidate) {
    allowedRoles.push(Role.SUPERVISOR, Role.THESIS_COMMITTEE_MEMBER, Role.COORDINATOR)
  }
  properties.doctoralCandidate && allowedRoles.push(Role.DOCTORAL_CANDIDATE)
  properties.supervisor && allowedRoles.push(Role.SUPERVISOR)
  properties.thesisCommitteeMember && allowedRoles.push(Role.THESIS_COMMITTEE_MEMBER)
  properties.coordinator && allowedRoles.push(Role.COORDINATOR)

  return {
    allowedRoles,
    mainSupervisorUserId: mainSupervisor?.userId
  }
}
