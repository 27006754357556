import { useTranslation } from 'react-i18next'
import k from '../../i18n/keys'
import React from 'react'
import { withRouter } from 'react-router'
import { inject, observer } from 'mobx-react'
import { UniButton } from '../common/uni/UniButton'

const CreateSupervisionPlanButton = inject('SupervisionPlanStore')(
  observer(({ SupervisionPlanStore, doctoralCandidateId, router }) => {
    const { t } = useTranslation()

    return (
      <UniButton
        icon="plus"
        onClick={() => {
          SupervisionPlanStore.createSupervisionPlan(doctoralCandidateId).then((supervisionPlan) =>
            router.push(`/doctoral-candidates/${doctoralCandidateId}/supervision-plans/${supervisionPlan.id}`)
          )
        }}
      >
        {t(k.CREATE_SUPERVISION_PLAN)}
      </UniButton>
    )
  })
)

export default withRouter(CreateSupervisionPlanButton)
