import React, { ReactNode } from 'react'
import classNames from 'classnames'

export enum MessageType {
  DANGER,
  INFO,
  SUCCESS,
  WARNING
}

interface MessageProps {
  type: MessageType
  message: string | ReactNode
  className?: string
}

const Message = ({ type, message, className }: MessageProps) => {
  const classes = classNames(
    'alert',
    {
      'alert-danger': type === MessageType.DANGER,
      'alert-info': type === MessageType.INFO,
      'alert-success': type === MessageType.SUCCESS,
      'alert-warning': type === MessageType.WARNING
    },

    className
  )

  return <div className={classes}>{message}</div>
}

Message.defaultProps = {
  type: 'success'
}

export default Message
