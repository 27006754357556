import { useTranslation } from 'react-i18next'
import k from '../../../i18n/keys'
import React from 'react'
import last from 'lodash/last'
import sortBy from 'lodash/sortBy'
import PropTypes from 'prop-types'
import StatusOfPhDProject from './StatusOfPhDProject'
import TimeSpentOnPhD from './TimeSpentOnPhD'
import Publications from './Publications'
import Courses from './Courses'
import InternationalActivities from './InternationalActivities'
import Teaching from './Teaching'
import SocialImpact from './SocialImpact'
import OtherWork from './OtherWork'
import Funding from './Funding'
import SummaryPositive from './SummaryPositive'
import SummaryNegative from './SummaryNegative'
import Progress from './Progress'
import CareerPlanning from './CareerPlanning'
import Attachments from './Attachments'
import Conclusion from './Conclusion'
import { formatTimePeriod } from './Helpers'
import highlightBulletYellow from '../../../../html/images/highlight-bullet-yellow.svg'
import DoctoralCandidate from '../../../models/DoctoralCandidate'
import { usePhdProject } from '../../doctoral-candidate-overview/phd-project-context/PhdProjectContextProvider'
import PageBlock from '../../common/page/PageBlock'
import { UniParagraph } from '../../common/uni/UniParagraph'
import { generatePageTitle } from '../../../util'
import { Helmet } from 'react-helmet'

interface CumulativeReportProps {
  doctoralCandidate: DoctoralCandidate
  padInline?: boolean
}

const CumulativeReport = ({ doctoralCandidate, padInline = true }: CumulativeReportProps) => {
  const { t } = useTranslation()
  const phdProject = usePhdProject()
  const reports = sortBy(phdProject.reports, 'reportingPeriodEnd')
  const HighlightBullet = () => (
    <div
      className="cumulative-report__highlight-bullet glyphicon glyphicon-ok text-success"
      role="img"
      aria-label={`${t(k.COMPLETED_IN_THE_MOST_RECENT)} ${t(k.INDICATOR)}`}
    ></div>
  )

  const InProgressBullet = () => (
    <img
      src={highlightBulletYellow}
      className="cumulative-report__highlight-bullet"
      alt={`${t(k.IN_PROGRESS_IN_THE_MOST_RECE)} ${t(k.INDICATOR)}`}
    />
  )

  return (
    <PageBlock padTop padBottom padInline={padInline}>
      <Helmet>
        <title>{generatePageTitle(`${t(k.CUMULATIVE_REPORT_FOR)} ${doctoralCandidate.name}`, t(k.THESSA))}</title>
      </Helmet>
      <div className="cumulative-report">
        <h2 className="cumulative-report__main-heading">
          {t(k.CUMULATIVE_REPORT_FOR)} {doctoralCandidate.name}
        </h2>
        <div className="cumulative-report__free-text-content">
          <UniParagraph className="lead">{doctoralCandidate.preliminaryTitleOfDissertation}</UniParagraph>
        </div>
        <div className="cumulative-report__cumulative-report-period">
          {formatTimePeriod(doctoralCandidate.beginningOfStudies, last(reports).reportingPeriodEnd)}
        </div>
        <div className="cumulative-report__legend">
          <div>
            <HighlightBullet /> {`= `}
            {t(k.COMPLETED_IN_THE_MOST_RECENT)}
          </div>
          <div>
            <InProgressBullet /> {`= `}
            {t(k.IN_PROGRESS_IN_THE_MOST_RECE)}
          </div>
        </div>
        <StatusOfPhDProject reports={reports} />
        <TimeSpentOnPhD reports={reports} />
        <Publications reports={reports} />
        <Courses reports={reports} />
        <InternationalActivities reports={reports} />
        <Teaching reports={reports} />
        <SocialImpact reports={reports} />
        <OtherWork reports={reports} />
        <Funding reports={reports} />
        <SummaryPositive reports={reports} />
        <SummaryNegative reports={reports} />
        <Progress reports={reports} />
        <CareerPlanning reports={reports} />
        <Attachments reports={reports} doctoralCandidate={doctoralCandidate} />
        <Conclusion reports={reports} />
      </div>
    </PageBlock>
  )
}

CumulativeReport.propTypes = {
  doctoralCandidate: PropTypes.object.isRequired
}

export default CumulativeReport
