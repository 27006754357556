import { useTranslation } from 'react-i18next'
import k from '../../i18n/keys'
import React from 'react'
import { withRouter } from 'react-router'
import { createCourse } from '../../actions/PhDProjectActions'
import { createCourseResponse } from '../../stores/PhDProjectStore'
import CardActionButton from '../doctoral-candidate-overview/common/CardActionButton'

const CreateCourseButton = ({ router }) => {
  const { t } = useTranslation()
  return <CardActionButton onClick={onClickCreateCourseButtonFunction(router)}>{t(k.ADD_COURSE)}</CardActionButton>
}

export const onClickCreateCourseButtonFunction = (router) => () => {
  createCourseResponse.first().onValue((course) => {
    const path = `/phd-project/courses/${course.id}?isNew=true`
    router.push(path)
  })
  createCourse.push()
}

export default withRouter(CreateCourseButton)
