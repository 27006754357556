import styled, { css } from 'styled-components'
import React from 'react'
import { useTranslation } from 'react-i18next'
import Tooltip, { TooltipFocusableControl } from '../Tooltip'
import k from '../../../i18n/keys'
import { ADDITIONAL_YELLOW_MEDIUM_DARK } from '../uni/colors'

interface HighlightCellProperties {
  tooltip: string
}

export const HighlightCell = ({ tooltip }: HighlightCellProperties) => {
  const { t } = useTranslation()
  return (
    <HighlightCellContainer className="list-highlight-cell">
      <Tooltip content={tooltip}>
        <HighlightCellContent role="img" aria-label={`${tooltip} ${t(k.INDICATOR)}`} />
      </Tooltip>
    </HighlightCellContainer>
  )
}

export const List = styled.div<{ columns: string }>`
  border-bottom: 1px solid #eee;
  display: grid;
  grid-template-columns: ${(properties) => properties.columns};
`

export const HeadingCell = styled.div`
  align-self: end;
  color: #767676;
  cursor: default;
  font-size: 13px;
  font-weight: 500;
  text-transform: uppercase;
  padding: 0 15px 10px 15px;
`

export const Cell = styled.div<{ clickable?: boolean }>`
  align-self: stretch;
  border-top: 1px solid #eee;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  justify-content: center;
  padding: 12px 15px;

  ${(props) =>
    props.clickable === false &&
    css`
      cursor: default;
    `}
`

export const FirstCellInRow = styled(Cell)`
  grid-column-start: 1;
`

export const StrongCell = styled(Cell)`
  font-weight: 500;
`

export const IconCell = styled(Cell)`
  align-items: center;
  color: #428bca;
  display: flex;
  font-size: 16px;
  justify-content: center;

  .glyphicon {
    height: 16px;
    width: 16px;
  }
`

const HighlightCellContainer = styled.div`
  grid-column-start: 1;
  position: relative;
`

const HighlightCellContent = styled(TooltipFocusableControl)`
  background: ${ADDITIONAL_YELLOW_MEDIUM_DARK};
  border-radius: 50%;
  height: 12px;
  left: calc(50% - 6px);
  position: absolute;
  top: calc(50% - 6px);
  width: 12px;
`
