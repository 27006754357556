import React from 'react'
import classNames from 'classnames'
import './PageBodyText.scss'
import { UniParagraph } from '../uni/UniParagraph'

interface IPageBodyText {
  id?: string
  className?: string
}

const PageBodyText: React.FC<IPageBodyText> = ({ id, className, children }) => (
  <UniParagraph noMargin id={id} className={classNames('page-body-text', className)}>
    {children}
  </UniParagraph>
)

export default PageBodyText
