import React from 'react'
import { useTranslation } from 'react-i18next'
import { UniButton } from './uni/UniButton'
import { UniParagraph } from './uni/UniParagraph'
import { Actions } from './uni/UniForm'
import { HelperText } from './uni/UniLabel'
import VisuallyHidden from './VisuallyHidden'
import k from '../../i18n/keys'

const Media = (props) => {
  const { t } = useTranslation()

  return (
    <div className="media list-group-item">
      <Actions lastToEnd noPadTop>
        <UniParagraph noMargin>{props.media.file_name}</UniParagraph>
        {props.media.humanReadableSize ? (
          <HelperText>
            {'('}
            {props.media.humanReadableSize}
            {')'}
          </HelperText>
        ) : null}

        <Actions lastToEnd noPadTop>
          <UniButton small secondary onClick={() => props.downloadMedia(props.media)}>
            {t(k.DOWNLOAD)} <VisuallyHidden>{props.media.file_name}</VisuallyHidden>
          </UniButton>

          {props.deleteMedia ? (
            <UniButton small secondary icon="remove" className="delete" onClick={() => props.deleteMedia(props.media)}>
              {props.deleteButtonText} <VisuallyHidden>{props.media.file_name}</VisuallyHidden>
            </UniButton>
          ) : null}
        </Actions>
      </Actions>
    </div>
  )
}
export default Media
