import React from 'react'
import { useTranslation } from 'react-i18next'
import k from '../../i18n/keys'
import { UniInputWithLabel } from '../common/uni/UniInputWithLabel'
import { Actions } from '../common/uni/UniForm'
import { UniButton } from '../common/uni/UniButton'
import { UniContainer } from '../common/uni/layout'

const EditForm = ({ onChange, onKeyPress, onReset, onSave, title }) => {
  const { t } = useTranslation()
  return (
    <form id="preliminaryTitleOfDissertationForm" onReset={onReset} onSubmit={onSave}>
      <UniContainer paddingBottom paddingInline={false} wide>
        <Actions noPadTop>
          <UniInputWithLabel
            id="preliminaryTitleOfDissertationInput"
            label={t(k.PRELIMINARY_TITLE_OF_DISSERTAT)}
            visuallyHideLabelHelperText
            maxLength="255"
            onChange={onChange}
            onKeyDown={onKeyPress}
            placeholder={t(k.PRELIMINARY_TITLE_OF_DISSERTAT)}
            type="text"
            value={title}
            flex
          />
          <Actions noWrap>
            <UniButton id="preliminaryTitleOfDissertationSaveButton" type="submit">
              {t(k.SAVE)}
            </UniButton>
            <UniButton id="preliminaryTitleOfDissertationCancelButton" type="reset" value="Cancel" secondary>
              {t(k.CANCEL)}
            </UniButton>
          </Actions>
        </Actions>
      </UniContainer>
    </form>
  )
}

export default EditForm
