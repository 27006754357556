import React from 'react'
import PropTypes from 'prop-types'
import initial from 'lodash/initial'
import last from 'lodash/last'
import classNames from 'classnames'
import MarkdownIt from 'markdown-it'

import Heading from './Heading'

import { formatReportingPeriod } from './Helpers'
import styled from 'styled-components'
import { UniParagraphStyles } from '../../common/uni/UniParagraph'

const md = new MarkdownIt('default')

const FreeTextRows = (props) => {
  const initialReports = initial(props.reports)
  const lastReport = last(props.reports)
  return (
    <FreeTextRowsContainer>
      <Heading text={props.heading} />
      {initialReports.map((report) => (
        <FreeTextRow key={report.id} report={report} reportFieldName={props.reportFieldName} />
      ))}

      <FreeTextRow highlight report={lastReport} reportFieldName={props.reportFieldName} />
    </FreeTextRowsContainer>
  )
}

FreeTextRows.propTypes = {
  heading: PropTypes.string.isRequired,
  reports: PropTypes.arrayOf(PropTypes.object).isRequired,
  reportFieldName: PropTypes.string.isRequired
}

export default FreeTextRows

export const FreeTextRowsContainer = ({ children, hybrid = false }) => {
  const classes = classNames('cumulative-report__free-text-list', {
    'cumulative-report__free-text-list--hybrid': hybrid
  })

  return <div className={classes}>{children}</div>
}

export const FreeTextRow = ({ highlight = false, report, reportFieldName }) => {
  const isEmptyContent = !report[reportFieldName]
  if (isEmptyContent) {
    return null
  }

  const dates = formatReportingPeriod(report)
  return (
    <div className={`cumulative-report__free-text-row cumulative-report__field-${reportFieldName}`}>
      <div className="cumulative-report__free-text-heading">
        <div className="cumulative-report__highlight-bullet-container">
          {highlight && (
            <span className="cumulative-report__highlight-bullet glyphicon glyphicon-ok text-success" alt="" />
          )}
        </div>
        <h4>{dates}</h4>
      </div>
      <FreeText
        className="cumulative-report__free-text-content"
        dangerouslySetInnerHTML={{ __html: md.render(report[reportFieldName]) }}
      />
    </div>
  )
}

FreeTextRow.propTypes = {
  report: PropTypes.object.isRequired,
  reportFieldName: PropTypes.string.isRequired,
  highlight: PropTypes.bool
}

const FreeText = styled.div`
  ${UniParagraphStyles}
`
