import { Bus } from 'baconjs'

export const updateSignUpFormRole = new Bus()
export const updateSignUpFormFirstName = new Bus()
export const updateSignUpFormLastName = new Bus()
export const updateSignUpFormEmail = new Bus()
export const updateSignUpFormBeginningOfDoctoralStudies = new Bus()
export const updateSignUpFormDoctoralProgramme = new Bus()
export const signUp = new Bus()
export const signedUp = new Bus()
export const signUpExternalUser = new Bus()
export const signUpExternalThesisCommitteeMember = new Bus()
