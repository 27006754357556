import React from 'react'
import { UniList, UniListItem } from '../uni/UniList'
import { Cell, CellRow } from './Table'
import { HeaderGroup, Row as RowType } from 'react-table'

interface CardListProperties {
  headerGroups: HeaderGroup<
    {
      [key: string]: any //eslint-disable-line @typescript-eslint/no-explicit-any
    } & object
  >[]
  tableData: RowType<
    {
      [key: string]: any //eslint-disable-line @typescript-eslint/no-explicit-any
    } & object
  >[]
  prepareRow: (
    row: RowType<
      {
        [key: string]: any //eslint-disable-line @typescript-eslint/no-explicit-any
      } & object
    >
  ) => void
  cardTitleAccessor: string
  white: boolean
}

const CardList = ({ headerGroups, tableData, prepareRow, cardTitleAccessor, white }: CardListProperties) => {
  return (
    <UniList>
      {tableData.map((row, index) => {
        prepareRow(row)
        return (
          <UniListItem key={index} noBullet>
            <CellRow as="dl" white={white} border={!white} grid>
              {cardTitleAccessor ? (
                <Cell as="h4" $title={true} noMargin fullWidth edgePadding={true} paddingInline={false}>
                  {row.cells.find((cell) => cell.column.id === cardTitleAccessor)?.render('Cell')}
                </Cell>
              ) : null}

              {headerGroups
                .map((headerGroup) => {
                  return {
                    headers: headerGroup.headers.filter((column) => column.id !== cardTitleAccessor)
                  }
                })
                .map((headerGroup) => {
                  return headerGroup.headers.map((column) => {
                    const cell = row.cells.find((cell) => cell.column.id === column.id)
                    return cell ? (
                      <div>
                        <Cell as="dt" header noMargin edgePadding={true} paddingInline={false}>
                          {column.render('Header')}
                        </Cell>
                        <Cell as="dd" body noMargin edgePadding={true} paddingInline={false}>
                          {cell.render('Cell')}
                        </Cell>
                      </div>
                    ) : null
                  })
                })}
            </CellRow>
          </UniListItem>
        )
      })}
    </UniList>
  )
}

export default CardList
