import styled, { css } from 'styled-components'
import { fromTabletUp, fromDesktopUp } from '../../styledUtils'
import { GRAYSCALE_MEDIUM, GRAYSCALE_LIGHT, GRAYSCALE_WHITE } from './colors'

export const UniContainer = styled.div<{
  borderTop?: boolean
  borderBottom?: boolean
  border?: boolean
  gray?: boolean
  white?: boolean
  paddingTop?: boolean
  paddingBottom?: boolean
  paddingInline?: boolean
  marginBlock?: boolean
  wide?: boolean
  row?: boolean
}>`
  margin: 0 auto;
  width: 100%;
  max-width: 1216px;
  transition-duration: 0.1s;
  transition-property: all;

  ${({ borderTop = false }) =>
    borderTop &&
    css`
      border-top: 1px solid ${GRAYSCALE_MEDIUM};
    `}

  ${({ borderBottom = false }) =>
    borderBottom &&
    css`
      border-bottom: 1px solid ${GRAYSCALE_MEDIUM};
    `}

  ${({ border = false }) =>
    border &&
    css`
      border: 1px solid ${GRAYSCALE_MEDIUM};
    `}

  ${({ gray = false }) =>
    gray &&
    css`
      background-color: ${GRAYSCALE_LIGHT};
    `}

  ${({ white = false }) =>
    white &&
    css`
      background-color: ${GRAYSCALE_WHITE};
    `}

  ${({ paddingTop = false }) =>
    paddingTop &&
    css`
      padding-top: 16px;

      ${fromTabletUp(css`
        padding-top: 32px;
      `)}
    `}

  ${({ paddingBottom = false }) =>
    paddingBottom &&
    css`
      padding-bottom: 16px;

      ${fromTabletUp(css`
        padding-bottom: 32px;
      `)}
    `}

  ${({ paddingInline = true }) =>
    paddingInline &&
    css`
      padding-inline: 16px;

      ${fromTabletUp(css`
        padding-inline: 32px;
      `)}

      ${fromDesktopUp(css`
        padding-inline: 64px;
      `)}
    `}

  ${({ marginBlock = false }) =>
    marginBlock &&
    css`
      margin-block: 32px;
    `}
  
  ${({ wide = false }) =>
    wide &&
    css`
      max-width: 1600px;
    `}

  ${({ row }) =>
    row &&
    css`
      display: flex;
      flex-flow: row wrap;
    `}
`
