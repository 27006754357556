import { withTranslation } from 'react-i18next'
import k from '../../i18n/keys'
import React from 'react'
import Card, { CardNoContentMessage } from '../common/Card'
import PublicationList from './PublicationList'
import CreatePublicationButton from './CreatePublicationButton'
class PublicationsCard extends React.Component {
  render() {
    const { t } = this.props
    const cardAction = this._getCardAction()
    return (
      <Card heading={t(k.PUBLICATIONS)} cardAction={cardAction} modifier="publications">
        <PublicationList
          publications={this.props.doctoralCandidate.publications}
          doctoralCandidateId={this.props.doctoralCandidate.id}
          linkTarget={`${this.props.baseUrl}publications/`}
          noContentMessage={this._getNoContentMessage()}
          white
        />
      </Card>
    )
  }

  _getCardAction() {
    if (this.props.showCreateButton) {
      return <CreatePublicationButton />
    }
    return null
  }

  _getNoContentMessage() {
    const { t, noContentMessageRole } = this.props
    return <CardNoContentMessage text={t(k.NO_PUBLICATIONS_IN_PLAN_YET)} role={noContentMessageRole} />
  }
}

PublicationsCard.defaultProps = {
  showCreateButton: false
}

export default withTranslation()(PublicationsCard)
