import React from 'react'
import { withTranslation } from 'react-i18next'
import k from '../../../i18n/keys'
import sortBy from 'lodash/sortBy'
import moment from 'moment'
import { inject, observer } from 'mobx-react'
import CreateSupervisionPlanButton from '../CreateSupervisionPlanButton'
import SupervisionPlanListItem from './SupervisionPlanListItem'
import { ITEM_TYPE } from '../../highlight/constants'
import styled, { css } from 'styled-components'
import { fromDesktopUp, fromTabletUp } from '../../styledUtils'
import { UniContainer } from '../../common/uni/layout'

@inject('HighlightStore')
@observer
class SupervisionPlanList extends React.Component {
  render() {
    const { t } = this.props
    const { HighlightStore, doctoralCandidateId } = this.props
    const doctoralCandidateHighlights = HighlightStore.getDoctoralCandidateHighlights(doctoralCandidateId)
    const sortedSupervisionPlans = sortBy(this.props.supervisionPlans, ({ beginning, end }) => `${beginning}${end}`)
    const supervisionPlansForRendering = sortedSupervisionPlans.map(
      ({ id, isDraft, isActive, isPending, isExpired, beginning, end }) => {
        let title = ''

        if (end) {
          const beginningFormatted = moment(beginning, 'YYYY-MM-DD').format('D MMM YYYY')
          const endFormatted = moment(end, 'YYYY-MM-DD').format('D MMM YYYY')
          title = `${beginningFormatted} — ${endFormatted}`
        } else {
          const beginningFormatted = moment(beginning, 'YYYY-MM-DD').format('D MMM YYYY')
          title = t(k.SUPERVISION_PLAN_TITLE_NO_END_DATE, { startDate: beginningFormatted })
        }

        return {
          id,
          isDraft,
          isActive,
          isPending,
          isExpired,
          title,
          linkTarget: `${this.props.linkTarget}${id}`,
          newOrChanged: doctoralCandidateHighlights && doctoralCandidateHighlights[ITEM_TYPE.SUPERVISION_PLAN][id]
        }
      }
    )

    return (
      <div className="supervision-plan-list">
        <ListWrapper id="doctoral-candidate-overview__supervision-plans" className="supervision-plan-list__list">
          {supervisionPlansForRendering.length > 0 ? (
            <List aria-label="Supervision plans">
              {supervisionPlansForRendering.map((supervisionPlan) => (
                <SupervisionPlanListItem
                  key={supervisionPlan.id}
                  linkTarget={supervisionPlan.linkTarget}
                  title={supervisionPlan.title}
                  draft={supervisionPlan.isDraft}
                  active={supervisionPlan.isActive}
                  pending={supervisionPlan.isPending}
                  expired={supervisionPlan.isExpired}
                  newOrChanged={supervisionPlan.newOrChanged}
                />
              ))}
            </List>
          ) : null}
        </ListWrapper>
        {this._renderNoContentMessage()}
        {this._renderCreateButton()}
      </div>
    )
  }

  _renderNoContentMessage() {
    if (this.props.supervisionPlans && this.props.supervisionPlans.length === 0) {
      return this.props.noContentMessage
    }
    return null
  }

  _renderCreateButton() {
    if (this.props.showCreateButton) {
      return (
        <UniContainer className="supervision-plan-list__create-button" wide paddingInline={false} paddingTop>
          <CreateSupervisionPlanButton doctoralCandidateId={this.props.doctoralCandidateId} />
        </UniContainer>
      )
    }
    return null
  }
}

const ListWrapper = styled.div`
  min-height: 1px;
`

const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;

  ${fromTabletUp(
    css`
      grid-template-columns: repeat(4, 1fr);
      gap: 32px;
    `
  )}

  ${fromDesktopUp(
    css`
      grid-template-columns: repeat(5, 1fr);
      gap: 32px;
    `
  )}
`

export default withTranslation()(SupervisionPlanList)
