import { useTranslation } from 'react-i18next'
import k from '../../../i18n/keys'
import React from 'react'
import PropTypes from 'prop-types'
import last from 'lodash/last'
import Heading from './Heading'
import Table from './Table'
import { formatDate, formatMonth, createCell } from './Helpers'
import { UniProgressBar } from '../../common/uni/UniProgressBar'

const estimateMadeAtClassModifier = 'estimate-made-at'
const statusClassModifier = 'status'
const estimatedTimeOfPhdDefenceClassModifier = 'estimated-time-of-phd-defence'

const getCells = (report, t) => [
  createCell(formatDate(report.reportingPeriodEnd), estimateMadeAtClassModifier),
  createCell(
    [
      <UniProgressBar
        key="progress"
        id="progressStatusCell"
        valueNow={report.phdCompletionPercentage}
        label={`${report.phdCompletionPercentage}${`% `}${t(k.COMPLETE)}`}
        className={`${statusClassModifier}-progress`}
      />
    ],
    statusClassModifier
  ),
  createCell(formatMonth(report.graduationMonth), estimatedTimeOfPhdDefenceClassModifier)
]

const reportsToRows = (reports, t) => {
  const lastReport = last(reports)
  return reports.map((report) => {
    return {
      highlight: report.id === lastReport.id,
      cells: getCells(report, t)
    }
  })
}

const StatusOfPhDProject = (props) => {
  const { t } = useTranslation()
  const headings = [
    createCell(t(k.ESTIMATE_MADE_ON), estimateMadeAtClassModifier),
    createCell(t(k.PHD_PROJECT_STATUS), statusClassModifier),
    createCell(t(k.ESTIMATED_TIME_OF_PHD_DEFENCE), estimatedTimeOfPhdDefenceClassModifier)
  ]
  const rows = reportsToRows(props.reports, t)
  return (
    <div>
      <Heading text={t(k.STATUS_OF_THE_PHD_PROJECT)} />
      <Table headings={headings} rows={rows} label={t(k.STATUS_OF_THE_PHD_PROJECT)} />
    </div>
  )
}

StatusOfPhDProject.propTypes = {
  reports: PropTypes.arrayOf(PropTypes.object).isRequired
}

export default StatusOfPhDProject
