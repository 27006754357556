import React, { useState, useEffect } from 'react'
import moment, { Moment } from 'moment'
import { DATE_FORMAT } from '../../../dateConstants'
import { getMonthObject, getMonths } from '../MonthSelector'
import { UniSelectWithLabel } from './UniSelectWithLabel'
import { UniInputWithLabel } from './UniInputWithLabel'

interface UniDatePickerProps {
  id: string
  label: string
  value?: string
  min?: Moment
  max?: Moment
  onChange: (value: string) => void
  required?: boolean
  isValid?: boolean
  type?: 'date' | 'month'
  fullWidth?: boolean
  validationHintAfter?: string | JSX.Element
  validationHintBefore?: string | JSX.Element
  validationHintInline?: string | JSX.Element
  hasInvalidHighlight?: boolean
  visuallyHideLabel?: boolean
  visuallyHideLabelHelperText?: boolean
  className?: string
}

export const UniDatePicker = ({
  id,
  label,
  value,
  min,
  max,
  onChange,
  required,
  isValid,
  type = 'date',
  fullWidth,
  validationHintAfter,
  validationHintBefore,
  validationHintInline,
  hasInvalidHighlight,
  visuallyHideLabel,
  visuallyHideLabelHelperText,
  className
}: UniDatePickerProps) => {
  const [supportsMonth, setSupportsMonth] = useState(true)
  const { formattedMin, formattedMax, defaultMin, defaultMax } = formatMinMaxDates(min, max, type)

  useEffect(() => {
    const testInput = document.createElement('input')
    testInput.setAttribute('type', 'month')
    setSupportsMonth(testInput.type === 'month')
  }, [])

  return (
    <>
      {!supportsMonth && type === 'month' ? (
        <UniSelectWithLabel
          id={id}
          className={`form-control ${className}`}
          label={label}
          required={required}
          isValid={isValid}
          value={value}
          onChange={(value) => onChange(value)}
          options={[...getMonths(min ?? defaultMin, max ?? defaultMax).map(getMonthObject)]}
          fullWidth={fullWidth}
          validationHintAfter={validationHintAfter}
          validationHintBefore={validationHintBefore}
          validationHintInline={validationHintInline}
          visuallyHideLabel={visuallyHideLabel}
          visuallyHideLabelHelperText={visuallyHideLabelHelperText}
        />
      ) : (
        <UniInputWithLabel
          id={id}
          className={className}
          label={label}
          type={type}
          required={required}
          isValid={isValid}
          min={formattedMin}
          max={formattedMax}
          value={value}
          onChange={(value) => onChange(value)}
          fullWidth={fullWidth}
          validationHintAfter={validationHintAfter}
          validationHintBefore={validationHintBefore}
          validationHintInline={validationHintInline}
          hasInvalidHighlight={hasInvalidHighlight}
          visuallyHideLabel={visuallyHideLabel}
          visuallyHideLabelHelperText={visuallyHideLabelHelperText}
        />
      )}
    </>
  )
}

export function formatMinMaxDates(
  min: moment.Moment | undefined,
  max: moment.Moment | undefined,
  type: UniDatePickerProps['type']
): {
  formattedMin: string
  formattedMax: string
  defaultMin: moment.Moment
  defaultMax: moment.Moment
} {
  const dateFormat = type === 'month' ? DATE_FORMAT.DEFAULT_MONTH : DATE_FORMAT.DEFAULT

  const defaultMin = moment().subtract(20, 'years')
  const defaultMax = moment().add(20, 'years')

  let formattedMin = ''
  if (min) {
    formattedMin = min.format(dateFormat)
  } else {
    formattedMin = defaultMin.format(dateFormat)
  }

  let formattedMax = ''
  if (max) {
    formattedMax = max.format(dateFormat)
  } else {
    formattedMax = defaultMax.format(dateFormat)
  }

  return {
    formattedMin,
    formattedMax,
    defaultMin,
    defaultMax
  }
}
