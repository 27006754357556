import React from 'react'
import styled, { css } from 'styled-components'

export const HideStyles = css`
  display: block;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(1px);
  white-space: nowrap;
  position: absolute;
`

interface Properties {
  id?: string
  hidden?: boolean
  children?: React.ReactNode
}

const VisuallyHidden = ({ id, hidden, children }: Properties) => (
  <Hide id={id} aria-hidden={hidden}>
    {children}
  </Hide>
)

const Hide = styled.span`
  ${HideStyles}
`

export default VisuallyHidden
