import { useTranslation } from 'react-i18next'
import k from '../../i18n/keys'
import React from 'react'
import { withRouter } from 'react-router'
import { createGrant } from '../../actions/PhDProjectActions'
import { createGrantResponse } from '../../stores/PhDProjectStore'
import CardActionButton from '../doctoral-candidate-overview/common/CardActionButton'

const CreateGrantButton = ({ router }) => {
  const { t } = useTranslation()
  return <CardActionButton onClick={onClickCreateGrantButtonFunction(router)}>{t(k.ADD_GRANT)}</CardActionButton>
}

export const onClickCreateGrantButtonFunction = (router) => () => {
  createGrantResponse.first().onValue((grant) => {
    const path = `/phd-project/grants/${grant.id}?isNew=true`
    router.push(path)
  })
  createGrant.push()
}

export default withRouter(CreateGrantButton)
