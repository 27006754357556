import React, { forwardRef, TextareaHTMLAttributes, useEffect, useRef, useCallback, useState } from 'react'
import styled, { css } from 'styled-components'
import classnames from 'classnames'
import {
  ADDITIONAL_GREEN_DARK,
  ADDITIONAL_RED_DARK,
  ADDITIONAL_YELLOW,
  BRAND_MAIN_LIGHT,
  GRAYSCALE_DARK_TEXT,
  GRAYSCALE_MEDIUM_DARK,
  GRAYSCALE_WHITE
} from './colors'
import { DEFAULT_FONT } from './typography'
import {
  Icon,
  ValidationErrorAfterContainer,
  ValidationHintAfterContainer,
  ValidationHintBeforeContainer
} from './UniInput'
import { useTranslation } from 'react-i18next'
import k from '../../../i18n/keys'
import { wasUpdatedNow } from '../../styledUtils'

interface UniTextareaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  label?: string
  action?: React.ReactNode
  fullWidth?: boolean
  isValid?: boolean
  validationHintAfter?: string | JSX.Element
  validationHintBefore?: string | JSX.Element
  hasInvalidHighlight?: boolean
  hasUpdatedHighlight?: boolean
}

const BORDER_HEIGHT = 2

export enum TextareaStateIcon {
  Required,
  Valid,
  Invalid
}

export const UniTextarea = forwardRef<HTMLTextAreaElement, UniTextareaProps>(
  (
    {
      id,
      label,
      required,
      action,
      isValid,
      validationHintAfter,
      validationHintBefore,
      hasInvalidHighlight,
      hasUpdatedHighlight,
      ...props
    },
    forwardedRef
  ) => {
    const { t } = useTranslation()
    const [hasFocus, setHasFocus] = useState(false)
    const onFocus = useCallback(() => setHasFocus(true), [])
    const onBlur = useCallback(() => setHasFocus(false), [])
    const textareaRef = useRef<HTMLTextAreaElement>()
    const ref = forwardedRef || textareaRef
    const hasIcon = required === true && isValid !== undefined
    const isEmpty = !props.value
    const { icon, highlightInvalidField } = getIcon(required, isValid, hasInvalidHighlight, isEmpty, hasFocus)

    useEffect(() => {
      if (typeof ref !== 'function') ref.current?.setAttribute('style', 'height: auto')
      if (typeof ref !== 'function')
        ref.current?.setAttribute('style', `height: ${ref.current?.scrollHeight + BORDER_HEIGHT}px`)
    }, [props.value])

    return (
      <TextareaWrapper>
        {validationHintBefore && (
          <ValidationHintBeforeContainer
            id={`${id}-help-before`}
            showInvalidColor={highlightInvalidField}
            aria-hidden="true"
          >
            {validationHintBefore}
          </ValidationHintBeforeContainer>
        )}
        <Container>
          <TextareaContainer>
            <Textarea
              id={id}
              ref={ref}
              showInvalidBorder={highlightInvalidField}
              showUpdatedBorder={hasUpdatedHighlight}
              aria-invalid={highlightInvalidField ? 'true' : undefined}
              hasIcon={hasIcon}
              onFocus={onFocus}
              onBlur={onBlur}
              aria-required={required ? 'true' : undefined}
              aria-describedby={
                validationHintBefore || validationHintAfter || highlightInvalidField
                  ? `${validationHintBefore ? `${id}-help-before ` : ''}${
                      validationHintAfter || highlightInvalidField ? `${id}-help-after ` : ''
                    }`
                  : undefined
              }
              {...props}
            />
            {hasIcon && (
              <IconContainer icon={icon} className="validated-form-group__validation-status">
                <Icon
                  valid={icon === TextareaStateIcon.Valid}
                  required={icon === TextareaStateIcon.Required}
                  info={icon === TextareaStateIcon.Invalid}
                  className={classnames({
                    'glyphicon-ok': icon === TextareaStateIcon.Valid
                  })}
                />
              </IconContainer>
            )}
            {action ? <Action>{action}</Action> : null}
          </TextareaContainer>
        </Container>
        <ValidationHintAfterContainer
          id={validationHintAfter || highlightInvalidField ? `${id}-help-after` : undefined}
          showInvalidColor={highlightInvalidField}
          aria-hidden="true"
        >
          {highlightInvalidField ? (
            <ValidationErrorAfterContainer>
              {isEmpty ? `${label} ${t(k.IS_MISSING)}` : t(k.THE_VALUE_ENTERED_IS_INVALID)}
            </ValidationErrorAfterContainer>
          ) : null}
          {validationHintAfter}
        </ValidationHintAfterContainer>
      </TextareaWrapper>
    )
  }
)

UniTextarea.displayName = 'UniTextarea'

function getIcon(
  isRequired: boolean,
  isValid: boolean,
  hasInvalidHighlight: boolean,
  isEmpty: boolean,
  hasFocus: boolean
): { icon: TextareaStateIcon | undefined; highlightInvalidField: boolean } {
  if (isRequired) {
    if (hasInvalidHighlight) {
      return { icon: TextareaStateIcon.Invalid, highlightInvalidField: true }
    }
    if (isValid) {
      return { icon: TextareaStateIcon.Valid, highlightInvalidField: false }
    }
    if (hasFocus) {
      return { icon: TextareaStateIcon.Required, highlightInvalidField: false }
    }
    if (isEmpty) {
      return { icon: TextareaStateIcon.Required, highlightInvalidField: false }
    }
    if (isValid === undefined) {
      return { icon: undefined, highlightInvalidField: false }
    }
    return { icon: TextareaStateIcon.Invalid, highlightInvalidField: true }
  }
  return { icon: undefined, highlightInvalidField: false }
}

export const TextareaWrapper = styled.div``

const Container = styled.div`
  line-height: 0;
`

const TextareaContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: stretch;
`

const Textarea = styled.textarea<{
  showInvalidBorder?: boolean
  showUpdatedBorder?: boolean
  fullWidth?: boolean
  hasIcon?: boolean
  action?: boolean
}>`
  background: ${GRAYSCALE_WHITE};
  border: 1px solid ${GRAYSCALE_MEDIUM_DARK};
  color: ${GRAYSCALE_DARK_TEXT};
  min-height: 46px;
  font-size: 16px;
  line-height: 24px;
  padding: 8px 12px;
  width: 100%;
  resize: none;
  ${DEFAULT_FONT}

  &:focus {
    border: 1px solid ${BRAND_MAIN_LIGHT};
    outline: solid 2px ${ADDITIONAL_YELLOW};
    outline-offset: 2px;
  }

  ${(props) =>
    props.showInvalidBorder &&
    css`
      border-color: ${ADDITIONAL_RED_DARK};
      box-shadow: 0 0 0 1px ${ADDITIONAL_RED_DARK};
    `}

  ${(props) => wasUpdatedNow(props.showUpdatedBorder)}

  ${(props) =>
    props.fullWidth &&
    css`
      width: 100%;
    `}

  ${(props) =>
    props.hasIcon &&
    css`
      padding-right: 32px;
    `}

    ${(props) =>
    props.action &&
    css`
      border-right-width: 0;
    `}
`

const Action = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${GRAYSCALE_MEDIUM_DARK};
  border-left-width: 0;
`

export const IconContainer = styled.div<{ icon: TextareaStateIcon }>`
  display: flex;
  align-items: flex-start;
  position: absolute;
  top: 0px;
  bottom: 0px;
  right: 12px;
  padding-top: 20px;

  ${(props) =>
    props.icon !== undefined &&
    css`
      color: ${colorByIcon[props.icon]};
    `}
`

const colorByIcon: { [key in TextareaStateIcon]: string } = {
  [TextareaStateIcon.Required]: BRAND_MAIN_LIGHT,
  [TextareaStateIcon.Valid]: ADDITIONAL_GREEN_DARK,
  [TextareaStateIcon.Invalid]: ADDITIONAL_RED_DARK
}
