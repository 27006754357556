import React, { KeyboardEvent, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import k from '../../../i18n/keys'
import { formattedConferenceParticipation, formattedType } from '../constants'
import { ConferenceOrMobilityType, ConferenceParticipationType } from '../types'
import { Actions, UniForm } from '../../common/uni/UniForm'
import { UniRadio } from '../../common/uni/UniRadio'
import { UniInputWithLabelAndField } from '../../common/uni/UniInputWithLabelAndField'
import { UniDateRangePicker } from '../../common/uni/UniDateRangePicker'
import { UniTextareaWithLabelAndField } from '../../common/uni/UniTextareaWithLabelAndField'
import { UniButton } from '../../common/uni/UniButton'
import { ContactUsLink } from '../../common/ContactUs'
import { useFields } from './useFields'
import InternationalActivity from '../../../models/InternationalActivity'
import { useSubmit } from './useSubmit'

const KEY_CODE_ESC = 27

interface ConferenceAndMobilityFormProps {
  onSave: (item: InternationalActivity) => Promise<void>
  onCancel: () => void
  item?: InternationalActivity
}

export const ConferenceAndMobilityForm = ({
  item: conferenceOrMobility,
  onSave,
  onCancel
}: ConferenceAndMobilityFormProps) => {
  const { t } = useTranslation()
  const fields = useFields(conferenceOrMobility)
  const { submit, isSaving, saveError } = useSubmit(fields, onSave)

  const onKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.keyCode === KEY_CODE_ESC) {
        onCancel()
      }
    },
    [onCancel]
  )

  return (
    <UniForm
      onSubmit={submit}
      onKeyDown={onKeyDown}
      role="presentation"
      className="conference-and-mobility__form"
      statusMessage={getStatusMessage(isSaving, fields.canSubmit, saveError, t)}
    >
      <UniInputWithLabelAndField
        required
        id={`conference-and-mobility__name--${fields.id}`}
        autoFocus
        className="conference-and-mobility__name"
        label={t(k.NAME)}
        field={fields.name}
        isValidating={isSaving}
      />

      <UniInputWithLabelAndField
        required
        id={`conference-and-mobility__location--${fields.id}`}
        className="conference-and-mobility__location"
        label={t(k.LOCATION)}
        field={fields.location}
        isValidating={isSaving}
      />

      <UniDateRangePicker
        id={`conference-and-mobility__date`}
        required
        label={t(k.DATES)}
        ariaStartLabel={t(k.BEGINNING_DATE)}
        ariaEndLabel={t(k.END_DATE)}
        start={fields.dates.value.start}
        end={fields.dates.value.end}
        onChangeStart={(value) => fields.dates.set({ start: value, end: fields.dates.value.end })}
        onChangeEnd={(value) => fields.dates.set({ start: fields.dates.value.start, end: value })}
      />

      <UniRadio<ConferenceOrMobilityType>
        required
        label={t(k.TYPE)}
        id={`conference-and-mobility__type--${fields.id}`}
        name={`conference-and-mobility__type--${fields.id}`}
        options={Object.values(ConferenceOrMobilityType).map((type) => ({ value: type, label: formattedType[type] }))}
        value={fields.type.value}
        isValid={Boolean(fields.type.value)}
        hasInvalidHighlight={isSaving && !fields.type.value}
        onChange={(value) => fields.type.set(value)}
        validationHintBefore={'Please select one of the following types.'}
      />

      {fields.type.value === ConferenceOrMobilityType.CONFERENCE && (
        <UniRadio<ConferenceParticipationType>
          required
          label={t(k.ROLE_IN_CONFERENCE)}
          id={`conference-and-mobility__participation-type--${fields.id}`}
          name={`conference-and-mobility__participation-type--${fields.id}`}
          options={Object.values(ConferenceParticipationType).map((type) => ({
            value: type,
            label: formattedConferenceParticipation[type]
          }))}
          value={fields.conferenceParticipation.value}
          isValid={Boolean(fields.conferenceParticipation.value)}
          hasInvalidHighlight={isSaving && !fields.conferenceParticipation.value}
          onChange={(value) => fields.conferenceParticipation.set(value)}
          validationHintBefore={'Please select your role in the conference.'}
        />
      )}

      <UniTextareaWithLabelAndField
        id={`conference-and-mobility__description--${fields.id}`}
        className="conference-and-mobility__description"
        label={t(k.DESCRIPTION)}
        field={fields.description}
        isValidating={isSaving}
      />

      <Actions lastToEnd>
        <UniButton type="submit" className="conference-and-mobility__save">
          {t(k.SAVE)}
        </UniButton>

        <UniButton type="button" secondary onClick={onCancel}>
          {t(k.CANCEL)}
        </UniButton>
      </Actions>
    </UniForm>
  )
}

const getStatusMessage = (
  isSaving: boolean,
  canSubmit: boolean,
  saveError: string | null,
  t: (key: string) => string
) => {
  if (saveError) {
    return (
      <>
        {t(saveError)}
        &nbsp;
        <ContactUsLink>{t(k.CONTACT_US)}</ContactUsLink>
        &nbsp;
        {t(k.IF_THE_PROBLEM_DOES_NOT_GO_AWA)}
      </>
    )
  }
  if (isSaving && !canSubmit) {
    return t(k.PLEASE_FILL_OUT_ALL_REQUIRED_FIELDS)
  }
  return undefined
}
