import { useTranslation } from 'react-i18next'
import k from '../../i18n/keys'
import React from 'react'
import { withRouter } from 'react-router'

import { createPublication } from '../../actions/PhDProjectActions'
import { createPublicationResponse } from '../../stores/PhDProjectStore'

import CardActionButton from '../doctoral-candidate-overview/common/CardActionButton'

const CreatePublicationButton = ({ router }) => {
  const { t } = useTranslation()
  return (
    <CardActionButton onClick={onClickCreatePublicationButtonFunction(router)}>{t(k.ADD_PUBLICATION)}</CardActionButton>
  )
}

export const onClickCreatePublicationButtonFunction = (router) => () => {
  createPublicationResponse.first().onValue((publication) => {
    const path = `/phd-project/publications/${publication.id}?isNew=true`
    router.push(path)
  })
  createPublication.push()
}

export default withRouter(CreatePublicationButton)
