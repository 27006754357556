import {
  ConferenceOrMobilityType,
  ConferenceParticipationType,
  getConferenceParticipation,
  getConferenceRoleAndParticipationType,
  getConferenceType
} from '../types'
import { Dates, Field } from '../../common/uni/formTypes'
import InternationalActivity from '../../../models/InternationalActivity'
import { useDatesField, useEnumField, useStringField } from '../../common/uni/field'

export interface Fields {
  id: number
  name: Field
  location: Field
  dates: Field<Dates>
  type: Field<ConferenceOrMobilityType>
  conferenceParticipation: Field<ConferenceParticipationType>
  description: Field
  canSubmit: boolean
}

export function useFields(conferenceOrMobility?: InternationalActivity): Fields {
  const id = conferenceOrMobility?.id ?? 0
  const name = useStringField(conferenceOrMobility?.name, (name) => name.trim().length > 0)
  const location = useStringField(conferenceOrMobility?.location, (location) => location.trim().length > 0)
  const dates = useDatesField(conferenceOrMobility?.beginsAt, conferenceOrMobility?.endsAt)
  const type = useEnumField(getConferenceType(conferenceOrMobility))
  const conferenceParticipation = useEnumField(
    getConferenceParticipation(conferenceOrMobility),
    (conferenceParticipation) =>
      type.value === ConferenceOrMobilityType.CONFERENCE
        ? conferenceParticipation !== null &&
          Object.values(ConferenceParticipationType).includes(conferenceParticipation)
        : true
  )
  const description = useStringField(conferenceOrMobility?.description)

  const fields = {
    name,
    location,
    dates,
    type,
    conferenceParticipation,
    description
  }

  const canSubmit = Object.values(fields).every((field) => field.isValid)

  return { id, ...fields, canSubmit }
}

export function fieldsToConferenceOrMobility(fields: Fields): InternationalActivity {
  const { conferenceRole, participationType } = getConferenceRoleAndParticipationType(
    fields.conferenceParticipation.value
  )
  return new InternationalActivity({
    id: fields.id,
    name: fields.name.value.trim(),
    location: fields.location.value.trim(),
    beginsAt: fields.dates.value.start,
    endsAt: fields.dates.value.end,
    type: fields.type.value,
    description: fields.description.value.trim(),
    conferenceRole,
    participationType
  })
}
