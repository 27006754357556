import { useTranslation } from 'react-i18next'
import k from '../../i18n/keys'
import React, { PropsWithChildren, ReactNode, useCallback } from 'react'
import { Link, withRouter, WithRouterProps } from 'react-router'
import sortBy from 'lodash/sortBy'
import last from 'lodash/last'
import { observer } from 'mobx-react'
import Tooltip, { TooltipFocusableControl, TooltipSmallMessageContainer } from '../common/Tooltip'
import { usePhdProject } from '../doctoral-candidate-overview/phd-project-context/PhdProjectContextProvider'
import { usePhdProjectActions } from '../doctoral-candidate-overview/phd-project-context/usePhdProjectActions'
import { Field } from '../doctoral-candidate-overview/types'
import { ReportResponse } from '../../models/Report'
import { ReportStatus } from './types'
import { UniButton } from '../common/uni/UniButton'

const CreateReportButton = observer(({ router }: WithRouterProps) => {
  const { t } = useTranslation()
  const phdProject = usePhdProject()
  const actions = usePhdProjectActions()

  const onCreateReport = useCallback(() => {
    actions.createReport().then((report) => {
      router.push(`/phd-project/reports/${report.id}`)
    })
  }, [])

  const lastReport = last(sortBy(phdProject[Field.Reports], 'periodEnd'))
  const hasLastReport = phdProject[Field.Reports].length > 0
  const isLastReportDraft = hasLastReport && lastReport.status === ReportStatus.Draft
  if (isLastReportDraft) {
    return <DisabledButton message={<DraftMessage draftReport={lastReport} />} />
  }

  const isLastReportSubmitted = hasLastReport && lastReport.status === ReportStatus.Submitted
  if (isLastReportSubmitted) {
    return <DisabledButton message={<SubmittedMessage submittedReport={lastReport} />} />
  }

  return <CreateButton onClick={onCreateReport}>{t(k.CREATE_REPORT)}</CreateButton>
})

interface DisabledButtonProps {
  message: ReactNode
}

const DisabledButton = ({ message }: DisabledButtonProps) => (
  <Tooltip content={message}>
    <TooltipFocusableControl>
      <CreateButton disabled />
    </TooltipFocusableControl>
  </Tooltip>
)

interface CreateButtonProps {
  disabled?: boolean
  onClick?: () => void
}

const CreateButton = ({ disabled, onClick }: PropsWithChildren<CreateButtonProps>) => {
  const { t } = useTranslation()
  return (
    <UniButton disabled={disabled} onClick={onClick} icon="plus">
      {t(k.CREATE_REPORT)}
    </UniButton>
  )
}

interface DraftMessageProps {
  draftReport: ReportResponse
}

const DraftMessage = ({ draftReport }: DraftMessageProps) => {
  const { t } = useTranslation()
  return (
    <TooltipSmallMessageContainer>
      <p>{t(k.CANNOT_CREATE_REPORT_WHEN_A_PR)}</p>
      <UniButton as={Link} to={`/phd-project/reports/${draftReport.id}`}>
        {t(k.OPEN_REPORT)}
      </UniButton>
    </TooltipSmallMessageContainer>
  )
}

interface SubmittedMessageProps {
  submittedReport: ReportResponse
}

const SubmittedMessage = ({ submittedReport }: SubmittedMessageProps) => {
  const { t } = useTranslation()
  return (
    <TooltipSmallMessageContainer>
      <p>{t(k.CANNOT_CREATE_REPORT_WHEN_A_PR1)}</p>
      <UniButton as={Link} to={`/phd-project/reports/${submittedReport.id}`}>
        {t(k.OPEN_REPORT)}
      </UniButton>
    </TooltipSmallMessageContainer>
  )
}

export default withRouter(CreateReportButton)
