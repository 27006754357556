import { Fields, fieldsToConferenceOrMobility } from './useFields'
import InternationalActivity from '../../../models/InternationalActivity'
import { FormEvent, useState } from 'react'
import k from '../../../i18n/keys'

export function useSubmit(fields: Fields, onSave: (conferenceOrMobility: InternationalActivity) => Promise<void>) {
  const [isSaving, setIsSaving] = useState(false)
  const [saveError, setSaveError] = useState<string | null>(null)

  const submit = (event: FormEvent) => {
    event.preventDefault()
    setSaveError(null)
    setIsSaving(true)

    if (fields.canSubmit) {
      onSave(fieldsToConferenceOrMobility(fields)).catch(() => {
        setIsSaving(false)
        setSaveError(k.FAILED_TO_CREATE_CONFERENCE_OR_MOBILITY)
      })
    }
  }

  return { submit, isSaving, saveError }
}
