import React, { forwardRef } from 'react'
import { Field } from './formTypes'
import { UniInputWithLabel, UniInputWithLabelProps } from './UniInputWithLabel'

interface UniInputWithLabelAndFieldProps extends Omit<UniInputWithLabelProps, 'value' | 'onChange' | 'isValid'> {
  field: Field
  isValidating?: boolean
}

export const UniInputWithLabelAndField = forwardRef<HTMLInputElement, UniInputWithLabelAndFieldProps>(
  ({ field, isValidating, ...restProps }, ref) => (
    <UniInputWithLabel
      ref={ref}
      value={field.value}
      onChange={field.set}
      isValid={field.isValid}
      hasInvalidHighlight={isValidating && !field.isValid}
      {...restProps}
    />
  )
)

UniInputWithLabelAndField.displayName = 'UniInputWithLabelAndField'
