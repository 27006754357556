import React from 'react'
import { UniTextareaWithLabel, UniTextareaWithLabelProps } from './UniTextareaWithLabel'
import { Field } from './formTypes'

interface UniTextareaWithLabelAndFieldProps extends Omit<UniTextareaWithLabelProps, 'value' | 'onChange'> {
  field: Field
  isValidating?: boolean
}

export const UniTextareaWithLabelAndField = ({
  field,
  isValidating,
  ...restProps
}: UniTextareaWithLabelAndFieldProps) => (
  <UniTextareaWithLabel
    value={field.value}
    onChange={field.set}
    isValid={field.isValid}
    hasInvalidHighlight={isValidating && !field.isValid}
    {...restProps}
  />
)

UniTextareaWithLabelAndField.displayName = 'UniTextareaWithLabelAndField'
