export const BRAND_MAIN = '#0e688b'
export const BRAND_MAIN_SOFT = '#b1e7ff'
export const BRAND_MAIN_BRIGHT = '#48c5f8'
export const BRAND_MAIN_LIGHT = '#107eab'
export const BRAND_MAIN_ACTIVE = '#005379'
export const BRAND_MAIN_DARK = '#003146'
export const BRAND_MAIN_NEARLY_BLACK = '#000222'
export const LINK_BLUE = '#0479a4'
export const LINK_DISABLED = '#767676'
export const GRAYSCALE_WHITE = '#fff'
export const GRAYSCALE_SLIGHTLY_GRAY = '#fefefe'
export const GRAYSCALE_LIGHT = '#f8f8f8'
export const GRAYSCALE_MEDIUM = '#d2d2d2'
export const GRAYSCALE_MEDIUM_TEXT = '#757575'
export const GRAYSCALE_BACKGROUND_BOX = '#f5f5f5'
export const GRAYSCALE_TABS_BORDER = '#e6e6e6'
export const GRAYSCALE_BACKGROUND_ARROW = '#dfdfdf'
export const GRAYSCALE_MEDIUM_DARK = '#979797'
export const GRAYSCALE_DARK = '#555555'
export const GRAYSCALE_DARK_TEXT = '#222222'
export const GRAYSCALE_BLACK = '#000000'
export const ADDITIONAL_RED_SOFT = '#ff6b76'
export const ADDITIONAL_RED_LIGHT = '#e5053a'
export const ADDITIONAL_RED_DARK = '#a31621'
export const ADDITIONAL_PURPLE_LIGHT = '#420039'
export const ADDITIONAL_YELLOW = '#f9a21a'
export const ADDITIONAL_YELLOW_MEDIUM_DARK = '#D18305'
export const ADDITIONAL_YELLOW_DARK = '#A66703'
export const ADDITIONAL_SKYBLUE = '#48c5f8'
export const ADDITIONAL_BLUE_SOFT = '#eef2fa'
export const ADDITIONAL_BLUE = '#107eab'
export const ADDITIONAL_ORANGE = '#d14600'
export const ADDITIONAL_GREEN_SOFT = '#83e172'
export const ADDITIONAL_GREEN_LIGHT = '#96ba3c'
export const ADDITIONAL_GREEN_DARK = '#006400'
export const HOVER_GRAY = '#eaeaea'
