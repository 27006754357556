import $ from 'jquery'
import { CustomHeaders } from './network'

class BaseApi {
  get(path) {
    return $.ajax(path, {
      headers: { ...CustomHeaders.localization() }
    })
  }

  post(path, body) {
    return $.ajax(path, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'X-XSRF-TOKEN': getCookie('XSRF-TOKEN'),
        ...CustomHeaders.localization()
      },
      contentType: 'application/json',
      data: body ? JSON.stringify(body) : undefined
    })
  }

  put(path, body) {
    return $.ajax(path, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'X-XSRF-TOKEN': getCookie('XSRF-TOKEN'),
        ...CustomHeaders.localization()
      },
      contentType: 'application/json',
      data: body ? JSON.stringify(body) : undefined
    })
  }

  delete(path) {
    return $.ajax(path, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'X-XSRF-TOKEN': getCookie('XSRF-TOKEN'),
        ...CustomHeaders.localization()
      }
    })
  }
}

export const getCookie = (cookieName) => {
  const name = `${cookieName}=`
  const cookies = document.cookie.split(';')
  for (let i = 0; i < cookies.length; i += 1) {
    let cookie = cookies[i]
    while (cookie.charAt(0) === ' ') {
      cookie = cookie.substring(1)
    }
    if (cookie.indexOf(name) === 0) {
      return decodeURIComponent(cookie.substring(name.length, cookie.length))
    }
  }
  return ''
}

export default BaseApi
