import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'
import {
  ADDITIONAL_RED_DARK,
  ADDITIONAL_YELLOW,
  GRAYSCALE_DARK_TEXT,
  GRAYSCALE_MEDIUM_DARK,
  GRAYSCALE_WHITE
} from './colors'
import { DEFAULT_FONT } from './typography'
import moment, { Moment } from 'moment'
import { DATE_FORMAT } from '../../../dateConstants'
import { useTranslation } from 'react-i18next'
import k from '../../../i18n/keys'
import {
  Icon,
  IconContainer,
  InputContainer as OriginalInputContainer,
  InputStateIcon,
  InputWrapper,
  ValidationHintAfterContainer,
  ValidationHintBeforeContainer,
  ValidationHintInlineContainer,
  ValidationErrorAfterContainer
} from './UniInput'
import { UniFieldsetWithLegend } from './UniFieldsetWithLegend'
import { getMonthOption, getMonths } from '../MonthSelector'
import { formatMinMaxDates } from './UniDatePicker'
import { fromPhoneUp } from '../../styledUtils'

interface UniDateRangePickerProps {
  id: string
  startId?: string
  endId?: string
  label: string
  ariaStartLabel: string
  ariaEndLabel: string
  start?: string
  end?: string
  min?: Moment
  max?: Moment
  onChangeStart: (value: string) => void
  onChangeEnd: (value: string) => void
  required?: boolean
  type?: 'date' | 'month'
  validationHintAfter?: string | JSX.Element
  validationHintBefore?: string | JSX.Element
  validationHintInline?: string | JSX.Element
}

export const UniDateRangePicker = ({
  id,
  startId,
  endId,
  label,
  ariaStartLabel,
  ariaEndLabel,
  start,
  end,
  min,
  max,
  onChangeStart,
  onChangeEnd,
  required,
  type = 'date',
  validationHintAfter,
  validationHintBefore,
  validationHintInline
}: UniDateRangePickerProps) => {
  const [isFocused, setIsFocused] = useState(false)
  const [supportsMonth, setSupportsMonth] = useState(true)
  const { icon, highlightInvalidField, hint } = useIconAndHint(start, end, isFocused)
  const { formattedMin, formattedMax, defaultMin, defaultMax } = formatMinMaxDates(min, max, type)

  useEffect(() => {
    const testInput = document.createElement('input')
    testInput.setAttribute('type', 'month')
    setSupportsMonth(testInput.type === 'month')
  }, [])

  return (
    <UniFieldsetWithLegend legend={label} required={required}>
      <InputWrapper>
        {validationHintBefore && (
          <ValidationHintBeforeContainer
            id={`${id}-help-before`}
            showInvalidColor={highlightInvalidField}
            aria-hidden="true"
          >
            {validationHintBefore}
          </ValidationHintBeforeContainer>
        )}
        <Container validationHintInline={validationHintInline}>
          <InputContainer showInvalidBorder={highlightInvalidField} hasIcon={icon}>
            {!supportsMonth && type === 'month' ? (
              <>
                <Select
                  id={startId}
                  aria-label={ariaStartLabel}
                  className="form-control"
                  value={start}
                  onChange={(event) => onChangeStart(event.target.value)}
                >
                  {getMonths(min ?? defaultMin, max ?? defaultMax).map(getMonthOption)}
                </Select>
                <Separator aria-hidden="true">{'–'}</Separator>
                <Select
                  id={endId}
                  aria-label={ariaEndLabel}
                  className="form-control"
                  value={end}
                  onChange={(event) => onChangeEnd(event.target.value)}
                >
                  {getMonths(min ?? defaultMin, max ?? defaultMax).map(getMonthOption)}
                </Select>
              </>
            ) : (
              <>
                <Input
                  id={startId}
                  aria-label={ariaStartLabel}
                  type={type}
                  min={formattedMin}
                  max={formattedMax}
                  onFocus={() => setIsFocused(true)}
                  onBlur={() => setIsFocused(false)}
                  value={start}
                  onChange={(event) => onChangeStart(event.target.value)}
                  aria-required={required}
                  aria-describedby={
                    validationHintBefore || validationHintInline || validationHintAfter
                      ? `${validationHintBefore ? `${id}-help-before ` : ''}${
                          validationHintInline ? `${id}-help-inline ` : ''
                        }${validationHintAfter ? `${id}-help-after ` : ''}`
                      : undefined
                  }
                />
                <Separator aria-hidden="true">{'–'}</Separator>
                <Input
                  id={endId}
                  aria-label={ariaEndLabel}
                  type={type}
                  min={formattedMin}
                  max={formattedMax}
                  onFocus={() => setIsFocused(true)}
                  onBlur={() => setIsFocused(false)}
                  value={end}
                  onChange={(event) => onChangeEnd(event.target.value)}
                  aria-required={required}
                  aria-describedby={
                    validationHintBefore || validationHintInline || validationHintAfter
                      ? `${validationHintBefore ? `${id}-help-before ` : ''}${
                          validationHintInline ? `${id}-help-inline ` : ''
                        }${validationHintAfter ? `${id}-help-after ` : ''}`
                      : undefined
                  }
                />
              </>
            )}

            <IconContainer icon={icon}>
              <Icon
                valid={icon === InputStateIcon.Valid}
                required={icon === InputStateIcon.Required}
                info={icon === InputStateIcon.Invalid}
              />
            </IconContainer>
          </InputContainer>

          {validationHintInline && (
            <ValidationHintInlineContainer
              id={`${id}-help-inline`}
              showInvalidColor={highlightInvalidField}
              aria-hidden="true"
            >
              {validationHintInline}
            </ValidationHintInlineContainer>
          )}
        </Container>
        <ValidationHintAfterContainer
          id={validationHintAfter || highlightInvalidField ? `${id}-help-after` : undefined}
          showInvalidColor={highlightInvalidField}
          aria-hidden="true"
        >
          {highlightInvalidField ? <ValidationErrorAfterContainer>{hint}</ValidationErrorAfterContainer> : null}
          {validationHintAfter}
        </ValidationHintAfterContainer>
      </InputWrapper>
    </UniFieldsetWithLegend>
  )
}

function useIconAndHint(
  start: string,
  end: string,
  isFocused: boolean
): { icon: InputStateIcon | undefined; highlightInvalidField: boolean; hint: string | undefined } {
  const { t } = useTranslation()
  const startMoment = moment(start, DATE_FORMAT.DEFAULT)
  const endMoment = moment(end, DATE_FORMAT.DEFAULT)

  if (!startMoment.isValid()) {
    return {
      icon: isFocused ? InputStateIcon.Required : InputStateIcon.Invalid,
      highlightInvalidField: !isFocused,
      hint: t(k.DATE_RANGE_PICKER.INVALID_START_DATE)
    }
  }

  if (!endMoment.isValid()) {
    return {
      icon: isFocused ? InputStateIcon.Required : InputStateIcon.Invalid,
      highlightInvalidField: !isFocused,
      hint: t(k.DATE_RANGE_PICKER.INVALID_END_DATE)
    }
  }

  if (!startMoment.isSameOrBefore(endMoment)) {
    return {
      icon: isFocused ? InputStateIcon.Required : InputStateIcon.Invalid,
      highlightInvalidField: !isFocused,
      hint: t(k.DATE_RANGE_PICKER.INVALID_END_IS_BEFORE_START)
    }
  }

  return { icon: InputStateIcon.Valid, highlightInvalidField: false, hint: undefined }
}

const Container = styled.div<{ validationHintInline?: string | JSX.Element }>`
  position: relative;

  ${(props) =>
    props.validationHintInline &&
    css`
      display: flex;
    `}
`

const InputContainer = styled(OriginalInputContainer)<{ showInvalidBorder?: boolean; hasIcon?: InputStateIcon }>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: ${GRAYSCALE_WHITE};
  border: 1px solid ${GRAYSCALE_MEDIUM_DARK};
  color: ${GRAYSCALE_DARK_TEXT};
  height: 44px;
  font-size: 16px;
  width: 100%;
  ${DEFAULT_FONT}

  ${fromPhoneUp(css`
    gap: 12px;
  `)}

  ${(props) =>
    props.showInvalidBorder &&
    css`
      border-color: ${ADDITIONAL_RED_DARK};
      box-shadow: 0 0 0 1px ${ADDITIONAL_RED_DARK};
    `}

    ${(props) =>
    props.hasIcon &&
    css`
      padding-right: 36px;
    `}
`

const Separator = styled.div``

const Select = styled.select`
  background: transparent;
  border: none;
  width: auto !important;
  height: 44px;
  font-size: 16px;
  padding: 8px 12px;
  box-shadow: none;

  &:focus {
    outline: solid 2px ${ADDITIONAL_YELLOW};
    outline-offset: 2px;
  }
`

const Input = styled.input<{ type: UniDateRangePickerProps['type'] }>`
  background: transparent;
  border: none;
  height: 44px;
  padding: 8px 12px;
  cursor: text;
  color-scheme: light;

  &:focus {
    outline: solid 2px ${ADDITIONAL_YELLOW};
    outline-offset: 2px;
  }

  &::-webkit-calendar-picker-indicator {
    position: relative;
    background-position: center;
    background-size: 22px;
    height: 44px;
    width: 44px;
    opacity: 0.6;

    &:hover,
    &:focus {
      cursor: pointer;
      opacity: 1;
    }

    &:focus {
      outline: solid 2px ${ADDITIONAL_YELLOW};
      outline-offset: -6px;
    }
  }
`
