import { HTMLAttributes } from 'react'
import styled, { css } from 'styled-components'
import { BRAND_MAIN_NEARLY_BLACK } from './colors'
import { DEFAULT_FONT } from './typography'
import { fromDesktopUp, fromTabletUp } from '../../styledUtils'

interface UniHeadingProps extends HTMLAttributes<HTMLHeadingElement> {
  grow?: boolean
  small?: boolean
  marginTop?: boolean
  marginBottom?: boolean
}

export const UniHeading1 = styled.h1<UniHeadingProps>`
  ${DEFAULT_FONT}
  color: ${BRAND_MAIN_NEARLY_BLACK};
  text-transform: uppercase;
  transition-duration: 0.1s;
  transition-property: all;
  margin: 0;

  ${({ marginTop }) =>
    marginTop &&
    css`
      margin-block-start: 16px;

      ${fromTabletUp(css`
        margin-block-start: 24px;
      `)}
    `}

  ${({ marginBottom }) =>
    marginBottom &&
    css`
      margin-block-end: 16px;

      ${fromTabletUp(css`
        margin-block-end: 24px;
      `)}
    `}

  ${({ grow = true }) =>
    grow &&
    css`
      flex: 1 1 auto;
    `}

  ${({ small }) =>
    small
      ? css`
          font-size: 28px;
          font-weight: 700;
          letter-spacing: -0.7px;
          line-height: 32px;

          ${fromTabletUp(css`
            font-size: 34px;
            letter-spacing: -0.85px;
            line-height: 40px;
          `)}

          ${fromDesktopUp(css`
            font-size: 42px;
            letter-spacing: -1.05px;
            line-height: 48px;
          `)}
        `
      : css`
          font-size: 32px;
          font-weight: 700;
          letter-spacing: -0.8px;
          line-height: 38px;

          ${fromTabletUp(css`
            font-size: 46px;
            letter-spacing: -1.15px;
            line-height: 54px;
          `)}

          ${fromDesktopUp(css`
            font-size: 52px;
            letter-spacing: -1.3px;
            line-height: 60px;
          `)}
        `}
`

export interface UniHeading2Props extends UniHeadingProps {
  withDivider?: boolean
}

export const UniHeading2 = styled.h2<UniHeading2Props>`
  ${DEFAULT_FONT}
  color: ${BRAND_MAIN_NEARLY_BLACK};
  display: flex;
  align-items: center;
  font-size: 26.4px;
  font-weight: 600;
  letter-spacing: -0.6px;
  line-height: 33px;
  transition-duration: 0.1s;
  transition-property: all;
  margin: 0;

  ${fromDesktopUp(css`
    font-size: 34px;
    letter-spacing: -0.68px;
    line-height: 40px;
  `)}

  ${({ marginTop }) =>
    marginTop &&
    css`
      margin-block-start: 16px;

      ${fromTabletUp(css`
        margin-block-start: 24px;
      `)}
    `}

  ${({ marginBottom }) =>
    marginBottom &&
    css`
      margin-block-end: 16px;

      ${fromTabletUp(css`
        margin-block-end: 24px;
      `)}
    `}

  ${({ grow = true }) =>
    grow &&
    css`
      flex: 1 1 auto;
    `}

  ${({ withDivider }) =>
    withDivider &&
    css`
      &:after {
        content: '';
        flex: 1 1 0;
        inline-size: 100%;
        block-size: 1px;
        margin-inline-start: 1em;
        background-color: ${BRAND_MAIN_NEARLY_BLACK};
      }
    `}

  ${({ small }) =>
    small &&
    css`
      font-size: 22px;
      font-weight: 600;
      letter-spacing: -0.8px;
      line-height: 27px;

      ${fromTabletUp(css`
        font-size: 26.4px;
        letter-spacing: -0.6px;
        line-height: 33px;
      `)}
    `}
`

export interface UniHeading3Props extends UniHeadingProps {
  withDivider?: boolean
  small?: boolean
}

export const UniHeading3 = styled.h3<UniHeading3Props>`
  ${DEFAULT_FONT}
  color: ${BRAND_MAIN_NEARLY_BLACK};
  display: flex;
  align-items: center;
  font-size: 22px;
  font-weight: 600;
  letter-spacing: -0.8px;
  line-height: 27px;
  transition-duration: 0.1s;
  transition-property: all;
  margin: 0;

  ${({ marginTop }) =>
    marginTop &&
    css`
      margin-block-start: 16px;

      ${fromTabletUp(css`
        margin-block-start: 24px;
      `)}
    `}

  ${({ marginBottom }) =>
    marginBottom &&
    css`
      margin-block-end: 16px;

      ${fromTabletUp(css`
        margin-block-end: 24px;
      `)}
    `}

  ${({ grow = true }) =>
    grow &&
    css`
      flex: 1 1 auto;
    `}

  ${({ withDivider }) =>
    withDivider &&
    css`
      &:after {
        content: '';
        flex: 1 1 0;
        inline-size: 100%;
        block-size: 1px;
        margin-inline-start: 1em;
        background-color: ${BRAND_MAIN_NEARLY_BLACK};
      }
    `}

  ${({ small }) =>
    small &&
    css`
      font-size: 18px;
      font-weight: 600;
      letter-spacing: -0.56px;
      line-height: 27px;

      ${fromTabletUp(css`
        font-size: 22px;
        letter-spacing: -0.8px;
        line-height: 27px;
      `)}
    `}
`

export interface UniHeading4Props extends UniHeadingProps {
  withDivider?: boolean
  small?: boolean
}

export const UniHeading4 = styled.h4<UniHeading4Props>`
  ${DEFAULT_FONT}
  color: ${BRAND_MAIN_NEARLY_BLACK};
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: -0.56px;
  line-height: 27px;
  transition-duration: 0.1s;
  transition-property: all;
  margin: 0;

  ${({ marginTop }) =>
    marginTop &&
    css`
      margin-block-start: 16px;

      ${fromTabletUp(css`
        margin-block-start: 24px;
      `)}
    `}

  ${({ marginBottom }) =>
    marginBottom &&
    css`
      margin-block-end: 16px;

      ${fromTabletUp(css`
        margin-block-end: 24px;
      `)}
    `}

  ${({ grow = true }) =>
    grow &&
    css`
      flex: 1 1 auto;
    `}

  ${({ withDivider }) =>
    withDivider &&
    css`
      &:after {
        content: '';
        flex: 1 1 0;
        inline-size: 100%;
        block-size: 1px;
        margin-inline-start: 1em;
        background-color: ${BRAND_MAIN_NEARLY_BLACK};
      }
    `}

  ${({ small }) =>
    small &&
    css`
      font-size: 16px;
      font-weight: 600;
      letter-spacing: -0.5px;
      line-height: 24px;

      ${fromTabletUp(css`
        font-size: 18px;
        letter-spacing: -0.56px;
        line-height: 27px;
      `)}
    `}
`
