import $ from 'jquery'
import Bacon from 'baconjs'
import { CustomHeaders } from './network'
import { maintenanceModeResponses } from './stores/AuthenticationStore'

function getCookie(cookieName) {
  const name = `${cookieName}=`
  const cookies = document.cookie.split(';')
  for (let i = 0; i < cookies.length; i += 1) {
    let cookie = cookies[i]
    while (cookie.charAt(0) === ' ') {
      cookie = cookie.substring(1)
    }
    if (cookie.indexOf(name) === 0) {
      return decodeURIComponent(cookie.substring(name.length, cookie.length))
    }
  }
  return ''
}

const isMaintenanceMode = ({ status }) => status === 503
const jqueryAjaxWrapper = (jqueryAjaxPromise) => {
  const ajaxResponseS = Bacon.fromPromise(jqueryAjaxPromise).first()

  ajaxResponseS.onError((errorResponse) => {
    if (isMaintenanceMode(errorResponse)) {
      maintenanceModeResponses.push(errorResponse)
    }
  })

  return ajaxResponseS
}

function getDefaultHeaders() {
  return {
    Accept: 'application/json',
    'X-XSRF-TOKEN': getCookie('XSRF-TOKEN')
  }
}

export function getHeaders(headers = {}) {
  return {
    ...getDefaultHeaders(),
    ...CustomHeaders.localization(),
    ...headers
  }
}

function ajaxGet(url) {
  return jqueryAjaxWrapper(
    $.ajax({
      type: 'GET',
      url: url,
      headers: { ...getDefaultHeaders(), ...CustomHeaders.localization() }
    })
  )
}

function ajaxPost(url) {
  return jqueryAjaxWrapper(
    $.ajax({
      type: 'POST',
      url: url,
      headers: { ...getDefaultHeaders(), ...CustomHeaders.localization() }
    })
  )
}

function ajaxPostFile(url, file, onComplete, fileKey = 'file') {
  const formData = new FormData()
  formData.append(fileKey, file)

  return jqueryAjaxWrapper(
    $.ajax({
      type: 'POST',
      url: url,
      data: formData,
      processData: false,
      contentType: false,
      headers: getDefaultHeaders(),
      complete: onComplete
    })
  )
}

function ajaxPostJSON(url, data) {
  return jqueryAjaxWrapper(
    $.ajax({
      type: 'POST',
      url: url,
      data: data,
      headers: getDefaultHeaders()
    })
  )
}

function ajaxPut(url) {
  return jqueryAjaxWrapper(
    $.ajax({
      type: 'PUT',
      url: url,
      headers: getDefaultHeaders()
    })
  )
}

function ajaxPutJSON(url, data) {
  return jqueryAjaxWrapper(
    $.ajax({
      type: 'PUT',
      url: url,
      data: data,
      headers: getDefaultHeaders()
    })
  )
}

function ajaxDelete(url) {
  return jqueryAjaxWrapper(
    $.ajax({
      type: 'DELETE',
      url: url,
      headers: getDefaultHeaders()
    })
  )
}

function ajaxDeleteJSON(url, data) {
  return jqueryAjaxWrapper(
    $.ajax({
      type: 'DELETE',
      url: url,
      data: data,
      headers: getDefaultHeaders()
    })
  )
}

export { ajaxGet, ajaxPost, ajaxPostFile, ajaxPostJSON, ajaxPut, ajaxPutJSON, ajaxDelete, ajaxDeleteJSON }
