const keys = {
  key: 'key',
  ABOUT: 'ABOUT',
  THESSA: 'THESSA',
  THESSA_IS_THE_DOCTORAL_STUDIES: 'THESSA_IS_THE_DOCTORAL_STUDIES',
  ALL_KINDS_OF_FEEDBACK_AND_ERRO: 'ALL_KINDS_OF_FEEDBACK_AND_ERRO',
  THESSA_ADMIN_HELSINKI_FI: 'THESSA_ADMIN_HELSINKI_FI',
  RIGHTS_OBLIGATIONS_AND_RESPON: 'RIGHTS_OBLIGATIONS_AND_RESPON',
  PROBLEMS_LOGGING_IN: 'PROBLEMS_LOGGING_IN',
  IF_YOU_FORGOT_YOUR_PASSWORD_Y: 'IF_YOU_FORGOT_YOUR_PASSWORD_Y',
  RESET_IT: 'RESET_IT',
  SEND_PASSWORD_RESET_LINK_TO_MY: 'SEND_PASSWORD_RESET_LINK_TO_MY',
  EMAIL_FOR_THESSA_ACCOUNT: 'EMAIL_FOR_THESSA_ACCOUNT',
  SEND: 'SEND',
  BACK_TO_LOGIN: 'BACK_TO_LOGIN',
  BACK_TO_MY_PHD_PROJECT: 'BACK_TO_MY_PHD_PROJECT',
  MESSAGE_SENT_CHECK_YOUR_EMAIL: 'MESSAGE_SENT_CHECK_YOUR_EMAIL',
  TOO_MANY_PASSWORD_RESET_REQUES: 'TOO_MANY_PASSWORD_RESET_REQUES',
  WAIT_FIVE_MINUTES_BEFORE_TRYIN: 'WAIT_FIVE_MINUTES_BEFORE_TRYIN',
  HELPDESK: 'HELPDESK',
  YOU_MAY_RESET_YOUR_PASSWORD: 'YOU_MAY_RESET_YOUR_PASSWORD',
  SOMETHING_WENT_WRONG: 'SOMETHING_WENT_WRONG',
  TRY_AGAIN_LATER: 'TRY_AGAIN_LATER',
  RIGHTS_OBLIGATIONS_AND_RESPON1: 'RIGHTS_OBLIGATIONS_AND_RESPON1',
  ABOUT_THESSA: 'ABOUT_THESSA',
  THE_DOCTORAL_STUDIES_PLANNING: 'THE_DOCTORAL_STUDIES_PLANNING',
  EMAIL: 'EMAIL',
  PASSWORD: 'PASSWORD',
  LOG_IN: 'LOG_IN',
  LOGIN_WAS_NOT_SUCCESSFUL: 'LOGIN_WAS_NOT_SUCCESSFUL',
  CHECK_USERNAME_AND_PASSWORD_I: 'CHECK_USERNAME_AND_PASSWORD_I',
  UNIVERSITY_OF_HELSINKI_LOGIN: 'UNIVERSITY_OF_HELSINKI_LOGIN',
  UNIVERSITY_OF_HELSINKI_USERNAM: 'UNIVERSITY_OF_HELSINKI_USERNAM',
  USE_UNIVERSITY_OF_HELSINKI_LOGIN: 'USE_UNIVERSITY_OF_HELSINKI_LOGIN',
  CHECK_USERNAME_AND_PASSWORD_I1: 'CHECK_USERNAME_AND_PASSWORD_I1',
  TOO_MANY_FAILED_LOGIN_ATTEMPTS: 'TOO_MANY_FAILED_LOGIN_ATTEMPTS',
  WAIT_FIVE_MINUTES_BEFORE_TRYIN1: 'WAIT_FIVE_MINUTES_BEFORE_TRYIN1',
  YOU_NEED_AN_INVITATION_OR_AN_A: 'YOU_NEED_AN_INVITATION_OR_AN_A',
  YOUR_EMAIL_ADDRESS_IS_ALREADY: 'YOUR_EMAIL_ADDRESS_IS_ALREADY',
  CONTACT_US: 'CONTACT_US',
  CONTACT_US_END_OF_SENTENCE: 'CONTACT_US_END_OF_SENTENCE',
  FOR_HELP_WITH_THIS_ERROR: 'FOR_HELP_WITH_THIS_ERROR',
  LOGIN_FAILED_WITH_ERROR_CODE: 'LOGIN_FAILED_WITH_ERROR_CODE',
  NON_UNIVERSITY_LOGIN_EXTERNAL: 'NON_UNIVERSITY_LOGIN_EXTERNAL',
  USE_YOUR_UNIVERSITY_OF_HELSINK: 'USE_YOUR_UNIVERSITY_OF_HELSINK',
  USE_NON_UNIVERSITY_LOGIN: 'USE_NON_UNIVERSITY_LOGIN',
  NON_UNIVERSITY_LOGIN: 'NON_UNIVERSITY_LOGIN',
  FORGOT_PASSWORD: 'FORGOT_PASSWORD',
  RESET_PASSWORD: 'RESET_PASSWORD',
  NEW_PASSWORD: 'NEW_PASSWORD',
  SAVE_PASSWORD: 'SAVE_PASSWORD',
  CANCEL: 'CANCEL',
  PASSWORD_SET_REDIRECTING_YOU: 'PASSWORD_SET_REDIRECTING_YOU',
  INSIDE: 'INSIDE',
  THERE_WERE_PROBLEMS_WITH_SAVIN: 'THERE_WERE_PROBLEMS_WITH_SAVIN',
  SIGN_UP_TO_THESSA: 'SIGN_UP_TO_THESSA',
  IF_YOU_HAVE_A_UNIVERSITY_OF_HE: 'IF_YOU_HAVE_A_UNIVERSITY_OF_HE',
  MON: 'MON',
  TUE: 'TUE',
  WED: 'WED',
  THU: 'THU',
  FRI: 'FRI',
  SAT: 'SAT',
  SUN: 'SUN',
  LOADING_WITH_ELLIPSIS: 'LOADING_WITH_ELLIPSIS',
  YOU_MAY_USE: 'YOU_MAY_USE',
  MARKDOWN: 'MARKDOWN',
  ITALICS: 'ITALICS',
  BOLD: 'BOLD',
  LINKS_HTTPS: 'LINKS_HTTPS',
  HEADINGS: 'HEADINGS',
  LISTS: 'LISTS',
  AND_MORE: 'AND_MORE',
  THESSA_FOR: 'THESSA_FOR',
  UNIVERSITY_OF_HELSINKI: 'UNIVERSITY_OF_HELSINKI',
  PRIVACY_POLICY: 'PRIVACY_POLICY',
  ASK_FOR_HELP_AND_GIVE_FEEDBACK: 'ASK_FOR_HELP_AND_GIVE_FEEDBACK',
  TOGGLE_NAVIGATION: 'TOGGLE_NAVIGATION',
  LOG_OUT: 'LOG_OUT',
  LOGIN: 'LOGIN',
  REMINDERS1: 'REMINDERS1',
  PROFILE1: 'PROFILE1',
  HELP1: 'HELP1',
  MY_PHD_PROJECT: 'MY_PHD_PROJECT',
  MY_DOCTORAL_CANDIDATES: 'MY_DOCTORAL_CANDIDATES',
  DOCTORAL_CANDIDATES1: 'DOCTORAL_CANDIDATES1',
  USERS1: 'USERS1',
  QUEUE1: 'QUEUE1',
  STATISTICS1: 'STATISTICS1',
  LEFT: 'LEFT',
  BOTTOM: 'BOTTOM',
  ARE_YOU_SURE: 'ARE_YOU_SURE',
  CONFIRM_DELETE: 'CONFIRM_DELETE',
  THANKS: 'THANKS',
  YOU_CAN_GIVE_MORE_FEEDBACK_HER: 'YOU_CAN_GIVE_MORE_FEEDBACK_HER',
  SEND_FEEDBACK: 'SEND_FEEDBACK',
  PAGE_HEADER: 'PAGE_HEADER',
  HEADING: 'HEADING',
  HEADING1: 'HEADING1',
  HEADING2: 'HEADING2',
  HEADING3: 'HEADING3',
  HEADING4: 'HEADING4',
  HEADING5: 'HEADING5',
  LEAD_TEXT: 'LEAD_TEXT',
  BODY_TEXT: 'BODY_TEXT',
  SMALL_TEXT: 'SMALL_TEXT',
  LABEL: 'LABEL',
  LABEL1: 'LABEL1',
  LABEL2: 'LABEL2',
  CHECK: 'CHECK',
  CHECK1: 'CHECK1',
  SUBMIT: 'SUBMIT',
  SAVE: 'SAVE',
  TOGGLE: 'TOGGLE',
  CALENDAR: 'CALENDAR',
  OK: 'OK',
  REMOVE: 'REMOVE',
  SUBMITTED: 'SUBMITTED',
  CONCLUSION_MISSING: 'CONCLUSION_MISSING',
  GIVE_FEEDBACK: 'GIVE_FEEDBACK',
  DEFAULT: 'DEFAULT',
  RIGHT: 'RIGHT',
  LEFT1: 'LEFT1',
  CALL_TO_ACTION: 'CALL_TO_ACTION',
  SECONDARY: 'SECONDARY',
  ACTION: 'ACTION',
  ACTIONS: 'ACTIONS',
  STUDENT_NUMBER: 'STUDENT_NUMBER',
  NAME: 'NAME',
  ACCOUNT: 'ACCOUNT',
  NO_ACCOUNT: 'NO_ACCOUNT',
  NO_SUPERVISORS_IN_OODI: 'NO_SUPERVISORS_IN_OODI',
  STUDY_RIGHT_START_DATE: 'STUDY_RIGHT_START_DATE',
  RECENT: 'RECENT',
  COORDINATOR_QUEUE: 'COORDINATOR_QUEUE',
  LOADING_INACTIVE_USERS: 'LOADING_INACTIVE_USERS',
  THERE_WAS_AN_ERROR_IN_LOADING: 'THERE_WAS_AN_ERROR_IN_LOADING',
  PLEASE: 'PLEASE',
  TRY_AGAIN: 'TRY_AGAIN',
  LET_US_KNOW: 'LET_US_KNOW',
  IF_THE_PROBLEM_DOES_NOT_GO_AWA: 'IF_THE_PROBLEM_DOES_NOT_GO_AWA',
  INACTIVE_USER: 'INACTIVE_USER',
  TYPE: 'TYPE',
  N_A: 'N_A',
  NO_COURSES_IN_PLAN_YET: 'NO_COURSES_IN_PLAN_YET',
  ADD_ONE_BY_CLICKING_THE: 'ADD_ONE_BY_CLICKING_THE',
  ADD: 'ADD',
  BUTTON_ABOVE: 'BUTTON_ABOVE',
  TITLE: 'TITLE',
  CREDITS: 'CREDITS',
  STATUS: 'STATUS',
  COMPLETED: 'COMPLETED',
  NOT_COMPLETED: 'NOT_COMPLETED',
  COMPLETED_AT: 'COMPLETED_AT',
  TO_BE_COMPLETED_AT: 'TO_BE_COMPLETED_AT',
  NOT_AVAILABLE: 'NOT_AVAILABLE',
  CHANGED_SINCE_LAST_OPENED: 'CHANGED_SINCE_LAST_OPENED',
  REPORT: 'REPORT',
  SUPERVISION_PLAN: 'SUPERVISION_PLAN',
  SUPERVISION_PLAN_other: 'SUPERVISION_PLAN_other',
  MY_DOCTORAL_CANDIDATES1: 'MY_DOCTORAL_CANDIDATES1',
  LOADING_DOCTORAL_CANDIDATES: 'LOADING_DOCTORAL_CANDIDATES',
  FAILED_TO_LOAD_DOCTORAL_CANDID: 'FAILED_TO_LOAD_DOCTORAL_CANDID',
  MISSING_DOCTORAL_CANDIDATES: 'MISSING_DOCTORAL_CANDIDATES',
  TO_SEE_YOUR_DOCTORAL_CANDIDATE: 'TO_SEE_YOUR_DOCTORAL_CANDIDATE',
  YOU_WILL_GET_THE_INVITATION_BY: 'YOU_WILL_GET_THE_INVITATION_BY',
  HIDE: 'HIDE',
  SHOW: 'SHOW',
  CLOSED_ACCOUNTS: 'CLOSED_ACCOUNTS',
  RELATIONSHIP: 'RELATIONSHIP',
  PROGRESS: 'PROGRESS',
  YOU_HAVE_NO_DOCTORAL_CANDIDATE: 'YOU_HAVE_NO_DOCTORAL_CANDIDATE',
  NO_DOCTORAL_CANDIDATES: 'NO_DOCTORAL_CANDIDATES',
  YOUR_USER_ACCOUNT_NEEDS_TO_BE: 'YOUR_USER_ACCOUNT_NEEDS_TO_BE',
  INVITE_ALL_YOUR_SUPERVISORS_AN: 'INVITE_ALL_YOUR_SUPERVISORS_AN',
  IN_THESSA: 'IN_THESSA',
  YOU_WILL_RECEIVE_AN_EMAIL_WHEN: 'YOU_WILL_RECEIVE_AN_EMAIL_WHEN',
  INVITE_SUPERVISORS: 'INVITE_SUPERVISORS',
  THERE_ARE_NO_SUPERVISION_PLANS: 'THERE_ARE_NO_SUPERVISION_PLANS',
  DOCTORAL_CANDIDATE_AND_SUPERVI: 'DOCTORAL_CANDIDATE_AND_SUPERVI',
  SUPERVISORS: 'SUPERVISORS',
  LOADING_NEXT_REPORT_DUE_DATE: 'LOADING_NEXT_REPORT_DUE_DATE',
  NEXT_REPORT_DUE_ON: 'NEXT_REPORT_DUE_ON',
  PERIOD: 'PERIOD',
  PUBLICATIONS: 'PUBLICATIONS',
  COURSES: 'COURSES',
  GRADUATION: 'GRADUATION',
  CONCLUSION: 'CONCLUSION',
  DRAFT: 'DRAFT',
  OPEN: 'OPEN',
  MISSING: 'MISSING',
  HAVE_A_THESIS_COMMITTEE_MEETIN: 'HAVE_A_THESIS_COMMITTEE_MEETIN',
  REPORTS: 'REPORTS',
  LOADING_REPORTS: 'LOADING_REPORTS',
  NO_REPORTS_YET: 'NO_REPORTS_YET',
  VIEW_CUMULATIVE_REPORT: 'VIEW_CUMULATIVE_REPORT',
  THESSA_IS_IN_MAINTENANCE: 'THESSA_IS_IN_MAINTENANCE',
  PLEASE_WAIT_A_FEW_MINUTES_AND: 'PLEASE_WAIT_A_FEW_MINUTES_AND',
  RELOAD_THE_PAGE: 'RELOAD_THE_PAGE',
  PAGE_NOT_FOUND: 'PAGE_NOT_FOUND',
  SORRY_THE_PAGE_YOU_OPENED_CAN: 'SORRY_THE_PAGE_YOU_OPENED_CAN',
  FRONT_PAGE_INSTEAD: 'FRONT_PAGE_INSTEAD',
  ADD_GRANT: 'ADD_GRANT',
  ADD_SALARY: 'ADD_SALARY',
  SALARIES: 'SALARIES',
  GRANTS: 'GRANTS',
  NO_SALARIES_IN_PLAN_YET: 'NO_SALARIES_IN_PLAN_YET',
  NO_SALARIES: 'NO_SALARIES',
  NO_GRANTS_IN_PLAN_YET: 'NO_GRANTS_IN_PLAN_YET',
  NO_GRANTS: 'NO_GRANTS',
  SOURCE: 'SOURCE',
  AMOUNT: 'AMOUNT',
  FOR_MONTHS: 'FOR_MONTHS',
  USED_FOR: 'USED_FOR',
  NOT_GRANTED: 'NOT_GRANTED',
  GRANTED: 'GRANTED',
  FOR_DATES: 'FOR_DATES',
  HELP_MORE_GUIDES_LINK_PREFIX: 'HELP_MORE_GUIDES_LINK_PREFIX',
  HELP_MORE_GUIDES_LINK_URL: 'HELP_MORE_GUIDES_LINK_URL',
  HELP_MORE_GUIDES_LINK_LINK_TEXT: 'HELP_MORE_GUIDES_LINK_LINK_TEXT',
  HELP_MORE_GUIDES_LINK_SUFFIX: 'HELP_MORE_GUIDES_LINK_SUFFIX',
  THE_THREE_MAIN_PARTS_OF_THESSA: 'THE_THREE_MAIN_PARTS_OF_THESSA',
  THESSA_CONSISTS_OF_THREE_MAIN: 'THESSA_CONSISTS_OF_THREE_MAIN',
  PHD_PROJECT1: 'PHD_PROJECT1',
  SUPERVISION_PLAN_IS_A_SET_OF_D: 'SUPERVISION_PLAN_IS_A_SET_OF_D',
  PHD_PROJECT_IS_A_PLACE_FOR_DOC: 'PHD_PROJECT_IS_A_PLACE_FOR_DOC',
  ANOTHER_GREAT_BENEFIT_OF_KEEPI: 'ANOTHER_GREAT_BENEFIT_OF_KEEPI',
  DOCTORAL_CANDIDATES_AND_SUPERV: 'DOCTORAL_CANDIDATES_AND_SUPERV',
  DOCTORAL_CANDIDATES_ARE_THE_ON: 'DOCTORAL_CANDIDATES_ARE_THE_ON',
  THE_DOCTORAL_CANDIDATE_CAN_STA: 'THE_DOCTORAL_CANDIDATE_CAN_STA',
  TO_LATER_CHANGE_DOCTORAL_CANDI: 'TO_LATER_CHANGE_DOCTORAL_CANDI',
  THESIS_COMMITTEES_TC: 'THESIS_COMMITTEES_TC',
  THESIS_COMMITTEES_ARE_REQUIRED: 'THESIS_COMMITTEES_ARE_REQUIRED',
  THESSA_SUPPORTS_INVITING_MEMBE: 'THESSA_SUPPORTS_INVITING_MEMBE',
  THE_CONCLUSIONS_AND_RECOMMEND: 'THE_CONCLUSIONS_AND_RECOMMEND',
  THESSA_ASKS_DOCTORAL_CANDIDATE: 'THESSA_ASKS_DOCTORAL_CANDIDATE',
  AFTER_THE_DOCTORAL_CANDIDATE_H: 'AFTER_THE_DOCTORAL_CANDIDATE_H',
  THERE_WILL_BE_REMINDER_EMAILS: 'THERE_WILL_BE_REMINDER_EMAILS',
  ONE_YEAR_FROM_THE_LAST_REPORT: 'ONE_YEAR_FROM_THE_LAST_REPORT',
  ONE_YEAR_FROM_THE_BEGINNING_OF: 'ONE_YEAR_FROM_THE_BEGINNING_OF',
  CLOSING_THE_REPORT: 'CLOSING_THE_REPORT',
  AT_THE_END_OF_REPORTING_CYCLE: 'AT_THE_END_OF_REPORTING_CYCLE',
  IN_SOME_DOCTORAL_PROGRAMS_THE: 'IN_SOME_DOCTORAL_PROGRAMS_THE',
  FEEDBACK_AND_ERROR_REPORTS: 'FEEDBACK_AND_ERROR_REPORTS',
  CONTACT_US1: 'CONTACT_US1',
  TRY_TO_INCLUDE_AS_MUCH_DETAI: 'TRY_TO_INCLUDE_AS_MUCH_DETAI',
  IF_YOU_NEED_MORE_HELP_PLEASE: 'IF_YOU_NEED_MORE_HELP_PLEASE',
  INVITE: 'INVITE',
  S_EMAIL_ADDRESS: 'S_EMAIL_ADDRESS',
  INVITE_TO_YOUR_PHD_PROJECT_IN: 'INVITE_TO_YOUR_PHD_PROJECT_IN',
  INVITATION_SUBMITTED: 'INVITATION_SUBMITTED',
  SUBMITTING_INVITATION_FAILED: 'SUBMITTING_INVITATION_FAILED',
  YOU_ARE_INVITED: 'YOU_ARE_INVITED',
  WHAT_IS_THESSA: 'WHAT_IS_THESSA',
  ALL_DOCTORAL_CANDIDATES_AND_TH: 'ALL_DOCTORAL_CANDIDATES_AND_TH',
  WELCOME_TO_THESSA: 'WELCOME_TO_THESSA',
  YOU_ARE_NOT_CONNECTED_TO_ANYON: 'YOU_ARE_NOT_CONNECTED_TO_ANYON',
  ASK_YOUR_DOCTORAL_CANDIDATES_T: 'ASK_YOUR_DOCTORAL_CANDIDATES_T',
  OTHERWISE_IT_S_GOING_TO: 'OTHERWISE_IT_S_GOING_TO',
  NO_NOTES: 'NO_NOTES',
  EDIT: 'EDIT',
  SAVING: 'SAVING',
  UPDATE_ANNUAL_REPORTS: 'UPDATE_ANNUAL_REPORTS',
  ANNUAL_REPORTS_SECTION_HAS_BEE: 'ANNUAL_REPORTS_SECTION_HAS_BEE',
  UPDATE_CUMULATIVE_REPORT: 'UPDATE_CUMULATIVE_REPORT',
  CUMULATIVE_REPORT_VIEW_SHOWS_A: 'CUMULATIVE_REPORT_VIEW_SHOWS_A',
  UPDATE_REVIEWING_REPORTS: 'UPDATE_REVIEWING_REPORTS',
  CUMULATIVE_REPORT: 'CUMULATIVE_REPORT',
  YOU_CAN_NOW_SEE_THE_CUMULATIVE: 'YOU_CAN_NOW_SEE_THE_CUMULATIVE',
  COMMENTING_REPORT: 'COMMENTING_REPORT',
  DISCUSS_REPORTS: 'DISCUSS_REPORTS',
  NEW_IMPROVEMENTS: 'NEW_IMPROVEMENTS',
  INVITE_SUPERVISORS_AND_THESIS: 'INVITE_SUPERVISORS_AND_THESIS',
  DISCUSS_IN_SIMPLIFIED_ANNUAL_R: 'DISCUSS_IN_SIMPLIFIED_ANNUAL_R',
  REPORTS_ARE_CLOSED_BY_WRITING: 'REPORTS_ARE_CLOSED_BY_WRITING',
  READ_MORE_FROM_HELP_PAGE: 'READ_MORE_FROM_HELP_PAGE',
  SET_OF_DECISIONS_BETWEEN_DOCTO: 'SET_OF_DECISIONS_BETWEEN_DOCTO',
  KEEPING_TRACK_OF_FUTURE_PLANS: 'KEEPING_TRACK_OF_FUTURE_PLANS',
  A_SNAPSHOT_OF_PHD_PROJECT_IT: 'A_SNAPSHOT_OF_PHD_PROJECT_IT',
  START_USING_THESSA: 'START_USING_THESSA',
  WE_RECOMMEND_THAT_YOU_START_US: 'WE_RECOMMEND_THAT_YOU_START_US',
  THEN_YOU_SHOULD_UPDATE_YOUR_PH: 'THEN_YOU_SHOULD_UPDATE_YOUR_PH',
  YOU_CAN_FIND_MORE_DETAILS_ABOU: 'YOU_CAN_FIND_MORE_DETAILS_ABOU',
  IF_YOU_FIND_BUGS_OR_ERRORS_IN: 'IF_YOU_FIND_BUGS_OR_ERRORS_IN',
  DROP_AN_EMAIL_TO_US: 'DROP_AN_EMAIL_TO_US',
  YOUR_DOCTORAL_CANDIDATES_WILL: 'YOUR_DOCTORAL_CANDIDATES_WILL',
  REMOVE1: 'REMOVE1',
  CONFERENCES_AND_MOBILITY: 'CONFERENCES_AND_MOBILITY',
  LOCATION: 'LOCATION',
  DATES: 'DATES',
  LOADING_CONFERENCES_AND_MOBILI: 'LOADING_CONFERENCES_AND_MOBILI',
  NO_CONFERENCES_OR_MOBILITY_PLA: 'NO_CONFERENCES_OR_MOBILITY_PLA',
  ADD_CONFERENCE_OR_MOBILITY: 'ADD_CONFERENCE_OR_MOBILITY',
  FAILED_TO_CREATE_CONFERENCE_OR_MOBILITY: 'FAILED_TO_CREATE_CONFERENCE_OR_MOBILITY',
  DELETE: 'DELETE',
  REALLY_CANCEL_INVITATION: 'REALLY_CANCEL_INVITATION',
  KEEP_INVITATION: 'KEEP_INVITATION',
  CANCEL_INVITATION: 'CANCEL_INVITATION',
  INVITATION_EXPIRED: 'INVITATION_EXPIRED',
  INVITED_NOT_YET_RESPONDED: 'INVITED_NOT_YET_RESPONDED',
  FIRST_NAME: 'FIRST_NAME',
  LAST_NAME: 'LAST_NAME',
  EMAIL_ADDRESS: 'EMAIL_ADDRESS',
  SAVED: 'SAVED',
  REALLY_REMOVE: 'REALLY_REMOVE',
  THE_LAST_MEMBER_CANNOT_BE_REMO: 'THE_LAST_MEMBER_CANNOT_BE_REMO',
  RESEND_INVITATION: 'RESEND_INVITATION',
  INVITATION_TO_BECOME_A: 'INVITATION_TO_BECOME_A',
  FOR: 'FOR',
  EXPIRES_ON: 'EXPIRES_ON',
  THIS_INVITATION_HAS_EXPIRED_Y: 'THIS_INVITATION_HAS_EXPIRED_Y',
  ACCEPT: 'ACCEPT',
  DECLINE: 'DECLINE',
  DUE_TO_DATA_MIGRATION_THESSA: 'DUE_TO_DATA_MIGRATION_THESSA',
  NO_PUBLICATIONS_IN_PLAN_YET: 'NO_PUBLICATIONS_IN_PLAN_YET',
  TOPIC_OR_TITLE: 'TOPIC_OR_TITLE',
  EDIT_REMINDER: 'EDIT_REMINDER',
  LOADING_YOUR_REMINDERS: 'LOADING_YOUR_REMINDERS',
  NEW_REMINDER: 'NEW_REMINDER',
  UPCOMING_REMINDERS: 'UPCOMING_REMINDERS',
  PAST_REMINDERS: 'PAST_REMINDERS',
  NO_REMINDERS: 'NO_REMINDERS',
  DUE: 'DUE',
  SEND_REMINDERS_TO: 'SEND_REMINDERS_TO',
  REMINDER_TIMING: 'REMINDER_TIMING',
  DAYS_BEFORE_DUE_DATE: 'DAYS_BEFORE_DUE_DATE',
  CREATE_REPORT: 'CREATE_REPORT',
  YOUR_ACCOUNT_IS_INACTIVE_THER: 'YOUR_ACCOUNT_IS_INACTIVE_THER',
  YOUR_DOCTORAL_PROGRAMME_S_STAF: 'YOUR_DOCTORAL_PROGRAMME_S_STAF',
  CANNOT_CREATE_REPORT_WHEN_A_PR: 'CANNOT_CREATE_REPORT_WHEN_A_PR',
  OPEN_REPORT: 'OPEN_REPORT',
  CANNOT_CREATE_REPORT_WHEN_A_PR1: 'CANNOT_CREATE_REPORT_WHEN_A_PR1',
  SUMMARIZE_THE_REPORTING_PERIOD: 'SUMMARIZE_THE_REPORTING_PERIOD',
  AS_AN_OVERVIEW_OF_THE_REPORTIN: 'AS_AN_OVERVIEW_OF_THE_REPORTIN',
  BELOW_ARE_ALL_COURSES_YOU_COMP: 'BELOW_ARE_ALL_COURSES_YOU_COMP',
  YOU_CAN_EDIT_THE_LIST_ON: 'YOU_CAN_EDIT_THE_LIST_ON',
  PHD_PROJECT_PAGE: 'PHD_PROJECT_PAGE',
  NO_COURSES_IN_REPORT_RIGHT_NOW: 'NO_COURSES_IN_REPORT_RIGHT_NOW',
  BELOW_ARE_ALL_ACTIVE_SALARIES: 'BELOW_ARE_ALL_ACTIVE_SALARIES',
  YOU_CAN_EDIT_THE_LISTS_ON_TH: 'YOU_CAN_EDIT_THE_LISTS_ON_TH',
  NO_SALARIES_IN_REPORT_RIGHT_NO: 'NO_SALARIES_IN_REPORT_RIGHT_NO',
  NO_GRANTS_IN_REPORT_RIGHT_NOW: 'NO_GRANTS_IN_REPORT_RIGHT_NOW',
  BELOW_ARE_ALL_CONFERENCES_AND: 'BELOW_ARE_ALL_CONFERENCES_AND',
  NO_CONFERENCES_OR_MOBILITY_IN: 'NO_CONFERENCES_OR_MOBILITY_IN',
  BELOW_ARE_ALL_PUBLICATIONS_THA: 'BELOW_ARE_ALL_PUBLICATIONS_THA',
  NO_PUBLICATIONS_IN_REPORT_RIGH: 'NO_PUBLICATIONS_IN_REPORT_RIGH',
  BELOW_ARE_ALL_ACTIVE_TEACHINGS: 'BELOW_ARE_ALL_ACTIVE_TEACHINGS',
  NO_COURSES_OR_WORKSHOPS_IN_REP: 'NO_COURSES_OR_WORKSHOPS_IN_REP',
  NO_THESIS_INSTRUCTIONS_IN_REPO: 'NO_THESIS_INSTRUCTIONS_IN_REPO',
  NOT_SUBMITTED: 'NOT_SUBMITTED',
  CONCLUSION_DONE: 'CONCLUSION_DONE',
  WRITE_CONCLUSION: 'WRITE_CONCLUSION',
  HAVE_A_THESIS_COMMITTEE_MEETIN1: 'HAVE_A_THESIS_COMMITTEE_MEETIN1',
  THE_DOCTORAL_CANDIDATE_NEEDS_T: 'THE_DOCTORAL_CANDIDATE_NEEDS_T',
  DONE: 'DONE',
  REPORT_SUBMITTED_FOR_REVIEW_SU: 'REPORT_SUBMITTED_FOR_REVIEW_SU',
  REPORT_SUBMITTED: 'REPORT_SUBMITTED',
  REPORT_UPDATED: 'REPORT_UPDATED',
  EDIT_REPORT: 'EDIT_REPORT',
  YOU_CAN_MAKE_CHANGES_TO_THE_RE: 'YOU_CAN_MAKE_CHANGES_TO_THE_RE',
  NO_REPORTS_TO_REVIEW_FOUND: 'NO_REPORTS_TO_REVIEW_FOUND',
  EDIT_REVIEW: 'EDIT_REVIEW',
  REVIEW_REPORT: 'REVIEW_REPORT',
  CHANGES_SAVED: 'CHANGES_SAVED',
  LAST_SAVED: 'LAST_SAVED',
  CANCEL_EDITING: 'CANCEL_EDITING',
  SAVE_REPORT: 'SAVE_REPORT',
  SUBMIT_REPORT_FOR_REVIEW: 'SUBMIT_REPORT_FOR_REVIEW',
  CREATED_IN: 'CREATED_IN',
  CONFERENCES_AND_MOBILITY1: 'CONFERENCES_AND_MOBILITY1',
  FUNDING: 'FUNDING',
  TEACHING: 'TEACHING',
  COURSES_AND_WORKSHOPS: 'COURSES_AND_WORKSHOPS',
  THESIS_INSTRUCTION: 'THESIS_INSTRUCTION',
  YOU_ARE_EDITING_THE_REPORT_S_C: 'YOU_ARE_EDITING_THE_REPORT_S_C',
  SHOW_EDITOR: 'SHOW_EDITOR',
  PLEASE_INDICATE_WHO_ATTENDED_T: 'PLEASE_INDICATE_WHO_ATTENDED_T',
  THESIS_COMMITTEE_MEETING_FORM: 'THESIS_COMMITTEE_MEETING_FORM',
  CONCLUSION_AND_RECOMMENDATIONS: 'CONCLUSION_AND_RECOMMENDATIONS',
  NO_REPORT_FOUND_CANNOT_EDIT_CONCLUSION: 'NO_REPORT_FOUND_CANNOT_EDIT_CONCLUSION',
  NO_TEXT_TO_PREVIEW: 'NO_TEXT_TO_PREVIEW',
  CANNOT_SUBMIT_CONCLUSION_EMPTY: 'CANNOT_SUBMIT_CONCLUSION_EMPTY',
  THE_DOCTORAL_CANDIDATE_IS_EXPE: 'THE_DOCTORAL_CANDIDATE_IS_EXPE',
  PREVIEW: 'PREVIEW',
  SUBMITTING_PLEASE_WAIT: 'SUBMITTING_PLEASE_WAIT',
  CONCLUSION_CONFIRM_CANCEL: 'CONCLUSION_CONFIRM_CANCEL',
  CONCLUSION_CANNOT_BE_EDITED: 'CONCLUSION_CANNOT_BE_EDITED',
  CUMULATIVE_REPORT_FOR: 'CUMULATIVE_REPORT_FOR',
  COMPLETED_IN_THE_MOST_RECENT: 'COMPLETED_IN_THE_MOST_RECENT',
  IN_PROGRESS_IN_THE_MOST_RECE: 'IN_PROGRESS_IN_THE_MOST_RECE',
  PUBLICATION_IS_IN_THESIS: 'PUBLICATION_IS_IN_THESIS',
  COMPLETE: 'COMPLETE',
  NOTHING_REPORTED_YET: 'NOTHING_REPORTED_YET',
  DELETE_REPORT: 'DELETE_REPORT',
  YOU_CAN_DELETE_YOUR_REPORT_BEF: 'YOU_CAN_DELETE_YOUR_REPORT_BEF',
  COMPLETED1: 'COMPLETED1',
  ESTIMATE_OF_HOW_COMPLETE_YOUR: 'ESTIMATE_OF_HOW_COMPLETE_YOUR',
  FULL_TIME: 'FULL_TIME',
  ESTIMATE_OF_FULL_PART_TIME_WOR: 'ESTIMATE_OF_FULL_PART_TIME_WOR',
  YOUR_DOCTORAL_PROGRAMME_ASKS_Y: 'YOUR_DOCTORAL_PROGRAMME_ASKS_Y',
  PLEASE_FILL_IN_ALL_OF_THE_FIEL: 'PLEASE_FILL_IN_ALL_OF_THE_FIEL',
  THE_REPORT_WILL_BE_VISIBLE_TO: 'THE_REPORT_WILL_BE_VISIBLE_TO',
  THIS_REPORT_COVERS_DATES_BETWE: 'THIS_REPORT_COVERS_DATES_BETWE',
  AND: 'AND',
  WHEN_YOU_ARE_DONE_PRESS_THE_S: 'WHEN_YOU_ARE_DONE_PRESS_THE_S',
  REMEMBER_TO_SAVE_THE_REPORT_PE: 'REMEMBER_TO_SAVE_THE_REPORT_PE',
  MESSAGE_REMOVED: 'MESSAGE_REMOVED',
  DISCUSSION: 'DISCUSSION',
  LOADING: 'LOADING',
  NO_DOCUMENT_FOUND_WITH_DATE: 'NO_DOCUMENT_FOUND_WITH_DATE',
  APPROVED_BY_THE_BOARD_OF_DOCTO: 'APPROVED_BY_THE_BOARD_OF_DOCTO',
  THESSA_JOIN_A_THESIS_COMMITT: 'THESSA_JOIN_A_THESIS_COMMITT',
  WITH_YOUR_THESSA_CREDENTIALS_O: 'WITH_YOUR_THESSA_CREDENTIALS_O',
  OTHERWISE: 'OTHERWISE',
  REGISTER_AN_EXTERNAL_ACCOUNT: 'REGISTER_AN_EXTERNAL_ACCOUNT',
  YOU_WILL_USE_THIS_FOR_LOGGING: 'YOU_WILL_USE_THIS_FOR_LOGGING',
  AT_LEAST_CHARACTERS: 'AT_LEAST_CHARACTERS',
  YOUR_ORGANIZATION: 'YOUR_ORGANIZATION',
  FOR_EXAMPLE_FINNISH_METEOROLO: 'FOR_EXAMPLE_FINNISH_METEOROLO',
  THERE_WERE_PROBLEMS_WITH_SIGN: 'THERE_WERE_PROBLEMS_WITH_SIGN',
  WELCOME_TO_THESSA_AS_A_NON_UN: 'WELCOME_TO_THESSA_AS_A_NON_UN',
  ERROR: 'ERROR',
  SORRY_AN_ERROR_HAS_OCCURRED_I: 'SORRY_AN_ERROR_HAS_OCCURRED_I',
  DOCTORAL_STUDIES: 'DOCTORAL_STUDIES',
  FILL_IN_DETAILS_OF_YOUR_STUDIE: 'FILL_IN_DETAILS_OF_YOUR_STUDIE',
  BEGINNING_OF_DOCTORAL_STUDIES: 'BEGINNING_OF_DOCTORAL_STUDIES',
  DOCTORAL_PROGRAMME: 'DOCTORAL_PROGRAMME',
  BACK: 'BACK',
  CONTINUE: 'CONTINUE',
  OR: 'OR',
  LOG_OUT1: 'LOG_OUT1',
  WELCOME_TO_THESSA1: 'WELCOME_TO_THESSA1',
  WE_NEED_SOME_INFORMATION_ABOUT: 'WE_NEED_SOME_INFORMATION_ABOUT',
  MY_ROLE_IN_THESSA: 'MY_ROLE_IN_THESSA',
  DOCTORAL_CANDIDATE: 'DOCTORAL_CANDIDATE',
  SUPERVISOR: 'SUPERVISOR',
  SUPERVISOR_other: 'SUPERVISOR_other',
  THESIS_COMMITTEE_MEMBER: 'THESIS_COMMITTEE_MEMBER',
  VERIFY_YOUR_DETAILS: 'VERIFY_YOUR_DETAILS',
  WE_FOUND_THE_FOLLOWING_DETAILS: 'WE_FOUND_THE_FOLLOWING_DETAILS',
  PLEASE_CHECK_THAT_THEY_ARE_COR: 'PLEASE_CHECK_THAT_THEY_ARE_COR',
  LIST_DOCTORAL_CANDIDATES_BY_RE: 'LIST_DOCTORAL_CANDIDATES_BY_RE',
  LIST_REVIEWERS_OF_NOT_COMPLETE: 'LIST_REVIEWERS_OF_NOT_COMPLETE',
  DOWNLOAD_THESSA_ADMIN_REPORT: 'DOWNLOAD_THESSA_ADMIN_REPORT',
  REPORT_CONCLUSIONS_OVERVIEW: 'REPORT_CONCLUSIONS_OVERVIEW',
  FILTER: 'FILTER',
  SHOW_ALL_REPORTS: 'SHOW_ALL_REPORTS',
  OVER_MONTH_OLD_REPORTS: 'OVER_MONTH_OLD_REPORTS',
  COPY_REVIEWER_EMAIL_ADDRESSES: 'COPY_REVIEWER_EMAIL_ADDRESSES',
  EMAIL_ADDRESSES_COPIED: 'EMAIL_ADDRESSES_COPIED',
  CREATED_AT: 'CREATED_AT',
  REVIEWERS: 'REVIEWERS',
  FILTER_BY_DOCTORAL_PROGRAMME: 'FILTER_BY_DOCTORAL_PROGRAMME',
  NO_REPORT_CONCLUSIONS_AVAILABL: 'NO_REPORT_CONCLUSIONS_AVAILABL',
  PRELIMINARY_TITLE_OF_DISSERTAT: 'PRELIMINARY_TITLE_OF_DISSERTAT',
  STUDENT_NUMBER1: 'STUDENT_NUMBER1',
  NAME1: 'NAME1',
  ACCOUNT_CLOSED: 'ACCOUNT_CLOSED',
  STUDIES: 'STUDIES',
  FACULTY: 'FACULTY',
  SUPERVISORS1: 'SUPERVISORS1',
  THESIS_COMMITTEE2: 'THESIS_COMMITTEE2',
  REPORT_CREATED: 'REPORT_CREATED',
  PROGRESS1: 'PROGRESS1',
  LINK_TO_THE_DOCTORAL_CANDIDATE: 'LINK_TO_THE_DOCTORAL_CANDIDATE',
  STUDENT_ID_NUMBER: 'STUDENT_ID_NUMBER',
  ACCOUNT_STATUS_OPEN_CLOSED: 'ACCOUNT_STATUS_OPEN_CLOSED',
  IF_ACCOUNT_IS_CLOSED_REASON_F: 'IF_ACCOUNT_IS_CLOSED_REASON_F',
  IS_ACCOUNT_ACTIVE_ACTIVE_INAC: 'IS_ACCOUNT_ACTIVE_ACTIVE_INAC',
  EMAIL2: 'EMAIL2',
  FACULTY1: 'FACULTY1',
  FACULTY_OF_DEGREE: 'FACULTY_OF_DEGREE',
  STARTING_DATE_OF_DOCTORAL_STUD: 'STARTING_DATE_OF_DOCTORAL_STUD',
  DOES_THE_DOCTORAL_CANDIDATE_HA: 'DOES_THE_DOCTORAL_CANDIDATE_HA',
  IS_THE_SUPERVISION_PLAN_IN_EFF: 'IS_THE_SUPERVISION_PLAN_IN_EFF',
  SUPERVISORS2: 'SUPERVISORS2',
  SUPERVISORS_AND_THEIR_EMAIL_AD: 'SUPERVISORS_AND_THEIR_EMAIL_AD',
  IS_THE_ANNUAL_REPORT_SUBMITTED: 'IS_THE_ANNUAL_REPORT_SUBMITTED',
  THE_ANNUAL_REPORTING_IS_CLOSED: 'THE_ANNUAL_REPORTING_IS_CLOSED',
  NEXT_REPORT_S_DEADLINE: 'NEXT_REPORT_S_DEADLINE',
  THESIS_COMMITTEE_HAS_BEEN_ASSI: 'THESIS_COMMITTEE_HAS_BEEN_ASSI',
  MEMBERS_OF_THE_THESIS_COMMITTE: 'MEMBERS_OF_THE_THESIS_COMMITTE',
  OVERALL_PERCENTAGE_OF_COMPLETI: 'OVERALL_PERCENTAGE_OF_COMPLETI',
  ADVANCEMENT_OF_THESIS_WORK_COM: 'ADVANCEMENT_OF_THESIS_WORK_COM',
  FULL_TIME_PART_TIME: 'FULL_TIME_PART_TIME',
  SALARIES2: 'SALARIES2',
  GRANTS2: 'GRANTS2',
  GRANTS_OBTAINED_AND_APPLIED_FO: 'GRANTS_OBTAINED_AND_APPLIED_FO',
  EXPORT: 'EXPORT',
  CREATE_SUPERVISION_PLAN: 'CREATE_SUPERVISION_PLAN',
  DELETE_SUPERVISION_PLAN: 'DELETE_SUPERVISION_PLAN',
  ACCEPT_PLAN: 'ACCEPT_PLAN',
  VALID_BETWEEN: 'VALID_BETWEEN',
  VALID_FROM: 'VALID_FROM',
  VALID_INDEFINITELY: 'VALID_INDEFINITELY',
  DECISIONS: 'DECISIONS',
  SIGNATURES: 'SIGNATURES',
  SIGNED: 'SIGNED',
  NOT_YET_SIGNED: 'NOT_YET_SIGNED',
  WHAT_HAVE_YOU_DECIDED_ABOUT_TH: 'WHAT_HAVE_YOU_DECIDED_ABOUT_TH',
  ACTIVE: 'ACTIVE',
  PENDING: 'PENDING',
  EXPIRED: 'EXPIRED',
  GENERAL_TERMS_ON: 'GENERAL_TERMS_ON',
  RIGHTS_OBLIGATIONS_AND_RESPON2: 'RIGHTS_OBLIGATIONS_AND_RESPON2',
  THAT_APPLY_TO_THE_SUPERVISION: 'THAT_APPLY_TO_THE_SUPERVISION',
  NO_COURSES_OR_WORKSHOPS_IN_PLA: 'NO_COURSES_OR_WORKSHOPS_IN_PLA',
  NO_THESIS_INSTRUCTIONS_IN_PLAN: 'NO_THESIS_INSTRUCTIONS_IN_PLAN',
  ADD_TEACHING: 'ADD_TEACHING',
  TYPE_OF_TEACHING: 'TYPE_OF_TEACHING',
  DATES1: 'DATES1',
  COURSE_OR_WORKSHOP_NAME: 'COURSE_OR_WORKSHOP_NAME',
  MY_ROLE: 'MY_ROLE',
  WORK_HOURS: 'WORK_HOURS',
  STUDENT_S_NAME: 'STUDENT_S_NAME',
  THESIS_LEVEL: 'THESIS_LEVEL',
  THESIS_TOPIC_OR_TITLE: 'THESIS_TOPIC_OR_TITLE',
  SIGNED_UP_ON: 'SIGNED_UP_ON',
  USERNAME: 'USERNAME',
  ACTIVATE_ACCOUNT_NOW: 'ACTIVATE_ACCOUNT_NOW',
  USER_ACCOUNT_IS_NOT_YET_ACTIVA: 'USER_ACCOUNT_IS_NOT_YET_ACTIVA',
  CHECK_THE_ACCOUNT_DETAILS_IF: 'CHECK_THE_ACCOUNT_DETAILS_IF',
  CONTACT_THESSA_S_ADMINS: 'CONTACT_THESSA_S_ADMINS',
  CLOSE_ACCOUNT: 'CLOSE_ACCOUNT',
  REASON_FOR_CLOSING_THE_ACCOUNT: 'REASON_FOR_CLOSING_THE_ACCOUNT',
  FACULTY2: 'FACULTY2',
  MAJOR: 'MAJOR',
  DOCTORAL_PROGRAMME1: 'DOCTORAL_PROGRAMME1',
  BEGINNING_OF_DOCTORAL_STUDIES1: 'BEGINNING_OF_DOCTORAL_STUDIES1',
  BACK_TO: 'BACK_TO',
  ORGANIZATION: 'ORGANIZATION',
  STUDENT_NUMBER2: 'STUDENT_NUMBER2',
  VERIFIED_FROM_LDAP: 'VERIFIED_FROM_LDAP',
  NOT_VERIFIED_REPORTED_BY_DOCT: 'NOT_VERIFIED_REPORTED_BY_DOCT',
  THESIS_COMMITTEE_MEMBER1: 'THESIS_COMMITTEE_MEMBER1',
  IN_THESIS_COMMITTEE_FOR: 'IN_THESIS_COMMITTEE_FOR',
  IN_THESIS_COMMITTEE_FOR_other: 'IN_THESIS_COMMITTEE_FOR_other',
  THE_INVITATION_HAS_EXPIRED: 'THE_INVITATION_HAS_EXPIRED',
  THERE_WAS_AN_ERROR_IN_LOADING_THE_INVITATION: 'THERE_WAS_AN_ERROR_IN_LOADING_THE_INVITATION',
  INVITATION_DECLINED: 'INVITATION_DECLINED',
  YOUR_ACCOUNT_IS_CLOSED: 'YOUR_ACCOUNT_IS_CLOSED',
  SUPERVISION_PLAN_NOT_FOUND: 'SUPERVISION_PLAN_NOT_FOUND',
  IN_THESIS: 'IN_THESIS',
  PUBLISHED: 'PUBLISHED',
  NOT_STARTED: 'NOT_STARTED',
  THE_SUPERVISION_PLAN_AFFECTS_THE_DOCTORAL: 'THE_SUPERVISION_PLAN_AFFECTS_THE_DOCTORAL',
  IT_IS_RECOMMENDED_THAT_SUPERVISION_PLANS: 'IT_IS_RECOMMENDED_THAT_SUPERVISION_PLANS',
  CREATE_AND_SIGN_PLAN_AS: 'CREATE_AND_SIGN_PLAN_AS',
  SAVE_DRAFT: 'SAVE_DRAFT',
  SAVE_DRAFT_FEEDBACK: 'SAVE_DRAFT_FEEDBACK',
  LOADING_DOCTORAL_PROGRAMMES: 'LOADING_DOCTORAL_PROGRAMMES',
  FORM_IS_INCOMPLETE: 'FORM_IS_INCOMPLETE',
  DESCRIPTION: 'DESCRIPTION',
  ROLE_IN_CONFERENCE: 'ROLE_IN_CONFERENCE',
  FAILED_TO_SAVE: 'FAILED_TO_SAVE',
  DOCTORAL_SCHOOL: 'DOCTORAL_SCHOOL',
  ROLE: 'ROLE',
  PREVIOUS_PAGE: 'PREVIOUS_PAGE',
  NEXT_PAGE: 'NEXT_PAGE',
  ROWS: 'ROWS',
  PAGE_OF: 'PAGE_OF',
  PAGE: 'PAGE',
  MAX_SIZE_PER_ATTACHMENT: 'MAX_SIZE_PER_ATTACHMENT',
  UPLOAD_ATTACHMENTS: 'UPLOAD_ATTACHMENTS',
  UPLOADING: 'UPLOADING',
  WHAT_PLANS_DO_YOU_HAVE: 'WHAT_PLANS_DO_YOU_HAVE',
  WRITE_HERE_ABOUT_YOUR_RESEARCH_PROGRESS: 'WRITE_HERE_ABOUT_YOUR_RESEARCH_PROGRESS',
  FOR_EXAMPLE_WRITE_ABOUT_HOSTING_A_VISITOR: 'FOR_EXAMPLE_WRITE_ABOUT_HOSTING_A_VISITOR',
  FOR_EXAMPLE_HAVE_YOU_WRITTEN_A_BLOG: 'FOR_EXAMPLE_HAVE_YOU_WRITTEN_A_BLOG',
  NO_REPORTS: 'NO_REPORTS',
  ANY: 'ANY',
  FILTER_BY: 'FILTER_BY',
  SORT_BY: 'SORT_BY',
  THERE_WAS_AN_ERROR_IN_LOADING_DOCTORAL_CANDIDATES: 'THERE_WAS_AN_ERROR_IN_LOADING_DOCTORAL_CANDIDATES',
  THERE_ARE_NO_DOCTORAL_CANDIDATES_IN_THE_SELECTED: 'THERE_ARE_NO_DOCTORAL_CANDIDATES_IN_THE_SELECTED',
  DOCTORAL_PROGRAMMES: 'DOCTORAL_PROGRAMMES',
  ERROR_LOADING_DOCTORAL_PROGRAMMES: 'ERROR_LOADING_DOCTORAL_PROGRAMMES',
  SELECT_NONE: 'SELECT_NONE',
  SELECT_ALL: 'SELECT_ALL',
  WHEN_WILL_EACH_STAGE_OF_THE_PUBLICATION_BEGIN: 'WHEN_WILL_EACH_STAGE_OF_THE_PUBLICATION_BEGIN',
  THE_STATUSES_ARE_IN_INCORRECT_ORDER: 'THE_STATUSES_ARE_IN_INCORRECT_ORDER',
  TO_BE_INCLUDED_IN_THE_PHD_THESIS: 'TO_BE_INCLUDED_IN_THE_PHD_THESIS',
  SAVE_PUBLICATION: 'SAVE_PUBLICATION',
  CREATE_ANOTHER: 'CREATE_ANOTHER',
  CREATING_ANOTHER_PUBLICATION: 'CREATING_ANOTHER_PUBLICATION',
  I_HAVE_COMPLETED_THIS_COURSE: 'I_HAVE_COMPLETED_THIS_COURSE',
  SAVE_COURSE: 'SAVE_COURSE',
  CREATING_ANOTHER_COURSE: 'CREATING_ANOTHER_COURSE',
  CREATING_ANOTHER_GRANT: 'CREATING_ANOTHER_GRANT',
  SOURCE_OF_GRANT: 'SOURCE_OF_GRANT',
  FROM_WHICH_ORGANIZATION_IS_THE_GRANT_FROM: 'FROM_WHICH_ORGANIZATION_IS_THE_GRANT_FROM',
  HOW_MUCH_IS_THIS_GRANT: 'HOW_MUCH_IS_THIS_GRANT',
  SAVE_GRANT: 'SAVE_GRANT',
  ENDS_AT: 'ENDS_AT',
  BEGINS_AT: 'BEGINS_AT',
  WHAT_IS_THE_INTENDED_USAGE_OF_THIS_GRANT: 'WHAT_IS_THE_INTENDED_USAGE_OF_THIS_GRANT',
  DURATION_OF_GRANT: 'DURATION_OF_GRANT',
  WHEN_DOES_THIS_GRANT_BEGIN_AND_END: 'WHEN_DOES_THIS_GRANT_BEGIN_AND_END',
  CREATING_ANOTHER_TEACHING: 'CREATING_ANOTHER_TEACHING',
  BEGINNING_DATE: 'BEGINNING_DATE',
  END_DATE: 'END_DATE',
  NAME_OF_THE_COURSE_OR_WORKSHOP: 'NAME_OF_THE_COURSE_OR_WORKSHOP',
  MY_ROLE_IN_TEACHING: 'MY_ROLE_IN_TEACHING',
  FOR_EXAMPLE_LECTURER_OR_ASSISTANT: 'FOR_EXAMPLE_LECTURER_OR_ASSISTANT',
  TOPIC_OR_TITLE_OF_THE_THESIS: 'TOPIC_OR_TITLE_OF_THE_THESIS',
  LEVEL_OF_THESIS: 'LEVEL_OF_THESIS',
  COURSE_OR_WORKSHOP: 'COURSE_OR_WORKSHOP',
  HOW_MANY_HOURS_OF_WORK_DID_YOU_SPEND_IN_TOTAL: 'HOW_MANY_HOURS_OF_WORK_DID_YOU_SPEND_IN_TOTAL',
  NAME_OF_THE_STUDENT_WHO_YOUR_WERE_INSTRUCTING: 'NAME_OF_THE_STUDENT_WHO_YOUR_WERE_INSTRUCTING',
  MY_ROLE_IN_INSTRUCTING_THE_STUDENT: 'MY_ROLE_IN_INSTRUCTING_THE_STUDENT',
  FOR_EXAMPLE_MAIN_SUPERVISOR_OR_CO_SUPERVISOR: 'FOR_EXAMPLE_MAIN_SUPERVISOR_OR_CO_SUPERVISOR',
  FOR_EXAMPLE_MASTERS_THESIS_OR_BACHELORS_THESIS: 'FOR_EXAMPLE_MASTERS_THESIS_OR_BACHELORS_THESIS',
  CREATING_ANOTHER_SALARY: 'CREATING_ANOTHER_SALARY',
  SOURCE_OF_SALARY: 'SOURCE_OF_SALARY',
  FROM_WHICH_ORGANIZATION_AND_OR_PROJECT_DO_YOU_GET: 'FROM_WHICH_ORGANIZATION_AND_OR_PROJECT_DO_YOU_GET',
  DURATION_OF_SALARY: 'DURATION_OF_SALARY',
  WHEN_DOES_THIS_SALARY_BEGIN_AND_END_IF_THERE_IS_NO: 'WHEN_DOES_THIS_SALARY_BEGIN_AND_END_IF_THERE_IS_NO',
  SAVE_SALARY: 'SAVE_SALARY',
  DUE_DATE: 'DUE_DATE',
  SEND_REMINDER_EMAILS_AT: 'SEND_REMINDER_EMAILS_AT',
  SAVE_REMINDER: 'SAVE_REMINDER',
  LOG_IN_OR_SIGN_UP_TO_ACCEPT_INVITATION: 'LOG_IN_OR_SIGN_UP_TO_ACCEPT_INVITATION',
  LOG_IN_WITH_UNIVERSITY_OF_HELSINKI_ACCOUNT: 'LOG_IN_WITH_UNIVERSITY_OF_HELSINKI_ACCOUNT',
  I_DON_T_HAVE_A_UNIVERSITY_OF_HELSINKI_ACCOUNT: 'I_DON_T_HAVE_A_UNIVERSITY_OF_HELSINKI_ACCOUNT',
  LOG_IN_WITH_AN_EXISTING_THESSA_ACCOUNT: 'LOG_IN_WITH_AN_EXISTING_THESSA_ACCOUNT',
  LOG_IN_OR_SIGN_UP_FOR_A_THESSA_ACCOUNT_TO: 'LOG_IN_OR_SIGN_UP_FOR_A_THESSA_ACCOUNT_TO',
  SIGN_UP_FOR_A_NEW_THESSA_ACCOUNT: 'SIGN_UP_FOR_A_NEW_THESSA_ACCOUNT',
  ERROR_LOGIN_OR_SIGN_UP: 'ERROR_LOGIN_OR_SIGN_UP',
  INVITATION_HAS_EXPIRED_AT: 'INVITATION_HAS_EXPIRED_AT',
  YOU_HAVE_BEEN_INVITED_TO_BECOME: 'YOU_HAVE_BEEN_INVITED_TO_BECOME',
  ACCEPT_INVITATION: 'ACCEPT_INVITATION',
  THERE_WAS_AN_ERROR_IN_DECLINING_THE_INVITATION: 'THERE_WAS_AN_ERROR_IN_DECLINING_THE_INVITATION',
  SIGN_UP_FOR_A_NEW_THESSA_ACCOUNT_TO: 'SIGN_UP_FOR_A_NEW_THESSA_ACCOUNT_TO',
  SIGN_UP: 'SIGN_UP',
  ERROR_IN_SIGN_UP: 'ERROR_IN_SIGN_UP',
  LOG_IN_FAILED_TRY_AGAIN: 'LOG_IN_FAILED_TRY_AGAIN',
  ROLES: 'ROLES',
  DOES_NOT_HAVE_ANY_ROLES_ASSIGNED: 'DOES_NOT_HAVE_ANY_ROLES_ASSIGNED',
  THERE_WAS_AN_ERROR_IN_UPDATING_ROLES: 'THERE_WAS_AN_ERROR_IN_UPDATING_ROLES',
  ONLY_ONE_PERSON_CAN_BE_THE_COORDINATING: 'ONLY_ONE_PERSON_CAN_BE_THE_COORDINATING',
  ONLY_ONE_PERSON_CAN_BE_THE_MAIN_SUPERVISOR: 'ONLY_ONE_PERSON_CAN_BE_THE_MAIN_SUPERVISOR',
  ONE_PERSON_SHOULD_BE_SELECTED_AS_THE_COORDINATING: 'ONE_PERSON_SHOULD_BE_SELECTED_AS_THE_COORDINATING',
  ONE_PERSON_SHOULD_BE_SELECTED_AS_THE_MAIN: 'ONE_PERSON_SHOULD_BE_SELECTED_AS_THE_MAIN',
  MYSELF: 'MYSELF',
  FILTERS: 'FILTERS',
  CHOOSE_A_DOCTORAL_PROGRAMME: 'CHOOSE_A_DOCTORAL_PROGRAMME',
  LOADING_INACTIVE_USER: 'LOADING_INACTIVE_USER',
  NO_STUDENT_NUMBER: 'NO_STUDENT_NUMBER',
  SIGNED_UP: 'SIGNED_UP',
  NO_SUPERVISORS: 'NO_SUPERVISORS',
  SUPERVISOR_INVITATIONS: 'SUPERVISOR_INVITATIONS',
  ACTIVATE: 'ACTIVATE',
  SEND_EMAIL: 'SEND_EMAIL',
  NO_EMAIL_ADDRESS: 'NO_EMAIL_ADDRESS',
  THERE_WAS_AN_ERROR_IN_ACTIVATING_THE_USER_ACCOUNT: 'THERE_WAS_AN_ERROR_IN_ACTIVATING_THE_USER_ACCOUNT',
  SELECT_DOCTORAL_PROGRAMME: 'SELECT_DOCTORAL_PROGRAMME',
  NO_DOCTORAL_PROGRAMME_SELECTED: 'NO_DOCTORAL_PROGRAMME_SELECTED',
  SELECT_DOCTORAL_PROGRAMMES: 'SELECT_DOCTORAL_PROGRAMMES',
  ALL_DOCTORAL_PROGRAMMES: 'ALL_DOCTORAL_PROGRAMMES',
  NOT_VERIFIED: 'NOT_VERIFIED',
  VERIFY_NOW: 'VERIFY_NOW',
  DOCTORAL_CANDIDATE_DOES_NOT_HAVE_ANY_SUPERVISORS: 'DOCTORAL_CANDIDATE_DOES_NOT_HAVE_ANY_SUPERVISORS',
  COUNT_THESIS_COMMITTEE_MEMBER: 'COUNT_THESIS_COMMITTEE_MEMBER',
  COUNT_THESIS_COMMITTEE_MEMBER_other: 'COUNT_THESIS_COMMITTEE_MEMBER_other',
  COUNT_SUPERVISOR_RELATIONSHIP: 'COUNT_SUPERVISOR_RELATIONSHIP',
  COUNT_SUPERVISOR_RELATIONSHIP_other: 'COUNT_SUPERVISOR_RELATIONSHIP_other',
  COUNT_PUBLICATION_IN_PROGRESS: 'COUNT_PUBLICATION_IN_PROGRESS',
  COUNT_PUBLICATION_IN_PROGRESS_other: 'COUNT_PUBLICATION_IN_PROGRESS_other',
  COUNT_PUBLICATION_PUBLISHED: 'COUNT_PUBLICATION_PUBLISHED',
  COUNT_PUBLICATION_PUBLISHED_other: 'COUNT_PUBLICATION_PUBLISHED_other',
  MAIN_SUPERVISOR: 'MAIN_SUPERVISOR',
  MAIN_SUPERVISOR_other: 'MAIN_SUPERVISOR_other',
  COORDINATING_ACADEMIC: 'COORDINATING_ACADEMIC',
  COORDINATING_ACADEMIC_other: 'COORDINATING_ACADEMIC_other',
  COUNT_DOCTORAL_CANDIDATE: 'COUNT_DOCTORAL_CANDIDATE',
  COUNT_DOCTORAL_CANDIDATE_other: 'COUNT_DOCTORAL_CANDIDATE_other',
  ONLY_SHOW_DOCTORAL_CANDIDATES_WITHOUT_ACCOUNT: 'ONLY_SHOW_DOCTORAL_CANDIDATES_WITHOUT_ACCOUNT',
  ONLY_SHOW_DOCTORAL_CANDIDATES_WITHOUT_SUPERVISOR: 'ONLY_SHOW_DOCTORAL_CANDIDATES_WITHOUT_SUPERVISOR',
  COUNT_DOCTORAL_CANDIDATE_S_REPORT: 'COUNT_DOCTORAL_CANDIDATE_S_REPORT',
  COUNT_DOCTORAL_CANDIDATE_S_REPORT_other: 'COUNT_DOCTORAL_CANDIDATE_S_REPORT_other',
  COUNT_DOCTORAL_CANDIDATE_S_LATEST: 'COUNT_DOCTORAL_CANDIDATE_S_LATEST',
  COUNT_DOCTORAL_CANDIDATE_S_LATEST_other: 'COUNT_DOCTORAL_CANDIDATE_S_LATEST_other',
  COUNT_DOCTORAL_CANDIDATE_IS_ON_TIME: 'COUNT_DOCTORAL_CANDIDATE_IS_ON_TIME',
  COUNT_DOCTORAL_CANDIDATE_IS_ON_TIME_other: 'COUNT_DOCTORAL_CANDIDATE_IS_ON_TIME_other',
  COUNT_ITEM_IN_QUEUE: 'COUNT_ITEM_IN_QUEUE',
  COUNT_ITEM_IN_QUEUE_other: 'COUNT_ITEM_IN_QUEUE_other',
  OLDEST_ITEM_FIRST: 'OLDEST_ITEM_FIRST',
  ISPROCEEDING: 'ISPROCEEDING',
  IS_THE_ANNUAL_REPORT_PROCEEDING_AS_PLANNED: 'IS_THE_ANNUAL_REPORT_PROCEEDING_AS_PLANNED',
  CONCLUSIONCOMMENT: 'CONCLUSIONCOMMENT',
  COMMENT_ABOUT_THE_PROGRESS_OF_THE: 'COMMENT_ABOUT_THE_PROGRESS_OF_THE',
  PROGRESS_FEEDBACK_BY: 'PROGRESS_FEEDBACK_BY',
  IS_THE_DISSERTATION_WORK_PROCEEDING_AS_PLANNED: 'IS_THE_DISSERTATION_WORK_PROCEEDING_AS_PLANNED',
  PROGRESS_FEEDBACK_IS_MISSING: 'PROGRESS_FEEDBACK_IS_MISSING',
  PROGRESS_FEEDBACK_SUBMITTED: 'PROGRESS_FEEDBACK_SUBMITTED',
  GIVE_PROGRESS_FEEDBACK: 'GIVE_PROGRESS_FEEDBACK',
  THERE_WAS_A_PROBLEM_SUBMITTING_PROGRESS_FEEDBACK: 'THERE_WAS_A_PROBLEM_SUBMITTING_PROGRESS_FEEDBACK',
  PROGRESS_FEEDBACK_SUBMITTED_REDIRECTING_YOU_BACK: 'PROGRESS_FEEDBACK_SUBMITTED_REDIRECTING_YOU_BACK',
  PROGRESS_FEEDBACK: 'PROGRESS_FEEDBACK',
  COMMENT1: 'COMMENT1',
  OPTIONAL_COMMENT_ABOUT_THE_PROGRESS_OF_THE: 'OPTIONAL_COMMENT_ABOUT_THE_PROGRESS_OF_THE',
  MOBILE: 'MOBILE',
  DESKTOP: 'DESKTOP',
  VIEW_ACCOUNT: 'VIEW_ACCOUNT',
  EDIT_ROLES: 'EDIT_ROLES',
  ENDING_ON_DATE: 'ENDING_ON_DATE',
  INVITE_SUPERVISOR: 'INVITE_SUPERVISOR',
  ADD_SUPERVISOR: 'ADD_SUPERVISOR',
  FAILED_TO_SEND_INVITE_PLEASE_TRY_AGAIN: 'FAILED_TO_SEND_INVITE_PLEASE_TRY_AGAIN',
  SAVE_ROLES: 'SAVE_ROLES',
  ERROR_IN_SAVING_ROLES_TRY_AGAIN_OR: 'ERROR_IN_SAVING_ROLES_TRY_AGAIN_OR',
  INVITATION: 'INVITATION',
  REMOVE_INVITATION: 'REMOVE_INVITATION',
  YOU_HAVE_NO_ACTIVE_DOCTORAL_CANDIDATES_IN_THESSA: 'YOU_HAVE_NO_ACTIVE_DOCTORAL_CANDIDATES_IN_THESSA',
  NOT_IN_THESSA: 'NOT_IN_THESSA',
  UPLOAD_AN_EXCEL_FILE_WITH_SUPERVISOR_RELATIONSHIPS: 'UPLOAD_AN_EXCEL_FILE_WITH_SUPERVISOR_RELATIONSHIPS',
  CHANGE_FILE: 'CHANGE_FILE',
  GO_BACK: 'GO_BACK',
  SAVE_SUPERVISOR_RELATIONSHIPS: 'SAVE_SUPERVISOR_RELATIONSHIPS',
  DOCTORAL_CANDIDATE_ROW: 'DOCTORAL_CANDIDATE_ROW',
  IGNORE: 'IGNORE',
  COORDINATING_ACADEMIC_EMAIL: 'COORDINATING_ACADEMIC_EMAIL',
  MAIN_SUPERVISOR_EMAIL: 'MAIN_SUPERVISOR_EMAIL',
  SUPERVISOR_EMAIL: 'SUPERVISOR_EMAIL',
  SUPERVISOR_RELATIONSHIPS: 'SUPERVISOR_RELATIONSHIPS',
  UPLOAD_A_FILE_WITH_SUPERVISOR_RELATIONSHIPS: 'UPLOAD_A_FILE_WITH_SUPERVISOR_RELATIONSHIPS',
  USE_THE_BUTTON_ABOVE_TO_UPLOAD_AN_EXCEL_FILE: 'USE_THE_BUTTON_ABOVE_TO_UPLOAD_AN_EXCEL_FILE',
  PICK_COLUMNS_AND_ROWS_TO_IMPORT: 'PICK_COLUMNS_AND_ROWS_TO_IMPORT',
  FOR_EACH_COLUMN_WITH_RELEVANT_DATA: 'FOR_EACH_COLUMN_WITH_RELEVANT_DATA',
  THEN_SELECT_EACH_ROW_WITH_A_SUPERVISOR: 'THEN_SELECT_EACH_ROW_WITH_A_SUPERVISOR',
  CHECK_THAT_THE_FOLLOWING_PREVIEW_IS_CORRECT: 'CHECK_THAT_THE_FOLLOWING_PREVIEW_IS_CORRECT',
  IMPORT_SUCCESSFUL: 'IMPORT_SUCCESSFUL',
  IMPORT_FAILED_PLEASE_CONTACT_US_FOR_HELP: 'IMPORT_FAILED_PLEASE_CONTACT_US_FOR_HELP',
  FILE_UPLOAD_WAS_NOT_SUCCESSFUL_PLEASE_TRY_AGAIN: 'FILE_UPLOAD_WAS_NOT_SUCCESSFUL_PLEASE_TRY_AGAIN',
  NOTES: 'NOTES',
  DONE1: 'DONE1',
  COURSE_TITLE: 'COURSE_TITLE',
  SALARY_SOURCE: 'SALARY_SOURCE',
  GRANT_SOURCE: 'GRANT_SOURCE',
  STUDENT: 'STUDENT',
  STATUS_OF_THE_PHD_PROJECT: 'STATUS_OF_THE_PHD_PROJECT',
  TIME_SPENT_ON_PHD: 'TIME_SPENT_ON_PHD',
  SOCIAL_IMPACT_AND_PUBLIC_OUTREACH: 'SOCIAL_IMPACT_AND_PUBLIC_OUTREACH',
  ATTACHMENTS: 'ATTACHMENTS',
  THREE_POSITIVE_THINGS: 'THREE_POSITIVE_THINGS',
  THREE_NEGATIVE_THINGS: 'THREE_NEGATIVE_THINGS',
  CAREER_PLANNING: 'CAREER_PLANNING',
  CONCLUSIONS: 'CONCLUSIONS',
  OTHER_WORK: 'OTHER_WORK',
  WAIT_FOR_ONE_MINUTE_BEFORE_RESENDING: 'WAIT_FOR_ONE_MINUTE_BEFORE_RESENDING',
  ACCOUNT_IS_CLOSED: 'ACCOUNT_IS_CLOSED',
  ACCOUNT_IS_ACTIVE: 'ACCOUNT_IS_ACTIVE',
  ACCOUNT_IS_INACTIVE: 'ACCOUNT_IS_INACTIVE',
  ESTIMATED_STATUS_OF_THE_PHD_PROJECT: 'ESTIMATED_STATUS_OF_THE_PHD_PROJECT',
  ESTIMATED_TIME_OF_PHD_DEFENCE: 'ESTIMATED_TIME_OF_PHD_DEFENCE',
  CAREER_PLANS_AFTER_YOUR_PHD: 'CAREER_PLANS_AFTER_YOUR_PHD',
  BACK_TO_OVERVIEW: 'BACK_TO_OVERVIEW',
  BACK_TO_REPORT: 'BACK_TO_REPORT',
  CHANGE_SUPERVISOR: 'CHANGE_SUPERVISOR',
  ADD_SUPERVISOR_FOR_NAME: 'ADD_SUPERVISOR_FOR_NAME',
  FAILED_TO_ADD_SUPERVISOR_PLEASE_TRY_AGAIN_OR: 'FAILED_TO_ADD_SUPERVISOR_PLEASE_TRY_AGAIN_OR',
  FAILED_TO_INVITE_SUPERVISOR_PLEASE_TRY_AGAIN_OR: 'FAILED_TO_INVITE_SUPERVISOR_PLEASE_TRY_AGAIN_OR',
  INVITE_SUPERVISOR_FOR_NAME: 'INVITE_SUPERVISOR_FOR_NAME',
  CHOOSE_LANGUAGE: 'CHOOSE_LANGUAGE',
  PREPARATION_AND_ANALYSIS: 'PREPARATION_AND_ANALYSIS',
  WRITING: 'WRITING',
  NAME_IS_NOT_YET_ON_THESSA_WHEN_THEY_SIGN_UP: 'NAME_IS_NOT_YET_ON_THESSA_WHEN_THEY_SIGN_UP',
  INVITE_THESIS_COMMITTEE: 'INVITE_THESIS_COMMITTEE',
  EDIT_THESIS_COMMITTEE: 'EDIT_THESIS_COMMITTEE',
  YOUR_USER_ACCOUNT_NEEDS_TO_BE_ACTIVATED: 'YOUR_USER_ACCOUNT_NEEDS_TO_BE_ACTIVATED',
  YOU_WILL_RECEIVE_AN_EMAIL_WHEN_YOUR_ACCOUNT: 'YOU_WILL_RECEIVE_AN_EMAIL_WHEN_YOUR_ACCOUNT',
  ESTIMATE_MADE_ON: 'ESTIMATE_MADE_ON',
  PHD_PROJECT_STATUS: 'PHD_PROJECT_STATUS',
  TIME_SPENT_PERIOD: 'TIME_SPENT_PERIOD',
  EDIT_PRELIMINARY_TITLE_OF_DISSERTATION: 'EDIT_PRELIMINARY_TITLE_OF_DISSERTATION',
  CREATE_PRELIMINARY_TITLE_OF_DISSERTATION: 'CREATE_PRELIMINARY_TITLE_OF_DISSERTATION',
  SUBJECT_OR_STUDENT: 'SUBJECT_OR_STUDENT',
  APPLICATION_SENT: 'APPLICATION_SENT',
  PLANNING_TO_APPLY: 'PLANNING_TO_APPLY',
  CREDITS_one: 'CREDITS_one',
  CREDITS_other: 'CREDITS_other',
  CR: 'CR',
  WHAT_WAS_DECIDED: 'WHAT_WAS_DECIDED',
  ROLE_NAME: 'ROLE_NAME',
  CHECK_REPORT_STATUS: 'CHECK_REPORT_STATUS',
  CHECK_SUPERVISION_PLAN_STATUS: 'CHECK_SUPERVISION_PLAN_STATUS',
  SEARCH_BY: 'SEARCH_BY',
  COMPLETE_THE_REPORT_FORM: 'COMPLETE_THE_REPORT_FORM',
  CONCLUSION_CAN_BE_WRITTER_AFTER: 'CONCLUSION_CAN_BE_WRITTER_AFTER',
  YES: 'YES',
  NO: 'NO',
  PLEASE_INVITE_TCMS_TO_THESSA: 'PLEASE_INVITE_TCMS_TO_THESSA',
  COORDINATOR_TOOLS: {
    MAIN_HEADING: 'COORDINATOR_TOOLS.MAIN_HEADING',
    HEADINGS: {
      MANAGE_USERS: 'COORDINATOR_TOOLS.HEADINGS.MANAGE_USERS',
      STATISTICS: 'COORDINATOR_TOOLS.HEADINGS.STATISTICS',
      OTHER: 'COORDINATOR_TOOLS.HEADINGS.OTHER'
    },
    TOOLS: {
      LATEST_REPORTS: 'COORDINATOR_TOOLS.TOOLS.LATEST_REPORTS',
      INCOMPLETE_REPORTS: 'COORDINATOR_TOOLS.TOOLS.INCOMPLETE_REPORTS',
      ADMIN_REPORT: 'COORDINATOR_TOOLS.TOOLS.ADMIN_REPORT',
      IMPORT_SUPERVISOR_RELATIONSHIPS: 'COORDINATOR_TOOLS.TOOLS.IMPORT_SUPERVISOR_RELATIONSHIPS'
    },
    DESCRIPTIONS: {
      DOCTORAL_CANDIDATES: 'COORDINATOR_TOOLS.DESCRIPTIONS.DOCTORAL_CANDIDATES',
      USERS: 'COORDINATOR_TOOLS.DESCRIPTIONS.USERS',
      REPORT_CONCLUSIONS_OVERVIEW: 'COORDINATOR_TOOLS.DESCRIPTIONS.REPORT_CONCLUSIONS_OVERVIEW',
      LATEST_REPORTS: 'COORDINATOR_TOOLS.DESCRIPTIONS.LATEST_REPORTS',
      INCOMPLETE_REPORTS: 'COORDINATOR_TOOLS.DESCRIPTIONS.INCOMPLETE_REPORTS',
      ADMIN_REPORT: 'COORDINATOR_TOOLS.DESCRIPTIONS.ADMIN_REPORT',
      IMPORT_SUPERVISOR_RELATIONSHIPS: 'COORDINATOR_TOOLS.DESCRIPTIONS.IMPORT_SUPERVISOR_RELATIONSHIPS'
    }
  },
  SHOW_ALL_DOCTORAL_CANDIDATES: 'SHOW_ALL_DOCTORAL_CANDIDATES',
  HIDE_INACTIVE_DOCTORAL_RESEARCHERS: 'HIDE_INACTIVE_DOCTORAL_RESEARCHERS',
  DOCTORAL_RESEARCHER_OVERVIEW: 'DOCTORAL_RESEARCHER_OVERVIEW',
  LAST_COMPLETED_REPORT: 'LAST_COMPLETED_REPORT',
  FILTER_DOCTORAL_RESEARCHERS: 'FILTER_DOCTORAL_RESEARCHERS',
  SUPERVISOR_RELATIONSHIP_OVERVIEW: {
    LINK: 'SUPERVISOR_RELATIONSHIP_OVERVIEW.LINK',
    IF_MISSING: 'SUPERVISOR_RELATIONSHIP_OVERVIEW.IF_MISSING',
    IF_MISSING_THEN_CONTACT: 'SUPERVISOR_RELATIONSHIP_OVERVIEW.IF_MISSING_THEN_CONTACT',
    IF_CHANGED: 'SUPERVISOR_RELATIONSHIP_OVERVIEW.IF_CHANGED',
    IF_CHANGED_THEN_CONTACT: 'SUPERVISOR_RELATIONSHIP_OVERVIEW.IF_CHANGED_THEN_CONTACT'
  },
  THESIS_COMMITTEE_MEMBERS: {
    SUPERVISORS_ARE_HANDLED_BY_OTHERS: 'THESIS_COMMITTEE_MEMBERS.SUPERVISORS_ARE_HANDLED_BY_OTHERS',
    LINK: 'THESIS_COMMITTEE_MEMBERS.LINK',
    LINK_TITLE: 'THESIS_COMMITTEE_MEMBERS.LINK_TITLE',
    INVITATION_HEADING: 'THESIS_COMMITTEE_MEMBERS.INVITATION_HEADING',
    INVITATION_HEADING_FOR_SUPERVISORS: 'THESIS_COMMITTEE_MEMBERS.INVITATION_HEADING_FOR_SUPERVISORS',
    INVITATION_LABEL: 'THESIS_COMMITTEE_MEMBERS.INVITATION_LABEL',
    INVITATION_LABEL_FOR_SUPERVISORS: 'THESIS_COMMITTEE_MEMBERS.INVITATION_LABEL_FOR_SUPERVISORS'
  },
  CONTACT: {
    HEADING: 'CONTACT.HEADING',
    PLANNING_OFFICER: 'CONTACT.PLANNING_OFFICER',
    DOCTORAL_PROGRAMME_CONTACT_LINK_PREFIX: 'CONTACT.DOCTORAL_PROGRAMME_CONTACT_LINK_PREFIX',
    DOCTORAL_PROGRAMME_CONTACT_LINK_URL: 'CONTACT.DOCTORAL_PROGRAMME_CONTACT_LINK_URL',
    PLANNING_OFFICER_HELPS_WITH: 'CONTACT.PLANNING_OFFICER_HELPS_WITH',
    PLANNING_OFFICER_HELPS_WITH_ITEMS: 'CONTACT.PLANNING_OFFICER_HELPS_WITH_ITEMS',
    TECHNICAL_PROBLEMS: 'CONTACT.TECHNICAL_PROBLEMS',
    TECHNICAL_EMAIL_PREFIX: 'CONTACT.TECHNICAL_EMAIL_PREFIX',
    TECHNICAL_EMAIL_LINK_TEXT: 'CONTACT.TECHNICAL_EMAIL_LINK_TEXT'
  },
  DATE_RANGE_PICKER: {
    INVALID_START_DATE: 'DATE_RANGE_PICKER.INVALID_START_DATE',
    INVALID_END_DATE: 'DATE_RANGE_PICKER.INVALID_END_DATE',
    INVALID_END_IS_BEFORE_START: 'DATE_RANGE_PICKER.INVALID_END_IS_BEFORE_START'
  },
  COORDINATOR_DOCTORAL_CANDIDATES: {
    PLACEHOLDER: 'COORDINATOR_DOCTORAL_CANDIDATES.PLACEHOLDER',
    LABEL: 'COORDINATOR_DOCTORAL_CANDIDATES.LABEL',
    ACTION: 'COORDINATOR_DOCTORAL_CANDIDATES.ACTION'
  },
  END_OF_SENTENCE_PERIOD: 'END_OF_SENTENCE_PERIOD',
  RESOURCES: {
    INTRO_VIDEO: 'RESOURCES.INTRO_VIDEO'
  },
  MENU: 'MENU',
  FRONTPAGE: 'FRONTPAGE',
  OPEN_PROFILE_MENU: 'OPEN_PROFILE_MENU',
  CLOSE_PROFILE_MENU: 'CLOSE_PROFILE_MENU',
  OPEN_MAIN_MENU: 'OPEN_MAIN_MENU',
  CLOSE_MAIN_MENU: 'CLOSE_MAIN_MENU',
  CHANGE_LANGUAGE: 'CHANGE_LANGUAGE',
  PROFILE_MENU: 'PROFILE_MENU',
  MAIN_MENU: 'MAIN_MENU',
  FOOTER_MENU: 'FOOTER_MENU',
  DATE_NOT_SPECIFIED: 'DATE_NOT_SPECIFIED',
  EMAIL_OR_USER_INPUT: {
    HINT: 'EMAIL_OR_USER_INPUT.HINT'
  },
  FORM: {
    REQUIRED_FIELD: 'FORM.REQUIRED_FIELD',
    OPTIONAL_FIELD: 'FORM.OPTIONAL_FIELD',
    VALID_EMAIL_ADDRESS_REQUIRED: 'FORM.VALID_EMAIL_ADDRESS_REQUIRED',
    PASSWORD_REQUIREMENTS: 'FORM.PASSWORD_REQUIREMENTS',
    VALID: 'FORM.VALID',
    INVALID: 'FORM.INVALID',
    OPTIONAL: 'FORM.OPTIONAL',
    COMPLETE_FIELDS_TO_SAVE_FORM: 'FORM.COMPLETE_FIELDS_TO_SAVE_FORM',
    COMPLETE_FIELDS_TO_SUBMIT_FORM: 'FORM.COMPLETE_FIELDS_TO_SUBMIT_FORM'
  },
  INVITATION_ROLE: {
    COORDINATING_ACADEMIC: 'INVITATION_ROLE.COORDINATING_ACADEMIC',
    MAIN_SUPERVISOR: 'INVITATION_ROLE.MAIN_SUPERVISOR',
    SUPERVISOR: 'INVITATION_ROLE.SUPERVISOR',
    THESIS_COMMITTEE_MEMBER: 'INVITATION_ROLE.THESIS_COMMITTEE_MEMBER'
  },
  EDIT_PUBLICATION: 'EDIT_PUBLICATION',
  EDIT_SALARY: 'EDIT_SALARY',
  EDIT_GRANT: 'EDIT_GRANT',
  EDIT_COURSE: 'EDIT_COURSE',
  EDIT_COURSE_WORKSHOP: 'EDIT_COURSE_WORKSHOP',
  EDIT_THESIS_INSTRUCTION: 'EDIT_THESIS_INSTRUCTION',
  EDIT_TEACHING: 'EDIT_TEACHING',
  FOR_STUDENT: 'FOR_STUDENT',
  CREATE_NEW_REMINDER: 'CREATE_NEW_REMINDER',
  LOGO_OF_UNIVERSITY_OF_HELSINKI: 'LOGO_OF_UNIVERSITY_OF_HELSINKI',
  NEW: 'NEW',
  CHANGED: 'CHANGED',
  INDICATOR: 'INDICATOR',
  STATUS_IN_THE_MOST_RECENT: 'STATUS_IN_THE_MOST_RECENT',
  SHOW_DETAILS: 'SHOW_DETAILS',
  HIDE_DETAILS: 'HIDE_DETAILS',
  ADD_PUBLICATION: 'ADD_PUBLICATION',
  ADD_COURSE: 'ADD_COURSE',
  EDIT_NOTES: 'EDIT_NOTES',
  CANCEL_EDITING_NOTES: 'CANCEL_EDITING_NOTES',
  SAVE_NOTES: 'SAVE_NOTES',
  COURSE_IS_COMPLETED: 'COURSE_IS_COMPLETED',
  SEND_MESSAGE: 'SEND_MESSAGE',
  REMOVE_COMMENT: 'REMOVE_COMMENT',
  REPORT_VIEW: {
    ERROR: {
      MISSING_REPORT: 'REPORT_VIEW.ERROR.MISSING_REPORT',
      FAILED_TO_SAVE_REPORT: 'REPORT_VIEW.ERROR.FAILED_TO_SAVE_REPORT',
      FAILED_TO_SUBMIT_REPORT: 'REPORT_VIEW.ERROR.FAILED_TO_SUBMIT_REPORT',
      FAILED_TO_DELETE_REPORT: 'REPORT_VIEW.ERROR.FAILED_TO_DELETE_REPORT'
    }
  },
  PHD_PROJECT: {
    ERROR: {
      GENERAL_ERROR_HEADING: 'PHD_PROJECT.ERROR.GENERAL_ERROR_HEADING',
      YOU_CAN_RETRY_OR: 'PHD_PROJECT.ERROR.YOU_CAN_RETRY_OR',
      INCLUDE_THIS_TEXT: 'PHD_PROJECT.ERROR.INCLUDE_THIS_TEXT',
      CANNOT_INITIALIZE: 'PHD_PROJECT.ERROR.CANNOT_INITIALIZE'
    }
  },
  CANCEL_INVITING_THESIS_COMMITTEE_MEMBER: 'CANCEL_INVITING_THESIS_COMMITTEE_MEMBER',
  INFORMATION: 'INFORMATION',
  COMMENT: 'COMMENT',
  WRITE_YOUR_COMMENT_HERE: 'WRITE_YOUR_COMMENT_HERE',
  HIDE_DISCUSSION: 'HIDE_DISCUSSION',
  SHOW_DISCUSSION: 'SHOW_DISCUSSION',
  VIEW_SUPERVISION_PLAN: 'VIEW_SUPERVISION_PLAN',
  ADD_COURSE_WORKSHOP: 'ADD_COURSE_WORKSHOP',
  ADD_THESIS_INSTRUCTION: 'ADD_THESIS_INSTRUCTION',
  REPORT_STATUS: 'REPORT_STATUS',
  UNTITLED: 'UNTITLED',
  UNTITLED_PUBLICATION: 'UNTITLED_PUBLICATION',
  UNTITLED_SALARY: 'UNTITLED_SALARY',
  UNTITLED_GRANT: 'UNTITLED_GRANT',
  UNTITLED_COURSE: 'UNTITLED_COURSE',
  UNTITLED_TEACHING: 'UNTITLED_TEACHING',
  REMOVAL_CONFIRMATION: 'REMOVAL_CONFIRMATION',
  THESSA_INTRODUCTION_VIDEO: 'THESSA_INTRODUCTION_VIDEO',
  ACCESSIBILITY_STATEMENT: 'ACCESSIBILITY_STATEMENT',
  INFORMATION_ON_THE_ACCESSIBILITY_OF_THE_WEBSITE: 'INFORMATION_ON_THE_ACCESSIBILITY_OF_THE_WEBSITE',
  LAST_UPDATED: 'LAST_UPDATED',
  LOGIN_FOR_EXTERNAL_USERS: 'LOGIN_FOR_EXTERNAL_USERS',
  YOU_WILL_NOT_BE_ABLE_TO_ACCESS: 'YOU_WILL_NOT_BE_ABLE_TO_ACCESS',
  ASK_YOUR_DOCTORAL_PROGRAMME: 'ASK_YOUR_DOCTORAL_PROGRAMME',
  REMINDER: 'REMINDER',
  BACK_TO_STATISTICS: 'BACK_TO_STATISTICS',
  BACK_TO_USER_DETAILS: 'BACK_TO_USER_DETAILS',
  TOTAL_AMOUNT_OF_CREDITS: 'TOTAL_AMOUNT_OF_CREDITS',
  FOR_EXAMPLE: 'FOR_EXAMPLE',
  DOWNLOAD: 'DOWNLOAD',
  PLEASE_SELECT_ONE_OF_THE_FOLLOWING_TYPES: 'PLEASE_SELECT_ONE_OF_THE_FOLLOWING_TYPES',
  PLEASE_SELECT_YOUR_ROLE_IN_THE_CONFERENCE: 'PLEASE_SELECT_YOUR_ROLE_IN_THE_CONFERENCE',
  PLEASE_NOTE_YOU_HAVE_THE_OPTION_TO_SAVE: 'PLEASE_NOTE_YOU_HAVE_THE_OPTION_TO_SAVE',
  DURATION_OF_TEACHING: 'DURATION_OF_TEACHING',
  CURRENT: 'CURRENT',
  UPCOMING: 'UPCOMING',
  SITEMAP: 'SITEMAP',
  UPON_LOGGING_IN: 'UPON_LOGGING_IN',
  OVERVIEW_OF_ALL_SITE_PAGES: 'OVERVIEW_OF_ALL_SITE_PAGES',
  UPDATED: 'UPDATED',
  PLEASE_FILL_OUT_ALL_REQUIRED_FIELDS: 'PLEASE_FILL_OUT_ALL_REQUIRED_FIELDS',
  PLEASE_FILL_OUT_ALL_REQUIRED_FIELDS_AND_DATES: 'PLEASE_FILL_OUT_ALL_REQUIRED_FIELDS_AND_DATES',
  PLEASE_ENTER_VALID_DATES: 'PLEASE_ENTER_VALID_DATES',
  IS_MISSING: 'IS_MISSING',
  THE_VALUE_ENTERED_IS_INVALID: 'THE_VALUE_ENTERED_IS_INVALID',
  CLOSE: 'CLOSE',
  CHOOSE: 'CHOOSE',
  SUBMITTING_FORM_FAILED: 'SUBMITTING_FORM_FAILED',
  TRANSCRIPT_OF_THESSA_INTRODUCTION_VIDEO: 'TRANSCRIPT_OF_THESSA_INTRODUCTION_VIDEO',
  INTRODUCTION_TO_USING_THESSA_FOR_DOCTORAL: 'INTRODUCTION_TO_USING_THESSA_FOR_DOCTORAL',
  CHOOSE_LANGUAGE_FROM_THE_LANGUAGE_NAVIGATION: 'CHOOSE_LANGUAGE_FROM_THE_LANGUAGE_NAVIGATION',
  AFTER_LOGGING_IN: 'AFTER_LOGGING_IN',
  PLANS_FOR_YOUR_PHD_PROJECT: 'PLANS_FOR_YOUR_PHD_PROJECT',
  SUPERVISION_PLANS_ARE_CREATED_BY_YOUR_SUPERVISORS: 'SUPERVISION_PLANS_ARE_CREATED_BY_YOUR_SUPERVISORS',
  PLANS_FOR_YOUR_PHD_PROJECT_ARE_CREATED_BY_YOU: 'PLANS_FOR_YOUR_PHD_PROJECT_ARE_CREATED_BY_YOU',
  REPORTS_ARE_CREATED_BY_YOU_AND_INCLUDE_INFORMATION: 'REPORTS_ARE_CREATED_BY_YOU_AND_INCLUDE_INFORMATION',
  YOUR_SUPERVISORS_ARE_ADDED_AUTOMATICALLY: 'YOUR_SUPERVISORS_ARE_ADDED_AUTOMATICALLY',
  YOU_CAN_SEE_INFORMATION_ABOUT_YOUR_THESIS_COMMITTEE: 'YOU_CAN_SEE_INFORMATION_ABOUT_YOUR_THESIS_COMMITTEE',
  DIGITAL_SERVICE_ACCESSIBILITY_STATUS: 'DIGITAL_SERVICE_ACCESSIBILITY_STATUS',
  GENERAL_SECTIONS_AND_FEATURES_THAT_ARE_NOT_ACCESSIBLE: 'GENERAL_SECTIONS_AND_FEATURES_THAT_ARE_NOT_ACCESSIBLE',
  SERVICES_FOR_DOCTORAL_RESEARCHERS_THAT_ARE_NOT_ACCESSIBLE:
    'SERVICES_FOR_DOCTORAL_RESEARCHERS_THAT_ARE_NOT_ACCESSIBLE',
  SERVICES_FOR_SUPERVISORS_THAT_ARE_NOT_ACCESSIBLE: 'SERVICES_FOR_SUPERVISORS_THAT_ARE_NOT_ACCESSIBLE',
  SERVICES_FOR_COORDINATORS_THAT_ARE_NOT_ACCESSIBLE: 'SERVICES_FOR_COORDINATORS_THAT_ARE_NOT_ACCESSIBLE',
  NOT_WITHIN_THE_SCOPE_OF_LEGISLATION: 'NOT_WITHIN_THE_SCOPE_OF_LEGISLATION',
  ACCESSIBILITY_FEEDBACK: 'ACCESSIBILITY_FEEDBACK',
  SUPERVISORY_AUTHORITY: 'SUPERVISORY_AUTHORITY',
  CONTACT_INFORMATION_OF_THE_REGULATOR: 'CONTACT_INFORMATION_OF_THE_REGULATOR',
  REGIONAL_STATE_ADMINISTRATIVE_AGENCY_FOR_SOUTHERN_FINLAND:
    'REGIONAL_STATE_ADMINISTRATIVE_AGENCY_FOR_SOUTHERN_FINLAND',
  ACCESSIBILITY_CONTROL_UNIT: 'ACCESSIBILITY_CONTROL_UNIT',
  TEL_SWITCHBOARD: 'TEL_SWITCHBOARD',
  PERCEIVABLE: 'PERCEIVABLE',
  OPERABLE: 'OPERABLE',
  UNDERSTANDABLE: 'UNDERSTANDABLE',
  ROBUST: 'ROBUST',
  ACCESSIBILITY_OF_THESSA: 'ACCESSIBILITY_OF_THESSA',
  THIS_ACCESSIBILITY_STATEMENT_APPLIES: 'THIS_ACCESSIBILITY_STATEMENT_APPLIES',
  THE_SERVICE_IS_SUBJECT_TO_THE_LAW: 'THE_SERVICE_IS_SUBJECT_TO_THE_LAW',
  THE_ACCESSIBILITY_STATEMENT_HAS_BEEN_CREATED_ON: 'THE_ACCESSIBILITY_STATEMENT_HAS_BEEN_CREATED_ON',
  INFO_AND_RELATIONSHIPS: 'INFO_AND_RELATIONSHIPS',
  ERROR_IDENTIFICATION: 'ERROR_IDENTIFICATION',
  ON_INPUT: 'ON_INPUT',
  USE_OF_COLOR: 'USE_OF_COLOR',
  CONTRAST_MINIMUM: 'CONTRAST_MINIMUM',
  PARSING: 'PARSING',
  NAME_ROLE_VALUE: 'NAME_ROLE_VALUE',
  STATUS_MESSAGES: 'STATUS_MESSAGES',
  THE_THESSA_SERVICE_OF_THE_UNIVERSITY_OF_HELSINKI_MEETS: 'THE_THESSA_SERVICE_OF_THE_UNIVERSITY_OF_HELSINKI_MEETS',
  DATA_TABLES_ARE_NOT_PROGRAMMATICALLY_DISPLAYED: 'DATA_TABLES_ARE_NOT_PROGRAMMATICALLY_DISPLAYED',
  THE_INFORMATION_ON_THE_CONFERENCES_AND_MOBILITY: 'THE_INFORMATION_ON_THE_CONFERENCES_AND_MOBILITY',
  FORMS_DO_NOT_SUFFICIENTLY_GUIDE_USERS_ON_INCORRECT: 'FORMS_DO_NOT_SUFFICIENTLY_GUIDE_USERS_ON_INCORRECT',
  THE_SUBMISSION_BUTTONS_IN_THE_FORMS_FOR_SUPERVISION: 'THE_SUBMISSION_BUTTONS_IN_THE_FORMS_FOR_SUPERVISION',
  THE_INFORMATION_ON_THE_MY_DOCTORAL_THESIS_PAGE: 'THE_INFORMATION_ON_THE_MY_DOCTORAL_THESIS_PAGE',
  A_FORM_DOES_NOT_DISPLAY_THE_SUBMISSION_BUTTON: 'A_FORM_DOES_NOT_DISPLAY_THE_SUBMISSION_BUTTON',
  THE_SUBMISSION_BUTTON_FOR_THE_PROGRESS_FEEDBACK: 'THE_SUBMISSION_BUTTON_FOR_THE_PROGRESS_FEEDBACK',
  GRAPHIC_ELEMENTS_LACK_TEXT_ALTERNATIVES_AND_ARE_NOT: 'GRAPHIC_ELEMENTS_LACK_TEXT_ALTERNATIVES_AND_ARE_NOT',
  ON_THE_LIST_DOCTORAL_RESEARCHERS_ACCORDING_TO_REPORT: 'ON_THE_LIST_DOCTORAL_RESEARCHERS_ACCORDING_TO_REPORT',
  TITLES_HAVE_NOT_BEEN_USED_TO_PARSE_CONTENT: 'TITLES_HAVE_NOT_BEEN_USED_TO_PARSE_CONTENT',
  THE_TITLES_ON_THE_OVERVIEW_OF_CONCLUDING_STATEMENTS: 'THE_TITLES_ON_THE_OVERVIEW_OF_CONCLUDING_STATEMENTS',
  THE_DISTINCTIVENESS_OF_LINKS_FROM_OTHER_TEXT_AND_FOCUS: 'THE_DISTINCTIVENESS_OF_LINKS_FROM_OTHER_TEXT_AND_FOCUS',
  IN_THE_COORDINATOR_SERVICES_THE_DISTINCTIVENESS: 'IN_THE_COORDINATOR_SERVICES_THE_DISTINCTIVENESS',
  THE_CONTRAST_RATIO_OF_TEXT_ELEMENTS_COMPARED: 'THE_CONTRAST_RATIO_OF_TEXT_ELEMENTS_COMPARED',
  THE_CONTRAST_RATIO_OF_TEXT_ELEMENTS_ON_THE: 'THE_CONTRAST_RATIO_OF_TEXT_ELEMENTS_ON_THE',
  THE_SUBMISSION_BUTTON_IN_THE_FORM_FOR_ADDING_SUPERVISORS: 'THE_SUBMISSION_BUTTON_IN_THE_FORM_FOR_ADDING_SUPERVISORS',
  THERE_ARE_DEFICIENCIES_IN_THE_PROGRAMMATIC: 'THERE_ARE_DEFICIENCIES_IN_THE_PROGRAMMATIC',
  THE_USER_INFORMATION_PAGE_CONTAINS_BUTTONS_THAT: 'THE_USER_INFORMATION_PAGE_CONTAINS_BUTTONS_THAT',
  BUTTONS_HAVE_NO_VISIBLE_OR_PROGRAMMATIC_TEXT: 'BUTTONS_HAVE_NO_VISIBLE_OR_PROGRAMMATIC_TEXT',
  THE_SUPERVISOR_FORM_ON_THE_DOCTORAL_RESEARCHERS_PAGE: 'THE_SUPERVISOR_FORM_ON_THE_DOCTORAL_RESEARCHERS_PAGE',
  SCREEN_READERS_DO_NOT_AUTOMATICALLY: 'SCREEN_READERS_DO_NOT_AUTOMATICALLY',
  ON_THE_DOCTORAL_RESEARCHERS_AND_LIST_THE_REVIEWERS: 'ON_THE_DOCTORAL_RESEARCHERS_AND_LIST_THE_REVIEWERS',
  NOT_COVERED_BY_LEGISLATION: 'NOT_COVERED_BY_LEGISLATION',
  CONTENT_PUBLISHED_BY_USERS_OR_OTHER_THIRD_PARTIES: 'CONTENT_PUBLISHED_BY_USERS_OR_OTHER_THIRD_PARTIES',
  CONTENT_PUBLISHED_BY_USERS_THAT_CANNOT: 'CONTENT_PUBLISHED_BY_USERS_THAT_CANNOT',
  DID_YOU_NOTICE_AN_ACCESSIBILITY_ISSUE_IN: 'DID_YOU_NOTICE_AN_ACCESSIBILITY_ISSUE_IN',
  IF_YOU_NOTICE_ACCESSIBILITY_ISSUES_ON: 'IF_YOU_NOTICE_ACCESSIBILITY_ISSUES_ON',
  PAGINATION: 'PAGINATION',
  GO_TO_PAGE: 'GO_TO_PAGE',
  AMOUNT_OF_ITEMS_PER_PAGE: 'AMOUNT_OF_ITEMS_PER_PAGE',
  THERE_WAS_AN_ERROR_IN_LOADING_SUPERVISION_PLANS: 'THERE_WAS_AN_ERROR_IN_LOADING_SUPERVISION_PLANS',
  PHD_PROJECT_LIST: {
    ERROR_DELETE_FAILED: 'PHD_PROJECT_LIST.ERROR_DELETE_FAILED'
  },
  THESIS_COMMITTEE: {
    TITLE: 'THESIS_COMMITTEE.TITLE',
    MEETINGS: 'THESIS_COMMITTEE.MEETINGS',
    CHOOSE_FIRST_MEETING_DATE: 'THESIS_COMMITTEE.CHOOSE_FIRST_MEETING_DATE',
    NO_FIRST_MEETING_DATE_YET: 'THESIS_COMMITTEE.NO_FIRST_MEETING_DATE_YET',
    CHOOSE_DATE: 'THESIS_COMMITTEE.CHOOSE_DATE',
    CHANGE_DATE: 'THESIS_COMMITTEE.CHANGE_DATE',
    FIRST_MEETING: 'THESIS_COMMITTEE.FIRST_MEETING',
    MEETING_DATE: 'THESIS_COMMITTEE.MEETING_DATE',
    MEETING_DATE_IN_PAST: 'THESIS_COMMITTEE.MEETING_DATE_IN_PAST',
    ERROR_CHOOSING_DATE: 'THESIS_COMMITTEE.ERROR_CHOOSING_DATE',
    ERROR_MISSING_DATE: 'THESIS_COMMITTEE.ERROR_MISSING_DATE'
  },
  SUPERVISION_PLAN_TITLE_NO_END_DATE: 'SUPERVISION_PLAN_TITLE_NO_END_DATE',
  SUPERVISION_PLAN_HEAD_TITLE: 'SUPERVISION_PLAN_HEAD_TITLE',
  SUPERVISION_PLAN_HEAD_TITLE_NO_END_DATE: 'SUPERVISION_PLAN_HEAD_TITLE_NO_END_DATE',
  VALIDITY: 'VALIDITY',
  COPY_DECISIONS_TO_NEW_PLAN: 'COPY_DECISIONS_TO_NEW_PLAN',
  COPY_DECISIONS_FROM_A_PREVIOUS_PLAN: 'COPY_DECISIONS_FROM_A_PREVIOUS_PLAN',
  PREVIEW_OF_THE_SUPEVISION_PLAN_FOR: 'PREVIEW_OF_THE_SUPEVISION_PLAN_FOR',
  SELECT_A_PREVIOUS_SUPERVISION_PLAN_TO_COPY_FROM: 'SELECT_A_PREVIOUS_SUPERVISION_PLAN_TO_COPY_FROM',
  PLEASE_NOTE_THAT_SUPERVISION_PLANS_MAY_CONTAIN: 'PLEASE_NOTE_THAT_SUPERVISION_PLANS_MAY_CONTAIN'
}

export default keys
