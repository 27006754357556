import React from 'react'
import PropTypes from 'prop-types'

const Heading = ({ text }) => <h3 className="cumulative-report__heading">{text}</h3>

Heading.propTypes = {
  text: PropTypes.string.isRequired
}

export default Heading

export const SubHeading = ({ text }) => <h4 className="cumulative-report__sub-heading">{text}</h4>

SubHeading.propTypes = {
  text: PropTypes.string.isRequired
}
