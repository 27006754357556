import PreliminaryTitleOfDissertationApi from './PreliminaryTitleOfDissertationApi'

export function createPreliminaryTitleOfDissertationStore(doctoralCandidate) {
  return {
    editing: false,
    error: undefined,
    tempTitle: doctoralCandidate.preliminaryTitleOfDissertation || '',
    title: doctoralCandidate.preliminaryTitleOfDissertation || '',

    onCancel() {
      this.editing = false
      this.error = false
      this.tempTitle = this.title
    },

    onKeyPress(event) {
      const ESC_KEY = 'Escape'
      const IE_EDGE_ESC_KEY = 'Esc'
      const isEscKeyPress = event.key === ESC_KEY || event.key === IE_EDGE_ESC_KEY

      if (this.editing && isEscKeyPress) {
        this.onCancel()
      }
    },

    onChange(value) {
      this.tempTitle = value
    },

    onEdit() {
      this.editing = true
    },

    onReset() {
      this.onCancel()
    },

    onSave(event) {
      event.preventDefault()
      new PreliminaryTitleOfDissertationApi()
        .updatePreliminaryTitleOfDissertation(doctoralCandidate.id, this.tempTitle)
        .then((response) => {
          if (response) {
            this.onSaveSuccess(response)
          }
        })
        .fail((error) => {
          this.onSaveError(error)
        })
    },

    onSaveError(error) {
      console.log(error)
      this.error = 'Could not save. Try again later.'
    },

    onSaveSuccess(response) {
      this.editing = false
      this.error = undefined
      this.tempTitle = response.preliminaryTitleOfDissertation
      this.title = response.preliminaryTitleOfDissertation
    }
  }
}
