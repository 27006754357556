import React from 'react'
import { useTranslation } from 'react-i18next'
import k from '../../../i18n/keys'
import FreeTextRows from './FreeTextRows'

const Progress = ({ reports }) => {
  const { t } = useTranslation()
  return <FreeTextRows heading={t(k.PROGRESS)} reports={reports} reportFieldName="progress" />
}

export default Progress
