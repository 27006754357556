import { useTranslation } from 'react-i18next'
import k from '../../../i18n/keys'
import React from 'react'
import './SupervisionPlanHeading.scss'
import UniTag from '../../common/uni/UniTag'
import styled from 'styled-components'
import { UniHeading2 } from '../../common/uni/UniHeading'
import { formatDate } from '../../../util'

export const generatePlanDate = (t, validFrom, validTo) => {
  if (validTo) {
    return `${formatDate(validFrom)} - ${formatDate(validTo)}`
  } else {
    return t(k.SUPERVISION_PLAN_TITLE_NO_END_DATE, { startDate: formatDate(validFrom) })
  }
}

export const generatePlanTitle = (t, validFrom, validTo) => {
  if (validTo) {
    return t(k.SUPERVISION_PLAN_HEAD_TITLE, {
      startDate: formatDate(validFrom),
      endDate: formatDate(validTo)
    })
  } else {
    return t(k.SUPERVISION_PLAN_HEAD_TITLE_NO_END_DATE, { startDate: formatDate(validFrom) })
  }
}

export const getStatusLabel = (t, isDraft, isActive, isPending, isExpired) => {
  if (isDraft) {
    return t(k.DRAFT)
  }
  if (isActive) {
    return t(k.ACTIVE)
  }
  if (!isDraft && isPending) {
    return t(k.PENDING)
  }
  if (isExpired) {
    return t(k.EXPIRED)
  }
  return ''
}

export const getTagVariant = (isDraft, isActive, isPending, isExpired) => {
  if (isDraft) {
    return 'info'
  }
  if (isActive) {
    return 'success'
  }
  if (!isDraft && isPending) {
    return 'warning'
  }
  if (isExpired) {
    return 'default'
  }
  return ''
}

const SupervisionPlanHeading = ({ validFrom, validTo, active = false, pending = false, expired = false, children }) => {
  const { t } = useTranslation()
  return (
    <div className="supervision-plan-form-heading">
      <HeadingWrapper>
        <UniHeading2 small aria-label={generatePlanTitle(t, validFrom, validTo)}>
          {t(k.SUPERVISION_PLAN)}
        </UniHeading2>
        <UniTag large variant={getTagVariant(false, active, pending, expired)}>
          {getStatusLabel(t, false, active, pending, expired)}
        </UniTag>
      </HeadingWrapper>
      {children}
    </div>
  )
}

export default SupervisionPlanHeading

const HeadingWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-flow: row wrap;
  gap: 16px;
`
