import { useTranslation } from 'react-i18next'
import k from '../../i18n/keys'
import React, { PropsWithChildren, ReactNode } from 'react'
import moment from 'moment'
import classNames from 'classnames'
import PublicationList from '../publication/PublicationList'
import CourseList from '../course/CourseList'
import InternationalActivityList from '../international-activity/InternationalActivityList'
import SalaryList from '../funding/SalaryList'
import GrantList from '../funding/GrantList'
import TeachingCourseOrWorkshopList from '../teaching/TeachingCourseOrWorkshopList'
import ThesisInstructorList from '../teaching/ThesisInstructorList'
import Report from '../../models/Report'
import { TFunction } from 'i18next'
import Publication from '../../models/Publication'
import Course from '../../models/Course'
import InternationalActivity from '../../models/InternationalActivity'
import Salary from '../../models/Salary'
import Grant from '../../models/Grant'
import Teaching from '../../models/Teaching'
import { UniParagraph } from '../common/uni/UniParagraph'
import { Instructions } from '../common/uni/UniForm'

const timeSpentOfYearPercentageOptions = (t: TFunction) => ({
  0: '0-25%',
  1: '26-50%',
  2: '51-75%',
  3: `76-100% (${t(k.FULL_TIME)})`
})

interface HeaderProps {
  report: Report
}

const Header = ({ report }: HeaderProps) => {
  const { t } = useTranslation()
  const reportingPeriodStart = moment(report.reportingPeriodStart, 'YYYY-MM-DD').format('D MMM YYYY')
  const reportingPeriodEnd = moment(report.reportingPeriodEnd, 'YYYY-MM-DD').format('D MMM YYYY')
  const createdAt = moment(report.createdAt, 'YYYY-MM').format('MMMM YYYY')
  return (
    <>
      <div className="report__header">
        <div className="heading">
          <h2>{t(k.REPORT)}</h2>
          <UniParagraph noMargin>
            {reportingPeriodStart} {`—`} {reportingPeriodEnd}
          </UniParagraph>
        </div>
        <div className="report__meta">
          <UniParagraph noMargin>
            {t(k.CREATED_IN)} {createdAt}
          </UniParagraph>
        </div>
      </div>
      <Instructions>{t(k.PLEASE_NOTE_YOU_HAVE_THE_OPTION_TO_SAVE)}</Instructions>
    </>
  )
}

interface FieldHeadingProps {
  smallHeading?: boolean
}

const FieldHeading = ({ smallHeading = false, children }: PropsWithChildren<FieldHeadingProps>) => {
  return (
    <h2
      className={classNames('report__heading', {
        'report__heading--small': smallHeading
      })}
    >
      {children}
    </h2>
  )
}

interface FieldLabelProps {
  htmlFor: string
}

export const FieldLabel = ({ htmlFor, children }: PropsWithChildren<FieldLabelProps>) => (
  <label htmlFor={htmlFor} className="control-label">
    {children}
  </label>
)

interface ListWithHeadingProps {
  hintText?: string
  noContentMessage: ReactNode
}

interface PublicationListWithHeadingProps extends ListWithHeadingProps {
  publications: Publication[]
}

const PublicationListWithHeading = ({
  publications,
  hintText = '',
  noContentMessage
}: PublicationListWithHeadingProps) => {
  const { t } = useTranslation()
  return (
    <div className="report__list-container">
      <FieldHeading>{t(k.PUBLICATIONS)}</FieldHeading>
      <UniParagraph noMarginTop>{hintText}</UniParagraph>
      <PublicationList publications={publications} noContentMessage={noContentMessage} />
    </div>
  )
}

interface CourseListWithHeadingProps extends ListWithHeadingProps {
  courses: Course[]
}

const CourseListWithHeading = ({ courses, hintText = '', noContentMessage }: CourseListWithHeadingProps) => {
  const { t } = useTranslation()
  return (
    <div className="report__list-container">
      <FieldHeading>{t(k.COURSES)}</FieldHeading>
      <UniParagraph noMarginTop>{hintText}</UniParagraph>
      <CourseList courses={courses} noContentMessage={noContentMessage} />
    </div>
  )
}

interface InternationalActivityListWithHeadingProps extends ListWithHeadingProps {
  internationalActivities: InternationalActivity[]
}

const InternationalActivityListWithHeading = ({
  internationalActivities,
  hintText = '',
  noContentMessage
}: InternationalActivityListWithHeadingProps) => {
  const { t } = useTranslation()
  return (
    <div className="report__list-container">
      <FieldHeading>{t(k.CONFERENCES_AND_MOBILITY1)}</FieldHeading>
      <UniParagraph noMarginTop>{hintText}</UniParagraph>
      <InternationalActivityList
        internationalActivities={internationalActivities}
        noContentMessage={noContentMessage}
      />
    </div>
  )
}

interface FundingListWithHeadingProps {
  salaries: Salary[]
  grants: Grant[]
  hintText?: string
  salariesNoContentMessage: ReactNode
  grantsNoContentMessage: ReactNode
}

const FundingListWithHeading = ({
  salaries,
  grants,
  hintText = '',
  salariesNoContentMessage,
  grantsNoContentMessage
}: FundingListWithHeadingProps) => {
  const { t } = useTranslation()
  return (
    <div className="report__list-container">
      <FieldHeading>{t(k.FUNDING)}</FieldHeading>
      <UniParagraph noMarginTop>{hintText}</UniParagraph>

      <FieldHeading smallHeading>{t(k.SALARIES)}</FieldHeading>
      <SalaryList salaries={salaries} noContentMessage={salariesNoContentMessage} />

      <FieldHeading smallHeading>{t(k.GRANTS)}</FieldHeading>
      <GrantList grants={grants} noContentMessage={grantsNoContentMessage} />
    </div>
  )
}

interface TeachingListWithHeadingProps {
  teachings: Teaching[]
  grants: Grant[]
  hintText?: string
  coursesOrWorkshopsNoContentMessage: ReactNode
  thesisInstructorNoContentMessage: ReactNode
}

const TeachingListWithHeading = ({
  teachings,
  hintText = '',
  coursesOrWorkshopsNoContentMessage,
  thesisInstructorNoContentMessage
}: TeachingListWithHeadingProps) => {
  const { t } = useTranslation()
  return (
    <div className="report__list-container">
      <FieldHeading>{t(k.TEACHING)}</FieldHeading>
      <UniParagraph noMarginTop>{hintText}</UniParagraph>

      <FieldHeading smallHeading>{t(k.COURSES_AND_WORKSHOPS)}</FieldHeading>
      <TeachingCourseOrWorkshopList teachings={teachings} noContentMessage={coursesOrWorkshopsNoContentMessage} />

      <FieldHeading smallHeading>{t(k.THESIS_INSTRUCTION)}</FieldHeading>
      <ThesisInstructorList teachings={teachings} noContentMessage={thesisInstructorNoContentMessage} />
    </div>
  )
}

export {
  timeSpentOfYearPercentageOptions,
  Header,
  FieldHeading,
  PublicationListWithHeading,
  CourseListWithHeading,
  InternationalActivityListWithHeading,
  FundingListWithHeading,
  TeachingListWithHeading
}
