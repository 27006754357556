import React from 'react'
import styled, { css } from 'styled-components'
import {
  GRAYSCALE_DARK_TEXT,
  GRAYSCALE_LIGHT,
  BRAND_MAIN_LIGHT,
  ADDITIONAL_YELLOW,
  BRAND_MAIN_ACTIVE,
  ADDITIONAL_BLUE_SOFT
} from './colors'
import { HyIcon } from '../HyIcon'
import { fromTabletUp } from '../../styledUtils'
import { HyIconProps } from '../HyIcon'
import { Link } from 'react-router'
import VisuallyHidden from '../VisuallyHidden'
import { DEFAULT_FONT } from './typography'
import { UniParagraph } from './UniParagraph'

interface Properties {
  id?: string
  link?: {
    label: string | React.ReactNode
    to: string
  }
  visuallyHideLinkLabel?: boolean
  className?: string
  children?: React.ReactNode
}

export const UniLinkBox = ({ id, link, visuallyHideLinkLabel, children, className }: Properties) => {
  return (
    <Liftup id={id} className={className} hasLink={link}>
      <Content>{children}</Content>
      {link ? (
        <Actions>
          <CtaLink to={link.to}>
            {visuallyHideLinkLabel ? (
              <VisuallyHidden>{link.label}</VisuallyHidden>
            ) : (
              <Label noMargin>{link.label}</Label>
            )}
            <Arrow arrowRight />
          </CtaLink>
        </Actions>
      ) : null}
    </Liftup>
  )
}

const Liftup = styled.div<{ hasLink?: Properties['link'] }>`
  position: relative;
  background-color: ${ADDITIONAL_BLUE_SOFT};
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;

  ${({ hasLink }) =>
    hasLink &&
    css`
      &:hover,
      &:focus-within {
        outline: solid 2px ${ADDITIONAL_YELLOW};
        outline-offset: 2px;
      }
    `}
`

const Content = styled.div`
  ${DEFAULT_FONT}
  color: ${GRAYSCALE_DARK_TEXT};
  font-size: 15px;
  line-height: 22px;
  padding-inline: 16px;
  padding-top: 8px;
  padding-bottom: 4px;

  ${fromTabletUp(css`
    padding-inline: 24px;
    padding-top: 16px;
    padding-bottom: 8px;
  `)}
`

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-bottom: 8px;
`

const CtaLink = styled(Link)`
  display: flex;
  align-items: center;
  gap: 8px;

  &:hover,
  &:focus {
    text-decoration: none;
    outline: none;
  }

  &:before {
    content: '';
    position: absolute;
    inset: 0;
  }
`

const Label = styled(UniParagraph)`
  color: ${BRAND_MAIN_LIGHT};
  font-weight: 600;
`

const Arrow = styled(HyIcon)<HyIconProps>`
  background-color: ${BRAND_MAIN_LIGHT};
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: ${GRAYSCALE_LIGHT};
  height: 40px;
  width: 40px;
  font-size: 24px;
  transform: translateX(6px);
  transition: background-color 0.12s ease-in-out;

  ${Liftup}:hover & {
    background-color: ${BRAND_MAIN_ACTIVE};
  }
`
