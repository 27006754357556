import { UniDatePicker } from '../common/uni/UniDatePicker'
import { addMonths, formatISO, isBefore, parseISO, startOfDay } from 'date-fns'
import { Actions, UniForm } from '../common/uni/UniForm'
import { UniButton } from '../common/uni/UniButton'
import React, { FormEvent, useState } from 'react'
import { useDateField } from '../common/uni/field'
import k from '../../i18n/keys'
import { useTranslation } from 'react-i18next'
import { TFunction } from 'i18next'

interface FirstMeetingDateFormProps {
  firstMeetingDate?: string
  onSave: (firstSupervisionMeetingDate: string) => void
  onCancel: () => void
  errorOccurredInSavingFirstMeetingDate: boolean
}

export const FirstMeetingDateForm = ({
  firstMeetingDate,
  onSave,
  onCancel,
  errorOccurredInSavingFirstMeetingDate
}: FirstMeetingDateFormProps) => {
  const { t } = useTranslation()
  const {
    value: firstSupervisionMeetingDate,
    set: setFirstSupervisionMeetingDate,
    isValid
  } = useDateField(
    firstMeetingDate ? firstMeetingDate : formatISO(addMonths(new Date(), 3), { representation: 'date' })
  )
  const [validationErrorMessage, setValidationErrorMessage] = useState<string | null>(null)

  function onSubmit(event: FormEvent) {
    event.preventDefault()
    setValidationErrorMessage(null)
    if (isValid) {
      onSave(firstSupervisionMeetingDate)
    } else {
      setValidationErrorMessage(k.THESIS_COMMITTEE.ERROR_MISSING_DATE)
    }
  }

  return (
    <UniForm
      itemsToStart={true}
      noGap
      onSubmit={onSubmit}
      statusMessage={getStatusMessage(t, errorOccurredInSavingFirstMeetingDate, validationErrorMessage)}
    >
      <UniDatePicker
        id="first-thesis-committee-meeting"
        label={t(k.THESIS_COMMITTEE.MEETING_DATE)}
        required
        value={firstSupervisionMeetingDate}
        onChange={(date) => setFirstSupervisionMeetingDate(date)}
        isValid={isValid}
        hasInvalidHighlight={!isValid}
        validationHintAfter={
          isValid && isBefore(parseISO(firstSupervisionMeetingDate), startOfDay(new Date()))
            ? t(k.THESIS_COMMITTEE.MEETING_DATE_IN_PAST)
            : undefined
        }
      />
      <Actions>
        <UniButton>{t(k.SAVE)}</UniButton>
        <UniButton type="button" secondary onClick={onCancel}>
          {t(k.CANCEL)}
        </UniButton>
      </Actions>
    </UniForm>
  )
}

function getStatusMessage(
  t: TFunction,
  errorOccurredInSavingFirstMeetingDate: boolean,
  validationErrorMessage: string | null
) {
  if (errorOccurredInSavingFirstMeetingDate) {
    return t(k.THESIS_COMMITTEE.ERROR_CHOOSING_DATE)
  }
  if (validationErrorMessage) {
    return t(validationErrorMessage)
  }
  return undefined
}
