import React from 'react'
import { UniLegend } from './UniLegend'
import styled, { css } from 'styled-components'
import { Instructions } from './UniForm'
import { OptionalText, RequiredText } from './UniLabel'

const Fieldset = styled.fieldset``

const Content = styled.div<{ row?: boolean; column?: boolean; noGap?: boolean }>`
  margin-top: 12px;

  ${(props) =>
    props.row
      ? css`
          display: flex;
          flex-flow: row wrap;
          row-gap: 12px;
          column-gap: 24px;

          ${(props: { noGap?: boolean }) =>
            props.noGap
              ? css`
                  gap: 0;
                `
              : css`
                  row-gap: 12px;
                  column-gap: 24px;
                `}
        `
      : props.column
      ? css`
          display: flex;
          flex-flow: column wrap;

          ${(props: { noGap?: boolean }) =>
            props.noGap
              ? css`
                  gap: 0;
                `
              : css`
                  gap: 12px;
                `}
        `
      : ''}
`

interface UniFieldsetWithLegendProps {
  id?: string
  legend: string | React.ReactNode
  instructions?: string | React.ReactNode
  row?: boolean
  column?: boolean
  noGap?: boolean
  required?: boolean
  className?: string
  children?: React.ReactNode
}

export const UniFieldsetWithLegend = ({
  id,
  legend,
  instructions,
  row,
  column = true,
  noGap,
  required,
  className,
  children
}: UniFieldsetWithLegendProps) => {
  return (
    <Fieldset id={id} className={className}>
      <UniLegend>
        {legend} {required ? <RequiredText /> : <OptionalText />}
      </UniLegend>
      {typeof instructions === 'string' ? (
        <Instructions marginTop>{instructions}</Instructions>
      ) : instructions ? (
        instructions
      ) : null}
      <Content row={row} column={column} noGap={noGap}>
        {children}
      </Content>
    </Fieldset>
  )
}

UniFieldsetWithLegend.displayName = 'UniFieldsetWithLegend'
