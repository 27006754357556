import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import moment from 'moment'
import k from '../../../i18n/keys'
import Heading from './Heading'
import Table from './Table'
import { reportsToRows, createCell } from './Helpers'

const titleClassModifier = 'course-title'
const creditsClassModifier = 'course-credits'
const completedAtClassModifier = 'course-completed-at'

const getCells = (t) => (reportItem) => {
  const credits = t(k.CR, { count: reportItem.credits })
  return [
    createCell(reportItem.title, titleClassModifier),
    createCell(credits, creditsClassModifier),
    createCell(moment(reportItem.completedAt, 'YYYY-MM-DD').format('YYYY MMMM'), completedAtClassModifier)
  ]
}

const headings = (t) => [
  createCell(t(k.TITLE), titleClassModifier),
  createCell(t(k.CREDITS), creditsClassModifier),
  createCell(t(k.COMPLETED_AT), completedAtClassModifier)
]

const Courses = (props) => {
  const { t } = useTranslation()
  const rows = reportsToRows(props.reports, ({ courses }) => courses, getCells(t))
  return (
    <div id="cumulative-report__courses">
      <Heading text={t(k.COURSES)} />
      <Table headings={headings(t)} rows={rows} label={t(k.COURSES)} />
    </div>
  )
}

Courses.propTypes = {
  reports: PropTypes.arrayOf(PropTypes.object)
}

export default Courses
