import React from 'react'
import { withTranslation } from 'react-i18next'
import assign from 'lodash/assign'
import sortBy from 'lodash/sortBy'
import moment from 'moment'
import { inject, observer } from 'mobx-react'
import k from '../../i18n/keys'
import ThessaList from '../common/ThessaList'
import { statusTexts, getCurrentStatus } from './PublicationHelpers'
import { ITEM_TYPE } from '../highlight/constants'

@inject('HighlightStore')
@observer
class PublicationList extends React.Component {
  render() {
    const { white, t } = this.props
    const { HighlightStore, doctoralCandidateId } = this.props
    const doctoralCandidateHighlights = HighlightStore.getDoctoralCandidateHighlights(doctoralCandidateId)
    const columns = {
      title: t(k.TOPIC_OR_TITLE),
      statusIcon: t(k.IN_THESIS),
      status: t(k.STATUS),
      completionDate: t(k.PUBLISHED)
    }
    const sortedPublications = sortBy(this.props.publications, ({ statuses }) => getPublishedAt(statuses))
    const publicationsForRendering = sortedPublications.map(({ id, title, inThesis, statuses }) => {
      const currentStatus = getCurrentStatus(statuses)
      const formattedStatus = formatPublicationStatus(currentStatus, t)
      const publicationForRendering = {
        id,
        title,
        statusIcon: getInThesisStatusIcon(inThesis, t),
        status: formattedStatus,
        completionDate: getFormattedPublishedAt(statuses, t),
        newOrChanged: doctoralCandidateHighlights && doctoralCandidateHighlights[ITEM_TYPE.PUBLICATION][id]
      }

      if (this.props.linkTarget) {
        assign(publicationForRendering, {
          linkTarget: `${this.props.linkTarget}${id}`
        })
      }

      return publicationForRendering
    })

    return (
      <ThessaList
        id="doctoral-candidate-overview__publications"
        label={t(k.PUBLICATIONS)}
        columns={columns}
        rows={publicationsForRendering}
        noContentMessage={this.props.noContentMessage}
        white={white}
        defaultSortByID="completionDate"
        defaultSortByDesc={true}
      />
    )
  }
}

function formatPublicationStatus(currentStatus, t) {
  if (currentStatus) {
    if (currentStatus.status === 'published') {
      return <span className="text-success">{t(statusTexts[currentStatus.status])}</span>
    }
    return t(statusTexts[currentStatus.status])
  } else {
    return t(k.NOT_STARTED)
  }
}

function getInThesisStatusIcon(inThesis, t) {
  if (inThesis) {
    return (
      <span className="glyphicon glyphicon-ok text-success" role="img" aria-label={t(k.PUBLICATION_IS_IN_THESIS)} />
    )
  }
  return null
}

function getFormattedPublishedAt(statuses, t) {
  const publishedAtDateTimeFormatted = moment(getPublishedAt(statuses), 'YYYY-MM-DD').format('YYYY-MM-DD')
  const publishedAt = moment(getPublishedAt(statuses), 'YYYY-MM-DD')
  if (publishedAt.isValid()) {
    return <time dateTime={publishedAtDateTimeFormatted}>{publishedAt.format('YYYY MMM')}</time>
  }
  return <span className="thessa-list__completion-date--missing">{t(k.MISSING)}</span>
}

function getPublishedAt(statuses) {
  return statuses.find(({ status }) => status === 'published').statusStartedAt
}

export default withTranslation()(PublicationList)
