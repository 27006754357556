import { Dispatch, SetStateAction } from 'react'
import type { Column as ReactTableColumn, Row as ReactTableRow } from 'react-table'
import Report from './models/Report'
import { Role } from './stores/role'

export enum SupervisorRole {
  CoordinatingAcademic = 'coordinating-academic',
  MainSupervisor = 'main-supervisor',
  Supervisor = 'supervisor'
}

export const supervisorRoleToGenericRole: { [key in SupervisorRole]: Role } = {
  [SupervisorRole.CoordinatingAcademic]: Role.COORDINATING_ACADEMIC,
  [SupervisorRole.MainSupervisor]: Role.MAIN_SUPERVISOR,
  [SupervisorRole.Supervisor]: Role.SUPERVISOR
}

export const ThesisCommitteeMemberRole = 'thesis-committee-member'

export type MyDoctoralCandidateRole = SupervisorRole | typeof ThesisCommitteeMemberRole

export type InvitationToRole =
  | 'coordinating-academic'
  | 'main-supervisor'
  | 'supervisor'
  | typeof ThesisCommitteeMemberRole

export type Invitation = {
  email: string
  expiresAt: string
  id: number
  inviter: string
  resendAllowed: boolean
  roles: InvitationToRole[]
  token: string
}

export type Reminder = {
  description: string
  due_at: string
  id: number
  isActive: boolean
  pivot: Pivot
  reminding_schedule: RemindingSchedule[]
  title: string
  users: Recipient[]
}

export type RemindingSchedule = {
  id: number
  is_reminder_sent: boolean
  send_reminder_at: string
}

export type LoginWithTokenResponse = {
  studentNumber: string
}

export type AcceptInvitationResponse = {
  studentNumber: string
}

export type DoctoralSchool = {
  id: number
  name: string
  doctoralProgrammes: number[]
}

export type DoctoralProgramme = {
  id: number
  name: string
  sisu_education_code: string
}

export type User = {
  [key: string]: any
  id: number
  isAccountClosed: boolean
  name: string
  roles: string[]
  doctoralProgrammes: number[]
  closingReason?: ClosingReason
}

export type ClosingReason = {
  description: string
}

export type AuthenticatedUser = {
  closedAt: string
  doctoralCandidate: UserDetailsDoctoralCandidate
  coordinator: UserDetailsCoordinator
  email: string
  firstName: string
  id: number
  isActivated: boolean
  isForeman: boolean
  isSupervisor: boolean
  isThesisCommitteeMember: boolean
  lastName: string
  onboardingLevel: number
  username: string
}

export type Supervisor = {
  id: number
  name: string
  email: string
  roles: SupervisorRole[]
}

export type UserDetails = {
  coordinator: UserDetailsCoordinator
  doctoralCandidate: UserDetailsDoctoralCandidate
  doctoralCandidateReports: Report[]
  supervisor: UserDetailsSupervisor
  thesisCommitteeMember: UserDetailsThesisCommitteeMember
  user: User
}

export type UserDetailsCoordinator = {
  id: number
}

export type UserDetailsDoctoralCandidate = {
  id: number
  name: string
  roles: Roles
  supervisorRelationships: UserDoctoralCandidateSupervisorRelationship[]
  salaries: Salary[]
  grants: Grant[]
  teachings: Teaching[]
  publications: Publication[]
  courses: Course[]
}

export type UserDetailsSupervisor = {
  id: number
}

export type UserDetailsThesisCommitteeMember = {
  id: number
}

export type Salary = {
  [key: string]: any
  id: number
  source: string
  beginsAt: string
  endsAt: string
  getFieldsForUpdateRequest(): Salary
}

export type Grant = {
  [key: string]: any
  id: number
}

export type Teaching = {
  id: number
  beginsAt: string
  courseName: string
  endsAt: string
  myRole: string
  studentName: string
  thesisLevel: string
  thesisTopicOrTitle: string
  type: string
  workHours: number
}

export type Publication = {
  id: number
  title: string
  inThesis: boolean
  statuses: Status[]
}

export type Course = {
  id: number
  title: string
  credits: number
  isCompleted: boolean
  completedAt: string
}

export type Status = {
  id: number
  created_at: string
  order: number
  pivot: Pivot
  status: string
  statusStartedAt: string
  updated_at: string
}

export type Roles = {
  isRequesterCoordinator: boolean
  isRequesterDoctoralCandidate: boolean
  isRequesterMemberOfThesisCommittee: boolean
  isRequesterSalarySystemForeman: boolean
  isRequesterSupervisor: boolean
}

export type Recipient = {
  id: number
  first_name: string
  last_name: string
  pivot?: Pivot
  closed_at?: null
  coordinators?: Array<{ [key: string]: any }>
  doctoral_candidates?: Array<{ [key: string]: any }>
  email?: string
  hy_username?: string
  isSignUpComplete?: boolean
  organization?: string
  supervisor_relationships?: Array<{ [key: string]: any }>
  thesis_committee_members?: Array<{ [key: string]: any }>
}

export type Pivot = {
  created_at: string
  is_owner: number
  reminder_id: number
  updated_at: string
  user_id: number
}

export type DoctoralCandidate = {
  name: string
  studentNumber: string
  userId: number | null
  supervisors: SupervisorRelationship[]
  supervisorInvitations: SupervisorInvitation[]
  studyRight: {
    educationId: string
    state: string
    studyStartDate: string
    valid: {
      startDate: string
    }
  }
}

export type Filter = {
  id: string
  label: string
  value: boolean
  setter: Dispatch<SetStateAction<boolean>>
}

export type UserDoctoralCandidateSupervisorRelationship = {
  id: number
  userId: number
  name: string
  roles: SupervisorRole[]
  isVerified: boolean
  verifiedAt: string
  verifiedBy: string | null
}

export type ReportForReportStatusStatistics = {
  [key: string]: any
  doctoralCandidateId: number
  doctoralProgramme: string
  email: string
  latestCompletedReportDate: string | null
  name: string
  mainSupervisor: string
  mainSupervisorId: number
  reportStatus: 'report-is-late' | 'latest-report-is-not-completed' | 'reports-are-ok'
  userId: number
  verifiedStudentNumber: string | null
}

export type Media = {
  id: number
  file_name: string
  humanReadableSize: string
}

export type Row<T extends object> = {
  original: {
    [key: string]: any
  }
} & ReactTableRow<T>

export type Column<T extends object> = Record<string, unknown> & ReactTableColumn<T>

export type Cell<T extends object> = {
  column: Column<T>
  row: Row<T>
  value: string | number | null | undefined
}

export type SupervisorRelationship = {
  id: number
  name: string
  email: string
  userId: number
  roles: SupervisorRole[]
  endDate: null | string
}

export type SupervisorInvitation = {
  id: number
  email: string
  roles: SupervisorRole[]
  validUntilDate: string
}

export type SisuTranslatedString = {
  fi: string
  sv: string
  en: string
}
export type I18NLanguage = keyof SisuTranslatedString
