import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import last from 'lodash/last'
import k from '../../../i18n/keys'
import { timeSpentOfYearPercentageOptions } from '../ViewReportParts'
import Heading from './Heading'
import Table from './Table'
import { formatReportingPeriod, createCell } from './Helpers'

const periodClassModifier = 'period'
const timeSpentOnPhDClassModifier = 'time-spent-on-phd'

const getCells = (report, t) => [
  createCell(formatReportingPeriod(report), periodClassModifier),
  createCell(timeSpentOfYearPercentageOptions(t)[report.timeSpentOfYearPercentage], timeSpentOnPhDClassModifier)
]

const reportsToRows = (reports, t) => {
  const lastReport = last(reports)
  return reports.map((report) => {
    return {
      highlight: report.id === lastReport.id,
      cells: getCells(report, t)
    }
  })
}

const TimeSpentOnPhD = (props) => {
  const { t } = useTranslation()
  const headings = [
    createCell(t(k.TIME_SPENT_PERIOD), periodClassModifier),
    createCell(t(k.TIME_SPENT_ON_PHD), timeSpentOnPhDClassModifier)
  ]
  const rows = reportsToRows(props.reports, t)
  return (
    <div>
      <Heading text={t(k.TIME_SPENT_ON_PHD)} />
      <Table headings={headings} rows={rows} label={t(k.TIME_SPENT_ON_PHD)} />
    </div>
  )
}

TimeSpentOnPhD.propTypes = {
  reports: PropTypes.arrayOf(PropTypes.object).isRequired
}

export default TimeSpentOnPhD
