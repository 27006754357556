import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import filter from 'lodash/filter'
import flatten from 'lodash/flatten'
import last from 'lodash/last'
import map from 'lodash/map'
import sortBy from 'lodash/sortBy'

import Publication from '../../../models/Publication'
import Course from '../../../models/Course'
import Grant from '../../../models/Grant'

import { getStatusAt } from '../../publication/PublicationHelpers'

import { FreeTextRowsContainer, FreeTextRow } from './FreeTextRows'

export const formatReportingPeriod = (report) => {
  return formatTimePeriod(report.reportingPeriodStart, report.reportingPeriodEnd)
}

export const formatTimePeriod = (start, end) => {
  const startDate = moment(start, 'YYYY-MM-DD')
  const endDate = moment(end, 'YYYY-MM-DD')
  return `${startDate.format('Do MMMM YYYY')} — ${endDate.format('Do MMMM YYYY')}`
}

export const formatMonthPeriod = (startMonth, endMonth) => {
  const start = moment(startMonth, 'YYYY-MM')
  const end = moment(endMonth, 'YYYY-MM')
  return `${start.format('YYYY MMMM')} — ${end.format('YYYY MMMM')}`
}

export const formatDate = (date) => {
  return moment(date, 'YYYY-MM-DD').format('Do MMMM YYYY')
}

export const formatMonth = (month) => {
  return moment(month, 'YYYY-MM').format('YYYY MMMM')
}

export const reportItemStatuses = {
  completed: 'completed',
  inProgress: 'in-progress',
  hidden: 'hidden'
}

export const getStatusOfReportItem = (reportItem, report) => {
  const reportingPeriodEndMoment = moment(report.reportingPeriodEnd, 'YYYY-MM-DD')

  if (reportItem instanceof Publication) {
    return getStatusOfPublicationReportItem(reportItem, reportingPeriodEndMoment)
  }

  if (reportItem instanceof Course) {
    return reportItemStatuses.completed
  }

  if (reportItem instanceof Grant) {
    return getStatusOfGrantReportItem(reportItem, reportingPeriodEndMoment)
  }

  return getDefaultStatusOfReportItem(reportItem, reportingPeriodEndMoment)
}

const getDefaultStatusOfReportItem = (reportItem, reportingPeriodEndMoment) => {
  const itemEndMoment = moment(reportItem.endsAt, 'YYYY-MM-DD')
  const itemHasEndedBeforeReportingPeriodEnd = itemEndMoment.isSameOrBefore(reportingPeriodEndMoment, 'day')
  if (itemHasEndedBeforeReportingPeriodEnd) {
    return reportItemStatuses.completed
  }
  return reportItemStatuses.inProgress
}

const getStatusOfPublicationReportItem = (reportItem, reportingPeriodEndMoment) => {
  const publicationStatusAtReportingPeriodEnd = getStatusAt(reportItem.statuses, reportingPeriodEndMoment).status
  if (publicationStatusAtReportingPeriodEnd === 'published') {
    return reportItemStatuses.completed
  }
  return reportItemStatuses.inProgress
}

const getStatusOfGrantReportItem = (reportItem) => {
  if (reportItem.status === 'response-positive') {
    return reportItemStatuses.completed
  }
  return reportItemStatuses.inProgress
}

export const createCell = (content, classModifier) => {
  return {
    content,
    classModifier
  }
}

export const getReportSortingValue = ({ reportingPeriodEnd }) => reportingPeriodEnd
export const sortReports = (reports) => sortBy(reports, getReportSortingValue)

export const getLastReport = (reports) => last(sortReports(reports.filter(({ isSubmitted }) => isSubmitted)))
export const getLastReportId = (reports) => getLastReport(reports).id

const getSortByValueOfReportItem = (reportItem) => {
  if (reportItem instanceof Publication) {
    return getPublishedAtDateOfReportItem(reportItem)
  }

  if (reportItem instanceof Course) {
    return reportItem.completedAt
  }

  if (reportItem instanceof Grant) {
    return getStartAndEndMonthOfReportItem(reportItem)
  }

  return getStartAndEndDateOfReportItem(reportItem)
}

const getStartAndEndDateOfReportItem = (reportItem) => `${reportItem.beginsAt}${reportItem.endsAt}`

const getStartAndEndMonthOfReportItem = (reportItem) => `${reportItem.beginsAtMonth}${reportItem.endsAtMonth}`

const getPublishedAtDateOfReportItem = (reportItem) => {
  const publishedStatus = reportItem.statuses.find(({ status }) => status === 'published')
  return publishedStatus.statusStartedAt
}

const formatReportItems = (extractReportItemFunction, lastReportId, getCellsFunction, t) => {
  return (report) => {
    const reportItems = extractReportItemFunction(report)
    return reportItems.map((reportItem) => {
      const isInLastReport = lastReportId === report.id
      const statusAtReportingTime = getStatusOfReportItem(reportItem, report)
      const inProgress = isInLastReport && statusAtReportingTime === reportItemStatuses.inProgress
      const highlight = isInLastReport && statusAtReportingTime === reportItemStatuses.completed

      reportItem.reportingPeriodEnd = report.reportingPeriodEnd

      const sortByValue = getSortByValueOfReportItem(reportItem)

      return {
        statusAtReportingTime: statusAtReportingTime,
        isInLastReport: isInLastReport,
        inProgress,
        highlight,
        cells: getCellsFunction(reportItem, t),
        sortByValue
      }
    })
  }
}

const filterVisibleReportItems = ({ statusAtReportingTime, isInLastReport }) => {
  const isCompleted = statusAtReportingTime === reportItemStatuses.completed
  const isInProgressInLastReport = isInLastReport && statusAtReportingTime === reportItemStatuses.inProgress
  return isCompleted || isInProgressInLastReport
}

const getReportItemSortingValue = ({ sortByValue }) => sortByValue

export const reportsToRows = (reports, extractReportItemFunction, getCellsFunction, t) => {
  if (reports.length === 0) {
    return []
  }

  const lastReportId = getLastReportId(reports)
  reports = map(reports, formatReportItems(extractReportItemFunction, lastReportId, getCellsFunction, t))
  reports = flatten(reports)
  reports = filter(reports, filterVisibleReportItems)
  reports = sortBy(reports, getReportItemSortingValue)
  return reports
}

export const FreeTextRowsHelper = ({ reports, reportFieldName, filterFunction }) => {
  const filteredReports = reports.filter(filterFunction)
  const sortedReports = sortReports(filteredReports)
  const freeTextRows = sortedReports.map((report) => {
    return (
      <FreeTextRow
        key={report.id}
        report={report}
        reportFieldName={reportFieldName}
        highlight={report.id === getLastReportId(reports)}
      />
    )
  })
  return <FreeTextRowsContainer hybrid>{freeTextRows}</FreeTextRowsContainer>
}

FreeTextRowsHelper.propTypes = {
  reports: PropTypes.arrayOf(PropTypes.object).isRequired,
  reportFieldName: PropTypes.string.isRequired,
  filterFunction: PropTypes.func.isRequired
}

export const hasFreeTextButNoTableContent = (reports, freeTextFilter, tableFilter) => {
  const hasFreeTexts = reports.filter(freeTextFilter).length > 0
  const hasNoTableContent = reports.filter(tableFilter).length === 0
  return hasFreeTexts && hasNoTableContent
}
