import React from 'react'
import { withTranslation } from 'react-i18next'
import assign from 'lodash/assign'
import sortBy from 'lodash/sortBy'
import moment from 'moment'
import { inject, observer } from 'mobx-react'
import k from '../../i18n/keys'
import ThessaList from '../common/ThessaList'
import { formattedStatuses } from './GrantHelpers'
import { ITEM_TYPE } from '../highlight/constants'

@inject('HighlightStore')
@observer
class GrantList extends React.Component {
  render() {
    const { HighlightStore, doctoralCandidateId, white, t } = this.props
    const doctoralCandidateHighlights = HighlightStore.getDoctoralCandidateHighlights(doctoralCandidateId)
    const columns = {
      title: t(k.GRANT_SOURCE),
      status: t(k.STATUS),
      months: t(k.FOR_MONTHS)
    }
    const sortedGrants = sortBy(this.props.grants, 'beginsAtMonth')
    const grantsForRendering = sortedGrants.map(({ id, source, status, beginsAtMonth, endsAtMonth }) => {
      const beginsAtDateTimeFormatted = moment(beginsAtMonth, 'YYYY-MM').format('YYYY-MM')
      const beginsAtFormatted = moment(beginsAtMonth, 'YYYY-MM').format('MMM YYYY')
      const endsAtDateTimeFormatted = moment(endsAtMonth, 'YYYY-MM').format('YYYY-MM')
      const endsAtFormatted = moment(endsAtMonth, 'YYYY-MM').format('MMM YYYY')
      const grantForRendering = {
        id,
        title: source,
        status: formatStatus(status, t),
        months: (
          <>
            <time dateTime={beginsAtDateTimeFormatted}>{beginsAtFormatted}</time>
            {'—'}
            <time dateTime={endsAtDateTimeFormatted}>{endsAtFormatted}</time>
          </>
        ),
        newOrChanged: doctoralCandidateHighlights && doctoralCandidateHighlights[ITEM_TYPE.GRANT][id]
      }

      if (this.props.linkTarget) {
        assign(grantForRendering, {
          linkTarget: `${this.props.linkTarget}${id}`
        })
      }

      return grantForRendering
    })

    return (
      <ThessaList
        id="doctoral-candidate-overview__grants"
        label="Grants"
        columns={columns}
        rows={grantsForRendering}
        noContentMessage={this.props.noContentMessage}
        white={white}
        defaultSortByID="months"
        defaultSortByDesc={true}
      />
    )
  }
}

function formatStatus(status, t) {
  if (status === 'response-positive') {
    return <span className="text-success">{formattedStatuses(t)[status]}</span>
  }
  return formattedStatuses[status]
}

export default withTranslation()(GrantList)
