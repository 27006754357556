import React, { PropsWithChildren } from 'react'
import LoadingIndicator from '../LoadingIndicator'
import PageBlock from './PageBlock'

const PageLoadingIndicator = ({ children }: PropsWithChildren<{}>) => (
  <PageBlock padTop>
    <LoadingIndicator>{children}</LoadingIndicator>
  </PageBlock>
)

export default PageLoadingIndicator
