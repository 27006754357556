import moment from 'moment'

import { getDoctoralCandidateId } from './AuthenticationStore'
import { ajaxPost, ajaxPostJSON, ajaxPutJSON, ajaxDelete } from '../AjaxUtil'
import {
  createCourse,
  updateCourse,
  deleteCourse,
  createPublication,
  updatePublication,
  deletePublication,
  createConference,
  updateConference,
  createInternationalActivity,
  updateInternationalActivity,
  deleteInternationalActivity,
  deleteSalary,
  createSalary,
  updateSalary,
  createGrant,
  updateGrant,
  deleteGrant,
  createTeaching,
  updateTeaching,
  deleteTeaching,
  updateNotes
} from '../actions/PhDProjectActions'

import Course from '../models/Course'
import Publication from '../models/Publication'
import Conference from '../models/Conference'
import InternationalActivity from '../models/InternationalActivity'
import Salary from '../models/Salary'
import Grant from '../models/Grant'
import Teaching from '../models/Teaching'

export const createCourseResponse = createCourse
  .flatMap(() =>
    ajaxPostJSON(`/rest/doctoral-candidates/${getDoctoralCandidateId()}/courses`, {
      title: '',
      credits: 0,
      isCompleted: false,
      completedAt: moment().format('YYYY-MM-DD')
    })
  )
  .map((courseResponse) => new Course(courseResponse))

export const updateCourseResponse = updateCourse.flatMap((course) =>
  ajaxPutJSON(`/rest/doctoral-candidates/${getDoctoralCandidateId()}/courses/${course.id}`, course)
)

export const deleteCourseResponse = deleteCourse.flatMap((courseId) =>
  ajaxDelete(`/rest/doctoral-candidates/${getDoctoralCandidateId()}/courses/${courseId}`)
)

export const createPublicationResponse = createPublication
  .flatMap(() =>
    ajaxPostJSON(`/rest/doctoral-candidates/${getDoctoralCandidateId()}/publications`, {
      title: '',
      inThesis: true,
      status: 'data-collection',
      publishedAt: moment().format('YYYY-MM-DD')
    })
  )
  .map((publicationResponse) => new Publication(publicationResponse))

export const updatePublicationResponse = updatePublication.flatMap((publication) =>
  ajaxPutJSON(`/rest/doctoral-candidates/${getDoctoralCandidateId()}/publications/${publication.id}`, publication)
)

export const deletePublicationResponse = deletePublication.flatMap((publicationId) =>
  ajaxDelete(`/rest/doctoral-candidates/${getDoctoralCandidateId()}/publications/${publicationId}`)
)

export const createConferenceResponse = createConference
  .flatMap(() => ajaxPost(`/rest/doctoral-candidates/${getDoctoralCandidateId()}/conferences`))
  .map((conferenceResponse) => new Conference(conferenceResponse))

export const updateConferenceResponse = updateConference.flatMap((conference) =>
  ajaxPutJSON(`/rest/doctoral-candidates/${getDoctoralCandidateId()}/conferences/${conference.id}`, conference)
)

export const createInternationalActivityResponse = createInternationalActivity
  .flatMap(() => ajaxPost(`/rest/doctoral-candidates/${getDoctoralCandidateId()}/international-activities`))
  .map((conferenceResponse) => new InternationalActivity(conferenceResponse))

export const updateInternationalActivityResponse = updateInternationalActivity.flatMap((internationalActivity) =>
  ajaxPutJSON(
    `/rest/doctoral-candidates/${getDoctoralCandidateId()}/international-activities/${internationalActivity.id}`,
    internationalActivity
  )
)

export const deleteInternationalActivityResponse = deleteInternationalActivity.flatMap((internationalActivityId) =>
  ajaxDelete(
    `/rest/doctoral-candidates/${getDoctoralCandidateId()}/international-activities/${internationalActivityId}`
  )
)

export const createSalaryResponse = createSalary
  .flatMap(() => ajaxPost(`/rest/doctoral-candidates/${getDoctoralCandidateId()}/salaries`))
  .map((salaryResponse) => new Salary(salaryResponse))

export const updateSalaryResponse = updateSalary.flatMap((salary) =>
  ajaxPutJSON(`/rest/doctoral-candidates/${getDoctoralCandidateId()}/salaries/${salary.id}`, salary)
)

export const deleteSalaryResponse = deleteSalary.flatMap((salaryId) =>
  ajaxDelete(`/rest/doctoral-candidates/${getDoctoralCandidateId()}/salaries/${salaryId}`)
)

export const createGrantResponse = createGrant
  .flatMap(() => ajaxPost(`/rest/doctoral-candidates/${getDoctoralCandidateId()}/grants`))
  .map((grantResponse) => new Grant(grantResponse))

export const updateGrantResponse = updateGrant.flatMap((grant) =>
  ajaxPutJSON(`/rest/doctoral-candidates/${getDoctoralCandidateId()}/grants/${grant.id}`, grant)
)

export const deleteGrantResponse = deleteGrant.flatMap((grantId) =>
  ajaxDelete(`/rest/doctoral-candidates/${getDoctoralCandidateId()}/grants/${grantId}`)
)

export const createTeachingResponse = createTeaching
  .flatMap(() => ajaxPost(`/rest/doctoral-candidates/${getDoctoralCandidateId()}/teachings`))
  .map((teachingResponse) => new Teaching(teachingResponse))

export const updateTeachingResponse = updateTeaching.flatMap((teaching) =>
  ajaxPutJSON(`/rest/doctoral-candidates/${getDoctoralCandidateId()}/teachings/${teaching.id}`, teaching)
)

export const deleteTeachingResponse = deleteTeaching.flatMap((teachingId) =>
  ajaxDelete(`/rest/doctoral-candidates/${getDoctoralCandidateId()}/teachings/${teachingId}`)
)

export const updateNotesResponse = updateNotes.flatMap((notes) =>
  ajaxPostJSON(`/rest/doctoral-candidates/${getDoctoralCandidateId()}/notes`, {
    notes
  })
)
export const isUpdatingNotesP = updateNotes.awaiting(updateNotesResponse)
