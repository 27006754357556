import React from 'react'
import MarkdownIt from 'markdown-it'
import styled from 'styled-components'
import { UniParagraphStyles } from './uni/UniParagraph'

const md = new MarkdownIt('default')

export const MarkdownParagraphs = ({ className = undefined, children }) => (
  <MarkdownElement className={className} dangerouslySetInnerHTML={{ __html: md.render(children) }} />
)

const MarkdownElement = styled.div`
  ${UniParagraphStyles}
`
