import styled, { css } from 'styled-components'
import React from 'react'
import { Link } from 'react-router'
import { Location } from 'history'
import { useTranslation } from 'react-i18next'
import { GRAYSCALE_WHITE } from '../uni/colors'
import { fromTabletUp } from '../../styledUtils'
import { HyIcon } from '../HyIcon'
import { LinkInteractionStyles, LinkStyles } from './LinkStyles'
import k from '../../../i18n/keys'

export interface NavigationItemProperties {
  path?: string
  onClickFunction?: () => void
  isActive?: RegExp
  icon?: string
  title?: string
  location?: Location
}
interface NavigationProperties {
  id?: string
  isLoading?: boolean
  isCollapsed?: boolean
  items?: NavigationItemProperties[]
  className?: string
}

const Navigation = ({ id, isLoading, isCollapsed, items, className }: NavigationProperties) => {
  const { t } = useTranslation()
  const isItemActive = (item: NavigationItemProperties) => !!location && item.isActive?.test(location.pathname)

  return (
    <Nav
      id={id}
      collapsed={isCollapsed}
      isLoading={isLoading}
      aria-hidden={isCollapsed}
      className={className}
      aria-label={t(k.MAIN_MENU)}
    >
      <List>
        {!isLoading &&
          items.map((navigationItem, key) => (
            <Item key={key}>
              <NavigationLink
                to={navigationItem.path}
                onClick={navigationItem.onClickFunction}
                aria-current={isItemActive(navigationItem) ? 'page' : false}
              >
                {navigationItem.icon && <Icon {...{ [navigationItem.icon]: true }} />}
                <span>{navigationItem.title}</span>
              </NavigationLink>
            </Item>
          ))}
      </List>
    </Nav>
  )
}

const Nav = styled.nav<{ collapsed?: boolean; isLoading?: boolean }>`
  background-color: ${GRAYSCALE_WHITE};
  margin: 0;
  padding: 0;
  gap: 2px;
  overflow: visible;
  transition: opacity 0.2s, transform 0.2s;

  ${({ isLoading }) =>
    isLoading &&
    css`
      opacity: 0;
    `}
`

const List = styled.ul<{ collapsed?: boolean; isLoading?: boolean }>`
  margin: 0;
  padding: 0;

  ${fromTabletUp(css`
    gap: 4px;
    overflow: auto;
    display: flex;
    flex-flow: row wrap;
  `)}
`

const Item = styled.li`
  list-style: none;
`

const NavigationLink = styled(Link)`
  position: relative;
  ${LinkStyles}
  ${({ 'aria-current': ariaCurrent }) => LinkInteractionStyles(ariaCurrent)}
`

const Icon = styled(HyIcon)`
  position: relative;
  font-size: 18px;
`

export default Navigation
