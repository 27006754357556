import filter from 'lodash/filter'
import find from 'lodash/find'
import moment from 'moment'
import k from '../../i18n/keys'

export const statusTexts = {
  'not-started': k.NOT_STARTED,
  'data-collection': k.PREPARATION_AND_ANALYSIS,
  manuscript: k.WRITING,
  'peer-review': k.SUBMITTED,
  published: k.PUBLISHED
}

export function getCurrentStatus(statuses) {
  const now = moment()
  return getStatusAt(statuses, now)
}

export function getStatusAt(statuses, date) {
  const validStatuses = filter(statuses, ({ statusStartedAt }) => moment(statusStartedAt, 'YYYY-MM-DD').isValid())
  const currentStatus = find(validStatuses, ({ statusStartedAt }, index) => {
    const nextStatus = validStatuses[index + 1]
    const statusStartedAtMoment = moment(statusStartedAt, 'YYYY-MM-DD')
    const statusHasStarted = statusStartedAtMoment.isSameOrBefore(date, 'day')
    if (nextStatus) {
      const nextStatusStartedAt = moment(nextStatus.statusStartedAt, 'YYYY-MM-DD')
      const nextStatusIsInTheFuture = nextStatusStartedAt.isAfter(date, 'day')
      return nextStatusIsInTheFuture && statusHasStarted
    } else {
      return statusHasStarted
    }
  })
  if (!currentStatus) {
    return { status: 'not-started' }
  }
  return currentStatus
}
