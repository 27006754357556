import React from 'react'
import isEmpty from 'lodash/isEmpty'
import { useTranslation } from 'react-i18next'
import k from '../../../i18n/keys'
import { teachingTypeFormatted } from '../../teaching/ViewTeaching'

import Heading from './Heading'
import Table from './Table'

import {
  formatTimePeriod,
  reportsToRows,
  createCell,
  FreeTextRowsHelper,
  hasFreeTextButNoTableContent
} from './Helpers'

const typeClassModifier = 'teaching-type'
const myRoleClassModifier = 'teaching-my-role'
const subjectClassModifier = 'teaching-subject'
const datesClassModifier = 'teaching-dates'

const getSubject = (teaching) => {
  if (teaching.type === 'course_or_workshop') {
    return teaching.courseName
  }
  return `${teaching.studentName}: ${teaching.thesisTopicOrTitle}`
}

const teachingExperienceIsEmpty = ({ teachingExperience }) => isEmpty(teachingExperience)
const teachingExperienceIsNotEmpty = ({ teachingExperience }) => !isEmpty(teachingExperience)

const getCells = (teaching) => [
  createCell(teachingTypeFormatted[teaching.type], typeClassModifier),
  createCell(teaching.myRole, myRoleClassModifier),
  createCell(getSubject(teaching), subjectClassModifier),
  createCell(formatTimePeriod(teaching.beginsAt, teaching.endsAt), datesClassModifier)
]

const headings = (t) => [
  createCell(t(k.TYPE_OF_TEACHING), typeClassModifier),
  createCell(t(k.MY_ROLE), myRoleClassModifier),
  createCell(t(k.SUBJECT_OR_STUDENT), subjectClassModifier),
  createCell(t(k.DATES), datesClassModifier)
]

const Teaching = (props) => {
  const { t } = useTranslation()
  return (
    <div id="cumulative-report__teaching">
      <Heading text={t(k.TEACHING)} />
      <TeachingFreeTextRows reports={props.reports} />
      <TeachingTable reports={props.reports} />
    </div>
  )
}

const TeachingFreeTextRows = ({ reports }) => {
  return (
    <FreeTextRowsHelper
      reports={reports}
      reportFieldName="teachingExperience"
      filterFunction={teachingExperienceIsNotEmpty}
    />
  )
}

const TeachingTable = ({ reports }) => {
  const { t } = useTranslation()
  const filteredReports = reports.filter(teachingExperienceIsEmpty)
  const rows = reportsToRows(filteredReports, (report) => report.teachings, getCells)
  return (
    <Table
      headings={headings(t)}
      rows={rows}
      label={t(k.TEACHING)}
      noContentMessage={
        hasFreeTextButNoTableContent(reports, teachingExperienceIsNotEmpty, teachingExperienceIsEmpty) ? '' : undefined
      }
    />
  )
}

export default Teaching
