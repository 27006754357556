export default class Course {
  constructor({ id, title, credits, isCompleted, completedAt }) {
    this._id = id
    this._title = title
    this._credits = credits
    this._isCompleted = isCompleted
    this._completedAt = completedAt
  }

  get id() {
    return this._id
  }

  get title() {
    return this._title
  }

  get credits() {
    return this._credits
  }

  get isCompleted() {
    return this._isCompleted
  }

  get completedAt() {
    return this._completedAt
  }

  updateFromResponse({ id, title, credits, isCompleted, completedAt }) {
    this._id = id
    this._title = title
    this._credits = credits
    this._isCompleted = isCompleted
    this._completedAt = completedAt
  }
}
