import React from 'react'
import Media from './Media'

export default function MediaList(props) {
  return props.media.length > 0 ? (
    <ul className="media-list list-group">{props.media.map((media) => renderMedia(media, props))}</ul>
  ) : null
}

function renderMedia(media, props) {
  revokePreviewUrl(media)

  return (
    <Media
      deleteButtonText={props.deleteButtonText}
      deleteMedia={props.deleteMedia}
      downloadMedia={props.downloadMedia}
      media={media}
      key={media.id}
    />
  )
}

/**
 * Important: react-dropzone doesn't manage dropped medias. You need to destroy
 * the object URL yourself whenever you don't need the preview by calling
 * window.URL.revokeObjectURL(media.preview); to avoid memory leaks.
 *
 * https://github.com/react-dropzone/react-dropzone/pull/400
 *
 * @param media
 */
function revokePreviewUrl(media) {
  window.URL.revokeObjectURL(media.preview)
}

MediaList.defaultProps = {
  media: []
}
