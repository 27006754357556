import { useTranslation } from 'react-i18next'
import k from '../../i18n/keys'
import React from 'react'
import { withRouter } from 'react-router'
import { createTeaching } from '../../actions/PhDProjectActions'
import { createTeachingResponse } from '../../stores/PhDProjectStore'
import CardActionButton from '../doctoral-candidate-overview/common/CardActionButton'

const CreateTeachingButton = ({ router }) => {
  const { t } = useTranslation()
  return <CardActionButton onClick={onClickCreateTeachingButtonFunction(router)}>{t(k.ADD_TEACHING)}</CardActionButton>
}

export const onClickCreateTeachingButtonFunction = (router) => () => {
  createTeachingResponse.first().onValue((teaching) => {
    const path = `/phd-project/teachings/${teaching.id}?isNew=true`
    router.push(path)
  })
  createTeaching.push()
}

export default withRouter(CreateTeachingButton)
