import React from 'react'
import { useTranslation } from 'react-i18next'
import k from '../../../i18n/keys'
import FreeTextRows from './FreeTextRows'

const CareerPlanning = ({ reports }) => {
  const { t } = useTranslation()
  return <FreeTextRows heading={t(k.CAREER_PLANNING)} reports={reports} reportFieldName="careerPlanning" />
}

export default CareerPlanning
