import { withTranslation } from 'react-i18next'
import k from '../../i18n/keys'
import React from 'react'
import Card, { CardNoContentMessage } from '../common/Card'
import CourseList from './CourseList'
import CreateCourseButton from './CreateCourseButton'

class CoursesCard extends React.Component {
  render() {
    const { t } = this.props
    const cardAction = this._getCardAction()
    return (
      <Card heading={t(k.COURSES)} cardAction={cardAction} modifier="courses">
        <CourseList
          courses={this.props.doctoralCandidate.courses}
          doctoralCandidateId={this.props.doctoralCandidate.id}
          linkTarget={`${this.props.baseUrl}courses/`}
          noContentMessage={this._getNoContentMessage()}
          white
        />
      </Card>
    )
  }

  _getCardAction() {
    if (this.props.showCreateButton) {
      return <CreateCourseButton />
    }
    return null
  }

  _getNoContentMessage() {
    const { t, noContentMessageRole } = this.props
    return <CardNoContentMessage text={t(k.NO_COURSES_IN_PLAN_YET)} role={noContentMessageRole} />
  }
}

CoursesCard.defaultProps = {
  showCreateButton: false
}

export default withTranslation()(CoursesCard)
