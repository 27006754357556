import { Dates, Field } from './formTypes'
import { useState } from 'react'
import moment from 'moment/moment'
import { DATE_FORMAT } from '../../../dateConstants'
import { isValid, parseISO } from 'date-fns'

export function useStringField(initialValue = '', isValid?: (value: string) => boolean): Field<string> {
  const [value, setValue] = useState(initialValue)
  return {
    value: value,
    set: setValue,
    isValid: isValid ? isValid(value) : true
  }
}

export function useDateField(initialDate?: string): Field {
  const [date, setDate] = useState(initialDate)
  return {
    value: date,
    set: setDate,
    isValid: isValid(parseISO(date))
  }
}

export function useDatesField(initialStart?: string, initialEnd?: string): Field<Dates> {
  const [dates, setDates] = useState<Dates>(
    initialStart && initialEnd
      ? { start: initialStart, end: initialEnd }
      : {
          start: moment().format(DATE_FORMAT.DEFAULT),
          end: moment().add(1, 'day').format(DATE_FORMAT.DEFAULT)
        }
  )
  return {
    value: dates,
    set: setDates,
    isValid: dates.start.length === 10 && dates.end.length === 10 && dates.start <= dates.end
  }
}

export function useEnumField<T>(initialValue?: T, isValid?: (value: T) => boolean): Field<T> {
  const [value, setValue] = useState<T>(initialValue)
  return { value: value, set: setValue, isValid: isValid ? isValid(value) : value !== null }
}
