export default class Grant {
  constructor({ id, source, amount, beginsAtMonth, endsAtMonth, status, usedFor }) {
    this._id = id
    this._source = source
    this._amount = amount
    this._beginsAtMonth = beginsAtMonth
    this._endsAtMonth = endsAtMonth
    this._status = status
    this._usedFor = usedFor
  }

  get id() {
    return this._id
  }

  set id(value) {
    this._id = value
  }

  get source() {
    return this._source
  }

  set source(value) {
    this._source = value
  }

  get amount() {
    return this._amount
  }

  set amount(value) {
    this._amount = value
  }

  get beginsAtMonth() {
    return this._beginsAtMonth
  }

  set beginsAtMonth(value) {
    this._beginsAtMonth = value
  }

  get endsAtMonth() {
    return this._endsAtMonth
  }

  set endsAtMonth(value) {
    this._endsAtMonth = value
  }

  get status() {
    return this._status
  }

  set status(value) {
    this._status = value
  }

  get usedFor() {
    return this._usedFor
  }

  set usedFor(value) {
    this._usedFor = value
  }

  updateFromResponse({ id, source, amount, beginsAtMonth, endsAtMonth, status, usedFor }) {
    this._id = id
    this._source = source
    this._amount = amount
    this._beginsAtMonth = beginsAtMonth
    this._endsAtMonth = endsAtMonth
    this._status = status
    this._usedFor = usedFor
  }

  getFieldsForUpdateRequest() {
    return {
      id: this.id,
      source: this.source,
      amount: this.amount,
      beginsAtMonth: this.beginsAtMonth,
      endsAtMonth: this.endsAtMonth,
      status: this.status,
      usedFor: this.usedFor
    }
  }
}
