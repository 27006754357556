import { withTranslation, useTranslation, Trans } from 'react-i18next'
import k from '../../i18n/keys'
import React from 'react'
import Card from '../common/Card'
import DoctoralCandidateOverviewNotes from './DoctoralCandidateOverviewNotes'
import EditMarkdown from '../common/EditMarkdown'
import CardActionButton from '../doctoral-candidate-overview/common/CardActionButton'
import { updateNotes } from '../../actions/PhDProjectActions'
import { updateNotesResponse, isUpdatingNotesP } from '../../stores/PhDProjectStore'
import VisuallyHidden from '../common/VisuallyHidden'
import { Actions } from '../common/uni/UniForm'

class NotesCard extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      notes: props.doctoralCandidate.notes,
      isEditingNotes: false
    }

    this._onClickEditNotes = this._onClickEditNotes.bind(this)
    this._onClickCancelEditingNotes = this._onClickCancelEditingNotes.bind(this)
    this._onClickSaveNotes = this._onClickSaveNotes.bind(this)
  }

  componentDidMount() {
    this.unsubscribeFromIsUpdatingNotesP = isUpdatingNotesP.onValue((isUpdatingNotes) =>
      this.setState({ isUpdatingNotes })
    )
  }

  componentWillUnmount() {
    this.unsubscribeFromIsUpdatingNotesP()
  }

  render() {
    const { t } = this.props

    if (this.state.isEditingNotes) {
      return (
        <Card heading={t(k.NOTES)} cardAction={this._renderCardActionWhenEditing()} modifier="notes">
          <EditMarkdown
            label={t(k.NOTES)}
            id="edit_notes_textarea"
            value={this.state.notes}
            onChange={(notes) => this.setState({ notes })}
            visuallyHideLabel
          />
        </Card>
      )
    }

    return (
      <Card heading={t(k.NOTES)} cardAction={this._renderCardActionWhenViewing()} modifier="notes">
        <DoctoralCandidateOverviewNotes notes={this.state.notes} />
      </Card>
    )
  }

  _renderCardActionWhenViewing() {
    const { t } = this.props
    if (this.props.showEditButton) {
      return (
        <CardActionButton icon="edit" onClick={this._onClickEditNotes}>
          <Trans
            t={t}
            i18nKey={k.EDIT_NOTES}
            components={[<VisuallyHidden />]} // eslint-disable-line react/jsx-key
          />
        </CardActionButton>
      )
    }
    return null
  }

  _renderCardActionWhenEditing() {
    const { t } = this.props
    const { isUpdatingNotes } = this.state
    return (
      <Actions noPadTop>
        <IsSavingFeedback isSaving={this.state.isUpdatingNotes} />
        <CardActionButton
          icon={false}
          secondary
          minimal={false}
          onClick={this._onClickCancelEditingNotes}
          disabled={isUpdatingNotes}
        >
          <Trans
            t={t}
            i18nKey={k.CANCEL_EDITING_NOTES}
            components={[<VisuallyHidden />]} // eslint-disable-line react/jsx-key
          />
        </CardActionButton>
        <CardActionButton
          minimal={false}
          secondary={false}
          icon={false}
          onClick={this._onClickSaveNotes}
          disabled={isUpdatingNotes}
        >
          <Trans
            t={t}
            i18nKey={k.SAVE_NOTES}
            components={[<VisuallyHidden />]} // eslint-disable-line react/jsx-key
          />
        </CardActionButton>
      </Actions>
    )
  }

  _onClickEditNotes() {
    this.setState({ isEditingNotes: true })
  }

  _onClickCancelEditingNotes() {
    this.setState({
      isEditingNotes: false,
      notes: this.props.doctoralCandidate.notes
    })
  }

  _onClickSaveNotes() {
    updateNotes.push(this.state.notes)
    updateNotesResponse.first().onValue(() => this.setState({ isEditingNotes: false }))
  }
}

const IsSavingFeedback = ({ isSaving }) => {
  const { t } = useTranslation()
  if (isSaving) {
    return (
      <span style={{ fontSize: '14px', verticalAlign: '3px' }}>
        <span className="progress-indicator--small" style={{ verticalAlign: '-2px' }} /> {t(k.SAVING)}
      </span>
    )
  }
  return null
}

export default withTranslation()(NotesCard)
