import { useTranslation } from 'react-i18next'
import k from '../../../i18n/keys'
import React from 'react'
import PropTypes from 'prop-types'
import highlightBulletYellow from '../../../../html/images/highlight-bullet-yellow.svg'
import VisuallyHidden from '../../common/VisuallyHidden'
import { UniParagraph } from '../../common/uni/UniParagraph'

const Table = (props) => {
  const { t } = useTranslation()
  if (props.rows.length === 0) {
    return (
      <div className="cumulative-report__no-content">
        <UniParagraph>
          <em>{props.noContentMessage || t(k.NOTHING_REPORTED_YET)}</em>
        </UniParagraph>
      </div>
    )
  }

  return (
    <ul className="cumulative-report__list" aria-label={props.label} role="table">
      <li className="cumulative-report__list-row" role="row">
        <div
          className="cumulative-report__list-heading cumulative-report__list-heading--highlight-bullet"
          role="columnheader"
        >
          <VisuallyHidden>{t(k.STATUS_IN_THE_MOST_RECENT)}</VisuallyHidden>
        </div>
        {props.headings.map(({ content, classModifier }, index) => (
          <TableHeading key={index} content={content} classModifier={classModifier} />
        ))}
      </li>
      {props.rows.map((row, index) => (
        <TableRow key={index} highlight={row.highlight} inProgress={row.inProgress} cells={row.cells} />
      ))}
    </ul>
  )
}

Table.propTypes = {
  headings: PropTypes.arrayOf(PropTypes.object).isRequired,
  rows: PropTypes.arrayOf(PropTypes.object).isRequired,
  noContentMessage: PropTypes.string,
  label: PropTypes.string
}

export default Table

export const TableHeading = ({ content, classModifier }) => (
  <div
    className={`cumulative-report__list-heading cumulative-report__list-heading--${classModifier}`}
    role="columnheader"
  >
    {content}
  </div>
)

TableHeading.propTypes = {
  content: PropTypes.string.isRequired,
  classModifier: PropTypes.string
}

TableHeading.defaultProps = {
  classModifier: ''
}

export const TableRow = ({ highlight = false, inProgress = false, cells }) => {
  return (
    <li className="cumulative-report__list-row" role="row">
      <div className="cumulative-report__list-content cumulative-report__list-content--highlight-bullet">
        {highlight && <HighlightBullet />}
        {inProgress && <InProgressBullet />}
      </div>
      {cells.map((cell, index) => (
        <div
          className={`cumulative-report__list-content cumulative-report__list-content--${cell.classModifier}`}
          key={index}
          role="cell"
        >
          {cell.content}
        </div>
      ))}
    </li>
  )
}

const HighlightBullet = () => {
  const { t } = useTranslation()
  return (
    <div role="cell">
      <div
        className="cumulative-report__highlight-bullet glyphicon glyphicon-ok text-success"
        role="img"
        aria-label={`${t(k.COMPLETED_IN_THE_MOST_RECENT)} ${t(k.INDICATOR)}`}
      ></div>
    </div>
  )
}

const InProgressBullet = () => {
  const { t } = useTranslation()
  return (
    <div role="cell">
      <img
        src={highlightBulletYellow}
        className="cumulative-report__highlight-bullet"
        alt={`${t(k.IN_PROGRESS_IN_THE_MOST_RECE)} ${t(k.INDICATOR)}`}
      />
    </div>
  )
}

TableRow.propTypes = {
  cells: PropTypes.arrayOf(PropTypes.object).isRequired,
  highlight: PropTypes.bool,
  inProgress: PropTypes.bool
}
