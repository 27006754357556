import { useTranslation } from 'react-i18next'
import k from '../../i18n/keys'
import React, { useContext } from 'react'
import MarkdownIt from 'markdown-it'
import styled from 'styled-components'
import { UniParagraphStyles } from '../common/uni/UniParagraph'
import { UniBanner } from '../common/uni/UniBanner'
import { WindowWidthContext } from '../../WindowWidthContext'
import { CardNoContentMessage } from '../common/Card'

const md = new MarkdownIt('default')

const DoctoralCandidateOverviewNotes = (props) => {
  const { t } = useTranslation()
  const { isPhoneOrSmaller } = useContext(WindowWidthContext)
  const notesOrEmptyString = props.notes || ''
  const notes = notesOrEmptyString.split(/\n/)
  const splittedNotesWithContent = notes.filter((note) => note.trim().length > 0)
  if (splittedNotesWithContent.length > 0) {
    return (
      <div id="doctoral-candidate-overview__notes" className="doctoral-candidate-overview__section">
        <UniBanner white border={false} paddingInline={isPhoneOrSmaller} paddingBlock={isPhoneOrSmaller}>
          <NotesContainer
            className="doctoral-candidate-overview__notes-container"
            dangerouslySetInnerHTML={{ __html: md.render(props.notes) }}
          />
        </UniBanner>
      </div>
    )
  }

  return (
    <div id="doctoral-candidate-overview__notes" className="thessa-list__no-content">
      <CardNoContentMessage text={t(k.NO_NOTES)} />
    </div>
  )
}

export default DoctoralCandidateOverviewNotes

const NotesContainer = styled.div`
  ${UniParagraphStyles}
`
