export default class Publication {
  constructor({ id, title, inThesis, statuses }) {
    this._id = id
    this._title = title
    this._inThesis = inThesis
    this._statuses = statuses
  }

  get id() {
    return this._id
  }

  get title() {
    return this._title
  }

  get inThesis() {
    return this._inThesis
  }

  get statuses() {
    return this._statuses
  }

  updateFromResponse({ id, title, inThesis, statuses }) {
    this._id = id
    this._title = title
    this._inThesis = inThesis
    this._statuses = statuses
  }
}
