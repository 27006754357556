import React from 'react'
import { withTranslation } from 'react-i18next'
import assign from 'lodash/assign'
import filter from 'lodash/filter'
import map from 'lodash/map'
import sortBy from 'lodash/sortBy'
import moment from 'moment'
import { inject, observer } from 'mobx-react'
import k from '../../i18n/keys'
import ThessaList from '../common/ThessaList'
import { ITEM_TYPE } from '../highlight/constants'
import VisuallyHidden from '../common/VisuallyHidden'

@inject('HighlightStore')
@observer
class ThesisInstructorList extends React.Component {
  render() {
    const { HighlightStore, doctoralCandidateId, white, t } = this.props
    const doctoralCandidateHighlights = HighlightStore.getDoctoralCandidateHighlights(doctoralCandidateId)
    const columns = {
      title: t(k.STUDENT),
      thesisLevel: t(k.THESIS_LEVEL),
      dates: t(k.DATES1)
    }
    const thesisInstructions = filter(this.props.teachings, (teaching) => teaching.isThesisInstruction())
    const sortedThesisInstructions = sortBy(thesisInstructions, 'beginsAt')
    const thesisInstructionsForRendering = map(sortedThesisInstructions, (thesisInstruction) => {
      const beginsAtDateTimeFormatted = moment(thesisInstruction.beginsAt, 'YYYY-MM-DD').format('YYYY-MM-DD')
      const beginsAtFormatted = moment(thesisInstruction.beginsAt, 'YYYY-MM-DD').format('MMM D YYYY')
      const endsAtDateTimeFormatted = moment(thesisInstruction.endsAt, 'YYYY-MM-DD').format('YYYY-MM-DD')
      const endsAtFormatted = moment(thesisInstruction.endsAt, 'YYYY-MM-DD').format('MMM D YYYY')
      const teachingForRendering = {
        id: thesisInstruction.id,
        title: thesisInstruction.studentName,
        thesisLevel: thesisInstruction.thesisLevel,
        dates: (
          <>
            <time dateTime={beginsAtDateTimeFormatted}>{beginsAtFormatted}</time>
            {'—'}
            <time dateTime={endsAtDateTimeFormatted}>{endsAtFormatted}</time>
          </>
        ),
        newOrChanged:
          doctoralCandidateHighlights && doctoralCandidateHighlights[ITEM_TYPE.TEACHING][thesisInstruction.id]
      }

      if (this.props.linkTarget) {
        assign(teachingForRendering, {
          linkTarget: `${this.props.linkTarget}${thesisInstruction.id}`
        })
      }

      return teachingForRendering
    })
    return (
      <ThessaList
        id="doctoral-candidate-overview__thesis-instructions"
        label="Thesis instructions"
        columns={columns}
        rows={thesisInstructionsForRendering}
        noContentMessage={this.props.noContentMessage}
        white={white}
        defaultSortByID="dates"
        defaultSortByDesc={true}
      />
    )
  }
}

export default withTranslation()(ThesisInstructorList)
