import { useTranslation } from 'react-i18next'
import k from '../../i18n/keys'
import React from 'react'
import { withRouter } from 'react-router'
import { createSalary } from '../../actions/PhDProjectActions'
import { createSalaryResponse } from '../../stores/PhDProjectStore'
import CardActionButton from '../doctoral-candidate-overview/common/CardActionButton'

const CreateSalaryButton = ({ router }) => {
  const { t } = useTranslation()
  return <CardActionButton onClick={onClickCreateSalaryButtonFunction(router)}>{t(k.ADD_SALARY)}</CardActionButton>
}

export const onClickCreateSalaryButtonFunction = (router) => () => {
  createSalaryResponse.first().onValue((salary) => {
    const path = `/phd-project/salaries/${salary.id}?isNew=true`
    router.push(path)
  })
  createSalary.push()
}

export default withRouter(CreateSalaryButton)
