import React, { PropsWithChildren } from 'react'
import styled, { css } from 'styled-components'
import { GRAYSCALE_DARK_TEXT, GRAYSCALE_MEDIUM_TEXT } from './colors'
import { DEFAULT_FONT } from './typography'
import VisuallyHidden from '../VisuallyHidden'
import { useTranslation } from 'react-i18next'
import k from '../../../i18n/keys'

interface UniLabelProps extends React.LabelHTMLAttributes<HTMLLabelElement> {
  id?: string
  htmlFor?: string
  visuallyHideText?: boolean
  small?: boolean
  visuallyHideHelperText?: boolean
  hideHelperText?: boolean
  required?: boolean
  as?: 'label' | 'p'
}

export const UniLabel = ({
  id,
  htmlFor,
  visuallyHideText,
  small,
  visuallyHideHelperText,
  hideHelperText,
  required = false,
  as = 'label',
  children,
  ...rest
}: PropsWithChildren<UniLabelProps>) => {
  return (
    <Label id={id} htmlFor={htmlFor} visuallyHideText={visuallyHideText} as={as} small={small} {...rest}>
      {visuallyHideText ? (
        <VisuallyHidden>
          {children}
          {required ? (
            <RequiredText visuallyHideText={visuallyHideHelperText} hideHelperText={hideHelperText} />
          ) : (
            <OptionalText visuallyHideText={visuallyHideHelperText} hideHelperText={hideHelperText} />
          )}
        </VisuallyHidden>
      ) : (
        <>
          {children}
          {required ? (
            <RequiredText visuallyHideText={visuallyHideHelperText} hideHelperText={hideHelperText} />
          ) : (
            <OptionalText visuallyHideText={visuallyHideHelperText} hideHelperText={hideHelperText} />
          )}
        </>
      )}
    </Label>
  )
}

const Label = styled.label<{ visuallyHideText?: boolean; small?: boolean }>`
  color: ${GRAYSCALE_DARK_TEXT};
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  margin: 0;
  ${DEFAULT_FONT}

  ${(props) =>
    props.visuallyHideText &&
    css`
      display: inline;
    `}

  ${(props) =>
    props.small &&
    css`
      font-size: 14px;
    `}
`

export const RequiredText = ({ visuallyHideText = false, hideHelperText = false }) => {
  const { t } = useTranslation()

  if (hideHelperText) {
    return null
  }

  if (visuallyHideText) {
    return (
      <VisuallyHidden>
        {' ('}
        {t(k.FORM.REQUIRED_FIELD)}
        {')'}
      </VisuallyHidden>
    )
  }
  return (
    <HelperText>
      {' ('}
      {t(k.FORM.REQUIRED_FIELD)}
      {')'}
    </HelperText>
  )
}

export const OptionalText = ({ visuallyHideText = true, hideHelperText = false }) => {
  const { t } = useTranslation()

  if (hideHelperText) {
    return null
  }

  if (visuallyHideText) {
    return (
      <VisuallyHidden>
        {' ('}
        {t(k.FORM.OPTIONAL_FIELD)}
        {')'}
      </VisuallyHidden>
    )
  }
  return (
    <HelperText>
      {' ('}
      {t(k.FORM.OPTIONAL_FIELD)}
      {')'}
    </HelperText>
  )
}

export const HelperText = styled.span`
  ${DEFAULT_FONT}
  color: ${GRAYSCALE_MEDIUM_TEXT};
  text-transform: uppercase;
  font-size: 13px;
  margin-left: 4px;
`
