import InternationalActivity from '../../models/InternationalActivity'

export enum ConferenceOrMobilityType {
  CONFERENCE = 'conference',
  RESEARCH_VISIT = 'research-visit',
  OTHER = 'other'
}

export enum ConferenceRole {
  ORGANIZER = 'organizer',
  PARTICIPANT = 'participant'
}

export enum ParticipationType {
  POSTER = 'poster',
  TALK = 'talk',
  NO_PRESENTATION = 'participation_without_presentation'
}

export enum ConferenceParticipationType {
  PARTICIPANT_POSTER = 'participant-poster',
  PARTICIPANT_TALK = 'participant-talk',
  PARTICIPANT_WITHOUT_PRESENTATION = 'participant-no-presentation',
  ORGANISER = 'organiser'
}

export function getConferenceType(internationalActivity?: InternationalActivity) {
  switch (internationalActivity?.type) {
    case 'conference':
      return ConferenceOrMobilityType.CONFERENCE
    case 'research-visit':
      return ConferenceOrMobilityType.RESEARCH_VISIT
    case 'other':
      return ConferenceOrMobilityType.OTHER
    default:
      return null
  }
}

export function getConferenceParticipation(internationalActivity?: InternationalActivity) {
  if (internationalActivity?.conferenceRole === 'organizer') {
    return ConferenceParticipationType.ORGANISER
  } else if (internationalActivity?.conferenceRole === 'participant') {
    switch (internationalActivity?.participationType) {
      case 'talk':
        return ConferenceParticipationType.PARTICIPANT_TALK
      case 'poster':
        return ConferenceParticipationType.PARTICIPANT_POSTER
      case 'participation_without_presentation':
        return ConferenceParticipationType.PARTICIPANT_WITHOUT_PRESENTATION
    }
  }
  return null
}

export function getConferenceRoleAndParticipationType(conferenceParticipation: ConferenceParticipationType): {
  conferenceRole: ConferenceRole
  participationType: ParticipationType
} {
  switch (conferenceParticipation) {
    case ConferenceParticipationType.ORGANISER:
      return { conferenceRole: ConferenceRole.ORGANIZER, participationType: null }
    case ConferenceParticipationType.PARTICIPANT_TALK:
      return { conferenceRole: ConferenceRole.PARTICIPANT, participationType: ParticipationType.TALK }
    case ConferenceParticipationType.PARTICIPANT_POSTER:
      return { conferenceRole: ConferenceRole.PARTICIPANT, participationType: ParticipationType.POSTER }
    case ConferenceParticipationType.PARTICIPANT_WITHOUT_PRESENTATION:
      return { conferenceRole: ConferenceRole.PARTICIPANT, participationType: ParticipationType.NO_PRESENTATION }
  }
  return { conferenceRole: null, participationType: null }
}
