import React from 'react'
import { useTranslation } from 'react-i18next'
import k from '../../../i18n/keys'
import FreeTextRows from './FreeTextRows'

const OtherWork = ({ reports }) => {
  const { t } = useTranslation()
  return <FreeTextRows heading={t(k.OTHER_WORK)} reports={reports} reportFieldName="otherWork" />
}
export default OtherWork
