import React, { useMemo, Fragment, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import k from '../../../i18n/keys'
import styled from 'styled-components'
import { toDisplayFormat, toGraduationMonthFormat } from '../../../dateConstants'
import Tooltip, { TooltipFocusableControl, TooltipSmallMessageContainer } from '../../common/Tooltip'
import { WithRole } from '../../phd-project/WithRole'
import Report from '../../../models/Report'
import DoctoralCandidate from '../../../models/DoctoralCandidate'
import { ReportStatus } from '../../report/types'
import { usePhdProject } from '../phd-project-context/PhdProjectContextProvider'
import { UniButton } from '../../common/uni/UniButton'
import { Link } from 'react-router'
import UniTag from '../../common/uni/UniTag'
import { GRAYSCALE_DARK } from '../../common/uni/colors'
import CellDefault from '../../common/table/CellDefault'
import Table from '../../common/table/Table'
import { WindowWidthContext } from '../../../WindowWidthContext'

interface ReportListProps {
  reports: Report[]
  doctoralCandidate: DoctoralCandidate
}

export const ReportList = ({ reports, doctoralCandidate }: ReportListProps) => {
  const { t } = useTranslation()
  const phdProject = usePhdProject()
  const sessionStorageId = `report-table`
  const { isPhoneOrSmaller, isTabletOrSmaller, isDesktopOrLarger } = useContext(WindowWidthContext)

  const columns = useMemo(
    () => [
      {
        Header: t(k.PERIOD),
        id: 'reportingPeriod',
        accessor: 'reportingPeriod',
        Cell: function DefaultCell({ row }: any) {
          const { original } = row
          const { reportingPeriodStart, reportingPeriodEnd } = original
          return (
            <CellDefault alignCenter={!isPhoneOrSmaller}>
              <>
                {toDisplayFormat(reportingPeriodStart)}
                {` – `}
                {toDisplayFormat(reportingPeriodEnd)}
              </>
            </CellDefault>
          )
        },
        maxWidth: 100
      },
      {
        Header: function CreditsWorkHoursCell() {
          return <CellDefault justifyEnd={!isPhoneOrSmaller}>{t(k.PROGRESS)}</CellDefault>
        },
        id: 'phdCompletionPercentage',
        accessor: 'phdCompletionPercentage',
        Cell: function DefaultCell({ row }: any) {
          const { original } = row
          const { phdCompletionPercentage } = original
          return (
            <CellDefault
              alignCenter={!isPhoneOrSmaller}
              justifyEnd={!isPhoneOrSmaller}
              className="report-list__progress"
            >
              {phdCompletionPercentage}
              {'%'}
            </CellDefault>
          )
        },
        maxWidth: 100
      },
      ...(isDesktopOrLarger || isPhoneOrSmaller
        ? [
            {
              Header: t(k.PUBLICATIONS),
              id: 'publications',
              accessor: 'publications',
              Cell: function DefaultCell({ row }: any) {
                const { original } = row
                const { summary } = original
                const { publications } = summary
                const { other, published } = publications
                return (
                  <CellDefault alignCenter={!isPhoneOrSmaller}>
                    <Tooltip content={<PublicationsTooltip other={other} published={published} />}>
                      <TooltipFocusableControl>
                        {other} {`+`} {published}
                        <TooltipInfoSign className="glyphicon glyphicon-question-sign" role="img" aria-hidden="true" />
                      </TooltipFocusableControl>
                    </Tooltip>
                  </CellDefault>
                )
              },
              maxWidth: 100
            }
          ]
        : []),
      ...(isDesktopOrLarger || isPhoneOrSmaller
        ? [
            {
              Header: function CreditsWorkHoursCell() {
                return <CellDefault justifyEnd={!isPhoneOrSmaller}>{t(k.COURSES)}</CellDefault>
              },
              id: 'courses',
              accessor: 'courses',
              Cell: function DefaultCell({ row }: any) {
                const { original } = row
                const { summary } = original
                const { courses } = summary
                return (
                  <CellDefault justifyEnd alignCenter>
                    {courses}
                    {'cr'}
                  </CellDefault>
                )
              },
              maxWidth: 90
            }
          ]
        : []),
      ...(isDesktopOrLarger
        ? [
            {
              Header: t(k.GRADUATION),
              id: 'graduationMonth',
              accessor: 'graduationMonth',
              Cell: function DefaultCell({ row }: any) {
                const { original } = row
                const { graduationMonth } = original
                return (
                  <CellDefault alignCenter={!isPhoneOrSmaller}>{toGraduationMonthFormat(graduationMonth)}</CellDefault>
                )
              },
              maxWidth: 100
            }
          ]
        : []),
      {
        Header: t(k.CONCLUSION),
        id: 'conclusion',
        accessor: 'conclusion',
        Cell: function DefaultCell({ row }: any) {
          const { original } = row
          return (
            <CellDefault alignCenter={!isPhoneOrSmaller}>
              <ConclusionStatus report={original} />
            </CellDefault>
          )
        },
        maxWidth: 100
      },
      {
        Header: function ActionsCell() {
          return (
            <CellDefault
              alignCenter={!isPhoneOrSmaller}
              justifyEnd={!isPhoneOrSmaller}
              visuallyHideText={!isPhoneOrSmaller}
            >
              {t(k.ACTIONS)}
            </CellDefault>
          )
        },
        id: 'actions',
        accessor: 'actions',
        Cell: function DefaultCell({ row }: any) {
          const { original } = row
          const { id, status, conclusionFeedback } = original

          return (
            <CellDefault alignCenter={!isPhoneOrSmaller} justifyEnd={!isPhoneOrSmaller} gap>
              {status === ReportStatus.Draft && (
                <Tooltip content="This report has not been submitted">
                  <TooltipFocusableControl>
                    <UniTag
                      variant="warning"
                      iconAfter={<span className="glyphicon glyphicon-question-sign" role="img" aria-hidden="true" />}
                    >
                      {t(k.DRAFT)}
                    </UniTag>
                  </TooltipFocusableControl>
                </Tooltip>
              )}
              {status === ReportStatus.Final && doctoralCandidate ? (
                <>
                  <WithRole mainSupervisorForDoctoralCandidate={doctoralCandidate}>
                    {typeof conclusionFeedback?.isProceeding === 'boolean' ? (
                      <div id="successfulConclusionFeedbackSubmission">
                        <UniTag
                          iconBefore={
                            <OkIcon className="glyphicon glyphicon-ok" role="img" aria-hidden="true"></OkIcon>
                          }
                        >
                          {t(k.PROGRESS_FEEDBACK_SUBMITTED)}
                        </UniTag>
                      </div>
                    ) : (
                      <UniButton
                        small
                        noWrap={isDesktopOrLarger}
                        as={Link}
                        to={`/doctoral-candidates/${phdProject.doctoralCandidateId}/reports/${id}/conclusion-feedback`}
                      >
                        {t(k.GIVE_PROGRESS_FEEDBACK)}
                      </UniButton>
                    )}
                  </WithRole>
                </>
              ) : null}
              {status !== ReportStatus.Final ? (
                <>
                  <WithRole doctoralCandidate>
                    <UniButton small as={Link} to={`/phd-project/reports/${id}`}>
                      {t(k.OPEN)}
                    </UniButton>
                  </WithRole>
                  <WithRole supervisor thesisCommitteeMember>
                    <UniButton
                      small
                      as={Link}
                      to={`/doctoral-candidates/${phdProject.doctoralCandidateId}/review-report`}
                    >
                      {t(k.OPEN)}
                    </UniButton>
                  </WithRole>
                </>
              ) : null}
            </CellDefault>
          )
        },
        maxWidth: 300
      }
    ],
    [isPhoneOrSmaller, isTabletOrSmaller]
  )

  return (
    <Table
      className="report-list"
      id={sessionStorageId}
      columns={columns}
      data={reports}
      truncatedCells={false}
      defaultSortByID={'reportingPeriod'}
      sorting
      edgePadding
      white
    />
  )
}

interface PublicationsTooltipProps {
  other: number
  published: number
}

const PublicationsTooltip = ({ other, published }: PublicationsTooltipProps) => {
  const { t } = useTranslation()
  return (
    <>
      {t(k.COUNT_PUBLICATION_IN_PROGRESS, { count: other })}
      <br />
      {t(k.COUNT_PUBLICATION_PUBLISHED, { count: published })}
    </>
  )
}

interface ConclusionStatusProps {
  report: Report
}

const ConclusionStatus = ({ report }: ConclusionStatusProps) => {
  const { t } = useTranslation()
  switch (report.status) {
    case ReportStatus.Draft:
      return <>{t(k.N_A)}</>
    case ReportStatus.Submitted:
      return (
        <>
          <WithRole doctoralCandidate>
            <Tooltip content={<SubmittedReportConclusionStatus />}>
              <TooltipFocusableControl>
                <UniTag
                  variant="error"
                  iconAfter={<span className="glyphicon glyphicon-question-sign" role="img" aria-hidden="true" />}
                >
                  {t(k.MISSING)}
                </UniTag>
              </TooltipFocusableControl>
            </Tooltip>
          </WithRole>
          <WithRole notDoctoralCandidate>{t(k.MISSING)}</WithRole>
        </>
      )

    case ReportStatus.Final:
      return <>{t(k.DONE1)}</>
  }
}

const SubmittedReportConclusionStatus = () => {
  const { t } = useTranslation()
  return <TooltipSmallMessageContainer>{t(k.HAVE_A_THESIS_COMMITTEE_MEETIN)}</TooltipSmallMessageContainer>
}

const TooltipInfoSign = styled.span`
  font-size: 12px;
  margin-left: 6px;
  color: ${GRAYSCALE_DARK};
`

const OkIcon = styled.span`
  color: green;
`
