import React from 'react'
import './PageSubHeading.scss'
import { UniHeading3 } from '../uni/UniHeading'

const PageSubHeading = ({ children }) => (
  <div className="thessa-page-sub-heading">
    <UniHeading3>{children}</UniHeading3>
  </div>
)

export default PageSubHeading
