import React from 'react'
import styled, { css } from 'styled-components'
import { fromTabletUp } from '../../styledUtils'
import { ADDITIONAL_RED_DARK, GRAYSCALE_WHITE } from './colors'
import { UniBanner } from './UniBanner'
import { UniParagraph } from './UniParagraph'
import { useTranslation } from 'react-i18next'
import k from '../../../i18n/keys'
import VisuallyHidden from '../VisuallyHidden'

interface UniFormProperties extends React.HTMLAttributes<HTMLFormElement> {
  id?: string
  instructions?: string | React.ReactNode
  noGap?: boolean
  itemsToStart?: boolean
  itemsToEnd?: boolean
  statusMessage?: string | React.ReactNode
  closeStatusMessage?: () => void
  children: React.ReactNode
}

export const UniForm = ({
  id,
  instructions,
  noGap,
  itemsToStart,
  itemsToEnd,
  statusMessage,
  closeStatusMessage,
  children,
  className,
  ...props
}: UniFormProperties) => {
  const { t } = useTranslation()
  return (
    <FormWrapper id={id} className={className}>
      {typeof instructions === 'string' ? (
        <Instructions>{instructions}</Instructions>
      ) : instructions ? (
        instructions
      ) : null}
      <Form noGap={noGap} itemsToStart={itemsToStart} itemsToEnd={itemsToEnd} {...props}>
        {children}
      </Form>
      {statusMessage && (
        <UniBanner variant="error" small close={closeStatusMessage}>
          <UniParagraph noMargin small>
            <VisuallyHidden>{t(k.SUBMITTING_FORM_FAILED)}</VisuallyHidden>
            {statusMessage}
          </UniParagraph>
        </UniBanner>
      )}
    </FormWrapper>
  )
}

const FormWrapper = styled.div`
  display: flex;
  align-items: stretch;
  flex-flow: column nowrap;
  gap: 24px;
`

const Form = styled.form<{ noGap?: boolean; itemsToStart?: boolean; itemsToEnd?: boolean }>`
  display: flex;
  align-items: stretch;
  flex-flow: column nowrap;
  gap: 12px;

  ${(props) =>
    props.noGap &&
    css`
      gap: 0;
    `}

  ${(props) =>
    props.itemsToStart &&
    css`
      align-items: flex-start;
    `}

  ${(props) =>
    props.itemsToEnd &&
    css`
      align-items: flex-end;
    `}
`

export const Instructions = ({
  marginTop,
  marginBottom,
  children
}: {
  marginTop?: boolean
  marginBottom?: boolean
  children: React.ReactNode
}) => (
  <UniBanner small marginTop={marginTop} marginBottom={marginBottom} variant="info">
    <UniParagraph noMargin small>
      {children}
    </UniParagraph>
  </UniBanner>
)

export const Actions = styled.div<{
  fullWidth?: boolean
  white?: boolean
  firstToStart?: boolean
  lastToEnd?: boolean
  alignItems?: 'top' | 'stretch' | 'center'
  stickyTop?: boolean
  stickyBottom?: boolean
  grid?: boolean
  noWrap?: boolean
  noPadTop?: boolean
  marginTop?: boolean
  marginBottom?: boolean
}>`
  display: flex;
  flex-direction: column;
  padding-top: 12px;
  gap: 12px;
  margin-top: ${(props) => (props.marginTop ? '12px' : '0')};
  margin-bottom: ${(props) => (props.marginBottom ? '12px' : '0')};

  ${fromTabletUp(css`
    align-items: center;
    flex-flow: row wrap;
    gap: 16px;
  `)}

  ${(props) =>
    props.alignItems &&
    props.alignItems === 'top' &&
    css`
      ${fromTabletUp(css`
        align-items: flex-start;
      `)}
    `}

  ${(props) =>
    props.alignItems &&
    props.alignItems === 'stretch' &&
    css`
      ${fromTabletUp(css`
        align-items: stretch;
      `)}
    `}
    
  ${(props) =>
    props.fullWidth &&
    css`
      width: 100%;
    `}

  ${(props) =>
    props.white &&
    css`
      background-color: ${GRAYSCALE_WHITE};
    `}

  ${(props) =>
    props.stickyTop &&
    css`
      position: sticky;
      top: 0;
    `}

  ${(props) =>
    props.stickyBottom &&
    css`
      position: sticky;
      bottom: 0;
    `}

  ${(props) =>
    props.firstToStart &&
    css`
      & > *:first-child {
        margin-right: auto;
      }
    `}

  ${(props) =>
    props.lastToEnd &&
    css`
      & > *:last-child {
        margin-left: auto;
      }
    `}

    ${(props) =>
    props.grid &&
    css`
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
      grid-auto-rows: min-content;
      gap: 16px;
    `}

  ${(props) =>
    props.noWrap &&
    css`
      flex-flow: row nowrap;
      align-items: center;

      ${fromTabletUp(css`
        flex-flow: row nowrap;
      `)}
    `}

  ${(props) =>
    props.noPadTop &&
    css`
      padding-top: 0;
    `}
`

export const ActionErrorFeedback = styled.div.attrs({ role: 'alert' })`
  align-items: center;
  color: ${ADDITIONAL_RED_DARK};
  display: flex;
  gap: 6px;
`
