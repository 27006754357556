import { Bus } from 'baconjs'

export const loadDoctoralCandidates = new Bus()
export const loadDoctoralCandidate = new Bus()
export const reloadPublications = new Bus()
export const reloadCourses = new Bus()
export const reloadConferences = new Bus()
export const reloadInternationalActivities = new Bus()
export const reloadSalaries = new Bus()
export const reloadGrants = new Bus()
export const reloadTeachings = new Bus()
