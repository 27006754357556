import { css, keyframes, FlattenSimpleInterpolation } from 'styled-components'

export const LARGE_DESKTOP_MIN = 1200
export const DESKTOP_MIN = 992
export const TABLET_MAX = DESKTOP_MIN - 1
export const TABLET_MIN = 768
export const PHONE_MAX = TABLET_MIN - 1
export const PHONE_MIN = 480
export const SMALL_PHONE_MAX = PHONE_MIN - 1

export const commonResponsiveProps = css<{
  hideOnSmallPhonesAndSmaller?: boolean
  hideOnPhonesAndSmaller?: boolean
  hideOnTabletsAndSmaller?: boolean
}>`
  ${(props) => props.hideOnSmallPhonesAndSmaller && hideOnSmallPhonesAndSmaller}
  ${(props) => props.hideOnPhonesAndSmaller && hideOnPhonesAndSmaller}
  ${(props) => props.hideOnTabletsAndSmaller && hideOnTabletsAndSmaller}
`

const hideOnSmallPhonesAndSmaller = maxSmallPhoneQuery(
  css`
    display: none;
  `
)

const hideOnPhonesAndSmaller = maxPhoneQuery(
  css`
    display: none;
  `
)

const hideOnTabletsAndSmaller = maxTabletQuery(
  css`
    display: none;
  `
)

export function maxSmallPhoneQuery(content: FlattenSimpleInterpolation) {
  return maxQuery(SMALL_PHONE_MAX, content)
}

export function maxPhoneQuery(content: FlattenSimpleInterpolation) {
  return maxQuery(PHONE_MAX, content)
}

export function maxTabletQuery(content: FlattenSimpleInterpolation) {
  return maxQuery(TABLET_MAX, content)
}

function maxQuery(maxWidth: number, content: FlattenSimpleInterpolation) {
  return css`
    @media screen and (max-width: ${maxWidth}px) {
      ${content}
    }
  `
}

export function minSmallPhoneQuery(content: FlattenSimpleInterpolation) {
  return minQuery(0, content)
}

export function fromPhoneUp(content: FlattenSimpleInterpolation) {
  return minQuery(PHONE_MIN, content)
}

export function fromTabletUp(content: FlattenSimpleInterpolation) {
  return minQuery(TABLET_MIN, content)
}

export function fromDesktopUp(content: FlattenSimpleInterpolation) {
  return minQuery(DESKTOP_MIN, content)
}

export function fromLargeDesktopUp(content: FlattenSimpleInterpolation) {
  return minQuery(LARGE_DESKTOP_MIN, content)
}

function minQuery(minWidth: number, content: FlattenSimpleInterpolation) {
  return css`
    @media screen and (min-width: ${minWidth}px) {
      ${content}
    }
  `
}

export function wasUpdatedNow(updated: boolean) {
  const animationKeyframes = keyframes`
    0% {
      box-shadow: inset 0 0 0 5px #fff;
    }
    
    5% {
      box-shadow: inset 0 0 0 5px #e9a500;
    }
    
    50% {
      box-shadow: inset 0 0 0 5px #e9a500;
    }
    
    100% {
      box-shadow: inset 0 0 0 5px #fff;
    }
  `

  if (updated) {
    return css`
      animation: ${animationKeyframes} linear 6s;
    `
  }

  return css``
}
