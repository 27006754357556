export default class Teaching {
  constructor({
    id,
    type,
    myRole,
    courseName,
    beginsAt,
    endsAt,
    workHours,
    studentName,
    thesisLevel,
    thesisTopicOrTitle
  }) {
    this.id = id
    this.type = type
    this.beginsAt = beginsAt
    this.endsAt = endsAt
    this.myRole = myRole
    this.courseName = courseName
    this.workHours = workHours
    this.studentName = studentName
    this.thesisLevel = thesisLevel
    this.thesisTopicOrTitle = thesisTopicOrTitle
  }

  isCourseOrWorkshop() {
    return this.type === 'course_or_workshop'
  }

  isThesisInstruction() {
    return this.type === 'thesis_instructor'
  }

  updateFromResponse({
    id,
    type,
    myRole,
    courseName,
    beginsAt,
    endsAt,
    workHours,
    studentName,
    thesisLevel,
    thesisTopicOrTitle
  }) {
    this.id = id
    this.type = type
    this.beginsAt = beginsAt
    this.endsAt = endsAt
    this.myRole = myRole
    this.courseName = courseName
    this.workHours = workHours
    this.studentName = studentName
    this.thesisLevel = thesisLevel
    this.thesisTopicOrTitle = thesisTopicOrTitle
  }
}
