import React, { ButtonHTMLAttributes, forwardRef, PropsWithChildren } from 'react'
import styled, { css } from 'styled-components'
import { HyIcon } from '../HyIcon'
import LoadingIndicator from '../LoadingIndicator'
import VisuallyHidden from '../VisuallyHidden'
import {
  ADDITIONAL_SKYBLUE,
  ADDITIONAL_YELLOW,
  BRAND_MAIN,
  BRAND_MAIN_ACTIVE,
  BRAND_MAIN_DARK,
  GRAYSCALE_DARK,
  GRAYSCALE_MEDIUM,
  GRAYSCALE_MEDIUM_DARK,
  GRAYSCALE_WHITE,
  GRAYSCALE_BLACK
} from './colors'
import { DEFAULT_FONT } from './typography'
import { UniParagraphAnchorStyles } from './UniParagraph'
import { Link } from 'react-router'

export interface UniButtonProps {
  as?: React.ElementType | Link
  to?: string
  icon?: 'left' | 'right' | 'plus' | 'edit' | 'remove'
  iconBefore?: React.ReactNode
  iconAfter?: React.ReactNode
  loading?: boolean
  secondary?: boolean
  tertiary?: boolean
  small?: boolean
  minimal?: boolean
  fullWidth?: boolean
  noWrap?: boolean
  visuallyHideText?: boolean
  className?: string
}

export const UniButton = forwardRef<
  HTMLButtonElement,
  PropsWithChildren<UniButtonProps & ButtonHTMLAttributes<HTMLButtonElement>>
>(
  (
    { icon, iconBefore, iconAfter, small, minimal, loading, visuallyHideText, children, className, ...restProps },
    ref
  ) => (
    <Button
      icon={icon}
      iconBefore={iconBefore}
      iconAfter={iconAfter}
      small={small}
      minimal={minimal}
      visuallyHideText={visuallyHideText}
      {...restProps}
      ref={ref}
      className={`uni-button ${className}`}
    >
      {(icon === 'left' || icon === 'plus' || icon === 'edit' || icon === 'remove' || iconBefore) && (
        <IconContainer
          icon={icon}
          iconBefore={iconBefore}
          iconAfter={iconAfter}
          small={small}
          minimal={minimal}
          visuallyHideText={visuallyHideText}
        >
          {icon === 'left' && (
            <Icon
              white={!restProps.secondary && !restProps.tertiary}
              blue={restProps.secondary}
              black={restProps.tertiary}
              icon={icon}
              arrowLeft
              aria-hidden={true}
            />
          )}
          {icon === 'plus' && (
            <Icon
              white={!restProps.secondary && !restProps.tertiary}
              blue={restProps.secondary}
              black={restProps.tertiary}
              icon={icon}
              plus
              aria-hidden={true}
            />
          )}
          {icon === 'edit' && (
            <Icon
              white={!restProps.secondary && !restProps.tertiary}
              blue={restProps.secondary}
              black={restProps.tertiary}
              icon={icon}
              edit
              aria-hidden={true}
            />
          )}
          {icon === 'remove' && (
            <Icon
              white={!restProps.secondary && !restProps.tertiary}
              blue={restProps.secondary}
              black={restProps.tertiary}
              icon={icon}
              remove
              aria-hidden={true}
            />
          )}
          {iconBefore}
        </IconContainer>
      )}
      {loading ? (
        <Text secondary={restProps.secondary} tertiary={restProps.tertiary} minimal={minimal}>
          <Loading className="login-progress-indicator" />
          <VisuallyHidden>{children}</VisuallyHidden>
        </Text>
      ) : visuallyHideText ? (
        <VisuallyHidden>{children}</VisuallyHidden>
      ) : (
        <Text secondary={restProps.secondary} tertiary={restProps.tertiary} minimal={minimal}>
          {children}
        </Text>
      )}

      {(icon === 'right' || iconAfter) && (
        <IconContainer
          icon={icon}
          iconBefore={iconBefore}
          iconAfter={iconAfter}
          small={small}
          minimal={minimal}
          visuallyHideText={visuallyHideText}
        >
          {icon === 'right' && (
            <Icon
              white={!restProps.secondary && !restProps.tertiary}
              blue={restProps.secondary}
              icon={icon}
              arrowRight
              aria-hidden={true}
            />
          )}
          {iconAfter}
        </IconContainer>
      )}
    </Button>
  )
)

UniButton.displayName = 'UniButton'

export const ButtonStyles = css`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  background: ${BRAND_MAIN};
  border: 2px solid ${BRAND_MAIN};
  color: ${GRAYSCALE_WHITE};
  cursor: pointer;
  font-weight: 400;
  font-size: ${(props) => (props.small && !props.minimal ? '14px' : '15px')};
  line-height: 15px;
  min-height: ${(props) => (props.small ? '0' : '44px')};
  padding: ${(props) => (props.small ? '6px 8px' : '12px 24px')};
  transition-duration: 0.1s;
  transition-property: all;
  user-select: none;

  ${({ secondary, small }) =>
    secondary &&
    css`
      background: ${GRAYSCALE_WHITE};
      border: 2px solid ${BRAND_MAIN};
      color: ${GRAYSCALE_DARK};
      padding: ${small ? '6px 8px' : '12px 24px'};
    `}

  ${({ tertiary, small }) =>
    tertiary &&
    css`
      background: ${GRAYSCALE_WHITE};
      border: 2px solid ${GRAYSCALE_BLACK};
      color: ${GRAYSCALE_BLACK};
      padding: ${small ? '0px 8px' : '12px 24px'};
    `}

  ${(props) =>
    props.minimal &&
    (props.secondary || props.tertiary) &&
    css`
      background-color: transparent;
      border-color: transparent;
    `}

  ${(props) =>
    props.fullWidth &&
    css`
      width: 100%;
    `}

    ${(props) =>
    props.noWrap &&
    css`
      white-space: nowrap;
    `}

  ${(props) =>
    (props.icon === 'left' ||
      props.icon === 'plus' ||
      props.icon === 'edit' ||
      props.icon === 'remove' ||
      props.iconBefore) &&
    css`
      padding-left: ${(props: UniButtonProps) => (props.small ? '10px' : '12px')};
      padding-right: ${(props: UniButtonProps) => (props.small ? '14px' : '24px')};
    `}
  
    ${(props) =>
    (props.icon === 'right' || props.iconAfter) &&
    css`
      padding-left: ${(props: UniButtonProps) => (props.small ? '14px' : '24px')};
      padding-right: ${(props: UniButtonProps) => (props.small ? '10px' : '12px')};
    `}
  

  ${(props) =>
    props.visuallyHideText &&
    css`
      padding: ${(props: UniButtonProps) => (props.small ? '8px' : '12px')};
    `}

  ${DEFAULT_FONT}
  &:disabled {
    cursor: not-allowed;

    ${(props) =>
      props.secondary
        ? css`
            background-color: ${GRAYSCALE_WHITE};
            border-color: ${GRAYSCALE_MEDIUM_DARK};
            color: ${GRAYSCALE_DARK};
          `
        : css`
            background-color: ${GRAYSCALE_MEDIUM};
            border-color: ${GRAYSCALE_MEDIUM};
            color: ${GRAYSCALE_DARK};
          `}
    &::after {
      background-color: ${GRAYSCALE_MEDIUM_DARK};
    }
  }

  &::after {
    content: '';
    display: block;
    background-color: ${ADDITIONAL_SKYBLUE};
    position: absolute;
    top: calc(100% + 2px);
    height: 2px;
    left: 3px;
    right: 3px;
    z-index: 0;
    transform-origin: top center;
    transition: all 0.1s ease-in-out;

    ${(props) =>
      (props.minimal || props.tertiary) &&
      css`
        background-color: transparent;
      `}
  }

  &:not(:disabled) {
    &:hover,
    &:focus {
      background: ${BRAND_MAIN_ACTIVE};
      border-color: ${BRAND_MAIN_ACTIVE};
      color: ${GRAYSCALE_WHITE};
      text-decoration: none;

      ${({ secondary }) =>
        secondary &&
        css`
          background: ${GRAYSCALE_WHITE};
          border-color: ${BRAND_MAIN_ACTIVE};
          color: ${BRAND_MAIN_ACTIVE};
        `}

      ${({ tertiary }) =>
        tertiary &&
        css`
          background: ${GRAYSCALE_BLACK};
          border-color: ${GRAYSCALE_BLACK};
          color: ${GRAYSCALE_WHITE};
        `}

      &:after {
        left: 1px;
        right: 1px;
        transform: ${(props: UniButtonProps) => (props.small ? 'scaleY(1.5)' : 'scaleY(2)')};
      }

      ${(props) =>
        props.minimal &&
        (props.secondary || props.tertiary) &&
        css`
          background: transparent;
        `}

      ${(props) =>
        props.minimal &&
        props.tertiary &&
        css`
          border-color: transparent;
        `}
    }

    &:active {
      background: ${(props) => (props.secondary ? GRAYSCALE_WHITE : BRAND_MAIN_DARK)};
      border-color: ${BRAND_MAIN_DARK};
      color: ${(props) => (props.secondary ? BRAND_MAIN_DARK : GRAYSCALE_WHITE)};

      ${(props) =>
        props.minimal &&
        (props.secondary || props.tertiary) &&
        css`
          background: transparent;
        `}
    }

    &:focus {
      outline: solid 2px ${ADDITIONAL_YELLOW};
      outline-offset: 2px;
    }
  }
`

const Button = styled.button<UniButtonProps>`
  ${ButtonStyles}
`

const Loading = styled(LoadingIndicator)`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
`

const Text = styled.span<{ secondary?: boolean; tertiary?: boolean; minimal?: boolean }>`
  flex: 1 1 100%;

  ${({ tertiary, minimal }) =>
    tertiary &&
    minimal &&
    css`
      ${UniParagraphAnchorStyles}
      color: ${GRAYSCALE_BLACK};
    `}
`

const IconContainer = styled.div<UniButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${(props) => (props.small ? '0.85em' : '1em')};

  ${(props) =>
    (props.icon === 'left' ||
      props.icon === 'plus' ||
      props.icon === 'edit' ||
      props.icon === 'remove' ||
      props.iconBefore) &&
    css`
      margin-inline-end: ${(props: UniButtonProps) => (props.visuallyHideText ? 0 : props.small ? '10px' : '12px')};
      padding-inline-end: ${(props: UniButtonProps) =>
        props.visuallyHideText || props.minimal ? 0 : props.small ? '10px' : '12px'};
      border-inline-end: ${(props: UniButtonProps) =>
        props.visuallyHideText || props.minimal ? 'none' : `1px solid ${ADDITIONAL_SKYBLUE}`};
    `}

  ${(props) =>
    (props.icon === 'right' || props.iconAfter) &&
    css`
      margin-inline-start: ${(props: UniButtonProps) => (props.visuallyHideText ? 0 : props.small ? '10px' : '12px')};
      padding-inline-start: ${(props: UniButtonProps) =>
        props.visuallyHideText || props.minimal ? 0 : props.small ? '10px' : '12px'};
      border-inline-start: ${(props: UniButtonProps) =>
        props.visuallyHideText || props.minimal ? 'none' : `1px solid ${ADDITIONAL_SKYBLUE}`};
    `}


  *:disabled & {
    border-color: ${GRAYSCALE_MEDIUM_DARK};
  }
`

const Icon = styled(HyIcon)<UniButtonProps>`
  font-size: 1em;
  transform: translate(0, -3%);

  *:disabled & {
    color: ${GRAYSCALE_MEDIUM_DARK};
  }
`
