export default class Conference {
  constructor({ id, name, beginsAt, endsAt, type, participationType, description }) {
    this._id = id
    this._name = name
    this._beginsAt = beginsAt
    this._endsAt = endsAt
    this._type = type
    this._participationType = participationType
    this._description = description
  }

  get id() {
    return this._id
  }

  get name() {
    return this._name
  }

  get beginsAt() {
    return this._beginsAt
  }

  get endsAt() {
    return this._endsAt
  }

  get type() {
    return this._type
  }

  get participationType() {
    return this._participationType
  }

  get description() {
    return this._description
  }

  updateFromResponse({ id, name, beginsAt, endsAt, type, participationType, description }) {
    this._id = id
    this._name = name
    this._beginsAt = beginsAt
    this._endsAt = endsAt
    this._type = type
    this._participationType = participationType
    this._description = description
  }
}
