import React, { useEffect, useState } from 'react'
import { DESKTOP_MIN, LARGE_DESKTOP_MIN, PHONE_MAX, TABLET_MIN, TABLET_MAX } from './components/styledUtils'

type WindowWidth = {
  isDesktopOrLarger: boolean
  isTabletOrSmaller: boolean
  isPhoneOrSmaller: boolean
  bootstrapContainerInnerWidth: number
}

export const WindowWidthContext = React.createContext<WindowWidth>({
  isDesktopOrLarger: false,
  isTabletOrSmaller: false,
  isPhoneOrSmaller: false,
  bootstrapContainerInnerWidth: 0
})

export const WindowWidthContextProvider: React.FC = ({ children }) => {
  const [windowWidth, setWindowWidth] = useState<WindowWidth>()

  const onResize = () => {
    window.innerWidth
    setWindowWidth({
      isDesktopOrLarger: window.innerWidth >= DESKTOP_MIN,
      isTabletOrSmaller: window.innerWidth <= TABLET_MAX,
      isPhoneOrSmaller: window.innerWidth <= PHONE_MAX,
      bootstrapContainerInnerWidth: getBootstrapContainerInnerWidth(window.innerWidth)
    })
  }

  useEffect(() => {
    onResize()
    window.addEventListener('resize', onResize)
    return () => window.removeEventListener('resize', onResize)
  }, [])

  return <WindowWidthContext.Provider value={windowWidth}>{children}</WindowWidthContext.Provider>
}

const horizontalContainerPadding = 30
function getBootstrapContainerInnerWidth(windowWidth: number) {
  if (windowWidth < TABLET_MIN) {
    return windowWidth - horizontalContainerPadding
  } else if (windowWidth < DESKTOP_MIN) {
    return 750 - horizontalContainerPadding
  } else if (windowWidth < LARGE_DESKTOP_MIN) {
    return 970 - horizontalContainerPadding
  } else {
    return 1170 - horizontalContainerPadding
  }
}
