import { Model } from 'bacon.model'
import Bacon from 'baconjs'
import first from 'lodash/first'
import includes from 'lodash/includes'
import isUndefined from 'lodash/isUndefined'
import { browserHistory } from 'react-router'
import { action, computed, observable } from 'mobx'
import { ajaxDelete, ajaxGet, ajaxPostJSON } from '../AjaxUtil'
import {
  loadAuthStatus,
  logInAction,
  logOutAction,
  requestPasswordResetEmail,
  resetPassword
} from '../actions/AuthenticationActions'
import { signedUp } from '../actions/SignUpActions'
import { saveProfileResponse } from './ProfileStore'
import k from '../i18n/keys'
import { Role } from './role'

export const IS_LOADING = 1
export const IS_LOGGED_IN = 2
export const IS_NOT_LOGGED_IN = 3
export const IS_NOT_REGISTERED = 4
export const IS_CLOSED = 6
export const ERROR_CODE_CSRF_TOKEN_MISMATCH = 8
export const ERROR_CODE_EMAIL_ALREADY_IN_USE = 9
export const ERROR_CODE_NO_ROLE = 10

export const formatRole = (role, t) => {
  const TRANSLATED_ROLES = t(k.ROLE_NAME, { returnObjects: true })
  return TRANSLATED_ROLES[role]
}

export const allRoles = [Role.DOCTORAL_CANDIDATE, Role.SUPERVISOR, Role.THESIS_COMMITTEE_MEMBER, Role.COORDINATOR]

const initialAuthStatusResponse = ajaxGet('/rest/auth')
export const logInResponse = logInAction.flatMap((credentials) => ajaxPostJSON('/rest/auth', credentials))
export const logOutResponse = logOutAction.flatMap(() => ajaxDelete('/rest/auth'))
logOutResponse.onValue(() => browserHistory.push('/'))

const authResponseToAuthStatus = (user) => {
  if (typeof user === 'undefined') return IS_NOT_LOGGED_IN
  if (user.isSignUpRequired) return IS_NOT_REGISTERED
  if (user.closedAt !== null) return IS_CLOSED
  else return IS_LOGGED_IN
}

const loadAuthStatusResponse = loadAuthStatus.flatMap(() => ajaxGet('/rest/auth'))

export const authenticatedUser = new Bacon.Model()
const authStatusResponses = initialAuthStatusResponse
  .merge(logInResponse)
  .merge(logOutResponse)
  .merge(signedUp)
  .merge(saveProfileResponse)
  .merge(loadAuthStatusResponse)
authenticatedUser.addSource(authStatusResponses.map(formatAuthenticatedUser))

export const authStatus = authenticatedUser.map(authResponseToAuthStatus)

export const logInFailed = authenticatedUser.errors()

function formatAuthenticatedUser(user) {
  if (user === undefined) {
    return user
  } else if (user.isSignUpRequired) {
    return { isSignUpRequired: user.isSignUpRequired }
  } else {
    const firstDoctoralCandidate = first(user.doctoral_candidates) // Only take the first because we don't yet support duplicate roles in the front end.
    const firstCoordinator = first(user.coordinators) // Only take the first because we don't yet support duplicate roles in the front end.
    const firstThesisCommitteeMember = first(user.thesis_committee_members) // Only take the first because we don't yet support duplicate roles in the front end.

    const formattedUser = {
      id: user.id,
      firstName: user.first_name,
      lastName: user.last_name,
      email: user.email,
      username: user.hy_username,
      closedAt: user.closed_at,
      isSupervisor: user.is_supervisor,
      isForeman: user.is_foreman
    }

    if (firstDoctoralCandidate) {
      formattedUser.doctoralCandidate = {
        id: firstDoctoralCandidate.id,
        startedAtDate: firstDoctoralCandidate.started_at_date,
        studentNumber: firstDoctoralCandidate.student_number
      }
    }

    if (firstThesisCommitteeMember) {
      formattedUser.thesisCommitteeMember = {
        id: firstThesisCommitteeMember.id
      }
      formattedUser.isThesisCommitteeMember = true
    } else {
      formattedUser.isThesisCommitteeMember = false
    }
    if (firstCoordinator) {
      formattedUser.coordinator = {
        id: firstCoordinator.id
      }
    }

    return formattedUser
  }
}

export function getUserId() {
  return authenticatedUser.get().id
}

export function getDoctoralCandidateId() {
  const doctoralCandidate = authenticatedUser.get().doctoralCandidate
  if (!isUndefined(doctoralCandidate)) {
    return doctoralCandidate.id
  }
  throw new Error('Logged in user is not a doctoral candidate')
}

export function getSupervisorId() {
  const supervisor = authenticatedUser.get().supervisor
  if (!isUndefined(supervisor)) {
    return supervisor.id
  }
  throw new Error('Logged in user is not a supervisor')
}

export function getCoordinatorId() {
  const coordinator = authenticatedUser.get().coordinator
  if (!isUndefined(coordinator)) {
    return coordinator.id
  }
  throw new Error('Logged in user is not a coordinator')
}

export function getThesisCommitteeMemberId() {
  const thesisCommitteeMember = authenticatedUser.get().thesisCommitteeMember
  if (!isUndefined(thesisCommitteeMember)) {
    return thesisCommitteeMember.id
  }
  throw new Error('Logged in user is not a thesis committee member')
}

export const requestPasswordResetEmailResponse = requestPasswordResetEmail.flatMap((email) =>
  ajaxPostJSON('/rest/auth/password-reset', { email })
)

export const resetPasswordResponse = resetPassword.flatMap(({ token, email, password }) =>
  ajaxPostJSON('/rest/auth/password', {
    token,
    email,
    password,
    password_confirmation: password
  })
)

const isCsrfTokenMismatchError = ({ status, responseJSON }) =>
  status === 403 && responseJSON.errorCode === ERROR_CODE_CSRF_TOKEN_MISMATCH

logInResponse.errors().onError((errorResponse) => {
  if (isCsrfTokenMismatchError(errorResponse)) {
    handleCsrfTokenMismatchError()
  }
})

function handleCsrfTokenMismatchError() {
  const shouldRefreshBrowser = confirm('Your session has been inactive for too long. Reload page to renew session?')
  if (shouldRefreshBrowser) {
    window.location.reload()
  }
}

export const maintenanceModeResponses = new Bacon.Bus()
export const maintenanceModeP = maintenanceModeResponses.map(true).toProperty()

const roles = new Model([])
roles.addSource(
  authenticatedUser.map((user) => {
    if (!user) {
      return []
    }

    const updatedRoles = []
    if (user.doctoralCandidate) {
      updatedRoles.push(Role.DOCTORAL_CANDIDATE)
    }
    if (user.isSupervisor) {
      updatedRoles.push(Role.SUPERVISOR)
    }
    if (user.isThesisCommitteeMember) {
      updatedRoles.push(Role.THESIS_COMMITTEE_MEMBER)
    }
    if (user.coordinator) {
      updatedRoles.push(Role.COORDINATOR)
    }
    if (user.isForeman) {
      updatedRoles.push(Role.SALARY_SYSTEM_FOREMAN)
    }
    return updatedRoles
  })
)

export const hasRole = (role) => includes(roles.get(), role)

export const hasAnyRole = () => {
  const isDoctoralCandidate = hasRole(Role.DOCTORAL_CANDIDATE)
  const isSupervisor = hasRole(Role.SUPERVISOR)
  const isCoordinator = hasRole(Role.COORDINATOR)
  const isThesisCommitteeMember = hasRole(Role.THESIS_COMMITTEE_MEMBER)
  const isForeman = hasRole(Role.SALARY_SYSTEM_FOREMAN)
  return isDoctoralCandidate || isSupervisor || isThesisCommitteeMember || isCoordinator || isForeman
}

class AuthenticationStore {
  @observable authenticatedUser

  constructor(authenticatedUser) {
    authenticatedUser.onValue(this.onChangeAuthenticatedUser)
  }

  @computed
  get isAuthenticated() {
    return !!this.authenticatedUser
  }

  @action
  onChangeAuthenticatedUser = (authenticatedUser) => {
    this.authenticatedUser = authenticatedUser
  }
}

export default AuthenticationStore
