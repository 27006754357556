import Publication from './Publication'
import Course from './Course'
import Conference from './Conference'
import Salary from './Salary'
import Grant from './Grant'
import Teaching from './Teaching'
import { ReportFields, ReportStatus } from '../components/report/types'

export interface ReportResponse {
  id: number
  createdAt: string
  submittedAt: null | string
  updatedAt: string
  status: ReportStatus
  isCompleted: boolean
  isSubmitted: boolean
  graduationMonth: string
  phdCompletionPercentage: number
  timeSpentOfYearPercentage: number
  publications: ReportPublicationResponse[]
  courses: ReportCourseResponse[]
  conferences: ReportConferenceResponse[]
  salaries: ReportSalaryResponse[]
  grants: ReportGrantResponse[]
  teachings: ReportTeachingResponse[]
  teachingExperience: string
  socialImpact: string
  progress: string
  careerPlanning: string
  otherWork: string
  summaryPositive: string
  summaryNegative: string
  reportingPeriodStart: string
  reportingPeriodEnd: string
  media: ReportMediaResponse[]
  internationalActivities: ReportInternationalActivityResponse[]
  conclusion: null | string
  conclusionFeedback: null | {
    conclusionComment: string
    feedbackBy: number
    isProceeding: boolean
  }
  summary: {
    courses: number
    publications: {
      published: number
      other: number
    }
  }
}

interface ReportPublicationResponse {
  id: number
  title: string
  statuses: Array<{
    id: number
    order: number
    status: 'data-collection' | 'manuscript' | 'peer-review' | 'published'
    statusStartedAt: string
  }>
  inThesis: boolean
}

interface ReportCourseResponse {
  id: number
  title: string
  credits: number
  isCompleted: boolean
  completedAt: string
}

interface ReportConferenceResponse {
  id: number
  name: string
  beginsAt: string
  endsAt: string
  type: 'participant' | 'organizer'
  participationType: 'poster' | 'talk' | 'participation_without_presentation'
  description: string
}

interface ReportSalaryResponse {
  id: number
  source: string
  beginsAt: string
  endsAt: string
}

interface ReportGrantResponse {
  id: number
  source: string
  amount: string
  beginsAtMonth: string
  endsAtMonth: string
  usedFor: string
  status: 'planning-to-apply' | 'application-sent' | 'response-positive' | 'response-negative'
}

interface ReportTeachingResponse {
  id: number
  type: 'course_or_workshop' | 'thesis_instructor'
  beginsAt: string
  endsAt: string
  myRole: string
  courseName: string
  workHours: number
  studentName: string
  thesisLevel: string
  thesisTopicOrTitle: string
}

interface ReportMediaResponse {
  id: number
  file_name: string
  humanReadableSize: string
}

interface ReportInternationalActivityResponse {
  id: number
  type: 'conference' | 'other' | 'research-visit'
  name: string
  location: string
  beginsAt: string
  endsAt: string
  conferenceRole: 'participant' | 'organizer'
  participationType: 'poster' | 'talk' | 'participation_without_presentation'
  description: string
}

interface ConclusionFeedback {
  conclusionComment: null | string
  feedbackBy: null | number
  isProceeding: null | boolean
}

interface ReportSummary {
  courses: number
  publications: {
    published: number
    other: number
  }
}

export default class Report {
  id: number
  createdAt: string
  submittedAt: string
  updatedAt: string
  status: ReportStatus
  isCompleted: boolean
  isSubmitted: boolean
  graduationMonth: string
  phdCompletionPercentage: number
  timeSpentOfYearPercentage: number
  internationalActivities: ReportInternationalActivityResponse[]
  teachingExperience: string
  socialImpact: string
  progress: string
  careerPlanning: string
  otherWork: string
  summaryPositive: string
  summaryNegative: string
  publications: Publication[]
  courses: Course[]
  reportingPeriodStart: string
  reportingPeriodEnd: string
  conferences: Conference[]
  salaries: Salary[]
  grants: Grant[]
  teachings: Teaching[]
  media: ReportMediaResponse[]
  conclusion: string
  conclusionFeedback: ConclusionFeedback
  summary: ReportSummary

  constructor(report: ReportResponse) {
    this.updateReportFields(report)
  }

  private updateReportFields(report: ReportResponse) {
    this.id = report.id
    this.createdAt = report.createdAt
    this.submittedAt = report.submittedAt
    this.updatedAt = report.updatedAt
    this.status = report.status
    this.isCompleted = report.isCompleted
    this.isSubmitted = report.isSubmitted
    this.graduationMonth = report.graduationMonth
    this.phdCompletionPercentage = report.phdCompletionPercentage
    this.timeSpentOfYearPercentage = report.timeSpentOfYearPercentage
    this.internationalActivities = report.internationalActivities
    this.teachingExperience = report.teachingExperience
    this.socialImpact = report.socialImpact
    this.progress = report.progress
    this.careerPlanning = report.careerPlanning
    this.otherWork = report.otherWork
    this.summaryPositive = report.summaryPositive
    this.summaryNegative = report.summaryNegative
    this.publications = report.publications.map((publication) => new Publication(publication))
    this.courses = report.courses.map((course) => new Course(course))
    this.reportingPeriodStart = report.reportingPeriodStart
    this.reportingPeriodEnd = report.reportingPeriodEnd
    this.conferences = report.conferences.map((conference) => new Conference(conference))
    this.salaries = report.salaries.map((salary) => new Salary(salary))
    this.grants = report.grants.map((grant) => new Grant(grant))
    this.teachings = report.teachings.map((teaching) => new Teaching(teaching))
    this.media = report.media
    this.conclusion = report.conclusion
    this.conclusionFeedback = report.conclusionFeedback
    this.summary = report.summary
  }

  get hasConclusion() {
    return this.conclusion && this.conclusion.length > 0
  }

  updateFromResponse(report: ReportResponse) {
    this.updateReportFields(report)
    return this
  }

  toReportRequest(): ReportFields {
    return {
      graduationMonth: this.graduationMonth,
      phdCompletionPercentage: this.phdCompletionPercentage,
      timeSpentOfYearPercentage: this.timeSpentOfYearPercentage,
      socialImpact: this.socialImpact,
      progress: this.progress,
      careerPlanning: this.careerPlanning,
      otherWork: this.otherWork,
      summaryPositive: this.summaryPositive,
      summaryNegative: this.summaryNegative
    }
  }
}
