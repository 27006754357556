import React from 'react'
import classNames from 'classnames'
import './PageHeading.scss'
import PageBlock from './PageBlock'
import PageNavigation from './PageNavigation'
import { UniHeading1 } from '../uni/UniHeading'
import { UniContainer } from '../uni/layout'
import VisuallyHidden from '../VisuallyHidden'

interface IPageHeading {
  parentHeading?: string
  parentLink?: string
  parentClassName?: string
  wide?: boolean
  sticky?: boolean
  visuallyHideText?: boolean
}

const PageHeading: React.FC<IPageHeading> = ({
  parentHeading,
  parentLink,
  parentClassName,
  children,
  wide = false,
  sticky = false,
  visuallyHideText = false
}) => {
  const classes = classNames('thessa-page-heading', { sticky: sticky })

  if (visuallyHideText) {
    return (
      <VisuallyHidden>
        <UniHeading1 small>{children}</UniHeading1>
      </VisuallyHidden>
    )
  } else {
    return (
      <div className={classes}>
        <PageBlock noGap wide>
          <UniContainer wide borderBottom paddingInline={false} paddingBottom paddingTop>
            <PageBlock noGap wide={wide} padInline={wide ? false : true}>
              {parentHeading && parentLink && (
                <>
                  <PageNavigation
                    items={[
                      {
                        path: parentLink,
                        title: parentHeading,
                        className: `thessa-page-heading__parent-heading ${parentClassName}`
                      }
                    ]}
                  />
                </>
              )}

              <UniHeading1 small>{children}</UniHeading1>
            </PageBlock>
          </UniContainer>
        </PageBlock>
      </div>
    )
  }
}

export default PageHeading
