import React, { Fragment, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import k from '../../../i18n/keys'
import { Cell } from '../../common/list/List'
import { StudentNumber } from '../StudentNumber'
import { NameAndRoles } from './NameAndRoles'
import { MyDoctoralCandidate } from '../MyDoctoralCandidates'
import { WindowWidthContext } from '../../../WindowWidthContext'
import styled from 'styled-components'

interface DoctoralCandidateWithoutAccountRowProperties {
  doctoralCandidate: MyDoctoralCandidate
}

export const DoctoralCandidateWithoutAccount = ({
  doctoralCandidate
}: DoctoralCandidateWithoutAccountRowProperties) => {
  const { t } = useTranslation()
  const { isDesktopOrLarger } = useContext(WindowWidthContext)
  return (
    <Fragment>
      <NameAndRoles
        name={doctoralCandidate.name}
        roles={doctoralCandidate.roles}
        hasActiveStudyRight={doctoralCandidate.hasActiveStudyRight}
        clickable={false}
      />
      {isDesktopOrLarger && (
        <Cell clickable={false}>
          <StudentNumber studentNumber={doctoralCandidate.studentNumber} />
        </Cell>
      )}
      {isDesktopOrLarger && <Cell clickable={false} />}
      <NotInThessaCells clickable={false}>{t(k.NOT_IN_THESSA)}</NotInThessaCells>
    </Fragment>
  )
}

const NotInThessaCells = styled(Cell)`
  align-items: center;
  color: #767676;
  display: flex;
  grid-column-end: span 2;
`
