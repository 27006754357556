import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { TopMenuBarButton } from './uni/TopMenuBar'
import { useLocalStorage } from '../../util'
import { Language } from './Language'
import k from '../../i18n/keys'
interface Properties {
  id?: string
  className?: string
}

export const LanguageMenu = ({ id, className }: Properties) => {
  const [currentLanguage, setCurrentLanguage] = useLocalStorage('currentLanguage', 'fi')
  const { t } = useTranslation()
  return (
    <Container id={id} className={className}>
      <nav aria-label={t(k.CHANGE_LANGUAGE)}>
        <List>
          <Item>
            <TopMenuBarButton
              active={currentLanguage === Language.FI}
              onClick={() => setCurrentLanguage(Language.FI)}
            >{`Suomi`}</TopMenuBarButton>
          </Item>
          <Item>
            <TopMenuBarButton
              active={currentLanguage === Language.SV}
              onClick={() => setCurrentLanguage(Language.SV)}
            >{`Svenska`}</TopMenuBarButton>
          </Item>
          <Item>
            <TopMenuBarButton
              active={currentLanguage === Language.EN}
              onClick={() => setCurrentLanguage(Language.EN)}
            >{`English`}</TopMenuBarButton>
          </Item>
        </List>
      </nav>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  gap: 15px;
`

const List = styled.ul`
  margin: 0;
  padding: 0;
  display: flex;
`

const Item = styled.li`
  list-style: none;
`
