import React, { ReactNode } from 'react'
import styled, { css } from 'styled-components'

interface ActionProps {
  buttons: ReactNode
  isValidatedForm?: boolean
}

export const Actions = ({ buttons, isValidatedForm = false }: ActionProps) => (
  <ActionsContainer isValidatedForm={isValidatedForm}>{buttons}</ActionsContainer>
)

const ActionsContainer = styled.div<{ isValidatedForm: boolean }>`
  display: flex;
  padding-top: 7px;
  padding-left: 24px;

  > * {
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }
  }

  ${(props) =>
    props.isValidatedForm &&
    css`
      padding-left: 22px;
    `}
`
