import React from 'react'

interface StudentNumberProperties {
  studentNumber: string
}

export const StudentNumber = ({ studentNumber }: StudentNumberProperties) => {
  return (
    <div>
      {studentNumber.slice(0, 3)}&nbsp;{studentNumber.slice(3, 6)}&nbsp;{studentNumber.slice(6)}
    </div>
  )
}
