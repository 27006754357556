import React from 'react'
import styled, { css } from 'styled-components'
import classNames from 'classnames'
import './PageBlock.scss'
import { fromTabletUp } from '../../styledUtils'
import { UniContainer } from '../uni/layout'

interface IPageBlock {
  id?: string
  gray?: boolean
  lightGray?: boolean
  padTop?: boolean
  padInline?: boolean
  padBottom?: boolean
  noGap?: boolean
  row?: boolean
  wide?: boolean
  column?: boolean
  fullHeight?: boolean
  lastToEnd?: boolean
  children?: React.ReactNode
}

const PageBlock: React.FC<IPageBlock> = ({
  id,
  gray = false,
  lightGray = false,
  padTop = false,
  padInline = true,
  padBottom = false,
  noGap = false,
  row,
  wide = false,
  column = true,
  fullHeight = false,
  lastToEnd = false,
  children
}) => {
  const classes = classNames('thessa-page-block', {
    'thessa-page-block--gray': gray,
    'thessa-page-block--light-gray': lightGray,
    'thessa-page-block--pad-top': padTop
  })
  return (
    <Block id={id} className={classes} fullHeight={fullHeight} padBottom={padBottom}>
      <BlockContent wide={wide} noGap={noGap} row={row} column={column} lastToEnd={lastToEnd} paddingInline={padInline}>
        {children}
      </BlockContent>
    </Block>
  )
}

const Block = styled.div<{ fullHeight?: boolean; padBottom?: boolean }>`
  width: 100%;
  flex: 1 1 100%;
  display: flex;
  flex-flow: column nowrap;

  ${(props) =>
    props.fullHeight
      ? css`
          flex: 1 1 100%;
          height: 100%;
        `
      : css`
          flex: 0 1 auto;
        `}

  ${(props) =>
    props.padBottom
      ? css``
      : css`
          padding-bottom: 0;
        `}
`

const BlockContent = styled(UniContainer)<{ row?: boolean; column?: boolean; lastToEnd?: boolean; noGap?: boolean }>`
  flex: 1 1 100%;

  ${(props) =>
    props.row
      ? css`
          display: flex;
          flex-flow: column nowrap;

          ${fromTabletUp(css`
            flex-flow: row nowrap;
            align-items: stretch;
          `)}
        `
      : props.column
      ? css`
          display: flex;
          flex-flow: column nowrap;
          gap: 12px;
        `
      : ''}

  ${(props) =>
    (props.row || props.column) && props.noGap
      ? css`
          gap: 0;
        `
      : ''}

  ${(props) =>
    props.lastToEnd &&
    css`
      & > *:last-child {
        margin-top: auto;
      }
    `}
`

export default PageBlock
