import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import k from '../../i18n/keys'
import { useLocalStorage } from './../../util'
import { UniSelectWithLabel } from './uni/UniSelectWithLabel'

const languageOptions = {
  fi: 'Suomi (fi)',
  sv: 'Svenska (sv)',
  en: 'English (en)'
}

interface Properties {
  id?: string
  className?: string
}

const LanguageSelect = ({ id, className }: Properties) => {
  const { t } = useTranslation()
  const [currentLanguage, setCurrentLanguage] = useLocalStorage('currentLanguage', 'fi')

  return (
    <Wrapper className={className}>
      <UniSelectWithLabel
        id={id}
        label={t(k.CHOOSE_LANGUAGE)}
        value={currentLanguage}
        onChange={(value) => setCurrentLanguage(value)}
        options={[
          { value: 'fi', label: languageOptions['fi'] },
          { value: 'sv', label: languageOptions['sv'] },
          { value: 'en', label: languageOptions['en'] }
        ]}
        small
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
`

export default LanguageSelect
