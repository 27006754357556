import React, { forwardRef, KeyboardEvent } from 'react'
import { UniLabel } from './UniLabel'
import styled, { css } from 'styled-components'
import { UniInput } from './UniInput'

export interface UniInputWithLabelProps {
  id: string
  label: string | JSX.Element
  value: string
  onChange: (value: string) => void
  required?: boolean
  autoFocus?: boolean
  type?: 'text' | 'email' | 'password' | 'number' | 'date' | 'month'
  isValid?: boolean
  validationHintAfter?: string | JSX.Element
  validationHintBefore?: string | JSX.Element
  validationHintInline?: string | JSX.Element
  hasInvalidHighlight?: boolean
  minLength?: number
  min?: number | string
  max?: number | string
  step?: string
  placeholder?: string
  small?: boolean
  fullWidth?: boolean
  flex?: boolean
  visuallyHideLabel?: boolean
  visuallyHideLabelHelperText?: boolean
  hideHelperText?: boolean
  className?: string
  onKeyDown?: (event: KeyboardEvent<HTMLInputElement>) => void
}

export const UniInputWithLabel = forwardRef<HTMLInputElement, UniInputWithLabelProps>(
  (
    {
      id,
      label,
      value,
      onChange,
      required,
      autoFocus,
      type,
      isValid,
      validationHintAfter,
      validationHintBefore,
      validationHintInline,
      hasInvalidHighlight,
      minLength,
      min,
      max,
      step,
      placeholder,
      small,
      fullWidth,
      flex,
      visuallyHideLabel,
      visuallyHideLabelHelperText,
      hideHelperText,
      className,
      onKeyDown
    },
    ref
  ) => (
    <Container fullWidth={fullWidth} flex={visuallyHideLabel ? false : flex} small={small}>
      <UniLabel
        required={required}
        htmlFor={id}
        visuallyHideText={visuallyHideLabel}
        visuallyHideHelperText={visuallyHideLabelHelperText}
        hideHelperText={hideHelperText}
        small={small}
      >
        {label}
      </UniLabel>
      <UniInput
        id={id}
        className={className}
        ref={ref}
        label={label}
        required={required}
        value={value}
        onChange={(event) => onChange(event.target.value)}
        autoFocus={autoFocus}
        type={type}
        isValid={isValid}
        validationHintAfter={validationHintAfter}
        validationHintBefore={validationHintBefore}
        validationHintInline={validationHintInline}
        hasInvalidHighlight={hasInvalidHighlight}
        minLength={minLength}
        min={min}
        max={max}
        step={step}
        placeholder={placeholder}
        small={small}
        fullWidth={fullWidth}
        onKeyDown={onKeyDown}
      />
    </Container>
  )
)

UniInputWithLabel.displayName = 'UniInputWithLabel'

const Container = styled.div<{ fullWidth?: boolean; flex?: boolean; small?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 12px;

  ${(props) =>
    props.fullWidth &&
    css`
      width: 100%;
    `}

  ${(props) =>
    props.flex &&
    css`
      flex: 1 1 auto;
    `}

  ${(props) =>
    props.small &&
    css`
      gap: 8px;
    `}
`
