import React, { Fragment } from 'react'
import { observer, useLocalStore } from 'mobx-react'
import Container from './Container'
import EditForm from './EditForm'
import Message, { MessageType } from '../common/Message'
import { createPreliminaryTitleOfDissertationStore } from './createPreliminaryTitleOfDissertationStore'

const PreliminaryTitleOfDissertation = observer(({ doctoralCandidate }) => {
  const store = useLocalStore(() => createPreliminaryTitleOfDissertationStore(doctoralCandidate))

  if (store.editing) {
    return (
      <Fragment>
        <EditForm
          onChange={store.onChange}
          onKeyPress={store.onKeyPress}
          onReset={store.onReset}
          onSave={store.onSave}
          title={store.tempTitle}
        />

        {store.error && <Message message={store.error} type={MessageType.DANGER} />}
      </Fragment>
    )
  }

  return <Container doctoralCandidate={doctoralCandidate} onEdit={store.onEdit} title={store.title} />
})

export default PreliminaryTitleOfDissertation
