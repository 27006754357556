import React from 'react'
import { useTranslation } from 'react-i18next'
import k from '../../../i18n/keys'
import FreeTextRows from './FreeTextRows'

const Conclusion = ({ reports }) => {
  const { t } = useTranslation()
  return <FreeTextRows heading={t(k.CONCLUSIONS)} reports={reports} reportFieldName="conclusion" />
}

export default Conclusion
