export default class Salary {
  constructor({ id, source, beginsAt, endsAt }) {
    this._id = id
    this._source = source
    this._beginsAt = beginsAt
    this._endsAt = endsAt
  }

  get id() {
    return this._id
  }

  get source() {
    return this._source
  }

  set source(value) {
    this._source = value
  }

  get beginsAt() {
    return this._beginsAt
  }

  set beginsAt(value) {
    this._beginsAt = value
  }

  get endsAt() {
    return this._endsAt
  }

  set endsAt(value) {
    this._endsAt = value
  }

  updateFromResponse({ id, source, beginsAt, endsAt }) {
    this._id = id
    this._source = source
    this._beginsAt = beginsAt
    this._endsAt = endsAt
  }

  getFieldsForUpdateRequest() {
    return {
      id: this.id,
      source: this.source,
      beginsAt: this.beginsAt,
      endsAt: this.endsAt
    }
  }
}
