import React from 'react'
import styled from 'styled-components'
import { MarkdownParagraphs } from '../../common/MarkdownParagraphs'
import { DEFAULT_FONT } from '../../common/uni/typography'

interface DescriptionProps {
  label: string
  text: string
  className: string
}

export const Description = ({ label, text, className }: DescriptionProps) => (
  <DescriptionContainer className={className}>
    <DescriptionHeading>{label}</DescriptionHeading>
    <DescriptionText className={`${className}__content`}>{text.trim() || 'N/A'}</DescriptionText>
  </DescriptionContainer>
)

const DescriptionContainer = styled.div`
  padding-left: 24px;
`

const DescriptionHeading = styled.div`
  font-weight: 600;
  padding-bottom: 2px;
  ${DEFAULT_FONT}
`

const DescriptionText = styled(MarkdownParagraphs)`
  ${DEFAULT_FONT}

  * {
    font-size: 14px;
    margin: 0 0 6px 0;

    &:last-child {
      margin-bottom: 0;
    }
  }
`
