import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { DEFAULT_FONT } from './uni/typography'
import { GRAYSCALE_DARK } from './uni/colors'

export const IconDetails = ({ rows = [], fontSize = 14, id }) => (
  <IconDetailsContainer fontSize={fontSize} id={id}>
    {rows.map((row) => (
      <IconRow key={row.text} icon={row.icon} text={row.text} hint={row.hint} fontSize={fontSize} />
    ))}
  </IconDetailsContainer>
)

IconDetails.propTypes = {
  rows: PropTypes.array.isRequired
}

const IconDetailsContainer = styled.div`
  display: grid;
  grid-auto-rows: max-content;
  grid-row-gap: ${(props) => props.fontSize * 0.5}px;
`

export const IconRow = ({ icon, text, hint, fontSize }) => (
  <IconRowContainer className="icon-details__row" fontSize={fontSize}>
    <IconRowIcon className={`glyphicon glyphicon-${icon}`} fontSize={fontSize} role="img" aria-hidden="true" />
    <IconRowText fontSize={fontSize}>
      {text}
      {hint && <IconRowHint fontSize={fontSize}>{hint}</IconRowHint>}
    </IconRowText>
  </IconRowContainer>
)

const IconRowContainer = styled.div`
  align-items: baseline;
  display: grid;
  grid-column-gap: 10px;
  grid-template-columns: ${(props) => props.fontSize}px 1fr;
  justify-items: start;
`

const IconRowIcon = styled.div`
  color: ${GRAYSCALE_DARK};
  font-size: ${(props) => props.fontSize}px;
  line-height: ${(props) => props.fontSize}px;
`

const IconRowText = styled.div`
  font-size: ${(props) => props.fontSize}px;
  line-height: ${(props) => props.fontSize * 1.4}px;
  ${DEFAULT_FONT}
`

const IconRowHint = styled.div`
  color: #767676;
  font-size: ${(props) => props.fontSize * 0.9}px;
  line-height: ${(props) => props.fontSize}px;
  padding-top: 3px;
`
