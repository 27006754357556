import { useTranslation } from 'react-i18next'
import k from '../../../i18n/keys'
import React from 'react'
import { toDisplayFormat } from '../../../dateConstants'
import { usePhdProject } from '../phd-project-context/PhdProjectContextProvider'
import { Field } from '../types'
import UniTag from '../../common/uni/UniTag'

export const Deadline = () => {
  const { t } = useTranslation()
  const phdProject = usePhdProject()
  const deadline = phdProject[Field.ReportDeadline]
  return (
    <UniTag large variant={deadline.isHighlighted ? 'error' : undefined}>
      <span>
        {t(k.NEXT_REPORT_DUE_ON)} <span className="next-report-deadline">{toDisplayFormat(deadline.date)}</span>
      </span>
    </UniTag>
  )
}
