import React from 'react'

export default function ({ children }) {
  const columnWidth = 12 / children.length
  const columnClassName = `col-md-${columnWidth}`
  return (
    <div className="row">
      {children.map((child, index) => {
        return (
          <div className={columnClassName} key={index}>
            {child}
          </div>
        )
      })}
    </div>
  )
}
