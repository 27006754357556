import BaseApi from '../../BaseApi'

class PreliminaryTitleOfDissertationApi extends BaseApi {
  updatePreliminaryTitleOfDissertation(doctoralCandidateId, preliminaryTitleOfDissertation) {
    return this.put(`/rest/doctoral-candidates/${doctoralCandidateId}/preliminary-title-of-dissertation`, {
      preliminaryTitleOfDissertation
    })
  }
}

export default PreliminaryTitleOfDissertationApi
