import React from 'react'
import assign from 'lodash/assign'
import sortBy from 'lodash/sortBy'
import moment from 'moment'
import { inject, observer } from 'mobx-react'
import ThessaList from '../common/ThessaList'
import { ITEM_TYPE } from '../highlight/constants'

@inject('HighlightStore')
@observer
class InternationalActivityList extends React.Component {
  render() {
    const { HighlightStore, doctoralCandidateId } = this.props
    const doctoralCandidateHighlights = HighlightStore.getDoctoralCandidateHighlights(doctoralCandidateId)
    const columns = {
      title: 'Name',
      location: 'Location',
      dates: 'Dates'
    }
    const sortedConferences = sortBy(this.props.internationalActivities, 'beginsAt')
    const conferencesForRendering = sortedConferences.map(({ id, name, location, beginsAt, endsAt }) => {
      const beginsAtFormatted = moment(beginsAt, 'YYYY-MM-DD').format('MMM D YYYY')
      const endsAtFormatted = moment(endsAt, 'YYYY-MM-DD').format('MMM D YYYY')
      const conferenceForRendering = {
        id,
        title: name,
        location: location,
        dates: `${beginsAtFormatted}—${endsAtFormatted}`,
        newOrChanged: doctoralCandidateHighlights && doctoralCandidateHighlights[ITEM_TYPE.INTERNATIONAL_ACTIVITY][id]
      }

      if (this.props.linkTarget) {
        assign(conferenceForRendering, {
          linkTarget: `${this.props.linkTarget}${id}`
        })
      }

      return conferenceForRendering
    })

    return (
      <ThessaList
        id="doctoral-candidate-overview__international-activities"
        label="Conferences and mobility"
        columns={columns}
        rows={conferencesForRendering}
        noContentMessage={this.props.noContentMessage}
      />
    )
  }
}

export default InternationalActivityList
