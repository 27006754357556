import React, { PropsWithChildren } from 'react'
import styled from 'styled-components'
import { GRAYSCALE_DARK_TEXT } from './colors'
import { DEFAULT_FONT } from './typography'

interface UniLegendProps {
  id?: string
  htmlFor?: string
  required?: boolean
}

export const UniLegend = ({ children }: PropsWithChildren<UniLegendProps>) => {
  return <Legend>{children}</Legend>
}

const Legend = styled.legend`
  color: ${GRAYSCALE_DARK_TEXT};
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  margin: 0;
  border-bottom: 0;
  ${DEFAULT_FONT}
`
